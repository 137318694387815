import React, { useState, useEffect } from 'react';
import { Col, ButtonToolbar, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import HeartIcon from 'mdi-react/HeartIcon';
import StarIcon from 'mdi-react/StarIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import { Link, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import ProductGallery from './ProductGallery';
import ProductTabs from './ProductTabs';
import { apiCallBegan } from '~/store/api';
import { readProductApi, getThumbnailsApi } from '~/apiCalls/marketplaceApi';
import { getProductInfo, marketplaceError, getThumbnails } from '~/store/marketplace';
import { addedToCart } from '~/store/cart';


const ProductCard = () => {
  const [color, setColor] = useState('white');
  const { product_id } = useParams();
  const dispatch = useDispatch();
  const { currentProductInfo, thumbnails, cart } = useSelector(state => state.marketplace);

  const addtoCart = () => {
    dispatch(addedToCart(product_id));
  };

  useEffect(() => {
    console.log(Cookies.get('cart'));
  }, [cart]);

  useEffect(() => {
    if (product_id) {
      dispatch(apiCallBegan({
        axiosCall: readProductApi,
        data: product_id,
        onSuccess: getProductInfo.type,
        onError: marketplaceError.type,
      }));
      dispatch(apiCallBegan({
        axiosCall: getThumbnailsApi,
        data: product_id,
        onSuccess: getThumbnails.type,
        onError: marketplaceError.type,
      }));
    }
  }, [product_id]);

  const onLike = () => {
    if (color === 'white') {
      setColor('#70bbfd');
    } else {
      setColor('white');
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="product-card">
            {thumbnails.length > 0 && (<ProductGallery images={thumbnails?.map((pt) => ({ src: `${process.env.REACT_APP_API_URL}assets/${pt.product_thumbnail_id}?fit=cover&width=438&height=438` }))} />)}
            <div className="product-card__info">
              <h3 className="product-card__title">{currentProductInfo.title}</h3>
              <div className="product-card__rate">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarOutlineIcon />
                <a className="product-card__link" href="/easydev/e-commerce/product_page">See all reviews</a>
              </div>
              <h1 className="product-card__price">${currentProductInfo.price} <span className="product-card__old-price">${currentProductInfo.price}</span></h1>
              <p className="typography-message">
                {currentProductInfo.description}
              </p>
              <form className="form product-card__form">
                <ButtonToolbar className="product-card__btn-toolbar">
                  <Link className="btn btn-primary" to="/cart" onClick={addtoCart}>Add to cart</Link>
                  <button
                    className="product-card__wish-btn"
                    type="button"
                    onClick={onLike}
                  >
                    <HeartIcon color={color} />Add to wishlist
                  </button>
                </ButtonToolbar>
              </form>
              <ProductTabs />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProductCard;

/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import readableMimeType from './readable-mime-type/index.ts';
import { addTokenToURL } from '~/apiCalls/authApi';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';

const FilePreview = ({
  mime,
  width = undefined,
  height = undefined,
  src,
  title,
  isThumbnail = false,
  isProfile = false,
  onClick = undefined,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { innerWidth } = window;
  const [imageUrl, setImageUrl] = useState("");
  const classForPreview = classNames({
    "profileContentImg": true,
    "thumbnail": isThumbnail,
  });

  useEffect(() => {
    const fetchImageUrl = async () => {
      const urlWithToken = await addTokenToURL(`${process.env.REACT_APP_API_URL}${src}`);
      setImageUrl(urlWithToken);
    };

    fetchImageUrl();
  }, [src]);


  const authenticatedSrc = () => addTokenToURL(process.env.REACT_APP_API_URL + src);
  const type = () => {
    if (mime === null) return 'unknown';
    if (mime.startsWith('image') || mime.startsWith('Image')) {
      return 'image';
    }
    if (mime.startsWith('video') || mime.startsWith('Video')) {
      return 'video';
    }
    if (mime.startsWith('audio')) {
      return 'audio';
    }
    return readableMimeType(mime, true) ?? 'unknown';
  };
  const loadedEvent = async (e) => {
    setIsLoading(false);
  };

  const imageOnErrorHandler = (event) => {
    event.currentTarget.src = `${process.env.REACT_APP_FRONTEND_URL}img/noimage.svg`;
  };

  const imageSrc = isThumbnail ? `${imageUrl}&width=335&height=250` : imageUrl;

  const renderPreview = () => {
    if (type() === "image") {
      return (
        <>
          {!isThumbnail && isLoading && (
            <LoadingLogo />
          )}
          {isThumbnail && isLoading && (
            <Skeleton
              height={innerWidth > 768 ? 250 : 120}
              className="profileContentImg"
            />
          )}
          <img
            className={classForPreview}
            key={src}
            width={width}
            height={height}
            src={imageSrc}
            alt={title}
            onLoad={loadedEvent}
            onError={imageOnErrorHandler}
            style={{ display: isLoading ? "none" : "block" }}
          />
        </>
      );
    }
    if (type() === "video") {
      return (
        <div className="video-container">
          <video
            className={classForPreview}
            width={width}
            height={height}
            src={imageUrl}
            controls={!isThumbnail}
          />
          {isThumbnail && <div className="videojs-thumbnail-cover" />}
        </div>
      );
    }

    if (type() === "audio") {
      return (
        <>
          {
            isThumbnail
              ? (
                <div className="gallery__img-wrap">
                  <img onLoad={loadedEvent} className="profileContentImg" src="/img/audio-file.svg" alt="Download file" />
                </div>
              )
              : (
                <div className="audio-container profileContentImg">
                  <audio
                    className="gallery__audio"
                    width={width}
                    height={height}
                    src={imageUrl}
                    controls
                  />
                </div>
              )
          }
        </>
      );
    }
    return (
      <div className="gallery__img-wrap">
        <img onLoad={loadedEvent} className="profileContentImg" src="/img/download-file.svg" alt="Download file" />
      </div>
    );
  };

  if (isThumbnail) {
    if (type() !== "video" && type() !== "image" && type() !== "audio") {
      return (
        <div
          className="preview-container"
          style={{ display: "flex" }}
        >
          {renderPreview()}
        </div>
      );
    }
    return (
      <>
        {type() && (
          <div
            className="preview-container"
            style={{ display: "flex" }}
          >
            <button
              key={`index_${title}`}
              type="button"
              className="gallery__img-wrap"
              onClick={onClick}
            >
              {renderPreview()}
            </button>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {type() && (
        <>
          <div
            className="preview-container"
          >
            {renderPreview()}
          </div>
          <div className="lightbox_tool-container">
            {isProfile && (
              <button
                key={`index_${title}`}
                type="button"
                className="btn-delete"
                onClick={onClick}
              >
                <i className="las la-trash"></i>
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FilePreview;

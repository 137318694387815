import '@brainhubeu/react-carousel/lib/style.css';
import classNames from 'classnames';
import {
  Field
} from "formik";
import produce from 'immer';
import React from 'react';
import { Col, Row } from 'reactstrap';
import {
  deleteLibraryPhoto
} from '~/apiCalls/quoApi';
import getTransText from '~~/get-text/getTransText';
import MediaItem from './MediaItem';

const MediaList = ({
  files,
  setFiles,
  values,
  toggler,
  resetForm,
  setToggler,
  arrayHelpers,
}) => {
  const handleDeleteGallery = async () => {
    const forDelete = values.uploadObject.filter((a) => (a.delete === true));
    await deleteLibraryPhoto(forDelete)
      .then((deletedArray) => {
        const newFilter = files.filter((b) => (!deletedArray.includes(b.id)));
        setFiles(newFilter);
        resetForm();
      });
  };

  return (
    <>
      {
        files.length > 0
          ? (
            <>
              <div className="medialist__topbar">
                <h3 className="page-title">{getTransText("My Media Library")} </h3>
                <div className="medialist__topbar-right">
                  {/* <button type="button" className="btn btn-secondary btn-profile_update" onClick={() => handleProfileUpdate(values)}><i className="las la-th-large"></i>{getTransText("Update Profile Gallery")}</button> */}
                  <button type="button" className="btn control-btn btn-primary " onClick={() => setToggler(!toggler)} label="Upload file"><i className="las la-upload"></i></button>
                  <button type="button" className="btn control-btn btn-danger " onClick={() => handleDeleteGallery(values, resetForm)}><i className="las la-trash" label="delete file"></i></button>
                </div>
              </div>
              <Row>
                {files.map((file, index) => (
                  <React.Fragment
                    key={file.id}
                  >
                    <Col xs={6} md={4} xl={3}>
                      <div
                        className="media__item__box"
                      >
                        <div className="media__item__box-tool">
                          <Field
                            name={`uploadObject.${index}.delete`}
                          >
                            {({ field, form: { touched, errors } }) => {
                              const selectedDelete = classNames({
                                "control-btn": true,
                                "btn-delete": true,
                                "display-delete": values.uploadObject.find((a) => (a.id === file.id))?.delete,
                              });
                              return (
                                <div className="btn-delete-container">
                                  <input
                                    {...field}
                                    id={`uploadObject.${index}.delete`}
                                    type="checkbox"
                                    placeholder={`uploadObject.${index}.delete`}
                                    className="checkbox-btn__checkbox"
                                    checked={values.uploadObject.find((a) => (a.id === file.id))?.delete}
                                    onChange={(e) => {
                                      const indexOfExists2 = values.uploadObject.map((a) => (a.id)).indexOf(file.id);
                                      if (e.target.checked) {
                                        if (indexOfExists2 > -1) {
                                          arrayHelpers.replace(indexOfExists2, produce(values.uploadObject[indexOfExists2], (draft) => { draft.delete = true; }));
                                        } else {
                                          arrayHelpers.push({ id: file.id, delete: true });
                                        }
                                      } else {
                                        arrayHelpers.replace(indexOfExists2, produce(values.uploadObject[indexOfExists2], (draft) => { delete draft.delete; }));
                                      }
                                    }}
                                  />
                                  <label htmlFor={`uploadObject.${index}.delete`} className={selectedDelete}><i className="las la-trash"></i><span className="checked"><i className="las la-check"></i></span></label>
                                </div>
                              );
                            }}
                          </Field>
                        </div>
                        <MediaItem file={file} index={index} />
                      </div>
                    </Col>
                  </React.Fragment>
                ))}
              </Row>
            </>
          )
          : (
            <div className="no-media">
              <div className="medialist__topbar">
                <h3 className="page-title">{getTransText("My Media Library")} </h3>
              </div>
              <div className="badge-icon">
                <span className="icon"><i className="las la-photo-video"></i></span>
                <h5>{getTransText("No Files")}</h5>
                <p>{getTransText("Upload and create your profile now!")}</p>
                <button type="button" className="btn control-btn btn-primary " onClick={() => setToggler(!toggler)} label="Upload file"><i className="las la-upload"></i></button>
              </div>
            </div>
          )
      }
    </>
  );
};


export default MediaList;

import React from 'react';
import { useSelector } from 'react-redux';
import formatDate from '~~/datetime_render/FormatDate';
import getTransText from '~~/get-text/getTransText';
import QuoStatusOutPut from '~~/status_panel/QuoStatusOutput.jsx';
import QuoSVG from '~~/quoSVG/';


const TimeLineIcon = ({ type }) => {
  let Icon;

  switch (type) {
    case 'Pending':
      Icon = <QuoSVG />;
      break;
    case 'Countering':
      Icon = <i className="las la-gavel"></i>;
      break;
    case 'Accepted':
      Icon = <i className="las la-glass-cheers"></i>;
      break;
    case 'Delivered':
      Icon = <i className="las la-upload"></i>;
      break;
    case 'Completed':
      Icon = <i className="las la-check-square"></i>;
      break;
    case 'Rating':
      Icon = <i className="las la-star"></i>;
      break;
    case 'Rejected':
      Icon = <i className="las la-comment-dots"></i>;
      break;
    case 'Timeout':
      Icon = <i className="las la-hourglass-end"></i>;
      break;
    default:
      Icon = <i className="las la-clock"></i>;
      break;
  }

  return (
    <div className={`timeline__icon ${type}`}>
      {Icon}
    </div>
  );
};



const TimeLineItem = ({ quo }) => {
  const {
    langCode,
  } = useSelector(state => state.ui);
  const {
    current_status,
    latest_description,
    date_created,
    latest_price,
    countering_price
  } = quo;
  const true_price = countering_price || latest_price;

const LangCodeForCurrency = () => {
  switch (langCode) {
    case 'zh-TW':
      return 'zh-TW';
    case 'en-US':
      return 'en-US';
    case 'ja':
      return 'ja-JP';
    default:
      return 'en-US';
  }
};

  return (
    <div className="timeline__item">
      <TimeLineIcon type={current_status} />
      <div className="timeline__content">
        <h5 className="timeline__title">{getTransText(current_status)}</h5>
        <h5 className="timeline__title">{new Intl.NumberFormat(LangCodeForCurrency(), { style: 'currency', currency: 'usd' }).format(true_price)}</h5>
        <h4 className="subhead timeline__date">{formatDate(date_created)}</h4>
        <QuoStatusOutPut quo={quo} isButton={false} />
      </div>
    </div>
  );
};


export default TimeLineItem;

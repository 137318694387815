import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
} from 'reactstrap';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import getTransText from '~~/get-text/getTransText';
import ProfileSettingForm from './components/ProfileSettingForm';

// import { Card } from '@material-ui/core';

const AccountSetting = () => {
  const {
    userData,
  } = useSelector(state => state.user);
  const { tabActive } = useParams();
  const [activeTab, setActiveTab] = useState(tabActive || 'profile');
  const [collapseToggle, setCollapseToggle] = useState((tabActive));

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    setCollapseToggle(true);
  };

  const account_setting_card = classNames({
    "account_setting_card": true,
    "open": collapseToggle,
  });


  return (
    <Container className="profile_setting">
      <ProfileSettingForm setCollapseToggle={setCollapseToggle} />
    </Container>
  );
};

export default AccountSetting;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import {
  Card, CardBody, Col, Row, Container, Button,
} from 'reactstrap';
import { DateTime } from "luxon";
import Countdown from 'react-countdown';
import ProfileTimeLine from './ProfileTimeLine';
import QuoDetail from '~~/quo-details/QuoDetail';
import { getOpenQuoPickedListById, pickMeClickApi, getOpenQuoById } from '~/apiCalls/openCallApi';
import QuoStatusOutPut from '~~/status_panel/QuoStatusOutput.jsx';
import getTransText from '~~/get-text/getTransText';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';
import ModalParent from '~~/modal_parent/ModalParent';
import PickMeForm from './PickMeForm';
import { getQuoInterestedList, pickedMe, pickMeAlreadyClicked, gotOpenCallDetailById } from '~/store/opencall';
import { apiCallBegan } from '~/store/api';
import { creatorLoginBeforeBuyingSellingPick } from '~/store/redirect';
import quoLogoClassSwitch from '~~/quoLogoClass/quoLogoClassSwitch';
import formatDate, { roundUpHour } from '~~/datetime_render/FormatDate';
import OpenComment from '~~/open_comment/OpenComment';


const OpenDetail = () => {
  const history = useHistory();
  const {
    authenticated,
  } = useSelector(state => state.auth);
  const {
    supportedCountry,
  } = useSelector(state => state.user);
  const {
    quoInterestedList,
    quoDetail: quo,
    error,
  } = useSelector(state => state.opencall);
  const [loaded, setLoaded] = useState(true);
  const location = useLocation();
  const pathnameSplited = location.pathname.split("/");
  const quo_id = pathnameSplited[2];
  // const { quo_id } = useParams();
  const true_price = quo.countering_price || quo.latest_price;
  const s = DateTime.fromSeconds(quo.charge_date, { zone: "utc" });
  const {
    langCode,
  } = useSelector(state => state.ui);
  const [toggler, setToggler] = useState(false);
  const dispatch = useDispatch();

  const switchToggler = () => {
    setToggler(!toggler);
  };

  const loginTrigger = () => {
    dispatch(creatorLoginBeforeBuyingSellingPick(quo.id));
    history.push("/log_in");
  };


  const onSubmit = (values) => {
    dispatch(apiCallBegan({
      axiosCall: pickMeClickApi,
      data: { quo_id, comment: values.comment },
      onSuccess: pickedMe.type,
      onError: pickMeAlreadyClicked.type,
    }));
    if (!error) setToggler(!toggler);
  };

  const LangCodeForCurrency = () => {
    switch (langCode) {
      case 'zh-TW':
        return 'zh-TW';
      case 'en-US':
        return 'en-US';
      case 'ja':
        return 'ja-JP';
      default:
        return 'en-US';
    }
  };

  const timerRenderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      // Render a completed state
      return ("");
    }
    // Render a countdown
    return (
      <div className="quo__timer">
        <span className="countdown_group">
          <span className="countdown_number">
            {days}
          </span>
          <span className="countdown_unit">
            {getTransText("D")}
          </span>
        </span>
        <span className="countdown_group">
          <span className="countdown_number">
            {hours}
          </span>
          <span className="countdown_unit">
            {getTransText("H")}
          </span>
        </span>
        <span className="countdown_group">
          <span className="countdown_number">
            {minutes}
          </span>
          <span className="countdown_unit">
            {getTransText("M")}
          </span>
        </span>
      </div>
    );
  };


  const renderBackLink = () => {
    if (location.state) {
      if (location.state.from) {
        if (location.state.from.includes("/c/")) {
          return (<div className="back"><Link to={location.state.from}><i className="las la-angle-left"></i>{getTransText("Back to Chat")}</Link></div>);
        }
        return (<div className="back"><Link to={location.state.from}><i className="las la-angle-left"></i>{getTransText("Back to manage QUO")}</Link></div>);
      }
    }
    return (<div className="back"><Link to="/manageQuo"><i className="las la-angle-left"></i>{getTransText("Back to manage QUO")}</Link></div>);
  };

  const renderButton = () => {
    if (authenticated) {
      if (quo && quo.isEnrolled) {
        return (
          <div className="btn-toolbar btn-toolbar--center">
            <Button
              id="clicke"
              className="btn-pick_me"
              disabled={true}
            >{getTransText("opencall.buying-selling-board.quopanel.clicked")}
            </Button>
          </div>
        );
      }
      if (!supportedCountry) {
        return (
          <div className="btn-toolbar btn-toolbar--center">
            <Button
              id="countrynotsupport"
              className="btn-pick_me"
              disabled={true}
            >{getTransText("opencall.buying-selling-board.quopanel.countrynotsupport")}
            </Button>
          </div>
        );
      }
      return (
        <div className="btn-toolbar btn-toolbar--center">
          <Button
            id="pickme"
            onClick={switchToggler}
            className="btn-pick_me"
            disabled={Boolean(quo.isMe)}
          >{getTransText("opencall.buying-selling-board.quopanel.pickme")}
          </Button>
        </div>
      );
    }

    return (
      <div className="btn-toolbar btn-toolbar--center">
        <Button
          id="googleLoginClick"
          onClick={loginTrigger}
          className="btn-pick_me"
        >{getTransText("opencall.buying-selling-board.quopanel.pickme")}
        </Button>
      </div>
    );
  };


  return (
    <>
      <Container>
        {quo
          ? (
            <Row>
              <Col md={4}>
                <div className="quo__badge">
                  <i className={`quo__badge-pic ${quoLogoClassSwitch(true_price)}`}></i>
                  <div className="quo__price">{new Intl.NumberFormat(LangCodeForCurrency(), { style: 'currency', currency: 'usd' }).format(true_price)}</div>
                </div>
                <div className="quo__delivery">
                  {`${getTransText("opencall.buying-selling-board.quopanel.closein")}: `}
                  <Countdown date={roundUpHour(s.plus({ hours: 48 })).setZone("system")} renderer={timerRenderer} />
                </div>
              </Col>
              <Col md={8}>
                <div className="page-title">{quo.latest_title}</div>
                <div className="quo__detail">
                  <p>
                    <ReactQuill
                      value={quo.latest_description}
                      readOnly={true}
                    />
                  </p>
                </div>
                {renderButton()}
                {toggler && (
                  <>
                    <PickMeForm onSubmit={onSubmit} />
                  </>
                )}
                <div className="quo__comment__wrapper">
                  {quoInterestedList && quoInterestedList.map((x) => (
                    <OpenComment comment={x} key={x.id} />
                  ))}
                </div>
              </Col>
            </Row>
          )
          : (
            <LoadingLogo />
          )}
      </Container>
    </>
  );
};
export default OpenDetail;

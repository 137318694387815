/* eslint-disable */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  ButtonToolbar,
  FormGroup,
  Label,
  Input,
  UncontrolledCollapse
} from 'reactstrap';
import getTransText from '~~/get-text/getTransText';

const ProfileContainer = () => {
  const {
    authenticated,
  } = useSelector(state => state.auth);

  return (
    <>
      <Container className="get__verified">
        <Row>
          <Col md="10">
            <h3 className="page-title">{getTransText("gerverified.title")}</h3>
            <p>Thank you for your interest in becoming a Verified Content Creator on our platform. Being a Verified Content Creator means your profile will gain more visibility and attract a larger audience.</p>
            <p>Additionally, you will have the opportunity to add links to websites where users can find more of your content. To become verified, please complete the following steps:</p>
            <div className="get__verified__form">
              <div className="steps__row">
                <div className="left">
                  <p className="subhead"><span>1</span>Provide your links:</p>
                  <p>Add two or more links to your social profiles. These links will be displayed on your account once you are verified.</p>
                  <p>At least one link from the required section must be provided. You can then add any other required or optional links, plus as many as you would like to see displayed on your profile.</p>
                </div>
                <div className="right">
                   .form....
                </div>
              </div>
              <div className="steps__row">
                <div className="left">
                  <p className="subhead"><span>2</span>Upload photo:</p>
                  <p>Ensure the photo uploaded meets the following requirements:</p>
                  <ul>
                    <li>It should be an SFW photo of you holding a sign with your platform profile URL written on it.</li>
                    <li>The photo must be compatible with the identity that can be seen on the links provided.</li>
                    <li>No digitally modified images.</li>
                    <li>Supported file types: JPG, PNG.</li>
                  </ul>
                </div>
                <div className="right">

                  .form...
                </div>
              </div>
              <div className="steps__row">
                <div className="left">
                  <p className="subhead"><span>3</span>Hit Verify Account button:</p>
                  <p>You will receive an email notification to confirm your account verification.</p>
                </div>
                <div className="right">
                  <ButtonToolbar className="btn-toolbar--center">
                    <Button color="primary" className="btn-get_verified">Verify Account<i className="las la-check-circle"></i></Button>
                  </ButtonToolbar>
                </div>
              </div>
            </div>
            <p>By following these steps, you can become a Verified Content Creator on our platform, increasing your visibility and attracting more fans to your content.</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};


export default ProfileContainer;

import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Switch from "react-switch";
import getTransText from '~~/get-text/getTransText';
import { notiToggleApi, getNotiSetting } from '~/apiCalls/userApi';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';


const NotificationSetting = ({ setCollapseToggle }) => {
  const [inboxNoti, setInboxNoti] = useState();
  const [quoNoti, setQuoNoti] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const networkErrorString = getTransText('Network Error');

  useEffect(async () => {
    const notiSettingRes = await getNotiSetting();
    if (notiSettingRes.length > 0) {
      setInboxNoti(notiSettingRes[0].inbox_noti);
      setQuoNoti(notiSettingRes[0].quo_noti);
    } else {
      setInboxNoti(true);
      setQuoNoti(true);
    }
    setIsLoaded(true);
  }, []);

  const messageNotiToggle = async (e) => {
    setIsDisable(true);
    const config = {
      "inbox_noti": e,
    };
    await notiToggleApi(config)
      .then((response) => {
        setInboxNoti(e);
      })
      .catch((response) => {
        NotificationManager.info(networkErrorString);
      });
    setIsDisable(false);
  };


  const quoNotiToggle = async (e) => {
    setIsDisable(true);
    const config = {
      "quo_noti": e,
    };
    await notiToggleApi(config)
      .then((response) => {
        setQuoNoti(e);
      })
      .catch((response) => {
        NotificationManager.info(networkErrorString);
      });
    setIsDisable(false);
  };

  return (
    <div>
      <h3 className="page-title">
        <button className="account_setting_button--menu" type="button" onClick={() => setCollapseToggle(false)}>
          <i className="las la-arrow-left"></i>
        </button>
        {getTransText("Email Notification")}
      </h3>
      <NotificationContainer leaveTimeout={6000} />
      <div className="account_setting_notification_wrapper">
        {isLoaded
          ? (
            <>
              <div className="account_setting_notification_item">
                <div>
                  {getTransText("Inbox Message")}
                </div>
                <div>
                  <div className="form__form-group-field form__form-group-input-wrap--error-above">
                    <Switch disabled={isDisable} checked={inboxNoti} uncheckedIcon={false} checkedIcon={false} onChange={messageNotiToggle} />
                  </div>
                </div>
              </div>
              <div className="account_setting_notification_item">
                <div>
                  {getTransText("Quo Status Update")}
                </div>
                <div>
                  <div className="form__form-group-field form__form-group-input-wrap--error-above">
                    <Switch disabled={isDisable} checked={quoNoti} uncheckedIcon={false} checkedIcon={false} onChange={quoNotiToggle} />
                  </div>
                </div>
              </div>
            </>
          )
          : <LoadingLogo />}
      </div>
    </div>
  );
};


export default NotificationSetting;

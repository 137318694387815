import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '~~/Card';
import { apiCallBegan } from '~/store/api';
import { getCartInfoListApi, marketplaceCheckoutApi } from '~/apiCalls/cartApi';
import { cartError, getCartInfoList, removedFromCart } from '~/store/cart';
import { colorAdditional, colorRedHover } from '~/utils/palette';
import { Table } from '~~/TableElements';
import CartPurchase from './CartPurchase';
import { addTokenToURL } from '~/apiCalls/authApi';


const CartCard = () => {
  const dispatch = useDispatch();
  const { cart, cartInfoList } = useSelector(state => state.cart);

  useEffect(() => {
    if (cart.length > 0) {
      dispatch(
        apiCallBegan({
          axiosCall: getCartInfoListApi,
          data: cart,
          onSuccess: getCartInfoList.type,
          onError: cartError.type,
        })
      );
    }
  }, [cart]);

  return (
    <>
      <Col md={8} lg={8}>
        <Card className="cart">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Cart</h5>
            </div>
            <Table className="table--bordered cart__table" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  {/* <th>Quantity</th> */}
                  <th>Price</th>
                  {/* <th>Tax</th> */}
                  {/* <th>Total</th> */}
                  <th aria-label="cart table" />
                </tr>
              </thead>
              <tbody>
                {cartInfoList.map((ct, i) => (
                  <tr key={`index_${ct.title}`}>
                    <td>{i + 1}</td>
                    <td>
                      <span className="cart__preview-img">
                        {ct?.thumbnail_list && (<img src={`${process.env.REACT_APP_API_URL}assets/${ct?.thumbnail_list[0].product_thumbnail_id}?fit=cover&width=150&height=150`} alt="product_preview" />)}
                      </span>
                      <span>{ct.title}</span>
                    </td>
                    {/* <td>{ct.quantity}</td> */}
                    <td>${ct.price}</td>
                    {/* <td>${ct.tax.toFixed(2)}</td> */}
                    {/* <td>${ct.total.toFixed(2)}</td> */}
                    <td>
                      <button className="cart__table-btn" type="button" onClick={() => dispatch(removedFromCart(ct.id))}>
                        <DeleteForeverIcon /> Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <h5 className="cart__sub-total">Sub-total:${cartInfoList.reduce((acc, cur) => (acc + Number(cur.price)), 0).toFixed(2)}</h5>
          </CardBody>
        </Card>
      </Col>
      <Col md={4} lg={4}>
        <Card>
          <CardBody>
            <CartPurchase onSubmit />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default CartCard;

import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import getTransText from '~~/get-text/getTransText';
import SidebarLink from './SidebarLink';
import SidebarMail from './SidebarMail';
import { apiCallBegan } from '~/store/api';
import { switchToCreatorModeApi, switchToBuyerModeApi } from '~/apiCalls/userApi';
import {
  userProfileUpdated,
  userProfileUpdateError
} from '~/store/user';
// import SidebarCategory from './SidebarCategory';

const SidebarContent = ({ onClick }) => {
  const {
    is_creator,
    avatar,
    description
  } = useSelector((state) => state.user.userData);
  const {
    active,
  } = useSelector((state) => state.quos);
  const dispatch = useDispatch();


  const isProfileDone = description !== null || avatar !== "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae";

  const switchToBuyerMode = () => {
    dispatch(apiCallBegan({
      axiosCall: switchToBuyerModeApi,
      onSuccess: userProfileUpdated.type,
      onError: userProfileUpdateError.type,
    }));
  };

  const switchToCreatorMode = () => {
    dispatch(apiCallBegan({
      axiosCall: switchToCreatorModeApi,
      onSuccess: userProfileUpdated.type,
      onError: userProfileUpdateError.type,
    }));
  };

  const handleHideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink title={getTransText("sidebar.menu.buying-selling-board")} icon="la-stream" route="/" onClick={handleHideSidebar} />
        {is_creator && (<SidebarLink title={getTransText("My Profile")} icon="la-user-alt" route="/p" onClick={handleHideSidebar} />)}
        <SidebarMail title={getTransText("Inbox")} icon="la-paper-plane" route="/c" onClick={handleHideSidebar} />
        <SidebarLink title={getTransText("Manage QUO")} newLink={active > 0} noti={active} quoicon="icon-quo" route="/manageQuo" onClick={handleHideSidebar} />
        <SidebarLink title={getTransText("Bookmarked Profiles")} icon="la-id-card-alt" route="/bookmarks" onClick={handleHideSidebar} />
        {
          is_creator && (
            <>
              <div className="account__or">
                <p>{getTransText("Creator Area")}</p>
              </div>
              <SidebarLink title={getTransText("Wallet")} icon="la-wallet" route="/d" onClick={handleHideSidebar} />
              <SidebarLink title={getTransText("sidemenu.my-product")} icon="la-photo-video" route="/my-product" onClick={handleHideSidebar} />
              <SidebarLink title={getTransText("Media Library")} icon="la-photo-video" route="/myupload" onClick={handleHideSidebar} />
            </>
          )
        }
        <hr />
        <SidebarLink title={getTransText("Account Settings")} icon="la-cog" route="/accountSetting" onClick={handleHideSidebar} />
        <SidebarLink title={getTransText("Log Out")} icon="la-sign-out-alt" route="/logout" onClick={handleHideSidebar} />
        <div className="sidebar__switch__mode">
          {
            is_creator
              ? (<Button outline color="primary" onClick={switchToBuyerMode}>{getTransText("sidemenu.profile.switch.buyermode")}</Button>)
              : (<Button outline color="primary" onClick={switchToCreatorMode}>{getTransText("sidemenu.profile.switch.creatormode")}</Button>)
          }
        </div>
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;

// action
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";

// export const getOpenCallList = createAction("opencall/getOpenCall");
export const gotQuoDetailById = createAction("managequos/gotOpenCallDetailById");
export const gotManageQuoInterestedList = createAction("managequos/gotManageQuoInterestedList");
export const chosenCreator = createAction("managequos/chosenCreator");



// reducer
const initialState = {
  quoDetail: null,
  quoInterestedList: [],
  success: "",
  error: "",
};

const managequoReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(gotQuoDetailById.type, (state, action) => ({
      ...state,
      quoDetail: action.payload,
    }))
    .addCase(gotManageQuoInterestedList.type, (state, action) => ({
      ...state,
      quoInterestedList: JSON.stringify(action.payload) !== "{}" ? action.payload : [],
    }))
    .addCase(chosenCreator.type, (state, action) => ({
      ...state,
      success: action.payload,
    }));
});

export default managequoReducer;
// export const getAuthStore = createSelector();

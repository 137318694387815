import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import {
  Card, CardBody, Col, Row, Container, ButtonGroup, Button,
} from 'reactstrap';
import ProfileTimeLine from './ProfileTimeLine';
import QuoDetail from '~~/quo-details/QuoDetail';
import { getOpenQuoPickedListById, getOpenQuoById, acceptSearchingApi } from '~/apiCalls/openCallApi';
import { gotQuoDetailById, gotManageQuoInterestedList, chosenCreator } from '~/store/managequos';
import QuoStatusOutPut from '~~/status_panel/QuoStatusOutput.jsx';
import { apiCallBegan } from '~/store/api';
import getTransText from '~~/get-text/getTransText';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';
import OpenComment from '~~/open_comment/OpenComment';
import ModalParent from '~~/modal_parent/ModalParent';

const QuoSearchingActivity = () => {
  const { quo_id } = useParams();
  const [quoLatest, setQuoLatest] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    quoDetail, quoInterestedList,
  } = useSelector(state => state.managequos);
  const [quoActivityDetails, setQuoActivityDetails] = useState();
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const [toggler, setToggler] = useState(false);

  const switchToggler = () => {
    setToggler(!toggler);
  };

  useEffect(async () => {
    let isCancelled = false;
    dispatch(apiCallBegan({
      axiosCall: getOpenQuoById,
      data: quo_id,
      onSuccess: gotQuoDetailById.type,
    }));
    dispatch(apiCallBegan({
      axiosCall: getOpenQuoPickedListById,
      data: quo_id,
      onSuccess: gotManageQuoInterestedList.type,
    }));
    return () => {
      isCancelled = true;
    };
  }, [quo_id]);

  const acceptSearchingClick = (creatorId) => {
    dispatch(apiCallBegan({
      axiosCall: acceptSearchingApi,
      data: { quo_id, creatorId },
      onSuccess: chosenCreator.type,
    }));
    history.push(`/Success/${quo_id}`);
  };

  const renderBackLink = () => {
    if (location.state) {
      if (location.state.from) {
        if (location.state.from.includes("/c/")) {
          return (<div className="back"><Link to={location.state.from}><i className="las la-angle-left"></i>{getTransText("Back to Chat")}</Link></div>);
        }
        return (<div className="back"><Link to={location.state.from}><i className="las la-angle-left"></i>{getTransText("Back to manage QUO")}</Link></div>);
      }
    }
    return (<div className="back"><Link to="/manageQuo"><i className="las la-angle-left"></i>{getTransText("Back to manage QUO")}</Link></div>);
  };

  return (
    <Container className="quo__history__container">

      {quoDetail && quoDetail.id === quo_id
        ? (
          <Row>
            <Col md={10} className="center">
              <Card className="quo__history">
                <CardBody>
                  <Row>
                    <Col xs={12} className="quo__history__detail">
                      {renderBackLink()}
                      {quoDetail ? <QuoDetail quo={quoDetail} isLink={false} /> : ""}
                      {
                        quoDetail
                        && (
                          <div className="quo__detail">
                            <p>
                              <ReactQuill
                                value={quoDetail.latest_description}
                                readOnly={true}
                              />
                            </p>
                          </div>
                        )
                      }
                      {/* {quoLatest ? <div className="quo__history-quo-status"><QuoStatusOutPut quo={quoLatest[0]} /></div> : ""} */}
                    </Col>
                    <Col xs={12}>
                      <div className="quo-searching__profile__wrapper">
                        {quoInterestedList
                          && quoInterestedList.map((quo) => (
                            <div className="quo-searching__profile__list">
                              <ModalParent className="2fa_modal" toggler={toggler} switchToggler={switchToggler}>
                                <div>
                                  {getTransText("QUO.status.reply.confirm.pick")}
                                </div>
                                <Button color="primary" type="submit" onClick={() => acceptSearchingClick(quo.user_created)}>{getTransText("Confirm")}</Button>
                              </ModalParent>
                              {/* //   <div className="profile__avatar">
                            //     <img src={`${process.env.REACT_APP_FRONTEND_URL}img/avatar/profile-test.jpg`} className="icon" alt="[talking icon]" />
                            //   </div>
                            //   <div className="profile__detial">
                            //     <div className="profile__top">
                            //       <div className="profile__name">lalaboom</div>
                            //       <div className="profile__location">Hong Kong</div>
                            //     </div>
                            //     <div className="profile__description">lit, sed do eiusmod tempor magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</div>
                            //   </div> */}
                              <OpenComment comment={quo} key={quo.id} />
                              <ButtonGroup>
                                <Button color="primary" onClick={switchToggler}>Pick</Button>
                              </ButtonGroup>
                            </div>
                          ))}
                        {(quoInterestedList.length === 0)
                          && (
                            getTransText("quo.searching.no-enroll")
                          )}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )
        : (
          <LoadingLogo />
        )}
    </Container>
  );
};
export default QuoSearchingActivity;

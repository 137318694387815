import React, { useEffect, useState, useRef } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';
import TagIcon from 'mdi-react/TagIcon';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import {
  Button,
  ButtonToolbar,
  Popover,
  PopoverBody,
  Input,
} from 'reactstrap';
import FilepondComponent from '~~/filepond-component/';
import getTransText from '~~/get-text/getTransText';
import { readProductApi, getProductFilesApi, getThumbnailsApi, editProductApi, publishProductApi } from '~/apiCalls/marketplaceApi';
import { apiCallBegan } from '~/store/api';
import {
  getProductInfo,
  getProductFiles,
  getThumbnails,
  marketplaceError,
  addedThumbnails,
  addedProductFiles,
} from '~/store/marketplace';
import ProductItems from '~~/catalog/ProductItems';

const ProductEditForm = () => {
  const {
    userData,
  } = useSelector(state => state.user);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverOpen2, setPopoverOpen2] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const toggle2 = () => setPopoverOpen2(!popoverOpen2);
  const [token, setToken] = useState(null);
  const thumbnailsInstance = useRef(null);
  const productInstance = useRef(null);
  const { product_id } = useParams();
  const dispatch = useDispatch();

  const {
    currentProductInfo,
    thumbnails,
    productFiles,
  } = useSelector(state => state.marketplace);

  const thumbnailOnload = (response) => {
    const lists = JSON.parse(response);
    dispatch(addedThumbnails(lists));
    thumbnailsInstance.current.removeFiles();
    toggle();
  };

  const productOnload = (response) => {
    const lists = JSON.parse(response);
    dispatch(addedProductFiles(lists));
    productInstance.current.removeFiles();
    toggle2();
  };

  const onSubmit = async (values) => {
    values.status = "published";
    const res = await publishProductApi({ id: product_id, values });
  };

  const saveLater = async (values) => {
    const res = await editProductApi({ id: product_id, values });
  };

  const unpublishProduct = async (values) => {
    values.status = "inactive";
    const res = await editProductApi({ id: product_id, values });
  };


  useEffect(async () => {
    if (product_id) {
      dispatch(apiCallBegan({
        axiosCall: readProductApi,
        data: product_id,
        onSuccess: getProductInfo.type,
        onError: marketplaceError.type,
      }));
      dispatch(apiCallBegan({
        axiosCall: getProductFilesApi,
        data: product_id,
        onSuccess: getProductFiles.type,
        onError: marketplaceError.type,
      }));
      dispatch(apiCallBegan({
        axiosCall: getThumbnailsApi,
        data: product_id,
        onSuccess: getThumbnails.type,
        onError: marketplaceError.type,
      }));
    }
  }, [product_id]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        title: currentProductInfo?.title,
        description: currentProductInfo?.description,
        price: currentProductInfo?.price,
        discount: currentProductInfo?.discount,
        thumbnails: thumbnails.map(x => x.product_thumbnail_id).join(','),
      }}
      validate={values => {
        const errors = {};
        if (!values.title) {
          errors.request = getTransText("Please write your request");
        }
        if (!values.description) {
          errors.price = getTransText('Please fill in price');
        }
        if (isNaN(values.price)) {
          errors.price = getTransText('Must be a number');
        }
        if (thumbnails.length <= 0) {
          errors.thumbnails = getTransText('Required');
        }
        if (productFiles.length <= 0) {
          errors.productFiles = getTransText('Required');
        }
        return errors;
      }}
    >
      {({ handleSubmit, form, errors }) => (
        <form className="form product-edit" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="card__title">
              <h5 className="bold-text">Main Information</h5>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Product Name</span>
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component="input"
                  type="text"
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field">
                      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                        <Input
                          {...input}
                          placeholder={getTransText("marketplace.product.title")}
                        />
                        {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                      </div>
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Short description <span dir="ltr">(300 characters max)</span></span>
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component="textarea"
                  type="text"
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field">
                      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                        <ReactQuill
                          {...input}
                          formats={[]}
                          modules={{
                            toolbar: []
                          }}
                          type="textarea"
                          placeholder={getTransText("marketplace.product.description")}
                        // className="input-without-border-radius"
                        // theme="bubble"
                        />
                        {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                      </div>
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="card__title">
              <h5 className="bold-text">Pricing</h5>
            </div>
            <div className="form__form-group-price-discount">
              <div className="form__form-group form__form-group-price">
                <span className="form__form-group-label">Price</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <CurrencyUsdIcon />
                  </div>
                  <Field
                    name="price"
                    component="input"
                    type="text"
                  >
                    {({ input, meta }) => (
                      <div className="form__form-group-field">
                        <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                          <Input
                            {...input}
                            placeholder={getTransText("marketplace.product.price")}
                          />
                          {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Discount</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <TagIcon />
                  </div>
                  <Field
                    name="discount"
                    component="input"
                    type="text"
                  >
                    {({ input, meta }) => (
                      <div className="form__form-group-field">
                        <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                          <Input
                            {...input}
                            placeholder={getTransText("marketplace.product.discount")}
                          />
                          {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
              </div>
            </div>

          </div>
          <div className="form__half">
            <div className="card__title">
              <h5 className="bold-text">Images</h5>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Thumbnails</span>
              <div className="form__form-group-field">
                <Field
                  component="input"
                  type="hidden"
                  name="thumbnails"
                >
                  {({ input, meta }) => (
                    <>
                      <div className="col-1">
                        <Button id="Popover1" type="button" className="btn control-btn btn-primary " label="Upload file"><i className="las la-upload"></i></Button>
                        <Popover trigger="legacy" placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
                          <PopoverBody>
                            <FilepondComponent filepurpose="marketplace-thumbnails" onload={thumbnailOnload} ref={thumbnailsInstance} description={product_id} />
                          </PopoverBody>
                        </Popover>
                        <Input {...input} value={thumbnails.map(x => x.product_thumbnail_id).join(',')} />
                        {meta.error && <span className="form__form-group-error">{meta.error}</span>}
                      </div>
                      <div className="col">{JSON.stringify(thumbnails)}</div>
                    </>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Secret Content</span>
              <div className="form__form-group-field">
                <Field
                  name="productFiles"
                >
                  {({ input, meta }) => (
                    <>
                      <div className="col-1">
                        <Button id="Popover2" type="button" className="btn btn-primary control-btn" onClick={() => console.log("ss")} label="Upload file"><i className="las la-upload"></i></Button>
                        <Popover trigger="legacy" placement="bottom" isOpen={popoverOpen2} target="Popover2" toggle={toggle2}>
                          <PopoverBody>
                            <FilepondComponent filepurpose="marketplace-products" onload={productOnload} ref={productInstance} description={product_id} />
                          </PopoverBody>
                        </Popover>
                        {meta.error && <span className="form__form-group-error">{meta.error}</span>}
                      </div>
                      <div className="col">{JSON.stringify(productFiles)}</div>
                    </>
                  )}
                </Field>
              </div>
            </div>
          </div>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <div className="form__form-group--section">
                <div className="card__title">
                  <h5 className="bold-text">Preview</h5>
                </div>
                <div className="product__preview">
                  <ProductItems items={[values]} />
                </div>
                <ButtonToolbar className="form__button-toolbar btn-toolbar--center">
                  <Button color="primary" type="submit" onClick={() => saveLater(values)}>Save</Button>
                  {currentProductInfo?.status === "published"
                    ? <Button type="button" onClick={() => unpublishProduct(values)}>Unpublish</Button>
                    : <Button type="submit">Publish</Button>}
                </ButtonToolbar>
              </div>
            )}
          </FormSpy>
        </form>
      )}
    </Form>
  );
};


export default ProductEditForm;

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ProfileForm from './components/ProfileForm';
import getTransText from '~~/get-text/getTransText';

const EditProfile = () => (
  <Container className="quabal__container">
    <Row>
      <Col sm={10} lg={8}>
        <h3 className="page-title">{getTransText("Set Your Username")}</h3>
        <h3 className="page-subhead subhead">{getTransText("This is your first time login! Please enrich your profile!")}
        </h3>
      </Col>
    </Row>
    <Row>
      <Col sm={10} lg={8}>
        <ProfileForm />
      </Col>
    </Row>
  </Container>
);

export default EditProfile;

import { Card as BootstrapCard, CardBody as BootstrapCardBody } from 'reactstrap';
import styled from 'styled-components';
import { colorBackground } from '~/utils/palette';

export const Card = styled(BootstrapCard)`
  width: 100%;
  padding-bottom: 30px;
  height: ${props => (props.height || '100%')};
  border: none;
  background-color: transparent;
`;

export const CardBody = styled(BootstrapCardBody)`
  height: 100%;
  background-color: ${colorBackground};
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

export const CardTitleWrap = styled.div`
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;
  text-align: left;

  &:not(:first-child) {
    margin-top: 40px;
  }

  * {
    margin-bottom: 0;
  }
`;

export const CardTitle = styled.h5`
  font-size: 13px;
  text-align: left;
  font-weight: 700;
  ${props => props.centered && `
    text-align: center;
  `}
`;

export const CardSubhead = styled.p`
  text-transform: none;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  margin-top: 3px;
`;

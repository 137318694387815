import React, { useState, useEffect } from 'react';
import {
  useHistory, Link,
} from 'react-router-dom';
import { useSelector } from "react-redux";
// import MoreVertIcon from 'mdi-react/MoreVertIcon';
// import StarIcon from 'mdi-react/StarIcon';
import ModalParent from '~~/modal_parent/ModalParent';
import AvatarRender from '~~/avatar_render';
import QuoForm from '~~/quo_form/QuoForm';
import getTransText from "~~/get-text/getTransText";

const ChatTopbar = () => {
  const {
    chatMate,
  } = useSelector((state) => state.chats);
  const { id } = useSelector((state) => state.user.userData);
  const [friendInfo, setFriendInfo] = useState(null);
  const [toggler, setToggler] = useState(false);
  const history = useHistory();
  const switchToggler = () => {
    setToggler(!toggler);
  };

  useEffect(() => {
    if (chatMate) {
      setFriendInfo(
        chatMate.memberb_id === id
          ? {
            avatar: "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae",
            username: getTransText("Anonymous"),
            random_no: chatMate.random_no,
          }
          : {
            avatar: chatMate.avatar,
            username: chatMate.username,
            random_no: chatMate.random_no,
          }
      );
    }
  }, [chatMate]);

  const backToContactList = () => {
    history.goBack();
  };

  return (
    <div className="chat__topbar">
      <ModalParent className="quo__modal" toggler={toggler} switchToggler={switchToggler}>
        <QuoForm friendInfo={friendInfo} />
      </ModalParent>
      <button className="chat__topbar-button chat__topbar-button--menu" type="button" onClick={() => backToContactList()}>
        <i className="las la-arrow-left"></i>
      </button>
      {friendInfo
        && (
          <div className="chat__topbar-contact">
            <div className="chat__topbar-avatar">
              <img
                src={
                  AvatarRender(friendInfo?.avatar, friendInfo?.random_no)
                }
                alt="ava"
              />
            </div>
            <p className="chat__topbar-contact-post"></p>
            <p className="chat__topbar-contact-name">
              {(
                chatMate
                && (chatMate.memberb_id !== id))
                ? <Link to={`/p/${friendInfo.username}`}>{friendInfo.username}</Link>
                : friendInfo.username}
            </p>
            {/* <button className="chat__topbar-button" type="button">
              <i className="las la-bookmark"></i>
            </button> */}
          </div>
        )}
      <div className="chat__topbar-right">
        <div className="chat__topbar-controls">
          {chatMate
            && (chatMate.memberb_id !== id)
            ? (
              <button className="chat__topbar-button chat_topbar-quo" type="button" onClick={() => setToggler(!toggler)}>
                <i className="icon-quo"></i><span className="text">{getTransText("Place a QUO")}</span>
              </button>
            )
            : ""}
        </div>
      </div>
    </div>
  );
};

export default ChatTopbar;

import axios from 'axios';
import EyeIcon from 'mdi-react/EyeIcon';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Button, ButtonToolbar,
  Card,
  CardBody,
  Col,
  Container, FormGroup,
  Input, Row
} from 'reactstrap';
import getTransText from '~~/get-text/getTransText';


const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});


const ResetPassword = ({ setCollapseToggle }) => {
  const [showPassword, setShowPassword] = useState(false);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const history = useHistory();

  const onSubmit = async (values) => {
    const config = {
      token: query.get("token"),
      password: values.new_password,
    };
    await api.post('/auth/password/reset', config).then((response) => {
      // Success
      if (response) {
        history.push("/ResetSuccess");
      }
    })
      .catch((response) => {
        NotificationManager.info(getTransText('Invaild Credentials.'));
      });
  };

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };


  return (
    <div className="verification__container quabal__container">
      <Container>
        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <div>
                  <h3 className="page-title">
                    <button className="account_setting_button--menu" type="button" onClick={() => setCollapseToggle(false)}>
                      <i className="las la-arrow-left"></i>
                    </button>
                    {getTransText("Reset Password")}
                  </h3>
                  <NotificationContainer leaveTimeout={6000} />
                  <Form
                    onSubmit={onSubmit}
                    validate={values => {
                      const errors = {};
                      if (!values.new_password) {
                        errors.new_password = "Required";
                      } else if (values.new_password.length < 8) {
                        errors.new_password = 'Please input more than 8 digit.';
                      }
                      if (!values.confirmPassword) {
                        errors.confirmPassword = "Required";
                      } else if (values.confirmPassword !== values.new_password) {
                        errors.confirmPassword = "Must match";
                      }
                      return errors;
                    }}
                  >
                    {({ handleSubmit, values }) => (
                      <form className="form login-form" onSubmit={handleSubmit}>
                        <FormGroup className="form__form-group">
                          <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                              <i className="las la-lock"></i>
                            </div>
                            <Field
                              name="new_password"
                            >
                              {({ input, meta }) => (
                                <div className="form__form-group-field form__form-group-input-wrap--error-above">
                                  <Input
                                    {...input}
                                    name="new_password"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder={getTransText("New Password")}
                                    className="input-without-border-radius"
                                  />
                                  <button
                                    type="button"
                                    className={`form__form-group-button${showPassword ? ' active' : ''}`}
                                    onClick={toggleShowPassword}
                                  ><EyeIcon />
                                  </button>
                                  {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </FormGroup>
                        <FormGroup className="form__form-group">
                          <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                              <i className="las la-lock"></i>
                            </div>
                            <Field
                              name="confirmPassword"
                            >
                              {({ input, meta }) => (
                                <div className="form__form-group-field">
                                  <Input
                                    {...input}
                                    name="confirmPassword"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder={getTransText("Confirm Password")}
                                    className="input-without-border-radius"
                                  />
                                  <button
                                    type="button"
                                    className={`form__form-group-button${showPassword ? ' active' : ''}`}
                                    onClick={toggleShowPassword}
                                  ><EyeIcon />
                                  </button>
                                  {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </FormGroup>
                        <ButtonToolbar className="form__button-toolbar">
                          <Button color="primary" type="submit">{getTransText("Reset Password")}</Button>
                        </ButtonToolbar>
                      </form>
                    )}
                  </Form>

                </div>
              </CardBody>
            </Card>
            <Link className="quobal__logo horizontal" to="/l" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPassword;

import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkedNsfwProfile
} from '~/store/profile';
import FilePreview from '~~/file-preview';
import LightBoxModalParent from '~~/modal_parent/LightBoxModalParent';

const PublicPhoto = ({
  me,
  userInfo,
  p,
  index,
  nsfwModel,
}) => {
  const {
    isNsfwContent,
  } = useSelector(state => state.profile);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const imageOnErrorHandler = (event) => {
    event.currentTarget.src = `${process.env.REACT_APP_FRONTEND_URL}img/noimage.svg`;
  };

  const checkAlreadyAccepted = () => {
    const saved = localStorage.getItem("nsfw-accepted");
    const initialValue = saved ? JSON.parse(saved) : [];
    return initialValue.some((element) => element === userInfo.username);
  };

  const loadedEvent = async (e) => {
    if (!me && !checkAlreadyAccepted()) {
      // Classify the image
      if (nsfwModel) {
        if (!isNsfwContent) {
          const predictions = await nsfwModel.classify(e.target);
          if ((predictions[0].className === "Porn" || predictions[0].className === "Hentai")) {
            dispatch(checkedNsfwProfile(true));
          }
        }
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <LightBoxModalParent isOpen={modal} toggle={toggle} size="lg">
        {p && <FilePreview mime="image" src={`public-access/asset/${p.id}`} title={p.id} />}
        {/* <LightBox file={{ id: p.id }} /> */}
      </LightBoxModalParent>
      <button
        key={`index_${p.index}`}
        type="button"
        className="gallery__img-wrap"
        onClick={() => {
          toggle();
        }}
      >
        {isLoading && (
          <Skeleton
            key={`index_${p.index}`}
            height={150}
            className="avatar-placeholder"
          />
        )}
        <img
          onLoad={loadedEvent}
          onError={imageOnErrorHandler}
          key={index}
          src={`${process.env.REACT_APP_API_URL}public-access/asset/${p.id}?width=335&height=250`}
          alt={index}
          style={{ display: isLoading ? "none" : "block" }}
          crossOrigin="anonymous"
        />
      </button>
    </>
  );
};

export default PublicPhoto;

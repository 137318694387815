import fileDownload from 'js-file-download';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import {
  Button,
  ButtonToolbar
} from 'reactstrap';
import { shareStatusUpdate } from '~/apiCalls/userApi';
import { addTokenToURL } from '~/apiCalls/authApi';
import getTransText from '~~/get-text/getTransText';

const StepCarousel = () => {
  const {
    username,
    avatar,
    random_no,
  } = useSelector(state => state.profile.userInfo);
  const customeSlider = useRef();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [avatarSrc, setAvatarSrc] = useState();
  const [loadAvatar, setLoadAvatar] = useState(false);
  const [dataURLForShowing, setDataURLForShowing] = useState();

  // useEffect(async () => {
  //   if (avatar && random_no) {
  //     const reader = new FileReader();
  //     const blob = await apiAxios.get(avatar === "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae"
  //       ? `${process.env.REACT_APP_FRONTEND_URL}img/avatar/avatar${random_no + 1}.jpg`
  //       : `${process.env.REACT_APP_API_URL}file-access/avatar/${avatar}`, { responseType: 'blob' }).then(r => r.data);
  //     reader.readAsDataURL(blob);
  //     reader.addEventListener("load", () => {
  //       // convert image file to base64 string
  //       setAvatarSrc(reader.result);
  //     }, false);
  //     setLoadAvatar(true);
  //   }
  // }, [avatar, random_no]);
  const gotoNext = () => {
    customeSlider.current.slickNext();
  };

  useEffect(async () => {
    if (loadAvatar) {
      try {
        const newFile = await fetch(addTokenToURL(`${process.env.REACT_APP_API_URL}media-share`)).then(r => r.blob());
        setData({
          files: [
            new File([newFile], "quobal-share.png", {
              type: newFile.type
            })
          ],
          title: getTransText("QUO Me Anonymously!"),
          text: getTransText("QUO Me Anonymously!"),
          url: `https://quob.al/${username}`,
        });
      } catch (error) {
        // console.log("catchBlobError", error);
      }
    }
  }, [loadAvatar]);

  useEffect(async () => {
    if (avatar && random_no) {
      setAvatarSrc(addTokenToURL(`${process.env.REACT_APP_API_URL}media-share`));
      setLoadAvatar(true);
    }
  }, [avatar, random_no]);

  const handleDownload = async () => {
    setLoading(true);
    await fetch()
      .then((res) => {
        fileDownload(res.data, "QUObal-share.png");
      });
    setLoading(false);
  };

  const handleShare = async () => {
    setLoading(true);
    try {
      if (navigator.share) {
        await navigator.share(data);
        await shareStatusUpdate();
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [copySuccess, setCopySuccess] = useState(getTransText("copy link"));

  return (
    <>
      <div className="share__profile__modal">
        <div className="modal__title">
          {getTransText('shareprofile.title')}
        </div>
        <div className="modal__body">
          <p>{getTransText('Share this link and invite people to make request anonymously.')}</p>
          <div className="profile__link">{`${process.env.REACT_APP_ENV_S === "production" ? "https://QUOb.al/" : `${process.env.REACT_APP_FRONTEND_URL}p/`}${username}`}</div>
          <ButtonToolbar className="form__button-toolbar form__button-toolbar--center">
            <Button
              color="primary"
              onClick={() => {
                navigator.clipboard.writeText(`${process.env.REACT_APP_ENV_S === "production" ? "https://QUOb.al/" : `${process.env.REACT_APP_FRONTEND_URL}p/`}${username}`).then(() => {
                  setCopySuccess(getTransText("Copied"));
                }, () => {
                  setCopySuccess(getTransText("Unable to write to clipboard. :-("));
                });
              }}
            >
              {copySuccess}
            </Button>
          </ButtonToolbar>
        </div>
      </div>
    </>
  );
};

export default StepCarousel;

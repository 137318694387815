import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row
} from 'reactstrap';
import getTransText from '~~/get-text/getTransText';

const ResetSuccess = () => (
  <div className="verification__container quabal__container">
    <Container>
      <Row>
        <Col md="6">
          <Card>
            <CardBody>
              <div className="badge-icon">
                <span className="icon"><i className="las la-check"></i></span>
                <h5>{getTransText("Password Reset Email Has Been Sent")}</h5>
                <p>{getTransText("Please check your Email.")}</p>
              </div>
            </CardBody>
          </Card>
          <Link className="quobal__logo horizontal" to="/l" />
        </Col>
      </Row>
    </Container>
  </div>
);

export default ResetSuccess;

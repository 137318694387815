import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { addTokenToURL } from '~/apiCalls/authApi';
import {
  checkedNsfwProfile,
  deletedProfilePhoto,
} from '~/store/profile';
import FilePreview from '~~/file-preview';
import LightBoxModalParent from '~~/modal_parent/LightBoxModalParent';
import { apiCallBegan } from '~/store/api';
import {
  deleteProfilePhoto,
} from '~/apiCalls/userApi';

const Photo = ({
  me,
  userInfo,
  p,
  index,
  nsfwModel,
}) => {
  const [urlWithToken, setUrlWithToken] = useState(null);
  const {
    isNsfwContent,
  } = useSelector(state => state.profile);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const imageOnErrorHandler = (event) => {
    event.currentTarget.src = `${process.env.REACT_APP_FRONTEND_URL}img/noimage.svg`;
  };
  const {
    profileContents,
  } = useSelector(state => state.profile);
  const checkAlreadyAccepted = () => {
    const saved = localStorage.getItem("nsfw-accepted");
    const initialValue = saved ? JSON.parse(saved) : [];
    return initialValue.some((element) => element === userInfo.username);
  };

  const loadedEvent = async (e) => {
    if (!me && !checkAlreadyAccepted()) {
      // Classify the image
      if (nsfwModel) {
        if (!isNsfwContent) {
          const predictions = await nsfwModel.classify(e.target);
          if ((predictions[0].className === "Porn" || predictions[0].className === "Hentai")) {
            dispatch(checkedNsfwProfile(true));
          }
        }
      }
    }
    setIsLoading(false);
  };

  const handleDeleteGallery = async (values, resetForm) => {
    // const forDelete = values.ProfileGallery.filter((a) => (a.delete === true));
    // await deleteProfilePhoto(forDelete)
    //   .then((deletedArray) => {
    //     const newFilter = files.filter((b) => (!deletedArray.includes(b.id)));
    //     setFiles(newFilter);
    //     dispatch(changedProfileContent(newFilter));
    //     resetForm();
    //   });
    dispatch(apiCallBegan({
      axiosCall: deleteProfilePhoto,
      data: [{ id: p.id }],
      onSuccess: deletedProfilePhoto.type,
    }));
  };
  useEffect(() => {
    addTokenToURL(`${process.env.REACT_APP_API_URL}file-access/${p.id}?width=335&height=250`).then(x => setUrlWithToken(x));
  }, []);

  return (
    <>
      <LightBoxModalParent isOpen={modal} toggle={toggle} size="lg">
        {p && <FilePreview mime="image" src={`file-access/${p.id}`} title={p.id} isProfile={me} onClick={handleDeleteGallery} />}
        {/* <LightBox file={{ id: p.id }} /> */}
      </LightBoxModalParent>
      <button
        key={`index_${p.index}`}
        type="button"
        className="gallery__img-wrap"
        onClick={() => {
          toggle();
        }}
      >
        {isLoading && (
          <Skeleton
            key={`index_${p.index}`}
            height={150}
            className="avatar-placeholder"
          />
        )}
        {urlWithToken && (
          <img
            onLoad={loadedEvent}
            onError={imageOnErrorHandler}
            key={index}
            src={urlWithToken}
            alt={index}
            style={{ display: isLoading ? "none" : "block" }}
            crossOrigin="anonymous"
          />
        )}
      </button>
    </>
  );
};

export default Photo;

import React from 'react';
import { Field, Form } from 'react-final-form';
import { NotificationContainer } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, ButtonGroup,
  ButtonToolbar, FormGroup
} from 'reactstrap';
import { changeLanguage } from '~/apiCalls/userApi';
import { apiCallBegan } from '~/store/api';
import {
  userProfileUpdated,
  userProfileUpdateError
} from '~/store/user';
import getTransText from '~~/get-text/getTransText';

const LanguageSettingForm = ({ setCollapseToggle }) => {
  const dispatch = useDispatch();
  const {
    language,
  } = useSelector(state => state.user.userData);

  const required = value => (value ? undefined : getTransText('Required'));

  const onSubmit = (values) => {
    dispatch(apiCallBegan({
      axiosCall: changeLanguage,
      data: values,
      onSuccess: userProfileUpdated.type,
      onError: userProfileUpdateError.type,
    }));
  };

  return (
    <div>
      <h3 className="page-title">
        <button className="account_setting_button--menu" type="button" onClick={() => setCollapseToggle(false)}>
          <i className="las la-arrow-left"></i>
        </button>
        {getTransText("Language setting")}
      </h3>
      <NotificationContainer leaveTimeout={6000} />
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, values }) => (
          <form className="form" onSubmit={handleSubmit}>
            <FormGroup className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="language"
                  validate={required}
                  initialValue={language}
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field form__form-group-input-wrap--error-above">
                      <select
                        {...input}
                        className="react-select"
                        style={{ display: 'block' }}
                      >
                        <option value="">{getTransText("Select Language")}</option>
                        <option value="en-US">English</option>
                        <option value="zh-TW">中文</option>
                        {/* <option value="ja">日本語</option> */}
                      </select>
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
            </FormGroup>
            <ButtonToolbar className="form__button-toolbar btn-toolbar--center">
              <Button color="primary" type="submit">{getTransText("Change Language")}</Button>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    </div>
  );
};


export default LanguageSettingForm;

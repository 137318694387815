import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import getTransText from '~~/get-text/getTransText';
import RegisterForm from '~~/login_register/LoginRegisterForm';

const Register = () => {
  const {
    action, type, quoId,
  } = useSelector(state => state.redirect);

  const { username } = useParams();
  const googleLogin = action === "redirect login"
    ? () => window.open(`${process.env.REACT_APP_API_URL}auth/login/google?redirect=${process.env.REACT_APP_FRONTEND_URL}outreach-pay/${quoId}?exlogin=true`, "_self")
    : () => window.open(`${process.env.REACT_APP_API_URL}auth/login/google?redirect=${process.env.REACT_APP_FRONTEND_URL}exlogin`, "_self");
  // const facebookLogin = () => window.open(`${process.env.REACT_APP_API_URL}auth/login/facebook?redirect=${process.env.REACT_APP_FRONTEND_URL}exlogin`, "_self");

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <div className="account__logo"></div>
            <div className="account__title">{getTransText("Welcome to QUObal")}</div>
            <h4 className="account__subhead subhead">{getTransText("Create an account")}</h4>
          </div>
          <RegisterForm default_username={username} />
          <div className="account__or">
            <p>{getTransText("Or Easily Using")}</p>
          </div>
          <div className="account__social">
            <Button color="primary" id="googleLoginClick" outline onClick={googleLogin}><i className="icon-google"></i>{getTransText("Sign in with Google")}</Button>
            {/* <Button color="primary" outline>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
              </svg>
              {getTransText("Sign in with Twitter")}
            </Button> */}
          </div>
          <div className="account__more">
            {getTransText("Already have an account?")} &nbsp;
            <Link
              to={{
                pathname: "/log_in",
              }}
            >
              {getTransText("Login")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;

import React from 'react';
import { useSelector } from 'react-redux';
import TimeLineItemNotYet from './TimeLineItemNotYet';
import getTransText from '~~/get-text/getTransText';


const TimeLineListNotYet = ({ data }) => {
  const {
    id,
    stripe_connected_id,
  } = useSelector(state => state.user.userData);
  return (
    <>
      {
        ((data
          && !stripe_connected_id
          && !data.some((q) => q.current_status === "Timeout")
          && !data.some((q) => q.current_status === "Rejected"))
          && data[0].receiver === id)
        && (
          <TimeLineItemNotYet
            key="Not connected"
            quo={{
              current_status: getTransText("Connect your stripe wallet")
            }}
          />
        )
      }
      {
        (!data?.some((q) => q.current_status === "Accepted")
          && !data?.some((q) => q.current_status === "Timeout")
          && !data.some((q) => q.current_status === "Rejected"))
        && (
          <TimeLineItemNotYet
            key="Not accpeted"
            quo={data[0].receiver === id ? {
              current_status: getTransText("Waiting to accept offer")
            } : {
              current_status: getTransText("Waiting to accept offer")
            }}
          />
        )
      }
      {
        (!data?.some((q) => q.current_status === "Delivered")
          && !data?.some((q) => q.current_status === "Timeout")
          && !data.some((q) => q.current_status === "Rejected"))
        && (
          <TimeLineItemNotYet
            key="Not delivered"
            quo={data[0].receiver === id ? {
              current_status: getTransText("Submit file(s)")
            } : {
              current_status: getTransText("Waiting for creator's file submission")
            }}
          />
        )
      }
      {
        (!data?.some((q) => q.current_status === "Rating")
          && !data?.some((q) => q.current_status === "Timeout")
          && !data.some((q) => q.current_status === "Rejected"))
        && (
          <TimeLineItemNotYet
            key="Not rating"
            quo={data[0].receiver === id ? {
              current_status: getTransText("Waiting for confirmation and get paid")
            } : {
              current_status: getTransText("Receive and confirm creator's deliverable")
            }}
          />
        )
      }
      {
        (!data?.some((q) => q.current_status === "Completed")
          && !data?.some((q) => q.current_status === "Timeout")
          && !data.some((q) => q.current_status === "Rejected"))
        && (
          <TimeLineItemNotYet
            key="Not completed"
            quo={{
              current_status: getTransText("Rate upvote if you enjoy!")
            }}
          />
        )
      }
    </>
  );
};

export default TimeLineListNotYet;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';


const CartPurchase = () => {
  const [options, setOptions] = useState();
  const { cart, cartInfoList } = useSelector(state => state.cart);
  const { stripePromise } = useSelector((state) => state.marketplace);

  useEffect(async () => {
    if (cartInfoList.length > 0) {
      setOptions({
        mode: 'payment',
        amount: cartInfoList.reduce((acc, cur) => (acc + Number(cur.price)), 0).toFixed(2) * 100,
        currency: 'usd',
        // Fully customizable with appearance API.
      });
    }
  }, [cartInfoList]);


  return (
    <>
      {options && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
};

export default CartPurchase;

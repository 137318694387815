// action
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import Cookies from 'js-cookie';

export const getCart = createAction("cart/getCart");
export const addedToCart = createAction("marketplace/addedToCart");
export const removedFromCart = createAction("marketplace/removedFromCart");
export const getCartInfoList = createAction("marketplace/getCartInfoList");
export const cartError = createAction("marketplace/cartError");


// reducer
const initialState = {
  cart: [],
  cartInfoList: [],
  error: "",
};

const marketplaceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCart.type, (state, action) => {
      if (Cookies.get('cart')) {
        state.cart = JSON.parse(Cookies.get('cart'));
      } else {
        state.cart = [];
      }
    })
    .addCase(addedToCart.type, (state, action) => {
      const exists = state.cart.some((product) => product === action.payload);
      if (!exists) {
        state.cart.push(action.payload);
      }
      Cookies.set('cart', JSON.stringify(state.cart), { expires: 7 });
    })
    .addCase(removedFromCart.type, (state, action) => {
      state.cart = state.cart.filter((product) => product !== action.payload);
      Cookies.set('cart', JSON.stringify(state.cart), { expires: 7 });
    })
    .addCase(getCartInfoList.type, (state, action) => ({
      ...state,
      cartInfoList: action.payload,
    }))
    .addCase(cartError.type, (state, action) => ({
      ...state,
      error: action.payload,
    }));
});

export default marketplaceReducer;
// export const getAuthStore = createSelector();

// action
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";

// export const userHydrateBegan = createAction("user/hydrateBegan");
export const userHydrated = createAction("user/hydrated");
export const userHydrateError = createAction("user/hydrateError");
export const userDehydrated = createAction("user/dehydrated");
export const userProfileUpdated = createAction("user/profileUpdated");
export const userProfileUpdateError = createAction("user/profileUpdateError");
export const userTfaEnable = createAction("user/tfaEnable");
export const userTfaDisable = createAction("user/tfaDisable");
export const userClearedError = createAction("user/clearedError");
export const userGotSupportedCountry = createAction("user/gotSupportedCountry");



// reducer
const initialState = {
  userData: {
    id: null,
  },
  success: "",
  error: "",
  supportedCountry: "",
};

const UserReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(userHydrated.type, (state, action) => ({
      userData: action.payload,
      success: "hydrated",
      error: "",
    }))
    .addCase(userHydrateError.type, (state, action) => ({
      ...state,
      success: "",
      error: action.payload,
    }))
    .addCase(userDehydrated.type, (state, action) => ({
      userData: {
        id: null,
      },
      success: "dehydrated",
      error: "",
    }))
    .addCase(userProfileUpdated.type, (state, action) => ({
      userData: {
        ...state.userData,
        ...action.payload,
      },
      success: "Profile Updated",
      error: "",
    }))
    .addCase(userProfileUpdateError.type, (state, action) => ({
      ...state,
      success: "",
      error: action.payload.response,
    }))
    .addCase(userTfaEnable.type, (state, action) => ({
      userData: {
        ...state.userData,
        tfa_secret: true,
      },
      success: "TFA enabled",
      error: "",
    }))
    .addCase(userTfaDisable.type, (state, action) => ({
      userData: {
        ...state.userData,
        tfa_secret: null,
      },
      success: "TFA disabled",
      error: "",
    }))
    .addCase(userClearedError.type, (state, action) => ({
      ...state,
      success: "",
      error: "",
    }))
    .addCase(userGotSupportedCountry.type, (state, action) => ({
      ...state,
      supportedCountry: action.payload.supported,
    }));
});

export default UserReducer;
// export const getAuthStore = createSelector();

import { Formik } from 'formik';
import React from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import {
  Button, ButtonToolbar, Form,
  FormGroup,
  Input
} from 'reactstrap';
import getTransText from '~~/get-text/getTransText';
import { payoutsApi } from '~/apiCalls/paymentApi';

const PayoutForm = ({
  balance, switchToggler, switchPayoutToggler,
}) => {
  const {
    userData,
  } = useSelector(state => state.user);
  // eslint-disable-next-line
  const ModifyUser = async (credentials, setFieldError, setSubmitting) => {
    await payoutsApi(credentials)
      .then((res) => {
        ReactGA.event({
          category: 'Banking',
          action: "Payout by user",
          label: userData?.id,
          value: credentials?.amount,
        });
        if (res.id) {
          switchToggler();
          switchPayoutToggler();
        }
        setSubmitting(false);
      })
      .catch((response) => {
        if (response.errors === "balance_insufficient") {
          setFieldError("amount", getTransText("Balance Insufficient."));
        } else {
          setFieldError("amount", response.errors);
        }
        setSubmitting(false);
      });
  };

  return (
    <div className="payout__form">
      <div className="modal__title">
        <h4 className="bold-text">{getTransText("payoutform.pagetitle")}</h4>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          amount: null,
          currency: null,
        }}
        validate={values => {
          const errors = {};
          if (!values.amount) {
            errors.amount = getTransText("Please input payouts amount");
          } if (isNaN(values.amount)) {
            errors.amount = getTransText('Must be a number');
          } else if (!values.currency) {
            errors.currency = getTransText("Please select payouts currency");
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          ModifyUser(values, setFieldError, setSubmitting);
        }}
      >

        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form className="form form--horizontal" onSubmit={handleSubmit}>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("Amount")}</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <Input
                    name="amount"
                    component="input"
                    type="text"
                    placeholder={getTransText("Payouts Amount")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.amount}
                    required
                    className="input-without-border-radius"
                  />
                  {errors?.amount && touched.amount && <span className="form__form-group-error">{errors.amount}</span>}
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("Currency")}</span>
              <div className="form__form-group-field">
                <select
                  name="currency"
                  value={values.currency}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ display: 'block' }}
                >
                  <option value="">{getTransText("Select Currency")}</option>
                  {balance?.available.map(item => (
                    <option value={item.currency}>{item.currency}</option>
                  ))}
                </select>
                {errors?.currency && touched.currency && <span className="form__form-group-error">{errors.currency}</span>}
              </div>
            </FormGroup>
            <ButtonToolbar className="form__button-toolbar btn-toolbar--center">
              <Button color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? `${getTransText("Loading")}...` : getTransText("Payout Now")}
              </Button>
              <Button type="button">
                {getTransText("Cancel")}
              </Button>
            </ButtonToolbar>
          </Form>
        )}
      </Formik>
    </div>
  );
};


export default PayoutForm;

// action
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";

export const authLogInBegan = createAction("auth/logInBegan");
export const authLoggedIn = createAction("auth/loggedIn");
export const authlogInError = createAction("auth/logInError");
export const authRefreshed = createAction("auth/Refreshed");
export const authRefreshFailed = createAction("auth/RefreshFailed");
export const authLoggedOut = createAction("auth/loggedOut");
// export const userProfileUpdated = createAction("user/profileUpdated");


// reducer
const initialState = {
  accessToken: null,
  accessTokenExpiry: null,
  authenticated: false,
  isFetching: false,
  refreshStatus: "none",
  error: '',
};

const AuthReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(authLogInBegan.type, (state, action) => ({
      accessToken: null,
      accessTokenExpiry: null,
      authenticated: false,
      isFetching: true,
      refreshStatus: "login",
      error: '',
    }))
    .addCase(authlogInError.type, (state, action) => ({
      accessToken: null,
      accessTokenExpiry: null,
      authenticated: false,
      isFetching: false,
      refreshStatus: "login",
      error: action.payload,
    }))
    .addCase(authLoggedIn.type, (state, action) => ({
      accessToken: action.payload.access_token,
      accessTokenExpiry: action.payload.accessTokenExpiry,
      authenticated: true,
      isFetching: false,
      refreshStatus: "profile",
      error: '',
    }))
    .addCase(authRefreshed.type, (state, action) => ({
      accessToken: action.payload.access_token,
      accessTokenExpiry: action.payload.accessTokenExpiry,
      authenticated: true,
      isFetching: false,
      refreshStatus: "profile",
      error: '',
    }))
    .addCase(authRefreshFailed.type, (state, action) => ({
      ...state,
      refreshStatus: "landing",
    }))
    .addCase(authLoggedOut.type, (state, action) => ({
      accessToken: null,
      accessTokenExpiry: null,
      authenticated: false,
      isFetching: false,
      refreshStatus: "landing",
      error: '',
    }));
});

export default AuthReducer;
// export const getAuthStore = createSelector();

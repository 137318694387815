import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import {
  Button, FormGroup, Input,
} from 'reactstrap';
import { CountryDropdown } from 'react-country-region-selector';
import ReactGA from 'react-ga4';
import { Field, Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import renderRadioButtonField from '~~/form/RadioButton';
// import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
// import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
// import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';

import getTransText from '~~/get-text/getTransText';


const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const RegisterForm = ({ default_username }) => {
  const {
    langCode,
  } = useSelector(state => state.ui);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const required = value => (value ? undefined : getTransText('Required'));
  const moreThan5 = value => (value.length < 5 ? getTransText('Please input more than 5 char.') : undefined);
  const noSpaceAllowed = value => (value.indexOf(' ') >= 0 ? getTransText('No space allowed') : undefined);
  const maxLength = value => (value.length > 30 ? getTransText('No more than 30 chars.') : undefined);
  const usernameOnlyContain = value => {
    const re = new RegExp("^(?:[a-zA-Z0-9]|([._])(?!\\1)){5,30}$");
    if (!re.test(value)) {
      return getTransText("contains only letters, numbers, periods and underscores.");
    }
    return undefined;
  };

  const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const onSubmit = async (values) => {
    let errorMsg = "";
    await api.post('mail-register', values).then((response) => {
      ReactGA.event({
        category: 'Pre-Registration Engagement',
        action: "Registration By Password",
        label: values?.email,
      });
      // Success
      if (response) {
        history.push(`/RegSuccess/${values.email}`);
      }
    })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 503) {
            if (error.response.data.errors[0].message.includes('username')) {
              errorMsg = { ...errorMsg, username: getTransText("Username has been taken.") };
            }
            if (error.response.data.errors[0].message.includes('email')) {
              errorMsg = { ...errorMsg, email: getTransText("Email has been taken.") };
            }
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
        }
      });
    return errorMsg;
  };





  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        email: '',
        password: '',
        role: 'f3734efa-3f78-4f53-8ee6-8d06d6353089',
        username: default_username,
        country: '',
        language: langCode,
        avatar: 'e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae',
      }}
      validate={values => {
        const errors = {};
        if (!values.username) {
          errors.username = getTransText("Required");
        }
        if (!values.password) {
          errors.password = getTransText("Required");
        }
        if (values.password !== values.confirmPassword) {
          errors.confirmPassword = getTransText("Passwords do NOT match");
        }
        return errors;
      }}
    >
      {({
        handleSubmit,
        submitError,
        submitting,
        values
      }) => (
        <form className="form" onSubmit={handleSubmit}>
          <FormGroup className="form__form-group">
            <div className="form__form-group-field form__form-group-input-wrap--error-above">
              <div className="form__form-group-icon">
                <i className="las la-user"></i>
              </div>
              <Field
                name="username"
                type="text"
                className="input-without-border-radius"
                validate={composeValidators(required, moreThan5, noSpaceAllowed, maxLength, usernameOnlyContain)}
              >
                {({ input, meta }) => (
                  <>
                    <Input {...input} placeholder="username" />
                    {(meta.error || meta.submitError) && meta.touched && <span className="form__form-group-error">{meta.error || meta.submitError}</span>}
                  </>
                )}
              </Field>
            </div>
          </FormGroup>
          <FormGroup className="form__form-group">
            <div className="form__form-group-field form__form-group-input-wrap--error-above">
              <div className="form__form-group-icon">
                <i className="las la-at"></i>
              </div>
              <Field
                name="email"
                type="email"
                className="input-without-border-radius"
                validate={composeValidators(required, noSpaceAllowed)}
              >
                {({ input, meta }) => (
                  <>
                    <Input {...input} placeholder="example@gmail.com" />
                    {(meta.error || meta.submitError) && meta.touched && <span className="form__form-group-error">{meta.error || meta.submitError}</span>}
                  </>
                )}
              </Field>
            </div>
          </FormGroup>
          <FormGroup className="form__form-group">
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <i className="las la-lock"></i>
              </div>
              <Field
                name="password"
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field form__form-group-input-wrap--error-above">
                    <Input
                      {...input}
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder={getTransText("Password")}
                      className="input-without-border-radius"
                    />
                    <button
                      type="button"
                      className={`form__form-group-button${showPassword ? ' active' : ''}`}
                      onClick={toggleShowPassword}
                    ><EyeIcon />
                    </button>
                    {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
          </FormGroup>
          <FormGroup className="form__form-group">
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <i className="las la-lock"></i>
              </div>
              <Field
                name="confirmPassword"
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field form__form-group-input-wrap--error-above">
                    <Input
                      {...input}
                      name="confirmPassword"
                      type={showPassword ? 'text' : 'password'}
                      placeholder={getTransText("Confirm Password")}
                      className="input-without-border-radius"
                    />
                    <button
                      type="button"
                      className={`form__form-group-button${showPassword ? ' active' : ''}`}
                      onClick={toggleShowPassword}
                    ><EyeIcon />
                    </button>
                    {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
          </FormGroup>
          <FormGroup className="form__form-group">
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <i className="las la-flag"></i>
              </div>
              <Field
                name="country"
                validate={required}
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <CountryDropdown
                        {...input}
                        name="country"
                        valueType="short"
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </div>
          </FormGroup>

          <FormGroup className="form__form-group">
            <div>
              <Field
                name="isCreator"
                component={renderRadioButtonField}
                label={getTransText("register.form.creator")}
                radioValue="Creator"
                // styleType="colored"
              />
            </div>
            <div>
              <Field
                name="isCreator"
                component={renderRadioButtonField}
                label={getTransText("register.form.buyer")}
                radioValue="Buyer"
                // styleType="colored"
              />
            </div>
          </FormGroup>
          <div className="account__btns register__btns">
            <Button type="submit" color="primary" className="account__btn">
              {getTransText("Register")}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

export default RegisterForm;

import { log } from '@tensorflow/tfjs';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import AvatarRender from '~~/avatar_render';
import formatDate from '~~/datetime_render/FormatDate';

const OpenComment = ({ comment }) => (
  <div className="quo__comment_list" key={comment.id}>
    <div className="quo__comment__left˝">
      <Link to={`/p/${comment.username}`}>
        <div className="profile__avatar"><img src={AvatarRender(comment.avatar, comment.random_no)} className="icon" alt="avatar" /> </div>
      </Link>
    </div>
    <div className="quo__comment_detail">
      <div className="quo__comment_top">
        <div className="profile__name">{comment.username}</div>
        <div className="quo__date">{formatDate(comment.date_created)}</div>
      </div>
      <div className="quo__comment">{comment.comment}</div>
    </div>
  </div>
);

export default OpenComment;

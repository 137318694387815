// action
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

export const chatGetSentBoxConversations = createAction("chat/getSentBoxConversations");
export const chatGetReceiveBoxConversations = createAction("chat/getReceiveBoxConversations");
export const chatToggleActiveTab = createAction("chat/toggleActiveTab");
export const chatToggleActiveChat = createAction("chat/toggleActiveChat");
export const chatStartFetchingMessage = createAction("chat/startFetchingMessage");
export const chatGetMessage = createAction("chat/getMessage");
export const chatFetchMoreMessageBagan = createAction("chat/fetchMoreMessageBagan");
export const chatFetchMoreMessageSuccess = createAction("chat/fetchMoreMessageSuccess");
export const chatStartFetchSentBoxConversations = createAction("chat/startFetchSentBoxConversations");
export const chatStartFetchReceiveBoxConversations = createAction("chat/startFetchReceiveBoxConversations");
export const chatFetchedMoreSentBoxConversations = createAction("chat/fetchedMoreSentBoxConversations");
export const chatFetchedMoreReceiveBoxConversations = createAction("chat/fetchedMoreReceiveBoxConversations");
export const chatAddMessagBegan = createAction("chat/addMessagBegan");
export const chatAddedMessage = createAction("chat/addedMessage");
export const chatAddedSentBoxConversaion = createAction("chat/addedSentBoxConversaion");
export const chatUpdatedSentBoxConversaion = createAction("chat/updatedSentBoxConversaion");
export const chatAddedReceiveBoxConversaion = createAction("chat/addedReceiveBoxConversaion");
export const chatUpdatedReceiveBoxConversaion = createAction("chat/updatedReceiveBoxConversaion");
export const testMessageReceive = createAction("chat/testMessageReceive");
export const chatGetUnRead = createAction("chat/getUnRead");
export const updateUnreadNumberAndUpdateMemberReadTimeByConversationIdAndMemberAB = createAction("chat/updateUnreadNumberAndUpdateMemberReadTimeByConversationIdAndMemberAB");


export const chatLeaveChat = createAction("chat/leaveChat");


// export const userProfileUpdated = createAction("user/profileUpdated");


// reducer
const initialState = {
  activeTab: "1",
  activeChat: null,
  sentBoxConversations: [],
  receiveBoxConversations: [],
  chatMate: null,
  messages: [],
  pagination: {},
  bookmarkedChat: [],
  archiveContacts: [],
  fetchingSent: "none",
  fetchingReceived: "none",
  fetched: "none",
  isMessageAdd: "none",
  unRead: {
    sentBoxUnread: 0,
    receivedBoxUnread: 0,
  },
};

const ChatsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(chatGetSentBoxConversations.type, (state, action) => ({
      ...state,
      sentBoxConversations: action.payload,
      fetchingSent: "done",
    }))
    .addCase(chatGetReceiveBoxConversations.type, (state, action) => ({
      ...state,
      receiveBoxConversations: action.payload,
      fetchingReceived: "done",
    }))
    .addCase(chatToggleActiveTab.type, (state, action) => ({
      ...state,
      activeTab: action.payload,
    }))
    .addCase(chatToggleActiveChat.type, (state, action) => ({
      ...state,
      activeChat: action.payload,
    }))
    .addCase(chatStartFetchSentBoxConversations.type, (state, action) => ({
      ...state,
      fetchingSent: "start",
    }))
    .addCase(chatStartFetchReceiveBoxConversations.type, (state, action) => ({
      ...state,
      fetchingReceived: "start",
    }))
    .addCase(chatFetchedMoreSentBoxConversations.type, (state, action) => ({
      ...state,
      sentBoxConversations: state.sentBoxConversations.concat(action.payload),
      fetchingSent: "done",
    }))
    .addCase(chatFetchedMoreReceiveBoxConversations.type, (state, action) => ({
      ...state,
      receiveBoxConversations: state.receiveBoxConversations.concat(action.payload),
      fetchingReceived: "done",
    }))
    .addCase(chatGetMessage.type, (state, action) => ({
      ...state,
      messages: action.payload.messages.reverse(),
      chatMate: action.payload.chatMate,
      pagination: action.payload.pagination,
      fetched: "done",
    }))
    .addCase(chatStartFetchingMessage.type, (state, action) => ({
      ...state,
      messages: "",
      chatMate: "",
      fetched: "start",
    }))
    .addCase(chatFetchMoreMessageBagan.type, (state, action) => ({
      ...state,
      fetched: "start",
    }))
    .addCase(chatFetchMoreMessageSuccess.type, (state, action) => ({
      ...state,
      messages: action.payload.messages.reverse().concat(state.messages),
      pagination: action.payload.pagination,
      fetched: "done",
    }))
    .addCase(chatAddMessagBegan.type, (state, action) => ({
      ...state,
      isMessageAdd: "start",
    }))
    .addCase(chatAddedMessage.type, (state, action) => {
      // add Message if Chat is active
      if (state.activeChat === action.payload.conversation_id) {
        state?.messages && state.messages.push(action.payload);
      }
      state.isMessageAdd = "done";


      if (action.payload.isSentBox) {
        // Add to conversation list
        const index = state.sentBoxConversations.findIndex((c) => c.id === action.payload.conversation_id);
        // const newConversationArray = state.sentBoxConversations.find((c) => c.id === action.payload.conversation_id);
        if (index > -1) {
          const update = {
            ...state.sentBoxConversations[index],
            ...action.payload,
            "id": action.payload.conversation_id,
          };
          state.sentBoxConversations.splice(index, 1);
          state.sentBoxConversations.unshift(update);
        } else {
          const newConversation = {
            ...action.payload,
            "id": action.payload.conversation_id,
          };
          // Add the new conversation at the beginning
          state.sentBoxConversations.unshift(newConversation);
        }
        // Add to unread list
        if (state.activeChat !== action.payload.conversation_id) {
          const existedUnread = state.sentBoxConversations.find(c => c.id === action.payload.conversation_id);

          if (existedUnread) {
            existedUnread.membera_unread += 1;
          }
        }
      } else {
        const index = state.receiveBoxConversations.findIndex((c) => c.id === action.payload.conversation_id);
        // const newConversationArray = state.receiveBoxConversations.find((c) => c.id === action.payload.conversation_id);
        if (index > -1) {
          const update = {
            ...state.receiveBoxConversations[index],
            ...action.payload,
            "id": action.payload.conversation_id,
          };
          state.receiveBoxConversations.splice(index, 1);
          state.receiveBoxConversations.unshift(update);
        } else {
          const newConversation = {
            ...action.payload,
            "id": action.payload.conversation_id,
          };
          // Add the new conversation at the beginning
          state.receiveBoxConversations.unshift(newConversation);
        }
        // Add to unread list
        if (state.activeChat !== action.payload.conversation_id) {
          const existedUnread = state.receiveBoxConversations.find(c => c.id === action.payload.conversation_id);

          if (existedUnread) {
            existedUnread.memberb_unread += 1;
          }
        }
      }
    })
    .addCase(chatGetUnRead.type, (state, action) => ({
      ...state,
      unRead: action.payload,
    }))
    .addCase(updateUnreadNumberAndUpdateMemberReadTimeByConversationIdAndMemberAB.type, (state, action) => {
      const sentCon = state.sentBoxConversations.find((c) => c.id === action.payload.id);
      const receiveCon = state.receiveBoxConversations.find((c) => c.id === action.payload.id);
      if (sentCon) {
        if (sentCon.membera_read !== action.payload.membera_read) {
          sentCon.membera_read = action.payload.membera_read;
          sentCon.membera_unread = 0;
        }
        if (sentCon.memberb_read !== action.payload.memberb_read) {
          sentCon.memberb_read = action.payload.memberb_read;
          sentCon.memberb_unread = 0;
        }
      }
      if (receiveCon) {
        if (receiveCon.membera_read !== action.payload.membera_read) {
          receiveCon.membera_read = action.payload.membera_read;
          receiveCon.membera_unread = 0;
        }
        if (receiveCon.memberb_read !== action.payload.memberb_read) {
          receiveCon.memberb_read = action.payload.memberb_read;
          receiveCon.memberb_unread = 0;
        }
      }
    })
    .addCase(chatLeaveChat.type, (state, action) => ({
      ...state,
      activeChat: null,
      chatMate: null,
    }))
    .addCase(testMessageReceive.type, (state, action) => ({
      ...state,
      chatMate: action.payload,
    }));
});

export default ChatsReducer;
// export const getAuthStore = createSelector();

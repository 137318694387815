import { DateTime } from "luxon";
import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ButtonToolbar, Card, CardBody } from 'reactstrap';
import { closedModal, openedModal } from '~/store/opencall';
import AvatarRender from '~~/avatar_render';
import getTransText from '~~/get-text/getTransText';
import ModalParent from '~~/modal_parent/ModalParent';
import quoLogoClassSwitch from '~~/quoLogoClass/quoLogoClassSwitch';

import { roundUpHour } from '~~/datetime_render/FormatDate';
import OpenDetailContainer from '../OpenDetailContainer';

const Searching = ({
  quo,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [toggler, setToggler] = useState(false);
  const [friendInfo, setFriendInfo] = useState(null);
  const true_price = quo.countering_price || quo.latest_price;
  const s = DateTime.fromSeconds(quo.charge_date, { zone: "utc" });
  const {
    langCode,
  } = useSelector(state => state.ui);
  const likedArray = quo?.latest_liked_person
    ? quo.latest_liked_person.split(",").map((item, index) => ({
      key: `liked_person_${index}`,
      value: item.trim(),
    }))
    : [];
  const likedRandomArray = quo?.latest_liked_person_random ? quo?.latest_liked_person_random.split(",")?.map((item) => item.trim()) : [];

  const LangCodeForCurrency = () => {
    switch (langCode) {
      case 'zh-TW':
        return 'zh-TW';
      case 'en-US':
        return 'en-US';
      case 'ja':
        return 'ja-JP';
      default:
        return 'en-US';
    }
  };

  const closeModal = e => {
    dispatch(closedModal());
    history.replace(`/opencall/`);
  };

  const switchToggler = () => {
    setToggler(!toggler);
    dispatch(openedModal());
    history.replace(`/opencall/${quo.id}`);
  };


  const timerRenderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      // Render a completed state
      return ("");
    }
    // Render a countdown
    return (
      <div className="quo__timer">
        <span className="countdown_group">
          <span className="countdown_number">
            {days}
          </span>
          <span className="countdown_unit">
            {getTransText("D")}
          </span>
        </span>
        <span className="countdown_group">
          <span className="countdown_number">
            {hours}
          </span>
          <span className="countdown_unit">
            {getTransText("H")}
          </span>
        </span>
        <span className="countdown_group">
          <span className="countdown_number">
            {minutes}
          </span>
          <span className="countdown_unit">
            {getTransText("M")}
          </span>
        </span>
      </div>
    );
  };

  const shareTrigger = async () => {
    if (navigator.share) {
      const shareData = {
        title: quo.latest_title,
        url: `${process.env.REACT_APP_FRONTEND_URL}opencall/${quo.id}`,
      };
      await navigator.share(shareData);
    }
  };
  return (
    <React.Fragment key={`${quo.id}-Searching`}>
      <ModalParent className="open__detail__modal" toggler={toggler} switchToggler={switchToggler} onClosed={closeModal}>
        <OpenDetailContainer friendInfo={friendInfo} quo={quo} switchToggler={switchToggler} />
      </ModalParent>
      <Card className="open__call-quo__card">
        <CardBody className="panel__body quo__body">
          <Button className="open__call-link" onClick={switchToggler}></Button>
          {navigator.share
            ? (<Button color="primary" className="btn-share control-btn" onClick={shareTrigger}><i className="las la-external-link-alt"></i></Button>)
            : ""}
          <div className="quo__badge">
            <i className={`quo__badge-pic ${quoLogoClassSwitch(true_price)}`}></i>
            <div className="quo__price">{new Intl.NumberFormat(LangCodeForCurrency(), { style: 'currency', currency: 'usd' }).format(true_price)}</div>
          </div>
          <div className="quo__title">{quo.latest_title}</div>
          <div className="quo__delivery">
            {`${getTransText("opencall.buying-selling-board.quopanel.closein")}: `}
            <Countdown date={roundUpHour(s.plus({ hours: 48 })).setZone("system")} renderer={timerRenderer} />
          </div>

          {quo.isEnrolled
            ? (
              <ButtonToolbar>
                <Button
                  className="btn-pick_me"
                  color="secondary"
                  onClick={switchToggler}
                  disabled={true}
                >{`${getTransText("opencall.buying-selling-board.quopanel.enrolled")}`}
                </Button>
              </ButtonToolbar>

            ) : (
              <ButtonToolbar>
                <Button
                  className="btn-pick_me"
                  color="secondary"
                  onClick={switchToggler}
                  disabled={Boolean(quo.isMe)}
                >{`${getTransText("opencall.buying-selling-board.quopanel.pickme")}`}
                </Button>
              </ButtonToolbar>
            )}
          {quo.total_like > 0
            ? (
              <div className="open__call-enroll" key={`${quo.id}-totallike`}>
                {likedArray?.map((x, i) => (
                  <div className="quo__status-avatar" key={`${x.key}`}>
                    <img
                      src={AvatarRender(x.value, Number(likedRandomArray[i]))}
                      alt=""
                    />
                  </div>
                ))}
                <span className="open__call-num">
                  {quo.total_like > 3 && (`+${quo.total_like - 3}`)}
                </span>
                <span>
                  {`${getTransText("opencall.buying-selling-board.quopanel.enroll")}`}
                </span>
              </div>
            ) : (
              <div className="open__call-enroll" key={`${quo.id}-firstlike`}>
                <span>
                  {`${getTransText("opencall.buying-selling-board.quopanel.first-enroll")}`}
                </span>
              </div>
            )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default Searching;

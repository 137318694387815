import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
// import CloseIcon from 'mdi-react/CloseIcon';
// import MinusIcon from 'mdi-react/MinusIcon';
// import AutorenewIcon from 'mdi-react/AutorenewIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import InvitingQuoDetail from '~~/quo-details/InvitingQuoDetail';
import InvitingStatus from '~~/status_panel/InvitingStatus';

import formatDate from '~~/datetime_render/FormatDate';

const QuoPanel = ({
  quo,
}) => (
  <Card>
    <CardBody className="panel__body quo__body quo__body-inviting">
      <div className="panel__refresh"><LoadingIcon /></div>
      <div className="panel__top">
        <div className="quo__start">{formatDate(quo.date_created)}</div>
        <div className="quo__more">
          <i className="las la-angle-right"></i>
        </div>
      </div>
      <InvitingQuoDetail quo={quo} />
      <InvitingStatus quo={quo} />
      <Link className="quo__body__link" to={{ pathname: `/q/${quo.quo_id ? quo.quo_id : quo.id}`, state: { from: location.pathname } }}></Link>
    </CardBody>
  </Card>
);

export default QuoPanel;

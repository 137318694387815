
import { client } from '~/apiCalls/directusConfig';
// Inbox API

export const getConversations = async () => {
  try {
    const res = await client.request(() => ({
      path: `/qm/`,
      method: "GET",
    }));
    return (res[0]);
  } catch (err) {
    // // console.log(err);
  }
};
export const getSentBoxConversations = async (data) => {
  try {
    const res = await client.request(() => ({
      path: `/qm/sentbox`,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }));
    return (res);
  } catch (err) {
    console.log(err);
  }
};

export const getMemberDetailsByConversationId = async ({ conversationId, messageId }) => {
  try {
    const res = await client.request(() => ({
      path: `/qm/get-member-by-conversation-id/${conversationId}/${messageId}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    console.log(err);
  }
};
export const getReceiveBoxConversations = async (data) => {
  try {
    const res = await client.request(() => ({
      path: `/qm/receivebox`,
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }));
    return (res);
  } catch (err) {
    console.log(err);
  }
};

export const getMemberB = async (conversation_id) => {
  try {
    const config = { "conversation_id": conversation_id };
    const res = await client.request(() => ({
      path: '/qm/getMemberBId/',
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res[0]);
  } catch (err) {
    // // console.log(err);
  }
};

export const getMessages = async ({ conversation_id, page }) => {
  try {
    const config = { "conversation_id": conversation_id, "page": page };
    const res = await client.request(() => ({
      path: '/qm/messages/',
      method: "POST",
      body: JSON.stringify(config),
    }));

    return (res[0]);
  } catch (err) {
    // // console.log(err);
  }
};

export const sendMessage = async (message) => {
  try {
    const res = await client.request(() => ({
      path: '/qm/messages/send',
      method: "POST",
      body: JSON.stringify(message),
    }));
    return (res[0][0]);
  } catch (err) {
    // // console.log(err);
  }
};

export const updateReadTime = async (conversation_id) => {
  try {
    const config = {
      "conversation_id": conversation_id,
    };

    const res = await client.request(() => ({
      path: `/qm/updateReadTime`,
      method: "PATCH",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};

export const getTotalUnreadNumberForMenu = async () => {
  try {
    const res = await client.request(() => ({
      path: `/qm/total-unread-number`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};


export const createConversation = async (username) => {
  try {
    const config = { "username": username };
    const res = await client.request(() => ({
      path: "/qm/getOrCreateNewConversation",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const getQuoActivity = async (id) => {
  try {
    const res = await client.request(() => ({
      path: `/manage-quo/quo_activity/${id}`,
      method: "GET",
    }));
    return (res[0]);
  } catch (err) {
    // // console.log(err);
  }
};



import React from 'react';
import {
  Modal,
  ModalBody,
  Button,
  ButtonGroup
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import getTransText from '~~/get-text/getTransText';
import { accepted18 } from '~/store/ui';

const ModalWarning = () => {
  const {
    isAccepted18,
  } = useSelector(state => state.ui);
  const history = useHistory();
  const dispatch = useDispatch();

  const switchToggler = () => {
    history.push("/selling");
  };

  const onAccepted = () => {
    localStorage.setItem('18-accepted', "true");
    dispatch(accepted18());
  };

  return (
    <Modal isOpen={!isAccepted18} toggle={() => switchToggler()} fade={false} modalClassName="warning__modal" backdropClassName="warning__modal__backdrop">
      <ModalBody>
        <i className="icon-eighteen">18</i>
        <h3 className="page-title">{getTransText("modal.warning.title")}</h3>
        <p>{getTransText("modal.warning.paragraph1")}</p>
      </ModalBody>
      <ButtonGroup>
        <Button
          color="warning"
          onClick={() => onAccepted()}
        >
          {getTransText("modal.warning.button.continue")}
        </Button>{' '}
        <Link
          className="btn btn-primary"
          to="/selling"
        >
          {getTransText("modal.warning.button.cancel")}
        </Link>
      </ButtonGroup>
    </Modal>
  );
};
export default ModalWarning;

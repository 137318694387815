import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, Link, useParams } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import getTransText from '~~/get-text/getTransText';

const AcceptInvite = () => {
  const { token } = useParams();
  const [done, setDone] = useState(false);

  const apiAccept = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const acceptInvitation = async () => {
    const config = {
      token,
    };
    const username = await apiAccept.post('mail-register/accept-invite', config);
    if (username) {
      setDone(true);
    }
  };

  useEffect(async () => {
    if (!done) {
      const res = await acceptInvitation();
    }
  }, [token]);

  return (
    <div className="verification__container quabal__container">
      <Container>
        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <div className="badge-icon">
                  <span className="icon"><i className="las la-check"></i></span>
                  <h5>{getTransText("Email Verification")}</h5>
                  <p>{getTransText("Your email has been verified. Please ")}<NavLink to="/log_in">{getTransText("Login")}</NavLink>{getTransText(" to get started.")}</p>
                </div>
              </CardBody>
            </Card>
            <Link className="quobal__logo horizontal" to="/l" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AcceptInvite;

/* eslint-disable global-require */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Col } from 'reactstrap';
import { createBookmark } from '~/apiCalls/userApi';
import { addTokenToURL } from '~/apiCalls/authApi';
import AvatarRender from '~~/avatar_render';
import getTransText, { GetCountryName } from '~~/get-text/getTransText';


const BookmarkedProfileCard = ({ info }) => {
  const [isBookmarked, setIsBookmarked] = useState(true);
  const urlWithToken = addTokenToURL(`${process.env.REACT_APP_API_URL}file-access/${info.gallery}`);
  const cardBG = (typeof (info.gallery) !== 'undefined' && info.gallery != null)
    ? {
      backgroundImage: `url(${urlWithToken})`,
    }
    : {
      backgroundImage: `url(${process.env.REACT_APP_FRONTEND_URL}img/bookmarks-bg.jpg)`,
    };

  const Ava = AvatarRender(info.avatar, info.random_no);

  const HandleBookmarkClick = async (action) => {
    const res = await createBookmark(info.username, action);
    if (res?.success) {
      setIsBookmarked(!isBookmarked);
    }
  };

  const succeed = (
    <Button color="danger" onClick={() => HandleBookmarkClick("cancel")} outline>{getTransText("bookmarked.profile.remove")}</Button>
  );

  const notsucceed = (
    <Button color="danger" onClick={() => HandleBookmarkClick("add")} outline>{getTransText("bookmarked.profile.follow")}</Button>
  );



  return (
    <div className="bookmarked__profile__item">
      <Link to={`/p/${info.username}`}>

        <div className="profile__avatar">
          <img src={Ava} alt="avatar" />
        </div>
        <div className="bookmarked__detail">
          <div className="profile__top">
            <div className="profile__name">{info.username}</div>
          </div>
          <div className="profile__location">{GetCountryName(info.location)}</div>
          {/* <ul className="profile__sns">
              <li><i className="lab la-instagram"></i></li>
              <li><i className="lab la-twitter"></i></li>
            </ul> */}
          {/* <div className="profile__description">{info.description}</div> */}
        </div>
      </Link>
      <ButtonGroup>
        {isBookmarked ? succeed : notsucceed}
      </ButtonGroup>
    </div>
  );
};

export default BookmarkedProfileCard;

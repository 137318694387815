import classNames from 'classnames';
import React, { useState } from 'react';
import {
  useHistory
} from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardTitle, Col, Container,
  Row
} from 'reactstrap';
import getTransText from '~~/get-text/getTransText';
import ModalParent from '~~/modal_parent/ModalParent';

const ProfileStepCard = ({ done }) => {
  const [image, setImage] = useState();
  const history = useHistory();

  const [toggler, setToggler] = useState(false);

  const switchToggler = () => {
    setToggler(!toggler);
  };

  const ChangeProfilePictureClick = (e) => {
    setImage(e.target.files[0]);
    switchToggler();
  };

  const steps__card = classNames({
    "steps__card": true,
    "completed": done,
  });

  return (
    <>
      <ModalParent toggler={toggler} switchToggler={switchToggler}>

      </ModalParent>
      <Card className={steps__card}>
        <CardBody>
          <Container className="steps__card__container">
            <Row>
              <Col xs={3} md={2} className="step__icon">
                <i className="las la-user"></i>
              </Col>
              <Col xs={6} md={8}>
                <CardTitle className="bold-text" tag="h5">{getTransText('Profile')}</CardTitle>
                <p>{getTransText('Complete your profile by uploading a profile picture and writing something about you.')}</p>
              </Col>
              <Col xs={3} md={2} className="step__button">
                <Button color="primary" outline type="button" onClick={() => history.push("/accountSetting/profile")}><i className="las la-angle-right"></i></Button>
                <i className="las la-check-circle"></i>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </>
  );
};

export default ProfileStepCard;

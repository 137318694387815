// action
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";

export const changedLangCode = createAction("ui/changedLangCode");
export const uiTextLoaded = createAction("ui/textLoaded");
export const uiTextLoadError = createAction("ui/textLoadError");
export const uiGroupTextLoaded = createAction("ui/GrouptextLoaded");
export const accepted18 = createAction("ui/accepted18");



// reducer
const initialState = {
  langCode: "en-US",
  webtext: [],
  grouptext: [],
  isAccepted18: false,
};

const uiReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changedLangCode.type, (state, action) => ({
      ...state,
      langCode: action.payload,
    }))
    .addCase(uiTextLoaded.type, (state, action) => ({
      ...state,
      webtext: action.payload,
    }))
    .addCase(uiTextLoadError.type, (state, action) => ({
      ...state,
      error: action.payload,
    }))
    .addCase(uiGroupTextLoaded.type, (state, action) => ({
      ...state,
      grouptext: action.payload,
    }))
    .addCase(accepted18.type, (state, action) => ({
      ...state,
      isAccepted18: true,
    }));
});

export default uiReducer;
// export const getAuthStore = createSelector();

import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { Badge } from 'reactstrap';
// import MailIcon from 'mdi-react/MailIcon';

const TopbarMail = ({
  title,
  icon,
  route,
  onClick,
}) => {
  const {
    unRead,
  } = useSelector((state) => state.chats);

  return (
    <NavLink
      to={route}
      onClick={onClick}
      activeClassName="sidebar__link-active"
    >
      <li className="sidebar__link">
        <i className={`sidebar__link-icon las ${icon}`}></i>
        {(unRead.sentBoxUnread + unRead.receivedBoxUnread) > 0
          ? (
            <div className="topbar__btn topbar__btn--mail topbar__btn--new">
              <div className="topbar__btn-new-label">
                <div />
              </div>
            </div>
          )
          : ""}
        <p className="sidebar__link-title">
          {title}
          {(unRead.sentBoxUnread + unRead.receivedBoxUnread) > 0 ? <Badge className="sidebar__link-badge"> </Badge> : ""}
        </p>
      </li>
    </NavLink>
  );
};

export default TopbarMail;

import React, { useEffect, useState } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
// import TagManager from 'react-gtm-module';
import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css';
import Router from './Router';
import ScrollToTop from './ScrollToTop';
import RouteChangeTracker from './RouteChangeTracker';
import { setStripePromise } from '~/store/marketplace';

/* eslint-disable global-require */
if (process.env.REACT_APP_ENV_S === "local") {
  require('../../scss/app-local.scss');
}
if (process.env.REACT_APP_ENV_S === "development") {
  require('../../scss/app-dev.scss');
}

if (process.env.REACT_APP_ENV_S === "staging") {
  require('../../scss/app-stg.scss');
}

if (process.env.REACT_APP_ENV_S === "production") {
  require('../../scss/app-pro.scss');
}

const stripePromise = loadStripe(process.env.REACT_APP_PAYMENT_APIKEY);

/* eslint-enable global-require */
const App = () => {
  const dispatch = useDispatch();

  ReactGA.initialize(process.env.REACT_APP_GA);

  useEffect(() => {
    dispatch(setStripePromise(stripePromise));
  }, []);

  // TagManager.initialize(process.env.REACT_APP_GTM);

  return (
    <BrowserRouter>
      <Helmet>
        <meta name="subject" content="QUObal | Buying Selling Custom Content Platform" />
        <meta name="description" content="QUOBal is the only platform of its kind that empowers fans to request what kind of content they want creators to make." />
        <meta property="og:subject" content="QUObal | Buying Selling Custom Content Platform" />
        <meta property="og:description" content="QUOBal is the only platform of its kind that empowers fans to request what kind of content they want creators to make." />
        <meta property="og:url" content="https://www.quobal.com/" />
        <meta property="og:image" content="http://www.quobal.com/img/bkend/quobal-logo-horizontal.png" />
        <title>QUObal | Buying Selling Custom Content Platform</title>
      </Helmet>
      <ScrollToTop>
        <RouteChangeTracker />
        <div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div>
        <div>
          <Router />
        </div>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;

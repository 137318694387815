import { DateTime } from "luxon";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import { Link, useLocation } from "react-router-dom";
import AvatarRender from '~~/avatar_render';
import formatDate, { roundUpHour } from '~~/datetime_render/FormatDate';
import getTransText from '~~/get-text/getTransText';
import quoLogoClassSwitch from '~~/quoLogoClass/quoLogoClassSwitch';
import UsernameRender from '~~/username_render';
import { getOutreachPreQuo } from '~/apiCalls/quoApi';

const InvitingQuoDetail = ({ quo }) => {
  const [friendInfo, setFriendInfo] = useState(null);
  const [isSender, setIsSender] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const true_price = quo.countering_price || quo.latest_price;
  const [socialInfo, setSocialInfo] = useState(null);
  const location = useLocation();
  const {
    langCode,
  } = useSelector(state => state.ui);
  const {
    id,
  } = useSelector(state => state.user.userData);

  const LangCodeForCurrency = () => {
    switch (langCode) {
      case 'zh-TW':
        return 'zh-TW';
      case 'en-US':
        return 'en-US';
      case 'ja':
        return 'ja-JP';
      default:
        return 'en-US';
    }
  };

  const annoymous = {
    avatar: "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae",
    username: getTransText("Anonymous"),
    random_no: 0,
  };
  useEffect(async () => {
    await getOutreachPreQuo(quo.id)
      .then((response) => {
        if (response && Object.keys(response).length > 0) {
          setSocialInfo(response);
        } else {
          setSocialInfo([]);
        }
      });
  }, [quo]);

  useEffect(async () => {
    if (socialInfo) {
      setFriendInfo(annoymous);
    }
    setIsSender(true);
    setIsLoaded(true);
  }, [socialInfo]);


  return (
    <div className="quo__info">
      <div className="quo__badge">
        <i className={`quo__badge-pic ${quoLogoClassSwitch(true_price)}`}></i>
        <div className="quo__price">{new Intl.NumberFormat(LangCodeForCurrency(), { style: 'currency', currency: 'usd' }).format(true_price)}</div>
      </div>
      <div className="quo__info-right">
        <div className="quo__from">
          <div className="quo__status-avatar">
            <img
              src={
                AvatarRender(friendInfo?.avatar, friendInfo?.random_no)
              }
              alt="avatar"
            />
          </div>
          <span className="quo__status-name">
            {UsernameRender(friendInfo?.username, quo.receiver === id)}
          </span>
        </div>
        <h5 className="bold-text">
          <>
            <div>
              {quo.latest_title}
            </div>
          </>
        </h5>
        <ReactQuill
          value={quo.latest_description}
          readOnly={true}
        />
        <div className="quo__delivery">
          {quo.current_status === "Pending" || quo.current_status === "Accepted" || quo.current_status === "Countering"
            ? (
              <>
                <strong>{`${getTransText("Delivery before")}: `}</strong>
                {`${formatDate(roundUpHour(DateTime.fromSeconds(quo.charge_date).plus({ hours: 144 })))}`}
              </>
            )
            : ""}
        </div>
      </div>
    </div>
  );
};

export default InvitingQuoDetail;

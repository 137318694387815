import React, { useState } from 'react';
import { Col } from 'reactstrap';
import { addTokenToURL } from '~/apiCalls/authApi';
import 'react-lazy-load-image-component/src/effects/blur.css';
import FilePreview from '~~/file-preview';
import LightBoxModalParent from '~~/modal_parent/LightBoxModalParent';

const MediaPreview = ({
  file,
}) => {
  const downloadUrl = addTokenToURL(`${process.env.REACT_APP_API_URL}file-access/private/${file?.file_permission_id}?download`);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <LightBoxModalParent downloadSrc={downloadUrl} isOpen={modal} toggle={toggle}>
        {file && <FilePreview mime={file.type} src={`file-access/private/${file.file_permission_id}`} title={file.filename_download} />}
        {/* <LightBox file={file} /> */}
      </LightBoxModalParent>

      <Col xs={6}>
        <FilePreview mime={file.type} src={`file-access/private/${file.file_permission_id}`} title={file.filename_download} isThumbnail={true} onClick={toggle} />
      </Col>

    </>
  );
};

export default MediaPreview;

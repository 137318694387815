import React from 'react';
import { Link } from 'react-router-dom';
import ForgetPasswordForm from '~~/forget-password/ForgetPasswordForm';
import getTransText from '~~/get-text/getTransText';

const Register = () => (
  <div className="account account--not-photo">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">{getTransText("Welcome to QUObal")}</h3>
          <h4 className="account__subhead subhead">{getTransText("Forget Password")}</h4>
        </div>
        <ForgetPasswordForm />
        <div className="account__have-account">
          <p>{getTransText("Go back to")}
            <Link
              to={{
                pathname: "/log_in",
              }}
            >
              {getTransText("Login")}
            </Link>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Register;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { Link, useParams, useLocation } from 'react-router-dom';
import { directusLoginCall } from '~/apiCalls/authApi';
import LogInForm from '~/components/login/LogInForm';
import { apiCallBegan } from '~/store/api';

import {
  authLoggedIn, authLogInBegan, authlogInError
} from '~/store/auth';
import getTransText from '~~/get-text/getTransText';

const LogIn = () => {
  const {
    action, role, type, quoId,
  } = useSelector(state => state.redirect);

  const googleLogin = () => {
    if (type === "outreach") {
      if (role === "buyer") {
        return (window.open(`${process.env.REACT_APP_API_URL}auth/login/google?redirect=${process.env.REACT_APP_FRONTEND_URL}outreach-pay/${quoId}?exlogin=true`, "_self"));
      }
      if (role === "creator") {
        return (window.open(`${process.env.REACT_APP_API_URL}auth/login/google?redirect=${process.env.REACT_APP_FRONTEND_URL}iq/${quoId}?exlogin=true`, "_self"));
      }
    }
    if (type === "payment") {
      if (role === "buyer") {
        return (window.open(`${process.env.REACT_APP_API_URL}auth/login/google?redirect=${process.env.REACT_APP_FRONTEND_URL}pay/${quoId}?exlogin=true`, "_self"));
      }
    }
    if (type === "buyingselling") {
      if (role === "creator") {
        return (window.open(`${process.env.REACT_APP_API_URL}auth/login/google?redirect=${process.env.REACT_APP_FRONTEND_URL}opencall/${quoId}?exlogin=true`, "_self"));
      }
    }
    return (window.open(`${process.env.REACT_APP_API_URL}auth/login/google?redirect=${process.env.REACT_APP_FRONTEND_URL}exlogin/`, "_self"));
  };

  const twitterLogin = () => {
    if (type === "outreach") {
      if (role === "buyer") {
        return (window.open(`${process.env.REACT_APP_API_URL}auth/login/twitter?redirect=${process.env.REACT_APP_FRONTEND_URL}outreach-pay/${quoId}?exlogin=true`, "_self"));
      }
      if (role === "creator") {
        return (window.open(`${process.env.REACT_APP_API_URL}auth/login/twitter?redirect=${process.env.REACT_APP_FRONTEND_URL}iq/${quoId}?exlogin=true`, "_self"));
      }
    }
    if (type === "payment") {
      if (role === "buyer") {
        return (window.open(`${process.env.REACT_APP_API_URL}auth/login/twitter?redirect=${process.env.REACT_APP_FRONTEND_URL}pay/${quoId}?exlogin=true`, "_self"));
      }
    }
    if (type === "buyingselling") {
      if (role === "creator") {
        return (window.open(`${process.env.REACT_APP_API_URL}auth/login/twitter?redirect=${process.env.REACT_APP_FRONTEND_URL}opencall/${quoId}?exlogin=true`, "_self"));
      }
    }
    return (window.open(`${process.env.REACT_APP_API_URL}auth/login/twitter?redirect=${process.env.REACT_APP_FRONTEND_URL}exlogin/`, "_self"));
  };

  // const facebookLogin = () => window.open(`${process.env.REACT_APP_API_URL}auth/login/facebook?redirect=${process.env.REACT_APP_FRONTEND_URL}exlogin`, "_self");
  const dispatch = useDispatch();
  const { reason } = useParams();


  const onSubmit = (values) => {
    dispatch(apiCallBegan({
      directusCall: directusLoginCall,
      data: values,
      onStart: authLogInBegan.type,
      onSuccess: authLoggedIn.type,
      onError: authlogInError.type,
    }));
  };

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <div className="account__logo"></div>
            <div className="account__title">{getTransText("quobal.login.signinto")}</div>
          </div>
          <LogInForm onSubmit={onSubmit} reason={reason} />
          <div className="account__or">
            <p>{getTransText("Or Easily Using")}</p>
          </div>
          <div className="account__social">
            {/* <button type="button" className="account__social-btn account__social-btn--facebook" onClick={() => facebookLogin()}>
              <FacebookIcon />
            </button> */}
            <Button color="primary" id="googleLoginClick" outline onClick={googleLogin}><i className="icon-google"></i>{getTransText("Sign in with Google")}</Button>
            {/* <Button color="primary" outline onClick={twitterLogin}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
              </svg>
              {getTransText("Sign in with Twitter")}
            </Button> */}
          </div>
          <div className="account__more">{getTransText("Don't have an account?")} <Link to="/register">{getTransText("Register Now")}</Link>.</div>
        </div>
        <div className="copyright">
          {getTransText("quobal.agree.to")} <Link to="/terms-and-condition" target="_blank">{getTransText("quobal.tnc")}</Link>{' '}
          {getTransText("quobal.agree.and")} <Link to="/privacy-policy" target="_blank">{getTransText("quobal.privacy")}</Link>
        </div>
      </div>
    </div>
  );
};

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Scrollbar from 'react-smooth-scrollbar';
import getTransText from '~~/get-text/getTransText';
import SidebarContent from './SidebarContent';
import SidebarProfile from './SidebarProfile';

const Sidebar = ({
  changeMobileSidebarVisibility, sidebarShow, sidebarCollapse,
}) => {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebarShow,
    'sidebar--collapse': sidebarCollapse,
  });

  return (
    <div className={sidebarClass}>
      {/* <button
        type="button"
        aria-label="sidebar visibility"
        className="sidebar__back"
        onClick={changeMobileSidebarVisibility}
      /> */}
      <div className="quobal__logo__container">
        <Link className="quobal__logo" to="/buying" />
        <Link to="/buying">{getTransText("About QUOBal")}</Link>
      </div>
      <SidebarProfile onClick={changeMobileSidebarVisibility} />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent onClick={changeMobileSidebarVisibility} />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent
            onClick={changeMobileSidebarVisibility}
          />
        </div>
      </Scrollbar>
    </div>
  );
};

Sidebar.propTypes = {
  sidebarShow: PropTypes.bool.isRequired,
  sidebarCollapse: PropTypes.bool.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Field, Form, FormSpy } from 'react-final-form';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  FormGroup,
  Label,
  Input,
  UncontrolledCollapse
} from 'reactstrap';
import getTransText from '~~/get-text/getTransText';
import CheckBox from '~~/form/CheckBox';
import { createOutreachPreQuo } from '../../apiCalls/quoApi';

const PlaceQuo = () => {
  const {
    userData,
  } = useSelector(state => state.user);
  const [username, setUsername] = useState();
  const history = useHistory();
  const [isToggleCheckboxEnabled, setIsToggleCheckboxEnabled] = useState(false);

  const handleToggleCheckBox = () => {
    setIsToggleCheckboxEnabled(!isToggleCheckboxEnabled);
  };

  const onSubmit = async (values) => {
    ReactGA.event({
      category: 'Pre-Registration Engagement',
      action: "Clicked Open a Quo Button",
      label: JSON.stringify(values),
    });

    const credentials = values;
    if (values?.instagram_username) {
      credentials.social_username.push({ ig_user: values.instagram_username });
    }
    if (values?.facebook_username) {
      credentials.social_username.push({ fb_user: values.facebook_username });
    }
    if (values?.twitter_username) {
      credentials.social_username.push({ x_user: values.twitter_username });
    }
    credentials.is_open_call = !values.is_target;
    const res = await createOutreachPreQuo(credentials);
    history.push(`/outreach-pay/${res}`);
  };

  const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);
  const mustBeNumber = value => (Number.isNaN(value) ? getTransText('Must be a number') : undefined);
  const minPrice = value => (value < 0.5 ? getTransText('At least $0.50 usd') : undefined);
  const maxPrice = value => (value > 10000 ? getTransText('No more than $10000 usd') : undefined);
  const isOpenCallInput = value => (value > 10000 ? getTransText('No more than $10000 usd') : undefined);


  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState('');

  const handleCheckboxChange = () => {
    setIsDisabled(!isDisabled);
  };

  const handleLogoSelect = (event) => {
    setSelectedLogo(event.target.value);
  };

  const logos = [
    { id: 'facebook', src: "/", name: 'Facebook' },
    { id: 'twitter', src: "/", name: 'Twitter' },
    { id: 'instagram', src: "/", name: 'Instagram' },
    // add more logos as needed
  ];



  return (
    <React.Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          title: "",
          request: "",
          price: "",
          social_username: [],
          requestor: userData.email,
        }}
        validate={values => {
          const errors = {};
          if (!values.title) {
            errors.title = getTransText('quoform.error.required.title');
          }
          if (!values.request) {
            errors.request = getTransText("Please write your request");
          } else if (!values.price) {
            errors.price = getTransText('Please fill in price');
            // eslint-disable-next-line 
          }
          if (isNaN(values.price)) {
            errors.price = getTransText('Must be a number');
          }
          if (values.is_target && (!values.facebook_username && !values.instagram_username && !values.twitter_username)) {
            errors.is_target = getTransText('quoform.error.socialmedia.atleastone');
          }
          return errors;
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <form className="form quo__form" onSubmit={handleSubmit}>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("quoform.title.title")}</span>
              <Field
                name="title"
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <Input
                        {...input}
                        name="title"
                        type="text"
                        placeholder={getTransText("quoform.title.placeholder")}
                        className="input-without-border-radius"
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("Your Request")}</span>
              <Field
                name="request"
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <ReactQuill
                        {...input}
                        formats={[]}
                        modules={{
                          toolbar: []
                        }}
                        name="request"
                        type="textarea"
                        placeholder={getTransText("I would like...")}
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
            <FormGroup className="form__form-group form__form-group-price">
              <span className="form__form-group-label">{getTransText("Request Price")}</span>
              <Field
                name="price"
                validate={composeValidators(mustBeNumber, minPrice, maxPrice)}
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <Input
                        {...input}
                        name="price"
                        type="text"
                        placeholder={getTransText("USD")}
                        className="input-without-border-radius"
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
            <FormGroup className="form__form-group">
              <Field name="is_target" component="input" type="checkbox" id="is_target">
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <CheckBox
                        {...input}
                        type="checkbox"
                        className="quoform"
                        name="is_target"
                        label={getTransText("quoform.targetseller")}
                      // value={isToggleCheckboxEnabled}
                      // checked={isToggleCheckboxEnabled}
                      // onChange={handleToggleCheckBox}
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
            <FormSpy subscription={{ values: true }}>
              {(sub) => (
                <div style={{ display: sub.values.is_target ? "block" : "none" }}>
                  <p className="form__form-group-label">{getTransText("quoform.targetseller.sharesocialmedia")}</p>
                  <FormGroup className="form__form-group-socialmeia">
                    <Button color="primary" className="control-btn" id="quo-creator_facebook"><i className="lab la-facebook-f"></i></Button>
                    <Button color="primary" className="control-btn" id="quo-creator_instagram"><i className="lab la-instagram"></i></Button>
                    <Button color="primary" className="control-btn" id="quo-creator_twitter">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                      </svg>
                    </Button>
                  </FormGroup>

                  <UncontrolledCollapse toggler="#quo-creator_facebook">
                    <FormGroup className="form__form-group-socialmeia">
                      <Label for="instagram_username"><i className="lab la-facebook-f"></i></Label>
                      <Field
                        name="facebook_username"
                      >
                        {({ input, meta }) => (
                          <div className="form__form-group-field">
                            <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                              <span>https://facebook.com/</span>
                              <Input
                                {...input}
                                name="facebook_username"
                                type="text"
                                placeholder={isDisabled ? "Anyone" : getTransText("quoform.targetseller.facebook")}
                                disabled={!sub.values.is_target}
                                className="input-without-border-radius"
                              />
                              {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                            </div>
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </UncontrolledCollapse>
                  <UncontrolledCollapse toggler="#quo-creator_instagram">
                    <FormGroup className="form__form-group-socialmeia">
                      <Label for="instagram_username"><i className="lab la-instagram"></i></Label>
                      <Field
                        name="instagram_username"
                      >
                        {({ input, meta }) => (
                          <div className="form__form-group-field">
                            <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                              <span>https://instagram.com/</span>
                              <Input
                                {...input}
                                name="instagram_username"
                                type="text"
                                placeholder={isDisabled ? "Anyone" : getTransText("quoform.targetseller.instagram")}
                                disabled={!sub.values.is_target}
                                className="input-without-border-radius"
                              />
                              {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                            </div>
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </UncontrolledCollapse>
                  <UncontrolledCollapse toggler="#quo-creator_twitter">
                    <FormGroup className="form__form-group-socialmeia">
                      <Label for="twitter_username">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                          <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                        </svg>
                      </Label>
                      <Field
                        name="twitter_username"
                      >
                        {({ input, meta }) => (
                          <div className="form__form-group-field">
                            <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                              <span>https://twitter.com/</span>
                              <Input
                                {...input}
                                name="twitter_username"
                                type="text"
                                placeholder={isDisabled ? "Anyone" : getTransText("quoform.targetseller.twitter")}
                                disabled={!sub.values.is_target}
                                className="input-without-border-radius"
                              />
                              {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                            </div>
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </UncontrolledCollapse>
                </div>
              )}
            </FormSpy>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit"><i className="icon-quo"></i> {getTransText("quoform.button.place")}</Button>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    </React.Fragment>
  );
};


export default PlaceQuo;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Col, Container, Row
} from 'reactstrap';

import TopbarSearch from '~/Search/TopbarSearch';
import Accepted from './QuoPanel/Accepted';
import Completed from './QuoPanel/Completed';
import Countering from './QuoPanel/Countering';
import Delivered from './QuoPanel/Delivered';
import Pending from './QuoPanel/Pending';
import Rating from './QuoPanel/Rating';
import Rejected from './QuoPanel/Rejected';
import TimeOut from './QuoPanel/TimeOut';

const MQG = () => {
  const [quos, setQuos] = useState([]);
  const quosState = { quos, setQuos };
  const {
    id,
  } = useSelector(state => state.user.userData);

  const reloadQuo = async () => {
    // 
    // console.log("done");
  };

  const capbable_t = true;
  const capbable_f = false;

  const q = {
    id: "e3fcc801-89c8-4b60-bfc1-7aa0854db0d2",
    receiver: "4b5e91b5-2c23-42dd-b7f5-d6f2ff3b5dbc",
    date_created: "20 Dec 2022",
    related_conversation: "1234",
    price: "10",
    countering_price: "20",
    latest_description: "hihihi",
    media_type: "Photo",
  };

  const q_me = {

    id: "e3fcc801-89c8-4b60-bfc1-7aa0854db0d2",
    receiver: id || "fcae1df5-f45a-4b8e-9a9d-de07161bb19e",
    date_created: "20 Dec 2022",
    related_conversation: "1234",
    price: "10",
    countering_price: "20",
    latest_description: "hihihi",
    media_type: "Photo",
  };

  return (
    <div className="container__wrap">
      <Container>
        <Row>
          <Col md={10}>
            <Row>
              <div className="topbar__search">
                <div className="topbar__btn topbar__search-btn">
                  <TopbarSearch />
                  <i className="las la-search"></i>
                </div>
              </div>
            </Row>
            <Row><h3>Pending</h3></Row>
            <Row>
              <Pending quo={q} reloadQuo={reloadQuo} capbable={capbable_t} />
              <Pending quo={q_me} reloadQuo={reloadQuo} capbable={capbable_t} />
            </Row>
            <Row><h3>Pending (No capbable)</h3></Row>
            <Row>
              <Pending quo={q} reloadQuo={reloadQuo} capbable={capbable_f} />
              <Pending quo={q_me} reloadQuo={reloadQuo} capbable={capbable_f} />
            </Row>
            <Row><h3>Countering</h3></Row>
            <Row>
              <Countering quo={q} reloadQuo={reloadQuo} />
              <Countering quo={q_me} reloadQuo={reloadQuo} />
              <div className="chat__quo-action">
                <i className="las la-gavel"></i><div className="bold-text">Counter</div>
              </div>
            </Row>
            <Row><h3>Rejected</h3></Row>
            <Row>
              <Rejected quo={q} quosState={quosState} />
              <Rejected quo={q_me} quosState={quosState} />
              <div className="chat__quo-action">
                <i className="las la-times"></i><div className="bold-text">Rejected</div>
              </div>
            </Row>
            <Row><h3>Timeout</h3></Row>
            <Row>
              <TimeOut quo={q} quosState={quosState} />
              <TimeOut quo={q_me} quosState={quosState} />
              <div className="chat__quo-action">
                <i className="las la-hourglass-end"></i><div className="bold-text">Timeout</div>
              </div>
            </Row>
            <Row><h3>Accepted</h3></Row>
            <Row>
              <Accepted quo={q} quosState={quosState} />
              <Accepted quo={q_me} quosState={quosState} />
              <div className="chat__quo-action">
                <i className="las la-glass-cheers"></i><div className="bold-text">Accepted</div>
              </div>
            </Row>
            <Row><h3>Delivered</h3></Row>
            <Row>
              <Delivered quo={q} quosState={quosState} />
              <Delivered quo={q_me} quosState={quosState} />
              <div className="chat__quo-action">
                <i className="las la-upload"></i><div className="bold-text">Delivered</div>
              </div>
            </Row>
            <Row><h3>Rating</h3></Row>
            <Row>
              <Rating quo={q} quosState={quosState} />
              <Rating quo={q_me} quosState={quosState} />
            </Row>
            <Row><h3>Completed</h3></Row>
            <Row>
              <Completed quo={q} quosState={quosState} />
              <Completed quo={q_me} quosState={quosState} />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MQG;

import '@brainhubeu/react-carousel/lib/style.css';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { addTokenToURL } from '~/apiCalls/authApi';
import MediaPreview from './MediaPreview';


const DeliverList = ({ files }) => (
  <Row className="delivered__list">
    {files?.map((file, index) => {
      if (file && (file?.type.includes("image") || file?.type.includes("video") || file?.type.includes("audio"))) {
        return (
          MediaPreview({ file })
        );
      }

      return (
        <React.Fragment key={file.file_permission_id}>
          <Col xs={6}>
            <a className="gallery__img-wrap" href={`${addTokenToURL(`${process.env.REACT_APP_API_URL}file-access/private/${file.file_permission_id}`)}&download`} target="_blank" rel="noreferrer" download>
              <span className="submit-container">
                <img className="profileContentImg" src="/img/download-file.svg" alt="/public/img/file.png" effect="blur" />
                <div className="file_name">{file.filename_download}</div>
              </span>
            </a>
          </Col>
        </React.Fragment>
      );
    })}
  </Row>
);


export default DeliverList;

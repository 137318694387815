import produce from 'immer';
import { DateTime } from "luxon";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  accountLinkAccess, capabilities, getBalanceTransaction, isPaymentUnsettle, isSupportedCountry
} from '~/apiCalls/paymentApi';
import QuoBalance from '~~/balance/QuoBalance';
import getTransText from '~~/get-text/getTransText';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';
import UnsettlePayment from '~~/unSettlePayment';
import DashboardStepCard from './components/StepCard';
import TradeHistory from './components/TradeHistory';

const Wallet = () => {
  const {
    authenticated,
  } = useSelector(state => state.auth);
  const [Date, setDate] = useState({
    year: DateTime.now().year,
    month: DateTime.now().month,
  });
  const [rows, setRows] = useState();
  const [capbable, setCapbable] = useState(undefined);
  const [supportedCountry, setSupportCountry] = useState(undefined);
  const [loaded, setLoaded] = useState(false);
  const [settle, setSettle] = useState({ has: false });

  const handleMonthChange = (e) => {
    const pickDate = DateTime.fromFormat(e.target.value, "yyyy-MM");
    setDate({
      year: pickDate.year,
      month: pickDate.month,
    });
  };

  const handlePage = (id) => {
    const DateTimeSet = DateTime.local(Date.year, Date.month);
    const start = DateTimeSet.startOf("month").toUnixInteger();
    const end = DateTimeSet.endOf("month").toUnixInteger();
    getBalanceTransaction(start, end, id)
      .then((loadmore) => {
        setRows(produce((draft) => {
          draft.data.push(...loadmore.data);
          draft.has_more = loadmore.has_more;
        }));
      });
  };

  useEffect(async () => {
    let isCancelled = false;
    if (authenticated) {
      await isSupportedCountry()
        .then((res) => {
          if (!isCancelled) {
            setSupportCountry(res.supported);
          }
        })
        .catch((e) => {
          setSupportCountry(false);
        });
      await capabilities()
        .then((response) => {
          if (!isCancelled) {
            setCapbable(response);
            setLoaded(true);
            if (response) {
              const DateTimeSet = DateTime.local(Date.year, Date.month);
              const start = DateTimeSet.startOf("month").toUnixInteger();
              const end = DateTimeSet.endOf("month").toUnixInteger();
              getBalanceTransaction(start, end, null)
                .then((responseB) => {
                  setRows(responseB);
                });
            }
          }
        });
      if (!isCancelled) {
        const settleRes = await isPaymentUnsettle();
        setSettle(settleRes);
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [authenticated, Date]);

  const accountLinkClick = async () => {
    await accountLinkAccess().then((e) => {
      window.open(e.url);
    });
  };

  const QuoTradeHistoryRender = () => {
    if (loaded === false) {
      return <LoadingLogo />;
    }
    if (loaded && (supportedCountry === undefined || (supportedCountry.status === 400) || !supportedCountry)) {
      return (
        <>
          <Row className="no-wallet">
            {/* <Col xs={12}>
              <h3 className="page-title">{getTransText("Wallet")}</h3>
            </Col> */}
            <Col>
              <div className="badge-icon">
                <span className="icon"><i className="las la-sad-tear"></i></span>
                <h5>{getTransText("Sorry! Unsupported Country to be a seller.")}</h5>
                <p>{getTransText("You can still place QUO to your favourite creators.")}</p>
              </div>
            </Col>
          </Row>
        </>
      );
    }
    if (loaded && (capbable !== undefined && capbable)) {
      return (
        <>
          {/* <Row>
            <Col xs={12}>
              <h3 className="page-title">{getTransText("Wallet")}</h3>
            </Col>
          </Row> */}
          <QuoBalance />
          <TradeHistory rows={rows} handleMonthChange={handleMonthChange} handlePage={handlePage} />
        </>
      );
    }
    if (capbable !== undefined && !capbable) {
      return (
        <>
          {/* <Row className="no-wallet">
            <Col xs={12}>
              <h3 className="page-title">{getTransText("Wallet")}</h3>
            </Col>
          </Row> */}
          <UnsettlePayment settle={settle} />
          <DashboardStepCard settle={settle} />
        </>
      );
    }
    if (capbable === undefined) {
      return (
        ""
      );
    }
  };

  return (
    <Container className="wallet__container">
      {QuoTradeHistoryRender()}
    </Container>
  );
};

export default Wallet;

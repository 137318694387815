import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button,
} from 'reactstrap';
import useSmoothScroll from 'react-smooth-scroll-hook';
import * as nsfwjs from 'nsfwjs';
import '@brainhubeu/react-carousel/lib/style.css';
import Photo from './Photo';
import PublicPhoto from './PublicPhoto';
import ModalParent from '~~/modal_parent/ModalParent';
import DragAndDropTable from '~~/profile_gallery_editor';
import getTransText from '~~/get-text/getTransText';
import {
  checkedNsfwProfile,
} from '~/store/profile';

const availableModels = {
  mobilenetv2: ['/quant_nsfw_mobilenet/'],
  mobilenetMid: ['/quant_mid/', { type: 'graph' }],
  inceptionv3: ['/model/', { size: 299 }],
};

const Gallery = () => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 0,
  });
  const {
    userInfo,
    profileContents,
    isMe,
  } = useSelector(state => state.profile);
  const {
    authenticated,
  } = useSelector(state => state.auth);
  const [togglerDnd, setTogglerDnd] = useState(false);
  const ref = useRef(null);
  const { scrollTo } = useSmoothScroll({
    ref,
    speed: 10,
    direction: 'x',
  });
  const [nsfwModel, setNsfwModel] = useState(null);
  const dispatch = useDispatch();


  const click = () => {
    const { scrollWidth, scrollLeft, clientWidth } = ref.current;
  };

  const switchTogglerDnd = () => {
    setTogglerDnd(!togglerDnd);
  };


  const openLightboxOnSlide = (number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const NoPhotoRender = () => {
    if (profileContents.length === 0) {
      if (!isMe) {
        return (
          <div className="no-photo">
            <span className="icon"><i className="las la-photo-video"></i></span>
            <h5>{getTransText("No photo yet")}</h5>
            <p>{getTransText("Welcome to")} <span className="username">{userInfo?.username}</span>{getTransText("’s profile")}!!</p>
          </div>
        );
      }

      return (
        <div className="no-photo">
          <span className="icon"><i className="las la-photo-video"></i></span>
          <h5>{getTransText("Upload highlight pictures now!")}</h5>
          {/* <button type="button" className="btn control-btn btn-primary " onClick={() => switchTogglerDnd()} label="Upload file"><i className="las la-plus-square"></i></button> */}
        </div>
      );
    }
    return "";
  };

  useEffect(() => {
    dispatch(checkedNsfwProfile(false));
    nsfwjs
      .load(...availableModels.mobilenetMid)
      .then((model) => {
        setNsfwModel(model);
      });
  }, []);


  return (
    <div className="gallery">
      <ModalParent toggler={togglerDnd} switchToggler={switchTogglerDnd}>
        <DragAndDropTable />
      </ModalParent>

      {profileContents.length > 0 && (
        <div className="gallery__list">
          {ref.current && ref.current.scrollWidth > ref.current.clientWidth && (
            <button className="btn control-btn gallery__img-left" type="button" onClick={() => scrollTo(-180)}>
              <i className="las la-angle-left"></i>
            </button>
          )}
          <div className="gallery__img-container" ref={ref}>
            {profileContents.map((p, index) => (
              <React.Fragment key={p.id}>
                {authenticated
                  ? (<Photo me={isMe} userInfo={userInfo} p={p} index={index} nsfwModel={nsfwModel} />)
                  : (<PublicPhoto me={isMe} userInfo={userInfo} p={p} index={index} nsfwModel={nsfwModel} />)}
              </React.Fragment>
            ))}


          </div>
          {ref.current && ref.current.scrollWidth > ref.current.clientWidth && (
            <button className="btn control-btn gallery__img-right" type="button" onClick={() => scrollTo(180)}>
              <i className="las la-angle-right"></i>
            </button>
          )}
        </div>
      )}
      <NoPhotoRender />


      <div className="gallery__top">
        {
          (isMe) ? (
            <>
              <Button color="primary" outline className="btn-upload__media" onClick={() => switchTogglerDnd()}>{getTransText("profile.upload.btn")}<i className="las la-plus-square"></i></Button>
            </>
          )
            : ""
        }

      </div>
    </div>
  );
};

export default Gallery;

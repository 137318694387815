/* eslint-disable jsx-a11y/media-has-caption */
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import TimeAgo from 'timeago-react';
import { addTokenToURL } from '~/apiCalls/authApi';
import AvatarRender from '~~/avatar_render';
import FilePreview from '~~/file-preview';
import LightBoxModalParent from '~~/modal_parent/LightBoxModalParent';
import VideoJS from '~~/videojs_player';
import Accepted from './ChatQuoPanel/Accepted';
import Completed from './ChatQuoPanel/Completed';
import Countering from './ChatQuoPanel/Countering';
import Delivered from './ChatQuoPanel/Delivered';
import Pending from './ChatQuoPanel/Pending';
import Rating from './ChatQuoPanel/Rating';
import Rejected from './ChatQuoPanel/Rejected';
import TimeOut from './ChatQuoPanel/TimeOut';

import {
  getQuoActivity
} from '~/apiCalls/chatApi';


const ChatBubble = ({
  memberB, friendInfo, message, messagePackageState,
}) => {
  const playerRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const toggle = () => setModal(!modal);
  const videoJsOptions = {
    autoplay: false,
    controls: false,
    responsive: true,
    height: 150,
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('loadeddata', () => {
      setIsLoading(false);
    });
    player.on('dispose', () => {
      VideoJS.log('player will dispose');
    });
  };

  const {
    langCode,
  } = useSelector(state => state.ui);
  const {
    sentBoxConversations, receiveBoxConversations, activeChat,
  } = useSelector(state => state.chats);
  const { conversation_id } = useParams();
  const [quo, setQuo] = useState({});
  const [checkMark, setCheckMark] = useState("la-check");
  const {
    id,
    avatar,
    random_no,
  } = useSelector(state => state.user.userData);
  let isOwn = false;
  let readTime;
  if (id === message.sender_id) {
    isOwn = true;
  } else {
    isOwn = false;
  }

  useEffect(() => {
    if (checkMark === "la-check") {
      if (activeChat) {
        const sentCon = sentBoxConversations.find((c) => c.id === activeChat);

        if (sentCon) {
          if (DateTime.fromISO(sentCon.memberb_read) >= DateTime.fromISO(message.date_created)) {
            setCheckMark("la-check-double");
          }
        }
      }
    }
  }, [sentBoxConversations]);

  useEffect(() => {
    if (checkMark === "la-check") {
      if (activeChat) {
        const receiveCon = receiveBoxConversations.find((c) => c.id === activeChat);

        if (receiveCon) {
          if (DateTime.fromISO(receiveCon.membera_read) >= DateTime.fromISO(message.date_created)) {
            setCheckMark("la-check-double");
          }
        }
      }
    }
  }, [receiveBoxConversations]);

  useEffect(async () => {
    let isCancelled = false;
    if (message.quo_activity_id && memberB) {
      await getQuoActivity(message.quo_activity_id)
        .then((response) => {
          const quoCorrectStucture = {
            "id": response.quoID,
            "latest_price": response.price,
            "confirmed_delivery_time": response.proposed_delivery_time,
            "latest_delivery_time": response.proposed_delivery_time,
            "latest_title": response.quo_title,
            "latest_description": response.description,
            "current_status": response.action,
            "countering_price": response.countering_price,
            "media_type": response.media_type,
            "date_created": response.date_created,
            "receiver": response.receiver,
            "random_no": memberB.random_no,
            "responsed": response.responsed,
            "charge_date": response.charge_date,
          };
          if (!isCancelled) {
            setQuo(quoCorrectStucture);
          }
        });
    }
    return () => {
      isCancelled = true;
    };
  }, [message.quo_activity_id, memberB]);

  useEffect(() => {
    const fetchImageUrl = async () => {
      const urlWithToken = await addTokenToURL(`${process.env.REACT_APP_API_URL}file-access/private/${message?.file_permission_id}?withoutEnlargement=true`);
      setImageUrl(urlWithToken);
    };

    fetchImageUrl();
  }, [message?.file_permission_id]);

  function onLoad() {
    // delay for demo only
    setIsLoading(false);

    // setIsLoading(false)
  }
  const imageOnErrorHandler = (event) => {
    event.currentTarget.src = `${process.env.REACT_APP_FRONTEND_URL}img/noimage.svg`;
  };

  const filePreviewOutput = () => {
    if (message?.q_message === "Image File") {
      return (
        <>
          {isLoading && (
            <Skeleton
              height={150}
            />
          )}
          <img
            type="image"
            src={`${imageUrl}&width=150&height=150`}
            alt={message?.q_message}
            onClick={() => toggle()}
            style={{ display: isLoading ? "none" : "block" }}
            role="presentation"
            onLoad={onLoad}
            onError={imageOnErrorHandler}
          />
        </>
      );
    }
    if (message?.q_message === "Video File") {
      return (
        (message && message.file_permission_id)
          ? (
            <div>
              <button
                key={`${message.file_permission_id}`}
                type="button"
                aria-label="toggle-video"
                className="chat__bubble-video-button"
                onClick={() => toggle()}
              >
                <video
                  src={imageUrl}
                  width={150}
                  height={150}
                  style={{ objectFit: "cover" }}
                />
                <div className="videojs-thumbnail-cover" />
              </button>
            </div>
          ) : ""
      );
    }
  };
  const quoOutput = (q) => {
    if (q.current_status === "Pending" || q.current_status === "pending") {
      return (<Pending quo={q} friendInfo={friendInfo} isOwn={isOwn} messagePackageState={messagePackageState} />);
    }
    if (q.current_status === "Countering" || q.current_status === "countering") {
      return (<Countering quo={q} isOwn={isOwn} messagePackageState={messagePackageState} />);
    }
    if (q.current_status === "Rejected" || q.current_status === "rejected") {
      return (<Rejected quo={q} isOwn={isOwn} messagePackageState={messagePackageState} />);
    }
    if (q.current_status === "Accepted" || q.current_status === "accepted") {
      return (<Accepted quo={q} isOwn={isOwn} messagePackageState={messagePackageState} />);
    }
    if (q.current_status === "Delivered" || q.current_status === "delivered") {
      return (<Delivered quo={q} isOwn={isOwn} messagePackageState={messagePackageState} />);
    }
    if (q.current_status === "Timeout" || q.current_status === "timeout") {
      return (<TimeOut quo={q} isOwn={isOwn} messagePackageState={messagePackageState} />);
    }
    if (q.current_status === "Rating") {
      return (<Rating quo={q} isOwn={isOwn} messagePackageState={messagePackageState} />);
    }
    if (q.current_status === "Completed") {
      return (<Completed quo={q} isOwn={isOwn} messagePackageState={messagePackageState} />);
    }
    return ("");
  };

  const messageOutput = () => {
    if (message.message_type === "file") {
      return (
        <div className="chat__bubble-message-wrap chat__bubble-message-wrap--file">
          <div id={`a${message.id}`} className="chat__bubble-image">
            {filePreviewOutput()}
          </div>
          <p className="chat__bubble-date">
            <TimeAgo
              datetime={message?.date_created}
              locale={langCode}
            />
            {isOwn && <span className="chat__bubble-checkmark"><i className={`las ${checkMark}`}></i></span>}
          </p>
        </div>
      );
    }

    if (message.message_type === "quos") {
      return (
        <div className="chat__bubble-message-wrap">
          <div className="chat__bubble-quo" id={`a${message.id}`}>
            {quoOutput(quo)}
            <p className="chat__bubble-date">
              <TimeAgo
                datetime={quo?.date_created}
                locale={langCode}
              />
              {isOwn && <span className="chat__bubble-checkmark"><i className={`las ${checkMark}`}></i></span>}
            </p>
          </div>
        </div>
      );
    }


    if (message.message_type === "chat") {
      return (
        <div className="chat__bubble-message-wrap">
          <div id={`a${message.id}`}>
            <p className="chat__bubble-message">{message?.q_message || <Skeleton />}</p>
          </div>
          <p className="chat__bubble-date">
            <TimeAgo
              datetime={message?.date_created}
              locale={langCode}
            />
            {isOwn && <span className="chat__bubble-checkmark"><i className={`las ${checkMark}`}></i></span>}
          </p>
        </div>
      );
    }
  };

  return (
    <>
      <div className={`chat__bubble${isOwn ? " chat__bubble--active" : ""}${message.message_type === "quos" ? " chat__bubble__quo" : ""}`}>
        {message
          && (
            <LightBoxModalParent isOpen={modal} toggle={toggle}>
              {message && <FilePreview mime={message.q_message} src={`file-access/private/${message.file_permission_id}`} title={message.file_permission_id} />}
            </LightBoxModalParent>
          )}
        <div className="chat__bubble-avatar">
          <img
            src={isOwn
              ? AvatarRender(avatar, random_no)
              : AvatarRender(friendInfo?.avatar, friendInfo?.random_no)}
            alt="ava"
          />
        </div>
        {messageOutput()}
      </div>
    </>
  );
};

export default ChatBubble;

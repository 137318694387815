import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  ButtonToolbar,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  accountLinkAccess,
} from '~/apiCalls/paymentApi';
import getTransText from '~~/get-text/getTransText';
import CollapseComponent from '~~/Collapse';

const DashboardStepCard = ({ settle }) => {
  const {
    username,
  } = useSelector(state => state.user.userData);
  const [copySuccess, setCopySuccess] = useState("copy link");
  const accountLinkClick = async () => {
    await accountLinkAccess().then((e) => {
      window.open(e.url);
    });
  };

  return (
    <>
      {!(settle?.has)
        && (
          <Row className="no-wallet__steps">
            <Col sm={7}>
              <div className="no-wallet">
                <h3 className="page-title">{getTransText("Please connect to Stripe to earn money in QUObal")}</h3>
                <ButtonToolbar className="btn-toolbar--center">
                  <Button color="primary" outline className="btn-connect-stripe" onClick={() => accountLinkClick()}>{getTransText("Connect Stripe")}<i className="lab la-stripe-s"></i></Button>
                </ButtonToolbar>
              </div>
            </Col>
          </Row>
        )}
      {/* <Row className="no-wallet__steps">
        <Col sm={7}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">{getTransText('Step 2: Complete the QUO')}</h5>
                <h5 className="subhead">{getTransText("Start getting paid for what you produce.")}</h5>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
      <Row className="faq__section">
        <Col>
          <CollapseComponent title={`Q: ${getTransText("faq.question.7")}`} className="with-shadow">
            {getTransText("faq.ans.7")}
          </CollapseComponent>
          <CollapseComponent title={`Q: ${getTransText("faq.question.8")}`} className="with-shadow">
            {getTransText("faq.ans.8")}
          </CollapseComponent>
          <CollapseComponent title={`Q: ${getTransText("faq.question.11")}`} className="with-shadow">
            {getTransText("faq.ans.11.1")}
            {getTransText("faq.ans.11.2")}
          </CollapseComponent>
          <CollapseComponent title={`Q: ${getTransText("faq.question.12")}`} className="with-shadow">
            {getTransText("faq.ans.12")}
          </CollapseComponent>
          <ButtonToolbar className="btn-toolbar--center">
            <Link className="btn btn-outline-primary" to="/FAQ">{getTransText("landing.faq.more")}</Link>
          </ButtonToolbar>
        </Col>
      </Row>
    </>
  );
};

export default DashboardStepCard;

import produce from "immer";
import React, { useState, useEffect, forwardRef } from "react";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import { useSelector } from 'react-redux';
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import en_US from "filepond/locale/en-en";
import ja from "filepond/locale/ja-ja";
import zh_TW from "filepond/locale/zh-tw";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { formatDate, PhotoFormatter } from '~~/table/index.jsx';
import { client, clientPublic } from '~/apiCalls/directusConfig';


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const FilepondComponent = forwardRef(
  ({ onload, filepurpose, onAdd, description = null }, ref) => {
    const [files, setFiles] = useState([]);
    const [token, setToken] = useState(null);
    const {
      langCode,
    } = useSelector(state => state.ui);
    const filePondLang = () => {
      switch (langCode) {
        case 'zh-TW':
          return zh_TW;
        case 'en-US':
          return en_US;
        case 'ja':
          return ja;
        default:
          return en_US;
      }
    };


    return (
      <div className="filepond-component-container" style={{ width: "200px" }}>
        <FilePond
          {...filePondLang()}
          ref={ref}
          files={files}
          allowImagePreview={false}
          allowReorder={true}
          allowMultiple={true}
          allowRevert={false}
          onremovefile={onAdd}
          onupdatefiles={setFiles}
          labelIdle={'<span class="filepond--label-action"> Upload file </span>'}
          credits=""
          server={{
            url: `${process.env.REACT_APP_API_URL}files`,
            process: {
              method: 'POST',
              withCredentials: true,
              onload,
              onerror: (response) => response.data,
              ondata: (formData) => {
                const a = new FormData();
                a.append('file_purpose', filepurpose);
                a.append('description', description);
                formData.forEach((value, key) => a.append(key, value));
                return a;
              },
            },
          }}
          instantUpload={true}
        />
      </div>
    );
  }
);

export default FilepondComponent;

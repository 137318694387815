/* eslint-disable */
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { sendQuoMessageTrigger } from '~/apiCalls/quoApi';
import { apiCallBegan } from '~/store/api';
import { emittedSuccessPayment } from '~/store/quos';

import getTransText from "~~/get-text/getTransText";

const PaySuccess = () => {
  const { quoId } = useParams();
  const dispatch = useDispatch();
  // If you want to actuall wait for a response to display:

  return (
    <div className="verification__container quabal__container">
      <Container>
        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <div className="badge-icon">
                  <span className="icon"><i className="las la-check"></i></span>
                  <h5>{getTransText("Request Delivered")}</h5>
                  <p>
                    {getTransText("You have delivered the request to creators. If this is your first request, the account will be automatically created. The login name and password are also sent to your email. You can ")}
                    <Link to="/log_in"> {getTransText("click here")} </Link>
                    {getTransText(" to log in. The Request details and deliverable will be shown under the 'manageQuo' page.")}
                  </p>
                </div>
              </CardBody>
            </Card>
            <Link className="quobal__logo horizontal" to="/l" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}



export default PaySuccess;

/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const ExamplePage = () => (
  <>
    <Container className="quabal__container terms__container">
      <Row>
        <Col md={8} xl={6}>
          <h3 className="page-title">QUOBal Terms and Conditions</h3>
          <p className="small">This FOSTA-SESTA Statement was last updated on Friday, August 19, 2022</p>
          <p>This agreement applies to and between you and Quomark Limited located at 1201 Orange Street, Suite 600, Wilmington, New Castle County, Delaware 19801. Your agreement to comply with and be bound by these Terms and Conditions is deemed to occur upon your first use of the website (www.quobal.com). If you do not agree to be bound by these Terms and Conditions, you should stop using the website immediately. Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using this website operated by Quomark Limited ("us", "we", or "our").</p>
          <p>In this Agreement &ldquo;you&rdquo; and &ldquo;your&rdquo; refer to the Users and prospective Users of our website/service, &ldquo;we&rdquo;, &ldquo;company&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo; refer to Quomark Limited and &ldquo;Service&rdquo; refers to the services provided by us. Party&rdquo;, &ldquo;Parties&rdquo;, or &ldquo;Us&rdquo;, refers to both Users and ourselves, or either the User or ourself. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to our Users in the most appropriate manner, for the express purpose of meeting the User&rsquo;s needs in respect of the provision of the Company&rsquo;s stated services, in accordance with and subject to, prevailing laws. Your Content" or &ldquo;User Content&rdquo; means Content that you submit or transmit to, through our website and in connection to our services, such as ratings, reviews, requests, invitations, photo images, messages, and information that you publicly display or displayed in your account profile.</p>
          <p>Any and all use of our services is subject to this Agreement, which may be updated by us from time to time without prior notice to you. Any change of terms shall be binding and effective upon posting of the revised Agreement on our website. In addition to this Agreement, specific services or information contained within this website may be subject to additional posted terms or guidelines applicable to such services. All such terms or guidelines are hereby incorporated by reference into this Agreement.</p>
          <p>By using our website- www.quobal.com (the &ldquo;Site&rdquo;) or any of our services, you agree to follow and be bound by these Terms of Services (the &ldquo;Terms of Services&rdquo;) and agree to comply with all applicable laws and regulations.</p>
          <ol>
            <li><strong>Age Restriction</strong><br />
              You must be eighteen (18) or over to register as a user of the Service. If you sign up for the Service but are prohibited from doing so, for example, because you are too young, then your profile will be invalid and considered a breach of these terms.<br />
              </li>
              <li><strong>Unauthorized access</strong>
                <ol>
                  <li>It is unlawful to try and gain unauthorized access to the Site, its servers, or any computer or database connected to the website. You must not attack the website via a denial-of-service attack or a distributed denial-of-service attack.</li>
                  <li>We will report any such breach to the relevant law enforcement authorities, and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use the website will cease immediately.</li>
                  <li>We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our website or to your downloading of any material posted on it, or on any website linked to it.</li>
                </ol>
              </li>
              <li><strong>Our Services</strong><br />
                Our Service allows users to post and share and otherwise make available certain information, text, graphics, videos, or other material (“Content”). Users are responsible for Content that they post on or through the website, including its legality, reliability, and appropriateness. As a user, you have the right to accept, counter or reject any request for content from other users of the website and service.<br />
                </li>
                <li><strong>Setting up an Account</strong>
                  <ol>
                    <li>To use our website/service, you may be required to sign up or set up an account with us which will contain certain personal details. In creating your account and continuing to use this Website, you represent and warrant that:
                      <ol>
                        <li>all information you submit is accurate and truthful;</li>
                        <li>the information does not violate any 3rd party intellectual property.</li>
                        <li>It is recommended that you do not share your Account details, particularly your username and password.</li>
                      </ol>
                    </li>
                    <li>We accept no liability for any losses or damages incurred as a result of your Account details being shared by you. If you use a shared device, it is recommended that you do not save your Account details in your internet browser. If you have reason to believe that your Account details have been obtained by another person without your consent, you should contact Quomark Limited immediately to assist in rectifying such an issue.</li>
                  </ol>
                </li>
                <li><strong>Communications from Quomark Limited and other Users</strong><br />
                  By creating an account, you agree to receive certain communications in connection with our service. For example, you might receive requests from other users or compliments/reviews about your content. You may also receive a periodic e-mail newsletter about our service or activities on your account. You can opt-out of this at any time.</li>
                <li><strong>Your provision of information</strong><br />
                  When you provide information about yourself to us or to other users of the website, including when you create an Account, you agree to: (a) provide accurate and current information, and not to provide information that attempts to impersonate another individual; and (b) maintain and promptly update such information to keep it accurate and current. If you provide any information about yourself that is untrue or inaccurate, or we have reasonable grounds to suspect that such information is untrue or inaccurate, we retain the right to suspend or terminate any account you establish, decline to provide you with services on the website, and/or refuse any or all current or future use of the website or any portion thereof.<br />
                  </li>
                  <li><strong>Your content</strong>
                    <ol>
                      <li>In uploading content (including photo images, posts, links, videos and comments) and continuing to use this website/service, you represent and warrant that:</li>
                      <li>You alone are responsible for Your Content.</li>
                      <li>You assume all risks associated with Your Content, including anyone's reliance on its accuracy, or reliability, or any disclosure by you of information in Your Content that makes you personally identifiable.</li>
                      <li>You represent that you own or have the necessary permissions to use and authorize the use of Your Content as described herein.</li>
                      <li>You may not imply that Your Content is in any way sponsored or endorsed by Quomark Limited.</li>
                    </ol>
                  </li>

                  <li><strong>Your conduct </strong>
                    <ol>
                      <li>You agree to comply with all laws, rules, and regulations applicable to your access to and use of the Platform. In addition, you agree not to:
                        <ol>
                          <li>upload, post, transmit, publish, display, or otherwise make available through the Platform any User-Provided Content that is known by you to be false, inaccurate, or misleading;</li>
                          <li>upload, post, transmit, publish, display, or otherwise make available through the Platform any User-Provided Content that violates any law, statute, ordinance, or regulation (including, but not limited to, those governing export control, consumer protection, unfair competition, anti-discrimination, or false advertising);</li>
                          <li>upload, post, transmit, publish, display, or otherwise make available through the Platform any User-Provided Content that is, or may reasonably be considered to be, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable, or that harms minors in any way;</li>
                          <li>forge headers or otherwise manipulate identifiers in order to disguise the origin of any User-Provided Content transmitted through the Platform;</li>
                          <li>upload, post, transmit, publish, display, or otherwise make available through the Platform any User-Provided Content that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, or proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);</li>
                          <li>upload, post, transmit, publish, display, or otherwise make available through the Platform any User-Provided Content that infringes any patent, trademark, trade secret, copyright, right of publicity, or other right of any party;</li>
                          <li>upload, post, transmit, publish, display, or otherwise make available through the Platform any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation;</li>
                          <li>upload, post, transmit, publish, display, or otherwise make available through the Platform any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
                          <li>take any action that interferes with the proper working of the Platform, compromises the security of the Platform, or otherwise damages the Platform or any materials or information available through the Platform;</li>
                          <li>attempt to gain unauthorized access to any portion or feature of the Platform, to any other systems or networks connected to the Platform, to any of our or our service providers’ servers, or to any of the services offered on or through the Platform, including but not limited to by hacking, password “mining”, or any other unauthorized means;</li>
                          <li>probe, scan, or test the vulnerability of the Platform or any network connected to the Platform or bypass the authentication measures on the Platform or any network connected to the Platform;</li>
                          <li>use any automated means to collect information or Content from or otherwise access the Platform, including, but not limited to, through the use of technical tools known as robots, spiders, or scrapers, without our prior permission;</li>
                          <li>harvest or otherwise collect and store information about other users of the Platform, including e-mail addresses;</li>
                          <li>install any software, file, or code that is not authorised by the user of a computer or device or that assumes control of all or any part of the processing performed by a computer or device without the authorization of the user of the computer or device; or</li>
                          <li>interfere with or disrupt the operation of the Platform or server networks connected to the Platform, or disobey any requirements, procedures, policies, or regulations of networks connected to the Platform.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li><strong>Intellectual Property</strong>
                    <ol>
                      <li>Subject to other terms in these Terms and Conditions, all Content on this website unless those uploaded by other users (such as reviews and photo images), including, but not limited to, text, graphics, logos, icons, images, sound clips, video clips, data compilations, page layout, underlying code and software are the property of Quomark Limited and our affiliates or other relevant third parties. By continuing to use the website, you acknowledge that such material is protected by applicable intellectual property laws in the state of Delaware, International Intellectual Property, and other relevant laws. You may not reproduce, copy, distribute, store or in any other fashion re-use material from the Site unless otherwise indicated on the website or unless given express written permission to do so by us.</li>
                      <li>As between you and Quomark Limited, you own Your Content.</li>
                    </ol>
                  </li>
                  <li><strong>Links to third-party Websites/App</strong><br />
                    The Website may contain links to third party sites and/or Apps. Unless expressly stated, these sites or Apps are not under the control of Quomark Limited or our affiliates. We assume no responsibility for the content of such Sites/Apps and disclaim liability for any and all forms of loss or damage arising out of the use of them. The inclusion of a link to a Site/App on www.quobal.com does not imply any endorsement of such Sites/Apps or those in control of them.</li>
                  <li><strong>Use of the Website</strong><br />
                    When using this website and our service, you shall act in accordance with the following rules:
                    <ol>
                      <li>The means by which you identify yourself must not violate these Terms and Conditions or any applicable laws;</li>
                      <li>You must be polite to our staff and fellow users when using the website/service. You shall not use any swear words or make any racist, sexist, threatening words towards them.</li>
                      <li>You must not display, publish, copy, print, post, modify or otherwise use the website and the information contained therein for the benefit of any third party without our prior written consent;</li>
                      <li>You must not use our website and/or services available on it for illegal activities;</li>
                      <li>Any attempts to publish or send malicious content with the intent to compromise another member&rsquo;s account or computer environment is strictly prohibited;</li>
                      <li>Quomark Limited does not allow any exchange or display of adult-oriented or pornographic materials and services;</li>
                      <li>You may not create a false identity on the Website, misrepresent your identity, create a profile for anyone other than yourself (a real person), or use or attempt to use another user&rsquo;s account or information;</li>
                      <li>Your profile information, including your name, email etc., while may be kept anonymous, must be accurate and complete and may not be misleading, illegal, offensive or otherwise harmful.
                        <ol>
                          <li>modify, post, distribute or reproduce in any way any copyrighted material, trademarks or other proprietary information without having first received the express written permission of Quomark Limited;</li>
                          <li>process or otherwise use the information contained on or within the Site for any illegal purpose; or</li>
                          <li>remove any copyright notices and other proprietary notices contained in the Website;</li>
                          <li>Provide fake or defamatory contents;</li>
                          <li>Violate any third party's rights, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;</li>
                          <li>Threaten, stalk, harm, or harass others, or promote bigotry or discrimination;</li>
                          <li>Violate any applicable law;</li>
                          <li>Violate these Terms;</li>
                          <li>All users of our service must respect other users. In the event you have been disrespected by another, kindly report this user to us and we shall take actions accordingly;</li>
                          <li>Use any robot, spider, mobile app search/retrieval application, or other automated devices, process or means to access, retrieve, scrape, or index any portion of this website;</li>
                          <li>Reverse engineer any portion of this website;</li>
                          <li>Use the website or its Content to transmit any viruses, worms, defects, Trojan horses or other items of a destructive nature (collectively, "Viruses");</li>
                          <li>Use any device, software or routine that interferes with the proper working of the website, or otherwise attempt to interfere with the proper working of the website;</li>
                          <li>Use the website to violate the security of any computer network, crack passwords or security encryption codes; disrupt or interfere with the security of, or otherwise cause harm to, the website or its content; or</li>
                          <li>Remove, circumvent, disable, damage or otherwise interfere with any security-related features of the website, features that prevent or restrict the use or copying of the website&rsquo;s content, or features that enforce limitations on the use of the website.</li>
                        </ol>
                      </li>
                      <li>You accept that Quomark Limited may retain copies of any and all communications made to us or using our service. Any restrictions you may wish to place upon our use of such information must be communicated to us in advance and we reserve the right to reject such terms and associated information.</li>
                    </ol></li>
                  <li><strong>Termination and Cancellation</strong><br />
                    We may terminate your profile/account or the use of our service If we observe you have breached any terms of this agreement. You will be notified by email and an explanation for the termination will be provided. Notwithstanding the foregoing, we reserve the right to terminate without giving reasons. If we terminate your account, any current or pending orders or payments on your account will be cancelled and provision of Services will not commence.</li>
                  <li><strong>Our responsibilities/Warranties</strong>
                    <ol>
                      <li>Your safety when using our website is paramount to us. Hence, all reasonable skills and care shall be taken to ensure your safety when using our website. Please kindly note that whilst your security is paramount to us, remember that no method of transmission over the Internet is 100% secure. While we strive to use commercially acceptable means to protect your transactions on our website, we cannot guarantee its absolute security.</li>
                      <li>We will do our best to maintain the operation of the website, however, we may need to temporarily suspend the website and its Services for operational reasons (e.g., for repairs, planned maintenance or upgrades). We promise to restore the website as soon as reasonably possible after any suspension.</li>
                      <li>If we cannot do what we have promised in these Terms because of something beyond our reasonable control (including, without limitation, disputes involving our employees, affiliates, partners or any third party using the website) we will not be liable for this.</li>
                    </ol>
                  </li>
                  <li><strong>Your Warranties</strong><br />
                    You hereby warrant that all activities under your account shall be performed by you and shall not be assigned to any third-party. We shall not be responsible for any damage, liability, injury, financial loss caused by any such assignment.<br />
                    </li>
                    <li><strong>Reviews/Comments</strong><br />
                      By providing reviews or comments about our service, you hereby grant us the non-exclusive and non-transferable license to use such review/comment for promotional and marketing purposes. We promise that such comment/review shall be used reasonably and legally without any prejudice to you.</li>
                    <li><strong>Suspension and termination </strong>
                      <ol>
                        <li>We will determine, in our discretion, whether there has been a breach of our acceptable use policy through your use of our Platform. When a breach of this policy has occurred, we may take such action as we deem appropriate.</li>
                        <li>Failure to comply with our acceptable use policy constitutes a material breach of the terms of use upon which you are permitted to use our Platform, and may result in our taking all or any of the following actions:
                          <ol>
                            <li>immediate temporary or permanent withdrawal of your right to use our Platform;</li>
                            <li>immediate temporary or permanent removal of any Contribution;</li>
                            <li>issuance of a warning to you;</li>
                            <li>legal proceedings against you for reimbursement of all costs on an indemnity basis (including but not limited to reasonable administrative and legal costs) resulting from the breach;</li>
                            <li>further legal action against you; and/or</li>
                            <li>disclosure of such information to law enforcement authorities as we reasonably feel is necessary.</li>
                            <li>We exclude liability for actions taken in response to breaches of our acceptable use policy. The responses described in this policy are not limited, and we may take any other action we reasonably deem appropriate.</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li><strong>Disclaimers</strong>
                      <ol>
                        <li>We do not make any warranty or representation that the website and/or our service or that of any third party using service/website will meet your requirements, that it will be fit for a particular purpose, that it will be compatible with all systems, that it will be secure, and that all information provided will be accurate. We make no guarantee of any specific results from the use of the Service/website.</li>
                        <li>Whilst we do our best to ensure that the website is secure and free of errors, viruses and other malware, all Users are advised to take responsibility for their security, personal details, and devices.</li>
                        <li>Occasionally there may be information on the website that contain typographical errors, inaccuracies or omissions that may relate to service descriptions etc. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update such information.</li>
                        <li>Quomark Limited makes no claims or promises with respect to any third party or other users. Accordingly, Quomark Limited is not liable to you for any loss or damage that might arise from their actions or omissions, including, for example, if another user misuses your content, identity, or personal information, or if you have a negative experience with another user of the website. Your use of any content offered by third parties through the website is at your discretion and risk.</li>
                        <li>In the event of damage or loss suffered due to the act, omission or negligence of another user, then such claims shall be between you and the user.</li>
                      </ol>
                    </li>
                    <li><strong>Modification of Service and these Terms and Conditions</strong><br />
                      Quomark Limited reserves the right to change this website, its Content or these Terms and Conditions at any time. You will be bound by any changes to the Terms and Conditions from the first time you use the website following the changes. If we are required by law to make any changes to Terms and Conditions, these changes will apply automatically to any transaction currently pending in addition to any transaction placed by you in the future.<br />
                      </li>
                      <li><strong>Indemnification<br />
                      </strong>To the extent permitted by applicable law, you agree to indemnify and hold harmless Quomark Limited, its respective affiliates, employees, agents, hired sub-contractors, and permitted successors and assigns against any and all claims, losses, damages, liabilities, penalties, punitive damages, expenses, reasonable legal fees and costs of any kind or amount whatsoever, which result from or arise out of any act, fault, negligence or omission by you or third parties on using this website and/or our service. This indemnification will survive the termination of this Agreement.</li>
                      <li><strong>Limitation of Liability</strong>
                        <ol>
                          <li>We are not liable for the completeness, accuracy or correctness of any information uploaded on our Platform and any Related Content. You expressly agree that your use of the Services and our Platform, is at your sole risk.</li>
                          <li>We do not assist with dispute resolution between any you and any Consultant and are not obliged at any time to adjudicate on any such dispute. In the event of any dispute, you are responsible for contacting the relevant Consultant. Without prejudice to the foregoing, we remain entitled at all times to investigate at our discretion any complaint regarding the use of our Platform or any suspected unlawful activity and to take any action that we deem appropriate, including to file a report with the appropriate authorities.</li>
                          <li>You agree not to use the Services, our Platform and the Related Content for any illegal and illicit purposes, and we have no liability to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with these Terms (including but not limited to the use of, or inability to use, the Services, our Platform or any other website or software) for:</li>
                          <li>loss of profits, sales, business, or revenue;</li>
                          <li>business interruption;</li>
                          <li>loss of anticipated savings;</li>
                          <li>loss or corruption of data or information;</li>
                          <li>loss of businessopportunity, goodwill or reputation; or</li>
                          <li>any other indirect or consequential loss or damage.</li>
                          <li>Nothing in these Terms shall limit or exclude our liability for:</li>
                          <li>death or personal injury resulting from our negligence;</li>
                          <li>fraud; and/or</li>
                          <li>any other matter in respect of which we are prohibited under applicable law from limiting or excluding our liability.</li>
                          <li>Our Platform is not intended to serve a record-keeping function and we shall not be liable for any loss of data or content.</li>
                          <li>These Terms set out the full extent of our obligations and liabilities in respect of the supply of the Services and our Platform. Except as expressly stated in these Terms, there are no conditions, warranties, representations or other terms, express or implied, that are binding on us. Any condition, warranty, representation or other term concerning the supply of the Services and our Platform which might otherwise be implied into, or incorporated in, these Terms whether by statute, common law or otherwise, is excluded to the fullest extent permitted by law.</li>
                        </ol>
                      </li>
                      <li><strong>No Waiver<br />
                      </strong>Any default, delay or omission of any of the provisions of this Agreement by either Party will not be construed as a waiver of any subsequent breach of the same or other provisions. We are entitled to still pursue any omitted or failed payment by you at any time.</li>
                      <li><strong>Resolving disputes</strong>
                        <ol>
                          <li>Any dispute arising from this Agreement shall be resolved through mediation. If the dispute cannot be resolved through mediation, then the dispute will be resolved through binding arbitration conducted by an expert arbitrator from the American Arbitration Association (AAA) or as agreed upon by the parties.</li>
                          <li>We encourage users to try and settle conflicts amongst themselves.</li>
                        </ol>
                      </li>
                      <li><strong>Previous Terms and Conditions<br />
                      </strong>In the event of any conflict between these Terms and Conditions and any prior versions thereof, the provisions of these Terms and Conditions shall prevail unless it is expressly stated otherwise.</li>
                      <li><strong>Third-Party Rights</strong><br />
                        Nothing in these Terms and Conditions shall confer any rights upon any third party. The agreement created by these Terms and Conditions is between you and Quomark Limited.</li>
                      <li><strong>Platform Management</strong>
                        <ol>
                          <li>We reserve the right, but not the obligation, to:
                            <ol>
                              <li>monitor the Platform for violations of these Terms and Conditions;</li>
                              <li>take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms and Conditions, including without limitation, reporting such user to law enforcement authorities;</li>
                              <li>in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;</li>
                              <li>in our sole discretion and without limitation, notice, or liability, to remove from the Platform or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems;</li>
                              <li>otherwise manage the Platform in a manner designed to protect our rights and property and to facilitate the proper functioning of the Platform.</li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li>Warranties<br />
                        While we make all efforts to maintain the accuracy of the information on our Platform, we provide the Services, Platform and all Related Content on an “as is” and “as available” basis, unless otherwise specified in writing. We make no representations or warranties of any kind, express or implied, as to the operation of any of the foregoing, unless otherwise specified in writing.<br />
                          To the full extent permissible by law, we disclaim all warranties, express or implied, relating to our Platform or any Services, including but not limited to implied warranties of merchantability and fitness for a particular purpose. We do not warrant that the Services, our Platform, the Related Content, or electronic communications sent by us are free of viruses or other harmful components.<br />
                          </li>
                          <li><strong>Entire agreement<br />
                          </strong>These Terms, the Privacy Policy, and any other agreement contained on our website or agreed by the parties constitute the entire agreement between you and Quomark Limited.</li>
                          <li><strong>Severability<br />
                          </strong>In the event that any of the provisions of this Agreement are held to be invalid or unenforceable in whole or in part, all other provisions will nevertheless continue to be valid and enforceable with the invalid or unenforceable parts severed from the remainder of this Agreement.</li>
                          <li><strong>Law and Jurisdiction<br />
                          </strong>These Terms and Conditions and the relationship between you and Quoamrk Limited shall be governed by and construed in accordance with the laws in the state of Delaware and you agree to submit to the exclusive jurisdiction of the courts within the state of Delaware.</li>
                        </ol>
                        <hr />
                        <h3 className="page-title">AML Policy</h3>
                        <p>This policy applies to all users of <a href="http://www.quobal.com">www.quobal.com</a> and forms a part of our Terms and Conditions when using our platform.</p>
                        <p>Money Laundering is the process by which criminally obtained money or other criminal property is exchanged for &ldquo;clean&rdquo; money or other assets with no obvious link to their criminal origins. The term is used for a number of offences involving the integration of &ldquo;dirty money&rdquo; (i.e. the proceeds of crime) into the mainstream economy. The aim is to legitimise the possession of such monies through circulation and this effectively leads to &ldquo;clean&rdquo; funds being received in exchange.</p>
                        <p>Our policy is to ensure all appropriate action is taken to prevent, wherever possible, the users and employees of QUObal from being exposed to money laundering and to comply with all legal and regulatory obligations, including the reporting of suspected or actual cases in line with disclosure requirements.</p>
                        <p><strong>What is Money Laundering? </strong></p>
                        <p>Money laundering is the process of channelling &lsquo;bad&rsquo; money into &lsquo;good&rsquo; money to hide the fact that the money originated form criminal activity, and often involves three steps:</p>
                        <ul>
                          <li>Placement - Cash Assets is introduced into the financial system by some means;</li>
                          <li>Layering - a financial transaction to camouflage the illegal source;</li>
                          <li>Integration - acquisition of financial wealth from the transaction of the illicit funds.</li>
                        </ul>
                        <p>Money laundering is the term used for a number of offences involving the proceeds of crime or terrorism funds. Organised crime groups and corrupt elites launder the proceeds of crime to fund lavish lifestyles and reinvest in criminality.</p>
                        <p>The following constitute the act of money laundering:</p>
                        <ul>
                          <li>concealing, disguising, converting, transferring criminal property, or removing it from the EU and its member states; or</li>
                          <li>entering into or becoming concerned in an arrangement which you know, or suspect, facilitates the acquisition, retention, use or control of criminal property by or on behalf of another person; or</li>
                          <li>acquiring, using, or possessing criminal property.</li>
                        </ul>
                        <p>These are the primary money laundering offences and thus prohibited acts under the Bank Secrecy Act and its implementing regulations ("AML rules"). There are also two secondary offences: failure to disclose any of the primary offences and tipping off. Tipping off is where someone informs a person or people who are, or are suspected of being involved in money laundering, in such a way as to reduce the likelihood of their being investigated or prejudicing an investigation.</p>
                        <p>Potentially any person could be caught by the money laundering provisions if they suspect money laundering and either become involved with it in some way and / or do nothing about it. The Policy sets out how any concerns should be raised.</p>
                        <p><strong>What's the risk? </strong></p>
                        <p>Violations of Anti Money Laundering Laws may lead to severe civil and/or criminal penalties against companies and individuals, including significant monetary fines, imprisonment, extradition, blacklisting, revocation of licences, and disqualification of directors.</p>
                        <p>In addition, violations of Anti Money Laundering Laws can lead to damaging practical consequences, including harm to reputation and commercial relationships, restrictions in the way we can do business, and extensive time and cost in conducting internal investigations and/or defending against government investigations and enforcement actions.</p>
                        <p><strong>Red Flag Indicators</strong></p>
                        <p>Where any suspicions arise that criminal conduct may have taken place involving a user, you should consider whether there is a risk that money laundering or terrorist financing has occurred or may occur.</p>
                        <p>Some examples of red flags to be reported include:</p>
                        <ul>
                          <li>A user provides insufficient, false or suspicious information or is reluctant to provide complete information.</li>
                          <li>Receipts of multiple negotiable instruments to pay a single invoice;</li>
                          <li>Payments to or from third parties that have no apparent or logical connection with the user or transaction;</li>
                          <li>Payment to or from countries considered high risk for money laundering or terrorist financing;</li>
                          <li>Payments to or from countries considered to be tax havens or offshore jurisdictions;</li>
                          <li>Payments from countries unrelated to the transaction or not logical for the user;</li>
                          <li>Overpayments followed by directions to refund a payment, especially if requested to send the payment to a third party;</li>
                          <li>Any user for whom you cannot determine the true beneficial owner;</li>
                          <li>Wire transfer activity that is not consistent with the business activities of the user, or which originates or terminates with parties unrelated to the transaction;</li>
                          <li>Unexpected spikes in a user&rsquo;s activities.</li>
                        </ul>
                        <p>The above is not intended to be an exhaustive list. Deviation from user and accepted business practice should alert you to further investigate the activity in accordance with this Policy.</p>
                        <p><strong>Compliance controls</strong></p>
                        <p>QUObal&rsquo;s Senior Management is responsible for ensuring that the Company has a culture of compliance and effective controls to comply with Anti Money Laundering Laws and regulations to prevent, detect and respond to money laundering and counter-terrorism financing and to communicate the serious consequences of non-compliance to employees.</p>
                        <p><strong>User Responsibility </strong></p>
                        <p>You have the obligation to read and follow this Policy, to understand and identify any red flags that may arise in their business activities and to escalate potential compliance concerns related to QUObal without notifying anyone involved in the transaction and should not take any actions prior to receiving advice and/or instructions.</p>
                        <p><strong>Due Diligence and Record Keeping </strong></p>
                        <p>It is our policy to carry out due diligence ("DD") at the outset of any business relationship and, if necessary, where any red flags arise subsequently on our users and any person with whom QUObal has an established business relationship that will involve the transfer to or receipt of funds ("Customers"), so we can be satisfied that they are who they say they are and so that we can ensure that there are no legal barriers to working with them before contracts are signed or transactions occur.</p>
                        <p><strong>Non-compliance </strong></p>
                        <p>Any QUObal user, who violates this Policy may be subject to appropriate legal action, independently from potential other penalties resulting from their behaviour including but not limited to account suspension.</p>
                        <p><strong>Monitoring for Suspicious Activity</strong></p>
                        <p>We will monitor account activity for unusual size, volume, pattern, or type of transactions, taking into account risk factors and red flags that are appropriate to our business. Monitoring will be conducted by means of automated monitoring and the relevant risk profile will serve as a baseline for assessing potentially suspicious activity. We will be responsible for this monitoring, will review any activity that our monitoring system detects, will determine whether any additional steps are required, will document when and how this monitoring is carried out, and will report suspicious activities to the appropriate authorities.</p>
                        <p><strong>Reporting </strong></p>
                        <p>Any user who becomes concerned that their involvement in a matter may amount to a prohibited act under the legislation, must disclose this promptly to QUObal. The disclosure should be at the earliest opportunity of the information coming to your attention, not weeks or months later. Should you not do so, then you may be liable to prosecution.</p>
                        <p>The user must follow any subsequent directions from QUObal and must not make any further enquiries themselves into the matter.</p>
                        <p>Additionally, they must not take any further steps in the transaction without authorisation from QUObal.</p>
                        <p>The user must not disclose or otherwise indicate their suspicions to the person(s) suspected of money laundering. The user must not discuss the matter with others or note on a file that a report has been made to QUObal in case this results in the suspect becoming aware of the suspicion.</p>
                        <p><strong>Who should I contact for more information?</strong></p>
                        <p>If you have any questions or comments about our AML Policy or wish to make a report, please contact us using the following contact details:</p>
                        <p>QUObal.com</p>
                        <p>Quomark Limited</p>
                        <p>1201 Orange Street, Suite 600,<br />
                          Wilmington, New Castle County,<br />
                            Delaware 19801, USA<br />
                            </p>
                            <p>Web: <a href="http://www.quobal.com">www.quobal.com</a></p>
                            <p>E-Mail: info@quobal.com</p>
                            <p>This AML Policy was last updated on Friday, August 19, 2022</p>
                            <hr />
                            <h3 className="page-title">FOSTA-SESTA Statement</h3>

                            <p>This statement is a response to Stop Enabling Sex Traffickers Act (SESTA) and the Fight Online Sex Trafficking Act (FOSTA) and sets out the steps QUObal.com Quomark Limited of 1201 Orange Street, Suite 600, Wilmington, New Castle County, Delaware 19801, USA is taking to ensure that online exploitation is not taking place in any part of our business.</p>
                            <p>QUObal recognizes that online exploitation and human trafficking are significant global issues presenting a challenge for businesses worldwide. QUObal is committed to improving its practices to combat online exploitation and human trafficking. QUObal has a zero-tolerance approach to online exploitation and human trafficking and is committed to acting ethically and with integrity in its business dealings.</p>
                            <p><strong>Our business and platform</strong></p>
                            <p>QUObal encourages transparency in its platform and is implementing a program of measures to mitigate the risk of online exploitation and human trafficking within its platform and the businesses is committed to having an ongoing and open dialogue with its users and working with them to ensure robust preventative measures are in place and it will continue to seek confirmations over time.</p>
                            <p><strong>Supporting Policies</strong></p>
                            <p>QUObal has in place policies reflecting its commitment to acting ethically and with integrity in all its business relationships. As part of QUObal&rsquo;s initiative to identify and online exploitation and human trafficking, systems will be developed to:</p>
                            <ul>
                              <li>Identify and assess potential risk areas in our platform;</li>
                              <li>Mitigate the risk of online exploitation and human trafficking occurring in our platform;</li>
                              <li>Monitor potential risk areas in our platform;</li>
                              <li>Provide staff with training to assist them in understanding the risks of online exploitation and human trafficking.</li>
                              <li>We will assess and monitor any risks through our risk register.</li>
                            </ul>
                            <p><strong>Due Diligence Questionnaires</strong></p>
                            <p>Any user identified as high risk are subject to further due diligence procedures, including the completion of a due diligence questionnaire designed to further assess the level of risk and the steps required to monitor and manage that risk. We remain committed to working with our users to ensure business is conducted ethically and honestly.</p>
                            <p><strong>Users adherence to our values</strong></p>
                            <p>QUObal has a zero-tolerance approach to online exploitation and human trafficking and it expects it users to uphold the same values. It will not conduct business knowingly with anyone engaged in online exploitation and human trafficking practices or knowingly permit them to be carried out in any part of its business.</p>
                            <p><strong>Monitoring for Suspicious Activity</strong></p>
                            <p>We will monitor account activity for online exploitation and human trafficking, taking into account risk factors and red flags that are appropriate to our business. Monitoring will be conducted by means of automated monitoring and the relevant risk profile will serve as a baseline for assessing potentially suspicious activity. We will be responsible for this monitoring, will review any activity that our monitoring system detects, will determine whether any additional steps are required, will document when and how this monitoring is carried out, and will report suspicious activities to the appropriate authorities.</p>
                            <p><strong>Reporting </strong></p>
                            <p>Any user who becomes concerned that their involvement in a matter may amount to a prohibited act under the legislation, must disclose this promptly to QUObal. The disclosure should be at the earliest opportunity of the information coming to your attention, not weeks or months later. Should you not do so, then you may be liable to prosecution.</p>
                            <p>The user must follow any subsequent directions from QUObal and must not make any further enquiries themselves into the matter.</p>
                            <p>The user must not disclose or otherwise indicate their suspicions to the person(s) suspected of online exploitation and human trafficking. The user must not discuss the matter with others or note on a file that a report has been made to QUObal in case this results in the suspect becoming aware of the suspicion.</p>
                            <p><strong>Who should I contact for more information?</strong></p>
                            <p>If you have any questions or comments about our FOSTA-SESTA Statement or wish to make a report, please contact us using the following contact details:</p>
                            <p>QUObal.com</p>
                            <p>Quomark Limited</p>
                            <p>1201 Orange Street, Suite 600,<br />
                              Wilmington, New Castle County,<br />
                                Delaware 19801, USA            <br />
                                </p>
                                <p>Web: <a href="http://www.quobal.com">www.quobal.com</a></p>
                                <p>E-Mail: info@quobal.com</p>
                              </Col>
                            </Row>
                            </Container>
                          </>
                          );

                          export default ExamplePage;

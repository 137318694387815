import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useSelector } from 'react-redux';
import AvatarRender from '~~/avatar_render';

const contactClass = (active, isUnread) => classNames({
  chat__contact: true,
  'chat__contact--active': active,
  'chat__contact-unread': isUnread,
});

const SentBoxContact = ({ conversation, active }) => {
const user = {
    avatar: conversation.avatar,
    username: conversation.username,
    random_no: conversation.random_no,
  };
  return (
    <div className={contactClass(active, conversation.membera_unread > 0)}>
      <div className="chat__contact-avatar">
        <LazyLoadImage
          src={
            AvatarRender(user.avatar, user.random_no)
          }
          alt="ava"
          effect="blur"
        />
      </div>
      <div className="chat__contact-preview">
        <p className="chat__contact-name">{user.username}</p>
        <p className="chat__contact-last-message">{conversation.q_message.length > 10 ? `${conversation.q_message.substring(0, 10)}…` : `${conversation.q_message}`}</p>
        {conversation.membera_unread > 0 ? <i><span>{conversation.membera_unread}</span></i> : ""}
      </div>
    </div>
  );
};

export default SentBoxContact;

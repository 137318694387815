import React from 'react';
// import { Col, Container, Row } from 'reactstrap';
import getTransText from '~~/get-text/getTransText';
import DropFilePond from './components/DropFilePond';

const FormDropzone = ({ fields, setFiles }) => (
  <div className="submssion__form">
    <div className="modal__title">
      <h4 className="bold-text">{getTransText("Upload Files")}</h4>
    </div>
    <div className="modal__quo-info">
    </div>
    <DropFilePond setFiles={setFiles} fields={fields} />
  </div>
);

export default FormDropzone;

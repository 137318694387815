import { client, clientPublic } from '~/apiCalls/directusConfig';


export const sendQuoMessageTrigger = async (quoId) => {
  try {
    const config = {
      token: quoId,
    };
    const res = await client.request(() => ({
      path: "/manage-quo/get-quoinfo-message",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    return null;
  }
};

export const createPreQuo = async (credential) => {
  try {
    const res = await client.request(() => ({
      path: "/quo-payment/pre-quo",
      method: "POST",
      body: JSON.stringify(credential),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const createOutreachPreQuo = async (credential) => {
  try {
    const res = await client.request(() => ({
      path: "/quo-payment/outreach-pre-quo",
      method: "POST",
      body: JSON.stringify(credential),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};


export const createOutreachPreQuoAdmin = async (credential) => {
  try {
    const res = await client.request(() => ({
      path: "/quo-payment/outreach-pre-quo-admin",
      method: "POST",
      body: JSON.stringify(credential),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const getOutreachPreQuo = async (quoId) => {
  try {
    const res = await client.request(() => ({
      path: `/quo-payment/outreach-pre-quo/${quoId}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const getOutreachQuo = async (quoId) => {
  try {
    const res = await client.request(() => ({
      path: `/manage-quo/inviting/${quoId}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};


export const creatorReceiveOutreachQuo = async (quoId) => {
  try {
    const res = await client.request(() => ({
      path: `/manage-quo/creator-receive-outreach/${quoId}`,
      method: "POST",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const getPreQuo = async (quoId) => {
  try {
    const res = await client.request(() => ({
      path: `/quo-payment/pre-quo/${quoId}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};
export const redeemInvitingApi = async (values) => {
  const res = await client.request(() => ({
    path: "/manage-quo/redeem-inviting",
    method: "POST",
    body: JSON.stringify(values),
  }));
  return (res);
};

export const acceptPending = async (quoId) => {
  try {
    const config = {
      quoId,
    };
    const res = await client.request(() => ({
      path: "/manage-quo/accept-pending",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const counterPending = async ({ quoId, values }) => {
  try {
    const config = {
      quoId,
    };
    if (values) {
      config.title = values.title;
      config.description = values.request;
      config.media_type = values.media;
      config.price = values.price;
    }
    const res = await client.request(() => ({
      path: "/manage-quo/counter-pending",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // console.log(err);
    return null;
  }
};

export const rejectPending = async (quoId) => {
  try {
    const config = {
      quoId,
    };
    const res = await client.request(() => ({
      path: "/manage-quo/reject-pending",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // console.log(err);
    return null;
  }
};


export const acceptCountering = async (quoId) => {
  try {
    const config = {
      quoId,
    };
    const res = await client.request(() => ({
      path: '/quo-payment/accept-countering',
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // console.log(err);
    return null;
  }
};


export const rejectCountering = async (quoId) => {
  try {
    const config = {
      quoId,
    };
    const res = await client.request(() => ({
      path: "/manage-quo/reject-countering",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // console.log(err);
    return null;
  }
};


export const pickedFileFirstSubmission = async ({ quoId, fileList }) => {
  try {
    const config = {
      "quo_id": quoId,
      "file_id_list": fileList,
    };
    const res = await client.request(() => ({
      path: `/file-access/first-submission`,
      method: "POST",
      body: JSON.stringify(config),
    }));
    return res;
  } catch (err) {
    // console.log(err);
    return null;
  }
};

export const pickedFileReSubmission = async ({ quoId, fileList }) => {
  try {
    const config = {
      "quo_id": quoId,
      "file_id_list": fileList,
    };
    const res = await client.request(() => ({
      path: `/file-access/re-submission`,
      method: "POST",
      body: JSON.stringify(config),
    }));
    return res;
  } catch (err) {
    // console.log(err);
    return null;
  }
};

export const acceptDeal = async (quoId) => {
  try {
    const config = {
      "quoId": quoId,
    };
    const res = await client.request(() => ({
      path: "/manage-quo/accept-deal",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // console.log(err);
    return null;
  }
};


// ManageQuo API

export const getQuos = async (cpage) => {
  try {
    const res = await client.request(() => ({
      path: `/manage-quo?current_page=${cpage}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};


export const getQuoStatus = async () => {
  try {
    const res = await client.request(() => ({
      path: `/manage-quo/status`,
      method: "GET",
    }));
    return (res[0]);
  } catch (err) {
    // // console.log(err);
  }
};

export const getQuoDetailsById = async (quo_id) => {
  try {
    const res = await client.request(() => ({
      path: `/manage-quo/${quo_id}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};

export const quoCreateConversation = async (receiver, quoId) => {
  try {
    const config = {
      "receiverId": receiver,
      "quoId": quoId,
    };
    const res = await client.request(() => ({
      path: "/manage-quo/createNewConversation",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const placeQuo = async (credentials) => {
  try {
    const config = {
      "description": credentials.request,
      "proposed_delivery_time": credentials.delivertime,
      "price": credentials.price,
      "media_type": credentials.media,
      "receiver": credentials.receiver,
    };
    const res = await client.request(() => ({
      path: "/manage-quo/placeQuo",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const responseToPending = async (response, quoId, credentials) => {
  try {
    const config = {
      "quoId": quoId,
      "response": response,
    };
    if (credentials) {
      config.description = credentials.request;
      config.media_type = credentials.media;
      config.price = credentials.price;
      config.proposed_delivery_time = credentials.delivertime;
    }
    const res = await client.request(() => ({
      path: "/manage-quo/responseToPending",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const responseToCountering = async (response, quoId, credentials) => {
  try {
    const config = {
      "quoId": quoId,
      "response": response,
    };
    if (credentials) {
      config.description = credentials.request;
      config.media_type = credentials.media;
      config.price = credentials.price;
      config.proposed_delivery_time = credentials.delivertime;
    }
    const res = await client.request(() => ({
      path: "/manage-quo/responseToCountering",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const getSubmittedMediaByQuoId = async (quoId, sub_no) => {
  try {
    const res = await client.request(() => ({
      path: `/file-access/quoMedia/${quoId}?no=${sub_no}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const pickedFileSubmission = async (quoId, fileList) => {
  try {
    const config = {
      "quo_id": quoId,
      "file_id_list": fileList,
    };
    const res = await client.request(() => ({
      path: `/file-access/submission`,
      method: "POST",
      body: JSON.stringify(config),
    }));
    return res;
  } catch (err) {
    // // console.log(err);
    return null;
  }
};




export const getUploadedGalleryFile = async (page = null) => {
  try {
    let res;
    let resCount;
    if (page !== null) {
      res = await client.request(() => ({
        path: `/files/?filter={"_and":[{"file_purpose":{"_neq":"avatar"}},{"file_purpose":{"_nstarts_with":"message"}},{"file_purpose":{"_neq":"profile"}}]}&&sort[]=-uploaded_on&meta=filter_count&limit=10&page=${page}`,
        method: "GET",
      }));
      resCount = await client.request(() => ({
        path: `/files/?filter={"_and":[{"file_purpose":{"_neq":"avatar"}},{"file_purpose":{"_nstarts_with":"message"}},{"file_purpose":{"_neq":"profile"}}]}&&sort[]=-uploaded_on&meta=filter_count&limit=10&page=${page}&aggregate={"count":"*"}`,
        method: "GET",
      }));
    } else {
      res = await client.request(() => ({
        path: `/files/?filter={"_and":[{"file_purpose":{"_neq":"avatar"}},{"file_purpose":{"_nstarts_with":"message"}},{"file_purpose":{"_neq":"profile"}}]}&sort[]=-uploaded_on`,
        method: "GET",
      }));
      resCount = await client.request(() => ({
        path: `/files/?filter={"_and":[{"file_purpose":{"_neq":"avatar"}},{"file_purpose":{"_nstarts_with":"message"}},{"file_purpose":{"_neq":"profile"}}]}&sort[]=-uploaded_on&aggregate={"count":"*"}`,
        method: "GET",
      }));
    }
    const returnRes = {
      data: res,
      meta: resCount,
    };

    return (returnRes);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};



export const deleteLibraryPhoto = async (values) => {
  try {
    const config = { payload: values };
    const res = await client.request(() => ({
      path: "/file-access/delete-media",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};




export const acceptTheDeal = async (quoId) => {
  try {
    const config = {
      "quoId": quoId,
    };
    const res = await client.request(() => ({
      path: "/manage-quo/acceptTheDeal",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const ratingApi = async (quoId, response, target) => {
  try {
    const config = {
      "quoId": quoId,
      "isGood": response,
      "rateTarget": target,
    };
    const res = await client.request(() => ({
      path: `/manage-quo/responseRating`,
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const isRatedApi = async (quoId, target) => {
  try {
    const config = {
      "quoId": quoId,
      "rateTarget": target,
    };
    const res = await client.request(() => ({
      path: `/manage-quo/isRated`,
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};


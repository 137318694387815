import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  Button,
  FormGroup,
  Input,
  ButtonToolbar,
} from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import getTransText from '~~/get-text/getTransText';
import { changePassword } from '~/apiCalls/userApi';
import ModalParent from '~~/modal_parent/ModalParent';
import TfaForm from './TfaForm';
import DisableTfaForm from './DisableTfaForm';

const PasswordSettingForm = ({ setCollapseToggle }) => {
  const {
    userData,
  } = useSelector(state => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const [toggler, setToggler] = useState(false);

  const switchToggler = () => {
    setToggler(!toggler);
  };
  const [toggler2, setToggler2] = useState(false);

  const switchToggler2 = () => {
    setToggler2(!toggler2);
  };

  const onSubmit = async (values) => {
    await changePassword(values)
      .then((response) => {
        if (response.success) {
          NotificationManager.info(getTransText('Password Changed.'));
        }
      })
      .catch((response) => {
        NotificationManager.info(getTransText('Invaild Credentials.'));
      });
  };

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };


  return (
    <div>

      <h3 className="page-title">
        <button className="account_setting_button--menu" type="button" onClick={() => setCollapseToggle(false)}>
          <i className="las la-arrow-left"></i>
        </button>
        {getTransText("Change Password")}
      </h3>
      <NotificationContainer leaveTimeout={6000} />
      <Form
        onSubmit={onSubmit}
        validate={values => {
          const errors = {};
          if (!values.password_check) {
            errors.password_check = "Required";
          }
          if (!values.new_password) {
            errors.new_password = "Required";
          } else if (values.new_password.length < 8) {
            errors.new_password = 'Please input more than 8 digit.';
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = "Required";
          } else if (values.confirmPassword !== values.new_password) {
            errors.confirmPassword = "Must match";
          }
          return errors;
        }}
      >
        {({ handleSubmit, values }) => (
          <form className="form change_pw-form" onSubmit={handleSubmit}>
            <FormGroup className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <i className="las la-lock"></i>
                </div>
                <Field
                  name="password_check"
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field form__form-group-input-wrap--error-above">
                      <Input
                        {...input}
                        name="password_check"
                        type={showPassword ? 'text' : 'password'}
                        placeholder={getTransText("Password")}
                        className="input-without-border-radius"
                      />
                      <button
                        type="button"
                        className={`form__form-group-button${showPassword ? ' active' : ''}`}
                        onClick={toggleShowPassword}
                      ><EyeIcon />
                      </button>
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
            </FormGroup>
            <FormGroup className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <i className="las la-lock"></i>
                </div>
                <Field
                  name="new_password"
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field form__form-group-input-wrap--error-above">
                      <Input
                        {...input}
                        name="new_password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder={getTransText("New Password")}
                        className="input-without-border-radius"
                      />
                      <button
                        type="button"
                        className={`form__form-group-button${showPassword ? ' active' : ''}`}
                        onClick={toggleShowPassword}
                      ><EyeIcon />
                      </button>
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
            </FormGroup>
            <FormGroup className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <i className="las la-lock"></i>
                </div>
                <Field
                  name="confirmPassword"
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field">
                      <Input
                        {...input}
                        name="confirmPassword"
                        type={showPassword ? 'text' : 'password'}
                        placeholder={getTransText("Confirm Password")}
                        className="input-without-border-radius"
                      />
                      <button
                        type="button"
                        className={`form__form-group-button${showPassword ? ' active' : ''}`}
                        onClick={toggleShowPassword}
                      ><EyeIcon />
                      </button>
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
            </FormGroup>
            <ButtonToolbar className="form__button-toolbar btn-toolbar--center">
              <Button color="primary" type="submit">{getTransText("Change Password")}</Button>
            </ButtonToolbar>
          </form>
        )}
      </Form>
      <hr />
      {userData && !userData.tfa_secret
        ? (
          <>
            <h4 className="bold-text">
              {getTransText("Two-Factor Authentication (2FA)")}
            </h4>
            <NotificationContainer leaveTimeout={6000} />
            <ModalParent className="2fa_modal" toggler={toggler} switchToggler={switchToggler}>
              <TfaForm />
            </ModalParent>
            <ButtonToolbar className="form__button-toolbar btn-toolbar--center">
              <Button color="primary" type="button" onClick={() => setToggler(!toggler)}>{getTransText("Click here to Enable TFA")}</Button>
            </ButtonToolbar>
          </>
        )
        : (
          <>
            <h3 className="page-title">
              <button className="account_setting_button--menu" type="button" onClick={() => setCollapseToggle(false)}>
                <i className="las la-arrow-left"></i>
              </button>
              {getTransText("Two-Factor Authentication (2FA)")}
            </h3>
            <NotificationContainer leaveTimeout={6000} />
            <ModalParent className="2fa_modal" toggler={toggler2} switchToggler={switchToggler2}>
              <DisableTfaForm />
            </ModalParent>
            <ButtonToolbar className="form__button-toolbar btn-toolbar--center">
              <Button color="primary" type="button" onClick={() => setToggler2(!toggler2)}>{getTransText("Click here to Disable TFA")}</Button>
            </ButtonToolbar>
          </>
        )}
    </div>
  );
};

export default PasswordSettingForm;

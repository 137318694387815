import React from 'react';
import { Container, Row } from 'reactstrap';
import MyUploadedGalleryContainer from './components/MyUploadedGalleryContainer';

const ExamplePage = () => (
  <Container className="media__library">
    <Row>
      <MyUploadedGalleryContainer />
    </Row>
  </Container>
);

export default ExamplePage;

import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import {
  Card, CardBody, Col, Row, Container, Button,
} from 'reactstrap';
import ProfileTimeLine from './ProfileTimeLine';
import QuoDetail from '~~/quo-details/QuoDetail';
import { getQuoDetailsById } from '~/apiCalls/quoApi';
import QuoStatusOutPut from '~~/status_panel/QuoStatusOutput.jsx';
import getTransText from '~~/get-text/getTransText';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';

const QuoHistory = () => {
  const { quo_id } = useParams();
  const [quoLatest, setQuoLatest] = useState();
  const [quoActivityDetails, setQuoActivityDetails] = useState();
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    let isCancelled = false;
    const res = await getQuoDetailsById(quo_id);
    if (res) {
      const { quo, quoActivity } = res;
      if (!isCancelled) {
        setQuoLatest(quo);
        setQuoActivityDetails(quoActivity);
      }
      setLoaded(true);
    }
    return () => {
      isCancelled = true;
    };
  }, [quo_id]);

  const renderBackLink = () => (<div className="back"><Button className="btn-back control-btn" color="primary" onClick={() => { history.goBack(); }}><i className="las la-angle-left"></i></Button></div>);

  return (
    <Container className="quo__history__container">
      {loaded
        ? (
          <Row>
            <Col xs={12}>
              <div className="quo__history">
                <div className="quo__history__detail">
                  <Card className="quo__history__detail__card">
                    <CardBody>
                      {renderBackLink()}
                      {quoLatest ? <QuoDetail quo={quoLatest[0]} isLink={false} /> : ""}
                      {quoLatest
                        ? (
                          <div>
                            <ReactQuill
                              value={quoLatest[0].latest_description}
                              readOnly={true}
                            />
                          </div>
                        ) : ""}
                      {quoLatest ? <div className="quo__history-quo-status"><QuoStatusOutPut quo={quoLatest[0]} /></div> : ""}
                      <p className="small-text">{getTransText("You don’t have a good experience?")}</p>
                      <p className="small-text"><Link to={{ pathname: "/accountSetting/contact", state: { id: quo_id } }}>{getTransText("Report")}</Link> {getTransText("now!")}</p>
                      {/* <div className="subhead">
                    <h4 className="bold-text">Rate</h4>
                  </div>
                  <div className="rate me_rate">
                    <div className="quo__status-avatar"></div>
                    <div className="rate-icon"><i className="las la-star"></i></div>
                    <div className="rate-icon"><i className="las la-star"></i></div>
                  </div>
                  <div className="rate other_rate">
                    <div className="quo__status-avatar"></div>
                    <div className="rate-icon"><i className="las la-star"></i></div>
                    <div className="rate-icon"><i className="las la-star"></i></div>
                  </div> */}
                    </CardBody>
                  </Card>
                </div>
                <div className="quo__history__timeline">
                  <h3 className="page-title">{getTransText("QUO History")}</h3>
                  <ProfileTimeLine data={quoActivityDetails} />
                </div>
              </div>
            </Col>
          </Row>
        )
        : (
          <LoadingLogo />
        )}
    </Container>
  );
};
export default QuoHistory;

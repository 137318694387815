import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { apiCallBegan } from '~/store/api';
import { readUserProductListApi } from '~/apiCalls/marketplaceApi';
import { getProductList, marketplaceError } from '~/store/marketplace';
import Catalog from './Catalog';

const ProfileContainer = () => {
  const dispatch = useDispatch();
  const {
    userInfo,
    isMe,
  } = useSelector(state => state.profile);
  const { productList } = useSelector(state => state.marketplace);

  useEffect(() => {
    if (userInfo?.username) {
      dispatch(apiCallBegan({
        axiosCall: readUserProductListApi,
        data: userInfo.username,
        onSuccess: getProductList.type,
        onError: marketplaceError.type,
      }));
    }
  }, [userInfo]);


  return (
    <>
      <Catalog />
    </>
  );
};


export default ProfileContainer;

import React from 'react';
import { Table } from 'reactstrap';
import { DateTime } from "luxon";
import getTransText from '~~/get-text/getTransText';
import ReadRenderProductList from './ReadRenderProductList';


const ProductsListTable = ({ rows, handlePage }) => {
  const header = [
    { id: 0, title: getTransText('marketplace.product.date') },
    { id: 1, title: getTransText("marketplace.product.title") },
    { id: 2, title: getTransText("marketplace.product.description") },
    { id: 3, title: getTransText("marketplace.product.price") },
    { id: 4, title: getTransText("marketplace.product.status") },
    { id: 5, title: getTransText("productedit.header.edit") },
  ];

  const monthRender = DateTime.now().toFormat('yyyy-MM');

  const renderList = () => {
    if (rows?.data && rows?.data.length > 0) {
      return (rows.data.map((item, index) => (
        <ReadRenderProductList item={item} index={index} key={item.id} />
      )));
    }

    return (<td colSpan="4" className="transaction__notice">{getTransText("productsetting.productlist.no")}</td>);
  };

  const Pagination = () => {
    if (rows?.has_more) {
      const lastElement = rows.data.slice(-1);
      return (
        <div className="btn-toolbar">
          <button className="btn btn-primary" type="button" onClick={() => handlePage(lastElement[0].id)}>
            More Activity
          </button>
        </div>
      );
    }
  };

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            {header.map(item => (
              <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {renderList()}
        </tbody>
      </Table>
      {Pagination(rows)}
    </>
  );
};

export default ProductsListTable;

import { DateTime } from "luxon";
import React from 'react';
import { FormGroup, Table } from 'reactstrap';
import getTransText from '~~/get-text/getTransText';
import Panel from '~~/Panel';
import ReadRenderRows from './ReadRenderRows';

const TradeHistory = ({ rows, handleMonthChange, handlePage }) => {
  const header = [
    { id: 0, title: getTransText('Date') },
    { id: 1, title: getTransText("Type") },
    { id: 2, title: getTransText("Source") },
    { id: 3, title: getTransText("Net") },
    { id: 4, title: '' },
  ];

  const monthRender = DateTime.now().toFormat('yyyy-MM');

  const renderList = () => {
    if (rows?.data && rows?.data.length > 0) {
      return (rows.data.map((item, index) => (
        <ReadRenderRows item={item} index={index} key={item.id} />
      )));
    }

    return (<td colSpan="4" className="transaction__notice">{getTransText("Not selling transactions in this month.")}</td>);
  };

  const Pagination = () => {
    if (rows?.has_more) {
      const lastElement = rows.data.slice(-1);
      return (
        <div className="btn-toolbar">
          <button className="btn btn-primary" type="button" onClick={() => handlePage(lastElement[0].id)}>
            More Activity
          </button>
        </div>
      );
    }
  };

  return (
    <div className="wallet__activity">
      <h3 className="page-title">{getTransText("ACTIVITY")}</h3>
      <p className="page-subhead">{getTransText("Balance Transaction List")}</p>
      <div className="wallet-calendar">
        <FormGroup className="form__form-group">
          <div className="form__form-group-field">
            <input
              type="month"
              id="start"
              name="start"
              onChange={(e) => handleMonthChange(e)}
              min="2022-01"
              max={monthRender}
              defaultValue={monthRender}
            />
          </div>
        </FormGroup>
      </div>
      <Table responsive>
        <thead>
          <tr>
            {header.map(item => (
              <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {renderList()}
        </tbody>
      </Table>
      {Pagination(rows)}
    </div>
  );
};

export default TradeHistory;

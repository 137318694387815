import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga4';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { CountryDropdown } from 'react-country-region-selector';
import {
  Button,
  FormGroup,
  Input,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
} from 'reactstrap';
import { firstSetupProfile } from '~/apiCalls/userApi';
import { apiCallBegan } from '~/store/api';
import {
  userProfileUpdated,
  userProfileUpdateError,
} from '~/store/user';
import getTransText from '~~/get-text/getTransText';
import renderRadioButtonField from '~~/form/RadioButton';


const errorMsgRender = (state) => {
  const { user } = state;
  let error = '';
  if (user.error) {
    if (user.error.status === 401 || user.error.status === 403) {
      if (user.error.errors[0].message.includes('ER_DUP_ENTRY')) {
        error = getTransText("Username has been taken.");
      }
    }
  }
  return ({
    errorMsg: error,
  });
};

const ProfileForm = ({ errorMsg = '' }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    username, email,
  } = useSelector(state => state.user.userData);

  const required = value => (value ? undefined : getTransText('Required'));
  const moreThan5 = value => (value.length < 5 ? getTransText('Please input more than 5 char.') : undefined);
  const noSpaceAllowed = value => (value.indexOf(' ') >= 0 ? getTransText('No space allowed') : undefined);
  const maxLength = value => (value.length > 30 ? getTransText('No more than 30 chars.') : undefined);
  const usernameOnlyContain = value => {
    const re = new RegExp("^(?:[a-zA-Z0-9]|([._])(?!\\1)){5,30}$");
    if (!re.test(value)) {
      return getTransText("contains only letters, numbers, periods and underscores.");
    }
    return undefined;
  };

  const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);


  const onSubmit = (values) => {
    ReactGA.event({
      category: 'Pre-Registration Engagement',
      action: "Registration By Google And Set Username",
      label: values?.username,
    });
    dispatch(apiCallBegan({
      axiosCall: firstSetupProfile,
      data: values,
      onSuccess: userProfileUpdated.type,
      onError: userProfileUpdateError.type,
    }));
  };

  useEffect(() => {
    if (username) {
      history.push(`/p/${username}`);
    }
  }, [username]);

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            username: "",
          }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, submitting, values }) => (
            <form className="form" onSubmit={handleSubmit}>
              {errorMsg && <span className="form__form-group-error" style={{ textAlign: 'center' }}>{errorMsg}</span>}
              <FormGroup className="form__form-group">
                <span className="form__form-group-label">Username</span>
                <Field
                  name="username"
                  validate={composeValidators(required, moreThan5, noSpaceAllowed, maxLength, usernameOnlyContain)}
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field">
                      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                        <Input
                          {...input}
                          name="username"
                          type="text"
                          placeholder="Name"
                          className="input-without-border-radius"
                        />
                        {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                      </div>
                    </div>
                  )}
                </Field>
              </FormGroup>
              <FormGroup className="form__form-group">
                <span className="form__form-group-label">Country</span>
                <Field
                  name="country"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field">
                      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                        <CountryDropdown
                          {...input}
                          name="country"
                          valueType="short"
                        />
                        {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                      </div>
                    </div>
                  )}
                </Field>
              </FormGroup>
              <FormGroup className="form__form-group">
                <div>
                  <Field
                    name="isCreator"
                    component={renderRadioButtonField}
                    label={getTransText("register.form.creator")}
                    radioValue="Creator"
                  // styleType="colored"
                  />
                </div>
                <div>
                  <Field
                    name="isCreator"
                    component={renderRadioButtonField}
                    label={getTransText("register.form.buyer")}
                    radioValue="Buyer"
                  // styleType="colored"
                  />
                </div>
              </FormGroup>
              <ButtonToolbar className="btn-toolbar--center">
                <Button color="primary" type="submit">{getTransText("Submit")}</Button>
              </ButtonToolbar>
            </form>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};


export default connect(errorMsgRender)(ProfileForm);

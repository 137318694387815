import React, { useState, useRef, useEffect } from 'react';
// import { Input } from 'reactstrap';
import produce from 'immer';
import classNames from 'classnames';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import getTransText from '~~/get-text/getTransText';
// import PaperclipIcon from 'mdi-react/PaperclipIcon';
// import EmoticonIcon from 'mdi-react/EmoticonIcon';
// import ChevronRightCircleIcon from 'mdi-react/ChevronRightCircleIcon';

const ChatField = ({
  handleSubmit, sendFileMessageClick, newMessages, setNewMessages,
}) => {
  const ref = useRef(null);
  const [selection, setSelection] = useState();
  const [emojiToggle, setEmojiToggle] = useState(true);

  const emojiStyle = classNames({
    "chat__emoji-panel": true,
    "open": !emojiToggle,
  });

  const changeEmojiToggleVisibility = () => {
    setEmojiToggle(!emojiToggle);
  };


  const onEmojiClick = (emojiObject) => {
    const cursor = ref.current.selectionStart;
    const text = produce(newMessages, (draft) => (draft.slice(0, cursor) + emojiObject.native + draft.slice(cursor)));
    setSelection({ start: cursor + 2, end: cursor + 2 });
    setNewMessages(text);
  };

  useEffect(() => {
    if (!selection) return;
    const { start, end } = selection;
    ref.current.focus();
    ref.current.setSelectionRange(start, end);
  }, [selection]);

  return (
    <div className="chat__text-field">
      <div className={emojiStyle}>
        <Picker
          showPreview={false}
          showSkinTones={false}
          style={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
          }}
          onSelect={(emoji) => {
            onEmojiClick(emoji);
            changeEmojiToggleVisibility();
          }}
        />
      </div>
      <form className="chat__form" onSubmit={handleSubmit}>
        <div className="chat__field-controls-left">
          <label className="chat__icon-button chat__icon-attach" htmlFor="uploadButton">
            <input
              id="uploadButton"
              type="file"
              className="uploadFile"
              onChange={(e) => sendFileMessageClick(e)}
              accept="image/*,video/*"
              style={{ display: 'none' }}
            />
            <i className="las la-paperclip"></i>
          </label>
          <button
            type="button"
            className="chat__icon-button"
            onClick={() => (changeEmojiToggleVisibility())}
          >
            <i className="las la-smile"></i>
          </button>
        </div>
        <input
          className="chat__field-input"
          name="chatField"
          ref={ref}
          placeholder={`${getTransText("Type here")}…`}
          onChange={(e) => {
            e.persist();
            const caretStart = e.target.selectionStart;
            const caretEnd = e.target.selectionEnd;
            // update the state and reset the caret
            setNewMessages(e.target.value);
            e.target.setSelectionRange(caretStart, caretEnd);
          }}
          value={newMessages}
          autoComplete="off"
        />
        <div className="chat__field-controls">
          <button type="submit" className="chat__icon-button">
            <i className="las la-paper-plane"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatField;


import React, {
  useRef, useState
} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { formatSeconds } from '~~/datetime_render/FormatDate';
import getTransText from '~~/get-text/getTransText';

const ReadRenderRows = ({ item }) => {
  const {
    langCode,
  } = useSelector(state => state.ui);
  const [getQuoId, setGetQuoId] = useState();
  const ref = useRef(null);

  const LangCodeForCurrency = (e) => {
    switch (e) {
      case 'zh-TW':
        return 'zh-TW';
      case 'en-US':
        return 'en-US';
      case 'ja':
        return 'ja-JP';
      default:
        return 'en-US';
    }
  };

  return (
    <tr key={item.id}>
      <td>{formatSeconds(item.created)}</td>
      <td>
        {item.type}
      </td>
      <td>
        {item?.metadata?.quo_id ? <Link to={`/q/${item?.metadata.quo_id}`}>{getTransText("Link to Quo")}</Link> : "-"}
      </td>
      <td>{new Intl.NumberFormat(LangCodeForCurrency(langCode), { style: 'currency', currency: item.currency }).format(item.amount / 100)} {item.exchange_rate ? <i className="las la-info-circle" ref={ref}></i> : ""}</td>
      {item.metadata && (
        <UncontrolledTooltip placement="top" target={ref}>
          <table className="tg">
            <thead>
              <tr>
                <th className="tg-73oq">{getTransText("QUO Price")}:</th>
                <th className="tg-3z1b"> </th>
                <th className="tg-3z1b">{`${new Intl.NumberFormat(LangCodeForCurrency(langCode), { style: 'currency', currency: 'usd' }).format(item.metadata.origin_amount)}`}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tg-73oq">{getTransText("Platform Fee")}({item.metadata.member_rate}%):</td>
                <td className="tg-3z1b">-</td>
                <td className="tg-3z1b">
                  {item?.metadata.origin_amount
                    ? `(${new Intl.NumberFormat(LangCodeForCurrency(langCode), { style: 'currency', currency: 'usd' }).format(item.metadata.origin_amount * (item.metadata.member_rate / 100))})`
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="tg-73oq">{getTransText("Stripe Fee")}({item.metadata.platform_rate}%):</td>
                <td className="tg-3z1b">-</td>
                <td className="tg-3z1b">
                  {item?.metadata.origin_amount
                    ? `(${new Intl.NumberFormat(LangCodeForCurrency(langCode), { style: 'currency', currency: 'usd' }).format(item.metadata.origin_amount * (item.metadata.platform_rate / 100))})`
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="tg-73oq">{getTransText("Exchange rate")}:</td>
                <td className="tg-3z1b">*</td>
                <td className="tg-3z1b">{item.exchange_rate}</td>
              </tr>
            </tbody>
          </table>
        </UncontrolledTooltip>
      )}
      <td className="align-center">{item.status === "pending" ? (<i className="las la-clock"></i>) : (<i className="las la-check"></i>)}</td>
    </tr>
  );
};

export default ReadRenderRows;

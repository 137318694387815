import React, { useState } from 'react';
import { Field, Form, useFormState } from 'react-final-form';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  useHistory
} from 'react-router-dom';
import {
  Button, ButtonToolbar, FormGroup,
  Input
} from 'reactstrap';
import { deleteAccountApi } from '~/apiCalls/userApi';
import getTransText from '~~/get-text/getTransText';
import ModalParent from '~~/modal_parent/ModalParent';

const RenderButton = ({ handleSubmit }) => {
  const { errors } = useFormState();
  const [toggler, setToggler] = useState(false);

  const switchToggler = () => {
    setToggler(!toggler);
  };

  return (
    <>
      <ModalParent className="2fa_modal" toggler={toggler} switchToggler={switchToggler}>
        <div>
          {getTransText("Remember to check if you have an existing balance that has not been withdrawn before deleting your account.")}
        </div>
        <Button color="primary" type="submit" onClick={handleSubmit}>{getTransText("Delete Account")}</Button>
      </ModalParent>
      <ButtonToolbar className="btn-toolbar--center">
        <Button color="primary" type="button" onClick={() => !errors?.delete && setToggler(!toggler)}>{getTransText("Delete Account")}</Button>
      </ButtonToolbar>
    </>
  );
};

const AccountSettingForm = ({ setCollapseToggle }) => {
  const history = useHistory();
  const required = value => (value ? undefined : getTransText('Required'));
  const wrongInputString = getTransText("Wrong input.");
  const accountDeleteString = getTransText('Account Deleted.');
  const networkErrorString = getTransText('Network Error');
  const typeExact = value => (value !== "Delete Account" ? wrongInputString : undefined);
  const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);

  const deleteAccount = async () => {
    await deleteAccountApi()
      .then((response) => {
        if (response.status === 200 && response.data === 1) {
          NotificationManager.info(accountDeleteString);
          history.push(`/logout/`);
        }
      })
      .catch((response) => {
        NotificationManager.info(networkErrorString);
      });
  };

  const onSubmit = (values) => {
    deleteAccount();
  };


  return (
    <div>
      <h3 className="page-title">
        <button className="account_setting_button--menu" type="button" onClick={() => setCollapseToggle(false)}>
          <i className="las la-arrow-left"></i>
        </button>
        {getTransText("Delete Account")}
      </h3>
      <NotificationContainer leaveTimeout={6000} />
      <Form
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          submitting,
          values,
        }) => (
          <form className="form" onSubmit={handleSubmit}>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("Type 'Delete Account' in the input box.")}</span>
              <div className="form__form-group-field">
                <Field
                  name="delete"
                  type="text"
                  validate={composeValidators(required, typeExact)}
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field form__form-group-input-wrap--error-above">
                      <Input
                        {...input}
                        placeholder={getTransText("Input Delete Account here")}
                        className="input-without-border-radius"
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
            </FormGroup>
            <RenderButton handleSubmit={handleSubmit} />
          </form>
        )}
      </Form>
    </div>
  );
};


export default AccountSettingForm;

import React from 'react';
import { Link } from 'react-router-dom';
import getTransText from '~~/get-text/getTransText';


const LandingTabPage = ({ buyingActive, sellingActive }) => (
  <div className="tab-page">
    <Link className={`btn-page ${buyingActive}`} to="/buying">{getTransText("landing.tab.buyer")}</Link>
    <Link className={`btn-page ${sellingActive}`} to="/selling">{getTransText("landing.tab.seller")}</Link>
  </div>
);

export default LandingTabPage;

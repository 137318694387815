import React, { useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  Col, Row
} from 'reactstrap';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Searching from './QuoPanel/Searching';
import TimeOut from './QuoPanel/TimeOut';
import { apiCallBegan } from "~/store/api";
import { getOpenCallList } from '~/store/opencall';
import { getOpenQuos } from '~/apiCalls/openCallApi';
import getTransText from '~~/get-text/getTransText';


const QuoTabs = ({
  quos,
}) => {
  const dispatch = useDispatch();
  const pageEnd = useRef();
  const {
    pagination,
  } = useSelector((state) => state.opencall);
  const fetchMessage = async () => {
    if (pagination && pagination.current_page) {
      const fetchDataInput = {
        page: pagination.current_page + 1,
      };
      dispatch(apiCallBegan({
        axiosCall: getOpenQuos,
        data: fetchDataInput,
        onSuccess: getOpenCallList.type,
      }));
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight <= scrollTop + clientHeight) {
      if (pagination.last_page > pagination.current_page) {
        fetchMessage();
      }
    }
  };
  return (
    <>
      {quos.length > 0
        ? (
          <div
            className="open__call-group"
            ref={pageEnd}
            onScroll={handleScroll}
          >
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 576: 2, 768: 3, 992: 4, 1200: 5 }}
            >
              <Masonry
                gutter="10px"
              >
                {quos?.map(q => {
                  if (q.current_status === "Searching") {
                    return (<Searching key={`${q.id}-serach`} quo={q} />);
                  }

                  if (q.current_status === "Timeout") {
                    return (<TimeOut key={`${q.id}-timeout`} quo={q} />);
                  }

                  return ("");
                })}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        ) : (
          <div className="opencall__no-quo-message">
            <p>{getTransText("opencall.no-quo.message")}</p>
          </div>
        )}
    </>
  );
};

export default QuoTabs;

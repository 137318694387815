import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sleep } from '@directus/sdk';
import { apiCallBegan } from '~/store/api';
import store from '~/store/store.js';
import { chatGetUnRead } from '~/store/chats.js';
import {
  chatAddedMessage, chatAddMessagBegan, updateUnreadNumberAndUpdateMemberReadTimeByConversationIdAndMemberAB,
} from "~/store/chats";
import { client } from '~/apiCalls/directusConfig';
import { getMemberDetailsByConversationId, getTotalUnreadNumberForMenu, updateReadTime, } from '~/apiCalls/chatApi';

export const INITIAL_STATE = {
  socket: null,
};

export const SocketContext = React.createContext(INITIAL_STATE);


export const SocketContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const {
    userData,
  } = useSelector(state => state.user);
  const {
    activeChat,
  } = useSelector(state => state.chats);

  const socketRef = useRef();
  let chat;
  let read;
  useEffect(async () => {
    if (userData?.id) {
      const unregister = client.onWebSocket('message', async (message) => {
        if ('type' in message && message.type === 'auth' && message.status === "ok") {
          // toDo add only when websocket open, trigger;
          if (chat) chat.unsubscribe();
          if (read) read.unsubscribe();

          chat = await client.subscribe('quo_messages', {
            query: { fields: ['*'], limit: 1 },
            uid: `chat-${userData.id}`
          });
          read = await client.subscribe('quo_conversation', {
            query: { fields: ['*'], limit: 1 },
            uid: `con-${userData?.id}`
          });
        }
        // new message arrive
        if (
          'type' in message
          && message.type === 'subscription'
          && message.event === "init"
          && message.uid.includes("chat")
        ) {
          // console.log("trueeee");
        }
        if (
          'type' in message
          && message.type === 'subscription'
          && message.event === "create"
          && message.uid.includes("chat")
        ) {
          const memberDetails = await getMemberDetailsByConversationId({ conversationId: message.data[0].conversation_id, messageId: message.data[0].id });
          const messageArrive = {
            ...message.data[0],
            ...memberDetails,
          };
          dispatch(chatAddMessagBegan());
          dispatch(chatAddedMessage(messageArrive));
          if (store.getState().chats.activeChat === message.data[0].conversation_id) {
            dispatch(
              apiCallBegan({
                axiosCall: updateReadTime,
                data: message.data[0].conversation_id,
              })
            );
          } else {
            dispatch(apiCallBegan({
              axiosCall: getTotalUnreadNumberForMenu,
              onSuccess: chatGetUnRead.type,
            }));
          }
        }
        // receiver/requestor clicked the conversation and updated the read time
        if (
          'type' in message
          && message.type === 'subscription'
          && message.event === "update"
          && message.uid.includes("con")
        ) {
          dispatch(updateUnreadNumberAndUpdateMemberReadTimeByConversationIdAndMemberAB(message.data[0]));
          dispatch(apiCallBegan({
            axiosCall: getTotalUnreadNumberForMenu,
            onSuccess: chatGetUnRead.type,
          }));
        }
      });

      await client.connect();

      return () => {
        if (chat) chat.unsubscribe();
        if (read) read.unsubscribe();
      };
    }
  }, [userData?.id]);


  return (
    <SocketContext.Provider
      value={{
        socket: socketRef.current,
      }}
    >{children}
    </SocketContext.Provider>
  );
};

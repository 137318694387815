import React from 'react';
import Lottie from 'react-lottie';
import animationData from './LoadingLogo.json';

const LoadingLogo = () => {
  const logoOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="loading__container">
      <div className="loading__animation">
        <Lottie
          options={logoOptions}
          isClickToPauseDisabled={true}
        />
      </div>
    </div>
  );
};


export default LoadingLogo;

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route, Switch, useHistory, useParams
} from 'react-router-dom';
import {
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import {
  getReceiveBoxConversations, getSentBoxConversations
} from '~/apiCalls/chatApi';
import { apiCallBegan } from '~/store/api';
import {
  chatFetchedMoreReceiveBoxConversations, chatFetchedMoreSentBoxConversations, chatGetReceiveBoxConversations, chatGetSentBoxConversations, chatStartFetchReceiveBoxConversations, chatStartFetchSentBoxConversations, chatToggleActiveTab
} from '~/store/chats';
import getTransText from '~~/get-text/getTransText';
import ChatDialog from './ChatDialog';
import ChatTopbar from './ChatTopbar';
import ReceiveBoxContact from './ReceiveBoxContact';
import SentBoxContact from './SentBoxContact';

const Chat = () => {
  const dispatch = useDispatch();
  const [openContacts, setOpenContacts] = useState(false);
  const {
    id,
    is_creator,
  } = useSelector(state => state.user.userData);
  const [sentUnread, setSentUnread] = useState(null);
  const [receivedUnread, setReceivedUnread] = useState(null);
  const {
    sentBoxConversations,
    receiveBoxConversations,
    activeTab,
    activeChat,
    fetchingSent,
    fetchingReceived,
    unRead,
  } = useSelector(state => state.chats);
  const { conversation_id } = useParams();
  const history = useHistory();

  // useEffect(() => {
  //   setSentUnread(unRead.sentBoxUnread.filter(a => a.membera_unread > 0).length);
  //   setReceivedUnread(unRead.receivedBoxUnread.filter(b => b.memberb_unread > 0).length);
  // }, [unRead]);

  const toggle = (tab) => {
    dispatch(chatToggleActiveTab(tab));
  };

  const dialogClass = classNames({
    'chat__dialog-container': true,
    'open': openContacts,
  });

  useEffect(() => {
    if (id) {
      const data = {
        page: 0,
      };
      if (!is_creator) {
        dispatch(apiCallBegan({
          axiosCall: getSentBoxConversations,
          data,
          onStart: chatStartFetchSentBoxConversations.type,
          onSuccess: chatGetSentBoxConversations.type,
        }));
      }
      if (is_creator) {
        dispatch(apiCallBegan({
          axiosCall: getReceiveBoxConversations,
          data,
          onStart: chatStartFetchReceiveBoxConversations.type,
          onSuccess: chatGetReceiveBoxConversations.type,
        }));
      }
    }
  }, [id, is_creator]);

  const contactClick = async (item) => {
    await history.push(`/c/${item.id}`);
  };

  const noReceiveMessage = getTransText("No Message Received");
  const noSentMessage = getTransText("DM people to start conversation");


  const fetchReceivedContact = async () => {
    const fetchDataInput = {
      page: receiveBoxConversations.length / 20
    };
    dispatch(
      apiCallBegan({
        axiosCall: getReceiveBoxConversations,
        data: fetchDataInput,
        onStart: chatStartFetchReceiveBoxConversations.type,
        onSuccess: chatFetchedMoreReceiveBoxConversations.type,
      })
    );
  };
  const fetchSentContact = async () => {
    const fetchDataInput = {
      page: receiveBoxConversations.length / 20
    };
    dispatch(
      apiCallBegan({
        axiosCall: getSentBoxConversations,
        data: fetchDataInput,
        onStart: chatStartFetchSentBoxConversations.type,
        onSuccess: chatFetchedMoreSentBoxConversations.type,
      })
    );
  };


  const handleReceivedScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    const lastScrollTop = scrollHeight - scrollTop;

    const bottom = (scrollTop + clientHeight) === scrollHeight;
    if (bottom && scrollHeight > clientHeight) {
      fetchReceivedContact();
    }
  };


  const handleSentScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    const lastScrollTop = scrollHeight - scrollTop;

    const bottom = (scrollTop + clientHeight) === scrollHeight;
    if (bottom && scrollHeight > clientHeight) {
      fetchSentContact();
    }
  };

  useEffect(() => {
    if (conversation_id) {
      setOpenContacts(true);
    } else {
      setOpenContacts(false);
    }
  }, [conversation_id]);

  return (
    <div
      className="chat"
      role="presentation"
    >
      <div className="chat__contact-list">
        <div className="tabs tabs--justify">
          <div className="tabs__wrap">
            <Nav tabs>
              {is_creator
                ? (
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === '1' })}
                      onClick={() => toggle('1')}
                    >
                      {getTransText("Creator's Inbox")}
                      <i className={unRead.receivedBoxUnread > 0 ? "tabs__unread exist" : "tabs__unread"}><span>{receivedUnread}</span></i>
                    </NavLink>
                  </NavItem>
                )
                : (
                  <NavItem>
                    <NavLink
                      className={classNames({ active: activeTab === '2' })}
                      onClick={() => toggle('2')}
                    >
                      {getTransText("Buyer's Inbox")}
                      <i className={unRead.sentBoxUnread > 0 ? "tab__unread exist" : "tabs__unread"}><span>{sentUnread}</span></i>
                    </NavLink>
                  </NavItem>
                )}
            </Nav>
            <TabContent activeTab={activeTab}>
              {is_creator
                ? (
                  <div className="chat__contacts">
                    <div
                      className="scroll chat__contacts-scroll"
                      onScroll={handleReceivedScroll}
                    >
                      <div className="receiveBox_content">
                        {receiveBoxConversations?.length > 0 && receiveBoxConversations.map(c => (
                          <button
                            key={c.id}
                            type="button"
                            aria-label="createContact"
                            onClick={() => contactClick(c)}
                            disabled={c.id === activeChat}
                          >
                            <ReceiveBoxContact conversation={c} active={c.id === activeChat} />
                          </button>
                        ))}
                        {fetchingReceived === "start" && (
                          <div className="loadingio-spinner-rolling-h0gdcpc6y5q">
                            <div className="ldio-vbm0w6sebpp">
                              <div>
                              </div>
                            </div>
                          </div>
                        )}
                        {fetchingReceived === "done" && receiveBoxConversations?.length === 0 && <div className="chat__contacts-empty-box">{noReceiveMessage}</div>}
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div className="chat__contacts">
                    <div
                      className="scroll chat__contacts-scroll"
                      onScroll={handleSentScroll}
                    >
                      {sentBoxConversations?.length > 0 && sentBoxConversations.map(c => (
                        <button
                          key={c.id}
                          type="button"
                          aria-label="createContact"
                          onClick={() => contactClick(c)}
                          disabled={c.id === activeChat}
                        >
                          <SentBoxContact conversation={c} active={c.id === activeChat} />
                        </button>
                      ))}
                      {fetchingSent === "start" && (
                        <div className="loadingio-spinner-rolling-h0gdcpc6y5q">
                          <div className="ldio-vbm0w6sebpp">
                            <div>
                            </div>
                          </div>
                        </div>
                      )}
                      {fetchingSent === "done" && sentBoxConversations?.length === 0 && (<div className="chat__contacts-empty-box">{noSentMessage}</div>)}
                    </div>
                  </div>
                )}
            </TabContent>
          </div>
        </div>
      </div>
      <Switch>
        <Route path="/c/:conversation_id">
          <ChatDialog dialogClass={dialogClass} />
        </Route>
        <Route path="/c/">
          <div className={dialogClass}>
            <ChatTopbar />
            <div className="chat__dialog">
              <div className="chat__dialog-select-message">
                <p>{getTransText("Select a chat to start messaging")}</p>
              </div>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default Chat;

/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import TopbarInfo from '~~/topbarInfo/Topbar';

const ExamplePage = () => (
  <>
    <div className="layout">
      <TopbarInfo />
    </div>
    <Container className="quabal__container terms__container">
      <Row>
        <Col md={10}>
          <p>Welcome to QUOBal and our platform at www.quobal.com. Our platform uses cookies and similar technologies (for the sake of simplicity, all of these are collectively referred to as "cookies"). Cookies are also placed by third parties contracted by us. In this Cookie Policy, we inform you about the use of cookies on our platform.</p>
          <p><strong>What are cookies?</strong></p>
          <p>A cookie is a small simple file that is sent along with pages of our platform and stored by your browser on the hard drive of your computer or other device. The information stored in it may be returned to our servers or to the servers of the relevant third parties on a subsequent visit.</p>
          <p><strong>What are scripts?</strong></p>
          <p>A script is a program code that allows our platform to function properly and interactively. This code is executed on our server or on your device.</p>
          <p><strong>What is a web beacon?</strong></p>
          <p>A web beacon (or pixel tag) is a small, invisible piece of text or image on a website that is used to monitor traffic on a website. To do this, web beacons are used to store various data about you.</p>
          <p><strong>Cookies</strong></p>
          <p><em>Technical or functional cookies</em></p>
          <p>Some cookies ensure that certain parts of the platform function properly and that your user preferences are remembered. By setting functional cookies, we make it easier for you to visit our platform. This way, you do not have to repeatedly enter the same information when visiting our platform. We may place these cookies without your consent.</p>
          <p><em>Marketing or Tracking Cookies</em></p>
          <p>Marketing or tracking cookies are cookies or other form of local storage used to create user profiles in order to display advertisements or track the user on our platform or across multiple websites for similar marketing purposes. Because these cookies are marked as tracking cookies, we ask for your permission to set them.</p>
          <p><strong>Consent</strong></p>
          <p>When you visit our platform for the first time, we will show you a pop-up with an explanation about cookies. Once you agree and accept you consent to our use of Marketing or tracking cookies, as described above. You can disable the use of cookies through your browser, but please note that if you do so, our platform may not function properly.</p>
          <p><strong>Manage your consent </strong></p>
          <p>When you visit our platform, one or more cookies are automatically stored on your device. If you do not want this to happen, it is best to use the following links (depending on the browser you use) to set your browser to prevent cookies from being stored on your device in the future. (<a href="https://support.google.com/chrome/answer/95647?hl=en-GB&amp;co=GENIE.Platform%3DDesktop">Google Chrome</a><u>,</u><a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox">Mozilla Firefox</a><u>,</u><a href="https://www.wikihow.com/Delete-Flash-Cookies">Flash cookies</a><u>,</u><a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Microsoft Internet Explorer</a><u>, </u>&nbsp;<a href="https://www.opera.com/use-cases/clean-browser-and-remove-trackers">Opera</a><u>,</u><a href="https://support.apple.com/en-gb/HT201265">Safari</a><u>)</u></p>
          <p><strong>Controls For Do-Not-Track Features</strong></p>
          <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
          <p><strong>Does this policy change?</strong></p>
          <p>We may update our Cookie Policy from time to time. This might be for a number of reasons, such as to reflect a change in the law or to accommodate a change in our business practices and the way we use cookies. We recommend that you check here periodically for any changes to our Cookie Policy.</p>
          <p><strong>Who should I contact for more information?</strong></p>
          <p>If you have any questions or comments about our Cookie Policy or wish to exercise your rights under applicable laws, please contact us using the following contact details:</p>
          <p>QUObal.com</p>
          <p>Quomark Limited</p>
          <p>1201 Orange Street, Suite 600,</p>
          <p>Wilmington, New Castle County,</p>
          <p>Delaware 19801, USA</p>
          <p>Web: <a href="http://www.quobal.com">www.quobal.com</a></p>
          <p>E-Mail: info@quobal.com</p>
          <p>This Cookie Policy was last updated on Friday, August 19, 2022</p>
        </Col>
      </Row>
    </Container>
  </>
);

export default ExamplePage;

import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Chat from './Chat';


const ChatCard = () => (
  <Card>
    <CardBody>
      <Chat />
    </CardBody>
  </Card>
);

export default ChatCard;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Switch, } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Button, Card, CardBody, Col, Container, Row,
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import ProfileMain from '~~/profile/ProfileMain';
import QuoForm from '~~/quo_form/QuoForm';
import QuoFormMe from '~~/quo_form/QuoForm_me';
// import ProfileCalendar from './components/ProfileCalendar';
import {
  checkedNsfwProfile,
  gotProfileContent,
  gotUserInfo,
  changedProfileTab
} from '~/store/profile';
import getTransText from '~~/get-text/getTransText';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';
import ProfileTabs from './components/ProfileTabs';
import { apiCallBegan } from '~/store/api';
import ModalWarning from '~~/ModalWarning';

const Profile = ({
  getMembers,
  getProfileContents,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { username } = useParams();
  const {
    userData,
  } = useSelector(state => state.user);
  const {
    userInfo,
    profileContents,
    isNsfwContent,
    isMe,
    supportedCountry,
    rating,
  } = useSelector(state => state.profile);
  const [loadedProfile, setLoadedProfile] = useState(false);
  const {
    isAccepted18,
  } = useSelector(state => state.ui);

  useEffect(async () => {
    if (username) {
      dispatch(apiCallBegan({
        axiosCall: getMembers,
        data: username,
        onSuccess: gotUserInfo.type,
      }));
    } else {
      if (userData && userData.id) {
        dispatch(apiCallBegan({
          axiosCall: getMembers,
          data: userData.username,
          onSuccess: gotUserInfo.type,
        }));
      }
    }
  }, [username, userData]);

  useEffect(async () => {
    if (userInfo) {
      dispatch(apiCallBegan({
        axiosCall: getProfileContents,
        data: userInfo.username,
        onSuccess: gotProfileContent.type,
      }));
    }
    if (userInfo) {
      setLoadedProfile(true);
    }
  }, [userInfo]);

  const acceptNfswProfile = () => {
    dispatch(checkedNsfwProfile(false));
    const saved = localStorage.getItem("nsfw-accepted");
    const initialValue = saved ? JSON.parse(saved) : [];
    initialValue.push(username);
    localStorage.setItem('nsfw-accepted', JSON.stringify(initialValue));
  };

  const toggle = (tab) => {
    dispatch(changedProfileTab(tab));
  };


  return (
    <>
      {!isAccepted18 && (
        <>
          <ModalWarning />
        </>
      )}
      {/* <Modal isOpen={isNsfwContent} fade={false} backdropClassName="nsfw--backdrop">
        <ModalHeader>{getTransText("profile.warning.nsfw.title")}</ModalHeader>
        <ModalBody>
          {getTransText("profile.warning.nsfw.paragraph1")}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => acceptNfswProfile()}>
            {getTransText("profile.warning.nsfw.button.continue")}
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => {
              dispatch(checkedNsfwProfile(false));
              history.push("/");
            }}
          >
            {getTransText("profile.warning.nsfw.button.cancel")}
          </Button>
        </ModalFooter>
      </Modal> */}
      {loadedProfile
        ? (
          <Container className="profile__container">
            <Helmet>
              <meta property="og:subject" content={`QUObal ${userInfo.username}'s Profile | Buying Selling Custom Content Platform`} />
              <meta name="subject" content={`QUObal ${userInfo.username}'s Profile | Buying Selling Custom Content Platform`} />
              <meta property="og:description" content={`${userInfo.description}`} />
              <meta name="description" content={`${userInfo.description}`} />
              <meta property="og:url" content={`https://www.quobal.com/p/${userInfo.username}`} />
              <meta property="og:image" content={userInfo.avatar === "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae" ? `http://www.quobal.com/img/bkend/quobal-logo-horizontal.png` : `http://www.quobal.com/file-access/avatar/${userInfo.avatar}`} />
              <title>QUObal {userInfo.username}&apos;s Profile | Buying Selling Custom Content Platform</title>
            </Helmet>
            <Row className="profile__card">
              <Col md={12}>
                <ProfileMain />
              </Col>
              {
                !userData.is_creator && !isMe && !supportedCountry
                  ? (
                    <Col md={12}>
                      <Card className="not__quo__card">
                        <CardBody>
                          <div className="alert alert--neutral">
                            <div className="alert__content">
                              <p className="center">{getTransText("This user location cannot be quoted.")}</p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                  )
                  : ""
              }
              {
                !userData.is_creator && !isMe && supportedCountry && userInfo
                  ? (
                    <Col xs={12} md={12}>
                      <Card className="quo__card">
                        <CardBody>
                          <QuoForm friendInfo={userInfo} />
                        </CardBody>
                      </Card>
                    </Col>
                  )
                  : ""
              }
            </Row>
            <Row>
              <Col>
                <Nav tabs className="profile__nav">
                  <NavItem>
                    <NavLink
                      className="active"
                      onClick={() => toggle('1')}
                    >
                      {getTransText("profile.tab.profile")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className=""
                      onClick={() => toggle('2')}
                    >
                      {getTransText("profile.tab.market")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className=""
                      onClick={() => toggle('3')}
                    >
                      {getTransText("profile.tab.form")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className=""
                      onClick={() => toggle('4')}
                    >
                      {getTransText("profile.tab.sexting")}
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              {profileContents
                ? (
                  <Col xs={12}>
                    <ProfileTabs activeTab="1" />
                  </Col>
                )
                : ""}
            </Row>
          </Container>
        )
        : <LoadingLogo />}
    </>
  );
};


export default Profile;

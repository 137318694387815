import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { roundUpHour } from '~~/datetime_render/FormatDate';
import getTransText from '~~/get-text/getTransText';
import MatTable from '~~/MaterialTable/MatTable/';
import ModalParent from '~~/modal_parent/ModalParent';

const AcceptStatus = ({
  quo,
  isButton = true,
}) => {
  const [friendInfo, setFriendInfo] = useState(null);
  const [isSender, setIsSender] = useState(false);
  const {
    id,
  } = useSelector(state => state.user.userData);
  const [toggler, setToggler] = useState(false);
  const annoymous = {
    avatar: "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae",
    username: getTransText("Anonymous"),
    random_no: quo.random_no,
  };
  const ref = useRef(null);

  const args = {
    autohide: false,
    flip: true,
  };

  const switchToggler = () => {
    setToggler(!toggler);
  };

  useEffect(async () => {
    if ((quo.receiver === id)) {
      setFriendInfo(annoymous);
      setIsSender(false);
    } else {
      if (quo.receiver) {
        setFriendInfo({
          avatar: quo.avatar,
          username: quo.username,
          random_no: quo.random_no,
        });
        setIsSender(true);
      }
    }
  }, [quo.receiver]);

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      // Render a completed state
      return ("");
    }
    // Render a countdown
    return (
      <div className="quo__btn__container">
        <button type="button" ref={ref} id="TooltipExample" className="quo__btn quo__btn-submit" onClick={() => setToggler(!toggler)}>
          {getTransText("Submit")}
        </button>
        <Tooltip
          placement="bottom"
          autohide={false}
          isOpen={true}
          target={ref}
        >
          <div className="quo__timer">
            <span className="countdown_group">
              <span className="countdown_number">
                {days}
              </span>
              <span className="countdown_unit">
                {getTransText("D")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {hours}
              </span>
              <span className="countdown_unit">
                {getTransText("H")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {minutes}
              </span>
              <span className="countdown_unit">
                {getTransText("M")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {seconds}
              </span>
              <span className="countdown_unit">
                {getTransText("S")}
              </span>
            </span>
          </div>
        </Tooltip>
      </div>
    );
  };

  const renderButton = () => {
    if (!isButton) {
      return ("");
    }

    if (isSender) {
      return ("");
    }

    if (quo.responsed) {
      return ("");
    }

    const s = DateTime.fromSeconds(quo.charge_date, { zone: "utc" });

    return (
      <div className="quo__btns">
        <Countdown className="quo__countdown" date={roundUpHour(s.plus({ hours: 144 })).setZone("system")} daysInHours={true} renderer={renderer} />
      </div>
    );
  };

  return (
    <>
      <ModalParent toggler={toggler} switchToggler={switchToggler}>
        <MatTable quo={quo} isFirstSubmission={true} />
        {/* <DragAndDropTable quo={quo} isFirstSubmission={true} /> */}
      </ModalParent>
      <div className="quo__status">
        {
          isSender
            ? (
              <>
                <div className="quo__status-notice">{getTransText("QUO.status.buyer.accepted")}</div>
              </>
            ) : (
              <>
                <div className="quo__status-notice">{getTransText("QUO.status.creator.accepted")}</div>
              </>
            )
        }
      </div>
      {renderButton()}
    </>
  );
};

export default AcceptStatus;

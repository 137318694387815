import PropTypes from 'prop-types';
import React from 'react';
import { TableToolbarSelected, TableToolbarWrap, Toolbar } from '@/shared/components/MaterialTableElements';

const MatTableToolbar = ({ numSelected, handleDeleteSelected, onRequestSort }) => (
  <TableToolbarWrap>
    <Toolbar className="material-table__toolbar">
      <div>
        {numSelected > 0 && (
          <TableToolbarSelected>
            {numSelected} <span>selected</span>
          </TableToolbarSelected>
        )}
      </div>
    </Toolbar>
  </TableToolbarWrap>
);

MatTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDeleteSelected: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

export default MatTableToolbar;

/* eslint-disable */
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';

const ExamplePage = () => (
  <div className="quabal__container error__container">
    <Container>
      <Row>
        <Col md="8">
          <h3 className="page-title">404 not found</h3>
          <p>Ooops! The page you are looking for could not be found :(</p>
          <p>Go back to<Link className="quobal__logo horizontal" to="/l" /></p>
        </Col>
      </Row>
    </Container>
  </div>
);

export default ExamplePage;

export const AvatarRender = ((
  avatar = "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae", random_no,
) => {
  const publicAvatarPath = !isNaN(random_no) && `img/avatar/avatar${random_no + 1}.jpg`;
  if (!random_no && avatar === "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae") {
    return (`${process.env.REACT_APP_FRONTEND_URL}img/avatar/avatar1.jpg`);
  }
  if (!isNaN(random_no) && avatar === "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae") {
    return (`${process.env.REACT_APP_FRONTEND_URL}${publicAvatarPath}`);
  }
  if (avatar && avatar !== "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae") {
    return (`${process.env.REACT_APP_API_URL}file-access/avatar/${avatar}`);
  }
  return "";
});

export default AvatarRender;

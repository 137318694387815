/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import produce from 'immer';
import { useSelector } from 'react-redux';
import {
  Progress, Col, Container, Row,
} from 'reactstrap';
import {
  getProfileContents,
} from '~/apiCalls/userApi';
import ProfileInfoCard from './components/ProfileInfoCard';
import ProfileGalleryCard from './components/ProfileGalleryCard';
import ProfileStepCard from './components/ProfileStepCard';
import getTransText from '~~/get-text/getTransText';

const HowtoStartPage = () => {
  const {
    username,
    avatar,
    description,
    is_shared,
  } = useSelector(state => state.user.userData);
  const [profileContents, setProfileContents] = useState([]);
  const isProfileDone = description !== null && avatar !== "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae";
  const [isProfileUploaded, setIsProfileUploaded] = useState(profileContents.length > 0);
  const [count, setCount] = useState(0);
  const [arr, setArr] = useState([isProfileDone, isProfileUploaded, is_shared]);



  useEffect(() => {
    setIsProfileUploaded(profileContents.length > 0);
    setArr([isProfileDone, isProfileUploaded, is_shared]);
  }, [isProfileDone, isProfileUploaded, profileContents, is_shared]);

  useEffect(() => {
    setCount(arr.filter(Boolean).length);
  }, [arr]);

  useEffect(async () => {
    let isCancelled = false;
    if (username) {
      const profileContentResponse = await getProfileContents(username);
      if (!isCancelled) {
        if (profileContentResponse) {
          setProfileContents(produce(() => (
            profileContentResponse
          )));
        } else {
          setProfileContents([]);
        }
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [username]);

  return (
    <Container className="how-to-start__container">
      <Row>
        <Col xs={12}>
          <h3 className="page-title">{getTransText("Complete your Profile")}</h3>
          <div className="steps__card__progress">
            <h5>{`${count} / ${arr.length}`} {getTransText("steps completed")}</h5>
            <div className="progress-wrap progress-wrap--middle">
              <Progress value={(count / arr.length) * 100}>{Math.floor((count / arr.length) * 100)}%</Progress>
            </div>
          </div>
          <div className="steps__card__list">
            <ProfileInfoCard done={isProfileDone} />
            <ProfileGalleryCard profileContents={profileContents} setProfileContents={setProfileContents} />
            <ProfileStepCard done={is_shared} />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default HowtoStartPage;

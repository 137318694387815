import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
// import TopbarNotification from './TopbarNotification';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const [scroll, setScroll] = useState(false);
  const [isMobileSearchBarCollapsed, setIsMobileSearchBarCollapsed] = useState(true);


  const searchStyle = classNames({
    "mobile__search-wrap": true,
    "open": !isMobileSearchBarCollapsed,
  });

  const changeSearchBarVisibility = () => {
    setIsMobileSearchBarCollapsed(!isMobileSearchBarCollapsed);
  };


  useScrollPosition(({ currPos }) => {
    if (currPos.y !== 0) setScroll(true); else setScroll(false);
  }, [scroll]);

  return (
    <div className={scroll ? 'topbar scroll' : 'topbar'}>
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <div className="quobal__logo__container">
            <Link className="quobal__logo" to="/" />
          </div>
          <TopbarSidebarButton
            onClickMobile={changeMobileSidebarVisibility}
            onClickDesktop={changeSidebarVisibility}
          />
        </div>
        {/* <div className="topbar__right">
          <TopbarProfile />
          <TopbarMail />
        </div> */}
      </div>
    </div>
  );
};

export default Topbar;

/* eslint-disable */

import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import ReactGA from 'react-ga4';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Button, ButtonToolbar, Card,
  CardBody, Col,
  Container, FormGroup,
  Input, Row
} from "reactstrap";
import getTransText from '~~/get-text/getTransText';
import ModalParent from '~~/modal_parent/ModalParent';
import { payNow } from '~/apiCalls/paymentApi';
import { buyerLoginBeforePayment } from '../../store/redirect';
import { getPreQuo } from '../../apiCalls/quoApi';
import LoginToPay from './LoginToPay';

// import Collapse from '../Collapse';

const CheckoutProvider = () => {
  const { quo_id } = useParams();
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const [preQuoDetails, setPreQuoDetails] = useState();
  const [loaded, setLoaded] = useState(false);
  const [checkoutLoadingStatus, setCheckoutLoadingStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const history = useHistory();
  const [toggler, setToggler] = useState(false);
  const [preSet, setPreSet] = useState();
  const switchToggler = () => {
    setToggler(!toggler);
  };

  const {
    langCode,
  } = useSelector(state => state.ui);
  const {
    authenticated,
  } = useSelector(state => state.auth);
  const {
    userData,
  } = useSelector(state => state.user);

  const LangCodeForCurrency = () => {
    switch (langCode) {
      case 'zh-TW':
        return 'zh-TW';
      case 'en-US':
        return 'en-US';
      case 'ja':
        return 'ja-JP';
      default:
        return 'en-US';
    }
  };

  useEffect(async () => {
    const res = await getPreQuo(quo_id);
    setPreQuoDetails(res);
    setLoaded(true);
  }, []);


  const onSubmit = async (cre) => {
    dispatch(buyerLoginBeforePayment(preQuoDetails.id))
    history.push("/log_in");
  };


  const checkoutClick = async () => {
    const values = {
      quoId: preQuoDetails.id,
    };
    const res = await payNow(values)
      .then((response) => {
        window.location.href = response;
      })
      .catch((response) => {
        if (response.response.status === 503) {
          if (response.response.data.errors[0].message.includes("yourself")) {
            setErrorMsg(getTransText(response.response.data.errors[0].message));
          } else {
            setErrorMsg(getTransText("Network Error"));
          }
          setCheckoutLoadingStatus(false);
        }
      });
    ReactGA.event({
      category: 'Pre-Purchase Activity',
      action: "Checkout Clicked: New Quo to seller",
      label: userData?.id,
    });
  };

  const googleLogin = () => window.open(`${process.env.REACT_APP_API_URL}auth/login/google?redirect=${process.env.REACT_APP_FRONTEND_URL}pay/${quo_id}?exlogin=true`, "_self");
  // const facebookLogin = () => window.open(`${process.env.REACT_APP_API_URL}auth/login/facebook?redirect=${process.env.REACT_APP_FRONTEND_URL}pay/${quo_id}?exlogin=true`, "_self");


  return (
    <>
      <ModalParent className="2fa_modal" toggler={toggler} switchToggler={switchToggler}>
        <LoginToPay preSet={preSet} />
      </ModalParent>
      {loaded
        ? (
          <Formik
            enableReinitialize
            onSubmit={() => {
              setTimeout(() => {
                // console.log("test");
              }, 400);
            }}
          >

            {() => (
              <div className="place__quo">
                <Container>
                  <Row>
                    <Col xs={12} sm={10} md={7}>
                      <Card className="quo__checkout__card">
                        <CardBody>
                          <div className="card__title">
                            <h5 className="bold-text">{getTransText("Place QUO to")} <span className="quo__card__user">{preQuoDetails.username}</span></h5>
                          </div>
                          <table className="quo__checkout__info">
                            <tbody>
                              <tr><th>{getTransText("checkoutprovider.title")}</th><td>{preQuoDetails.quo_title}</td></tr>
                              <tr>
                                <th>{getTransText("QUO detail:")}</th>
                                <td>
                                  <ReactQuill
                                    value={preQuoDetails.request}
                                    readOnly={true}
                                  />
                                </td>
                              </tr>
                              <tr><th>{getTransText("Request Price")}</th><td>{new Intl.NumberFormat(LangCodeForCurrency(), { style: 'currency', currency: 'usd' }).format(preQuoDetails.price)}</td></tr>
                            </tbody>
                          </table>
                        </CardBody>
                      </Card>
                      <div className="quo__checkout__notice">
                        <h5>{getTransText("Please read before checking out:")}</h5>
                        <ol>
                          <li>{getTransText("You will not charged until the seller submit the deliverable. The credit amount will be hold.")} {getTransText("We only place a hold on your eligible payment method to reserve funds, but capture them only after creator complete the service.")}</li>
                          <li>{getTransText("The request will not be delivered to the creator until you have finished the payment (link at below).")}</li>
                          <li>{getTransText("The payment link is only avaliable for 24 hours.")}</li>
                          <li>{getTransText("You cannot cancel the payment after the request is placed.")}</li>
                          <li>{getTransText("You will recieve your deliverable through our website. Please log in to check.")}</li>
                        </ol>
                      </div>
                      <div className="quo__checkout__form">
                        {(authenticated && userData.id !== null)
                          ? (
                            <>
                              <div className="notification-container">
                                {query.get("exlogin") === "true" ? <div className="notification notification-success">{getTransText("Logged In! Please click Checkout to proceed")}</div> : ""}
                              </div>
                              {errorMsg && <span className="form__form-group-error" style={{ textAlign: 'center' }}>{errorMsg}</span>}
                              <ButtonToolbar className="form__button-toolbar btn-toolbar--center">
                                <Button color="primary" onClick={() => checkoutClick()}>{getTransText("Checkout")}</Button>
                              </ButtonToolbar>
                            </>
                          )
                          : (
                            <ButtonToolbar className="form__button-toolbar btn-toolbar--center">
                              <Button color="primary" type="submit" onClick={() => onSubmit()}>{checkoutLoadingStatus ? getTransText("Loading") : getTransText("Sign in")}</Button>
                            </ButtonToolbar>
                          )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}
          </Formik>
        )
        : "loading"}
    </>

  );
};

export default CheckoutProvider;

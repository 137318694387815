import produce from "immer";
import React, { useState, useEffect } from "react";
// Import React FilePond
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { FilePond, registerPlugin } from "react-filepond";
import { useSelector, useDispatch } from 'react-redux';

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import en_US from "filepond/locale/en-en";
import ja from "filepond/locale/ja-ja";
import zh_TW from "filepond/locale/zh-tw";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { unshiftProfileContent } from '~/store/profile';
import { client, clientPublic } from '~/apiCalls/directusConfig';



// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

const DropFilePond = () => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [gotToken, setGotToken] = useState(null);
  const {
    langCode,
  } = useSelector(state => state.ui);
  const filePondLang = () => {
    switch (langCode) {
      case 'zh-TW':
        return zh_TW;
      case 'en-US':
        return en_US;
      case 'ja':
        return ja;
      default:
        return en_US;
    }
  };

  useEffect(() => {
    client.getToken().then(x => setGotToken(x));
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div className="App">
      {gotToken && (
        <FilePond
          {...filePondLang()}
          files={files}
          allowReorder={true}
          allowMultiple={true}
          allowRevert={false}
          onupdatefiles={setFiles}
          acceptedFileTypes={["image/*"]}
          server={{
            url: `${process.env.REACT_APP_API_URL}files`,
            process: {
              method: 'POST',
              headers: {
                "Authorization": `Bearer ${gotToken}`,
              },
              onload: (response) => {
                const res = JSON.parse(response);
                dispatch(unshiftProfileContent(res.data.id));
                // if (setProfileContents) {
                //   setProfileContents(produce((draft) => {
                //     draft.unshift({
                //       id: res.data.id,
                //     });
                //   }));
                // }
              },
              onerror: (response) => response.data,
              ondata: (formData) => {
                const a = new FormData();
                a.append('file_purpose', "profile");
                formData.forEach((value, key) => a.append(key, value));
                return a;
              },
            },
          }}
          instantUpload={true}
        />
      )}
    </div>
  );
};

export default DropFilePond;

import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector, connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AuthCode from 'react-auth-code-input';
import {
  Button,
  Card,
  CardBody,
  Col,
  ButtonToolbar,
  ButtonGroup,
  FormGroup,
} from 'reactstrap';
// import CloseIcon from 'mdi-react/CloseIcon';
// import MinusIcon from 'mdi-react/MinusIcon';
// import AutorenewIcon from 'mdi-react/AutorenewIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import InvitingQuoDetail from './InvitingQuoDetailForCreator';
import { apiCallBegan } from '~/store/api';
import { redeemedInviting, redeemedInvitingError } from '~/store/inviting';
import getTransText from '~~/get-text/getTransText';
import { getOutreachQuo, redeemInvitingApi } from '~/apiCalls/quoApi';
import { creatorLoginBeforeAcceptingInviting } from '~/store/redirect';
import formatDate from '~~/datetime_render/FormatDate';

const errorMsgRender = (state) => {
  const { inviting } = state;
  let error = '';
  if (inviting.error) {
    if (inviting.error.response.status === 401 || inviting.error.response.status === 403) {
      if (inviting.error.response.errors[0].message.includes('quoExpired')) {
        error = getTransText("quoform.expired");
      }
      if (inviting.error.response.errors[0].message.includes('tokenInvalid')) {
        error = getTransText("quoform.token.invalid");
      }
      if (inviting.error.response.errors[0].message.includes('ownQuo')) {
        error = getTransText("quoform.ownQUO.invalid");
      }
    }
  }
  return ({
    errorMsg: error,
  });
};

const InvitationCreatorQuoPanel = ({
  id,
  errorMsg = '',
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [quo, setQuo] = useState();

  const {
    userData,
  } = useSelector(state => state.user);
  const {
    success,
  } = useSelector(state => state.inviting);
  const { authenticated } = useSelector(state => state.auth);
  const [socialIdentity, setSocialIdentity] = useState(null);
  const redeemSucessString = getTransText('redeem.success.message');

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  const directLogin = async (cre) => {
    dispatch(creatorLoginBeforeAcceptingInviting(id));
    history.push("/log_in");
  };

  const onSubmit = async (cre) => {
    dispatch(apiCallBegan({
      axiosCall: redeemInvitingApi,
      data: cre,
      onSuccess: redeemedInviting.type,
      onError: redeemedInvitingError.type,
    }));
    if (success) {
      NotificationManager.info(redeemSucessString);
      history.push(`/manageQuo`);
    }
  };

  useEffect(() => {
    if (success) {
      NotificationManager.info(redeemSucessString);
      history.push(`/redeemSuccess`);
    }
  }, [success]);


  useEffect(async () => {
    if (id) {
      const invitingQuo = await getOutreachQuo(id);
      setQuo(invitingQuo.quo);
      setSocialIdentity(invitingQuo.preQuo);
    }
  }, [id]);

  return (
    <>
      {quo && (
        <Card>
          <CardBody className="panel__body quo__body quo__body-inviting">
            <div className="panel__refresh"><LoadingIcon /></div>
            <div className="panel__top">
              <div className="quo__start">{formatDate(quo.date_created)}</div>
              <div className="quo__inbox">
              </div>
            </div>
            <InvitingQuoDetail quo={quo} />
            {(authenticated && userData.id !== null)
              ? (
                <>
                  <div className="notification-container">
                    {query.get("exlogin") === "true" ? <div className="notification notification-success">{getTransText("quoform.inviting.loggedin.toaccept")}</div> : ""}
                  </div>
                  {errorMsg && <span className="form__form-group-error" style={{ textAlign: 'center' }}>{errorMsg}</span>}
                  <Form
                    onSubmit={onSubmit}
                    initialValues={{ quoId: id }}
                  >
                    {({ handleSubmit, submitting, values }) => (
                      <div className="redeem__code__container">
                        <form className="form" onSubmit={handleSubmit}>
                          <FormGroup className="form__form-group">
                            <span className="form__form-group-label">{getTransText("quoform.inviting.verificationcode")}</span>
                            <div className="form__form-group-field">
                              <Field
                                name="token"
                              >
                                {({ input, meta }) => (
                                  <AuthCode {...input} containerClassName="otp__container" length={4} inputClassName="otp__input" />
                                )}
                              </Field>
                            </div>
                          </FormGroup>
                          <ButtonToolbar className="btn-toolbar--center">
                            <ButtonGroup>
                              <Button color="primary" type="submit">{getTransText("inviting.quoform.signin.accept")}</Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        </form>
                      </div>
                    )}
                  </Form>
                </>
              )
              : (
                <>
                  {errorMsg && <span className="form__form-group-error" style={{ textAlign: 'center' }}>{errorMsg}</span>}
                  <ButtonToolbar className="form__button-toolbar btn-toolbar--center">
                    <Button color="primary" type="submit" onClick={directLogin}>{getTransText("inviting.quoform.signin.accept")}</Button>
                  </ButtonToolbar>
                </>
              )}
            {/* <Link className="quo__body__link" to={{ pathname: `/q/${quo.quo_id ? quo.quo_id : quo.id}`, state: { from: location.pathname } }}></Link> */}
          </CardBody>
        </Card>
      )}
    </>
  );
};
export default connect(errorMsgRender)(InvitationCreatorQuoPanel);

/* eslint-disable */
import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import CollapseComponent from '~~/Collapse';
import getTransText from '~~/get-text/getTransText';

const FaqContainer = () => (
  <Container className="quabal__container faq__container">
    <Row>
      <Col>
        <h3 className="page-title">FAQ</h3>
        <CollapseComponent title={`Q: ${getTransText("faq.question.2")}`} className="with-shadow">
          {getTransText("faq.ans.2")}
        </CollapseComponent>
        <CollapseComponent title={`Q: ${getTransText("faq.question.3")}`} className="with-shadow">
          {getTransText("faq.ans.3.1")}{getTransText("faq.ans.3.2")}
        </CollapseComponent>
        <CollapseComponent title={`Q: ${getTransText("faq.question.4")}`} className="with-shadow">
          {getTransText("faq.ans.4.1")}{getTransText("faq.ans.4.2")}
        </CollapseComponent>
        <CollapseComponent title={`Q: ${getTransText("faq.question.5")}`} className="with-shadow">
          {getTransText("faq.ans.5")}
        </CollapseComponent>
        <CollapseComponent title={`Q: ${getTransText("faq.question.6")}`} className="with-shadow">
          {getTransText("faq.ans.6")}
        </CollapseComponent>
        <CollapseComponent title={`Q: ${getTransText("faq.question.7")}`} className="with-shadow">
          {getTransText("faq.ans.7")}
        </CollapseComponent>
        <CollapseComponent title={`Q: ${getTransText("faq.question.8")}`} className="with-shadow">
          {getTransText("faq.ans.8")}
        </CollapseComponent>
        <CollapseComponent title={`Q: ${getTransText("faq.question.9")}`} className="with-shadow">
          {getTransText("faq.ans.9.1")}{getTransText("faq.ans.9.2")}{getTransText("faq.ans.9.3")}{getTransText("faq.ans.9.4")}
        </CollapseComponent>
        <CollapseComponent title={`Q: ${getTransText("faq.question.10")}`} className="with-shadow">
          <p>{getTransText("faq.ans.10.1")}</p>

          <p>{getTransText("faq.ans.10.2")}</p>

          <p>{getTransText("faq.ans.10.3")}</p>
          <p>{getTransText("faq.ans.10.4")}</p>
          <p>{getTransText("faq.ans.10.5")}</p>
          <p>{getTransText("faq.ans.10.6")}</p>
          <p>{getTransText("faq.ans.10.7")}</p>

          <p>{getTransText("faq.ans.10.8")}</p>

          <p>{getTransText("faq.ans.10.9")}</p>

          <p>{getTransText("faq.ans.10.10")}</p>
          <p>{getTransText("faq.ans.10.11.1")}{getTransText("faq.ans.10.11.2")}</p>

          <p>{getTransText("faq.ans.10.12")}</p>
          <p>{getTransText("faq.ans.10.13.1")}{getTransText("faq.ans.10.13.2")}</p>
        </CollapseComponent>
        <CollapseComponent title={`Q: ${getTransText("faq.question.11")}`} className="with-shadow">
          {getTransText("faq.ans.11.1")}{getTransText("faq.ans.11.2")}
        </CollapseComponent>
        <CollapseComponent title={`Q: ${getTransText("faq.question.12")}`} className="with-shadow">
          {getTransText("faq.ans.12")}
        </CollapseComponent>
        <CollapseComponent title={`Q: ${getTransText("faq.question.13")}`} className="with-shadow">
          {getTransText("faq.ans.13")}
        </CollapseComponent>
        <CollapseComponent title={`Q: ${getTransText("faq.question.14")}`} className="with-shadow">
          {getTransText("faq.ans.14.1")}{getTransText("faq.ans.14.2")}
        </CollapseComponent>
        <CollapseComponent title={`Q: ${getTransText("faq.question.15")}`} className="with-shadow">
          {getTransText("faq.ans.15")}
        </CollapseComponent>
      </Col>
    </Row>
  </Container>
);

export default FaqContainer;

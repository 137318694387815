import { DateTime } from "luxon";
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ButtonToolbar, Tooltip } from 'reactstrap';
import { acceptCountering, rejectCountering, quoCreateConversation } from '~/apiCalls/quoApi';
import { SocketContext } from "~/context/SocketContext";
import { apiCallBegan } from "~/store/api";
import { roundUpHour } from '~~/datetime_render/FormatDate';
import getTransText from '~~/get-text/getTransText';
import ModalParent from '~~/modal_parent/ModalParent';

const CounteringStatus = ({
  quo,
  isButton = true,
}) => {
  const dispatch = useDispatch();
  const { socket } = useContext(SocketContext);
  const [friendInfo, setFriendInfo] = useState(null);
  const history = useHistory();
  const [isSender, setIsSender] = useState(false);
  const ref = useRef(null);
  const {
    id,
  } = useSelector(state => state.user.userData);
  const annoymous = {
    avatar: "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae",
    username: getTransText("Anonymous"),
    random_no: quo.random_no,
  };
  const [rejectToggler, setRejectToggler] = useState(false);

  const switchRejectToggler = () => {
    setRejectToggler(!rejectToggler);
  };

  const rejectClick = async (quoId) => {
    dispatch(
      apiCallBegan({
        axiosCall: rejectCountering,
        data: quoId,
        // socketEmit: { socket, event: "sendMessage" },
        // onSuccess: chatAddedMessage.type,
      })
    );
    const conversationId = await quoCreateConversation(quo.receiver, quo.id);
    setTimeout(() => history.push(`/c/${conversationId}`), 100);
  };

  const acceptClick = async (quoId) => {
    const acceptCounteringRes = await acceptCountering(quoId);
    if (acceptCounteringRes.url) {
      window.location.href = acceptCounteringRes.url;
    }
    if (acceptCounteringRes.conversation_id) {
      // socket.emit("sendMessage", acceptCounteringRes);
      history.push(`/c/${acceptCounteringRes.conversation_id}`);
    }
  };

  useEffect(async () => {
    if ((quo.receiver === id)) {
      setFriendInfo(annoymous);
      setIsSender(false);
    } else {
      if (quo.receiver) {
        setFriendInfo({
          avatar: quo.avatar,
          username: quo.username,
          random_no: quo.random_no,
        });
        setIsSender(true);
      }
    }
  }, [quo.receiver]);

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      // Render a completed state
      return ("");
    }
    // Render a countdown
    return (
      <>
        <button type="button" ref={ref} className="quo__btn quo__btn-accept" onClick={() => acceptClick(quo.id)}>
          {getTransText("Accept")}
        </button>
        <Tooltip
          placement="bottom"
          autohide={false}
          isOpen={true}
          target={ref}
        >
          <div className="quo__timer">
            <span className="countdown_group">
              <span className="countdown_number">
                {days}
              </span>
              <span className="countdown_unit">
                {getTransText("D")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {hours}
              </span>
              <span className="countdown_unit">
                {getTransText("H")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {minutes}
              </span>
              <span className="countdown_unit">
                {getTransText("M")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {seconds}
              </span>
              <span className="countdown_unit">
                {getTransText("S")}
              </span>
            </span>
          </div>
        </Tooltip>
      </>
    );
  };

  const renderButton = () => {
    if (!isButton) {
      return ("");
    }

    if (quo.responsed) {
      return ("");
    }

    const s = DateTime.fromSeconds(quo.charge_date, { zone: "utc" });

    return (
      <div className="quo__btns">
        <button type="button" className="quo__btn quo__btn-reject" onClick={() => switchRejectToggler()}>{getTransText("Reject")}</button>
        <Countdown date={roundUpHour(s.plus({ hours: 48 })).setZone("system")} renderer={renderer} />
      </div>
    );
  };


  return (
    <>
      <ModalParent className="confirm__modal" toggler={rejectToggler} switchToggler={switchRejectToggler}>
        <div className="modal__title">
          <h4 className="bold-text">{getTransText("QUO.status.reply.confirm.reject")}</h4>
        </div>
        <ButtonToolbar className="btn-toolbar--center">
          <Button color="primary" type="submit" onClick={() => rejectClick(quo.id)}>{getTransText("Confirm")}</Button>
        </ButtonToolbar>
      </ModalParent>
      {
        isSender
          ? (
            <div className="quo__countering">
              <div className="quo__status">
                <div className="quo__status-notice">{getTransText("QUO.status.buyer.countering")}</div>
              </div>
              {renderButton()}
            </div>
          )
          : (
            <div className="quo__countering">
              <div className="quo__status">
                <div className="quo__status-notice">{getTransText("QUO.status.creator.countering")}</div>
              </div>
            </div>
          )
      }
    </>
  );
};

export default CounteringStatus;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import Carousel from '~~/carousel/CarouselSingle';

const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;
const thumbnailBase = `${process.env.REACT_APP_API_URL}file-access/thumbnail/`;
const settings = "?fit=cover&width=400&height=400";

const ProductItems = ({ items }) => (
  <div className="catalog-items__wrap">
    <div className="catalog-items">
      {items.map(item => (
        <Card className="catalog-item" key={item.i}>
          <CardBody className="catalog-item__body">
            <Link className="catalog-item__link" to={`/product_page/${item.id}`}>
              {item.new ? <img className="catalog-item__label" src={New} alt="new" /> : ''}
              {item.sale ? <img className="catalog-item__label" src={Sale} alt="sale" /> : ''}
              <Carousel>
                {item.thumbnails && item.thumbnails.split(',').map((thumbnail, index) => (
                  <div className="catalog-item__img-wrap">
                    <img className="catalog-item__img" src={`${thumbnailBase}${thumbnail.trim()}${settings}`} alt="catalog-item-img" />
                  </div>
                ))}
              </Carousel>
              <div className="catalog-item__info">
                <h4 className="catalog-item__title">{item.title}</h4>
                <p className="catalog-item__description">{item.description}</p>
                <h1 className="catalog-item__price">${item.price}</h1>
                {item.sale ? <p className="catalog-item__old-price">${item.oldPrice}</p> : ''}
                {/* {item.colors.map(color => (
                <span
                  key={color.i}
                  className="catalog-item__color"
                  style={{ background: color }}
                />
              ))} */}
              </div>
            </Link>
          </CardBody>
        </Card>
      ))}
    </div>
  </div>
);

ProductItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    description: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.string),
    new: PropTypes.bool,
  })),
};

ProductItems.defaultProps = {
  items: [],
};

export default ProductItems;

/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DateTime } from "luxon";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from '@material-ui/core/TableBody';
import { SocketContext } from "~/context/SocketContext";
import { addTokenToURL } from '~/apiCalls/authApi';
import {
  getUploadedGalleryFile,
  quoCreateConversation,
  pickedFileFirstSubmission,
  pickedFileReSubmission,
} from '~/apiCalls/quoApi';
// import {  } from '~/apiCalls/userApi';
import DropFilePond from "~~/FormDropzone/components/DropFilePond";
import { apiCallBegan } from "~/store/api";
import {
  chatAddedMessage,
  chatAddMessagBegan,
} from "~/store/chats";

import {
  Table, TableCheckbox, TableRow, TableWrap, TablePagination, TableCell,
} from '@/shared/components/MaterialTableElements';
import MatTableHead from './MatTableHead';
import MatTableToolbar from './MatTableToolbar';

export const formatDate = (datetimeValue) => {
  const UTCTime = DateTime.fromISO(datetimeValue, { zone: "utc" });
  const SystemTime = UTCTime.setZone("system");
  return SystemTime.toFormat('yyyy LLL dd HH:mm');
};

export const PhotoFormatter = ({ file }) => {
  const [src, setSrc] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState();

  useEffect(() => {
    if (file.type.includes('video')) {
      addTokenToURL(`${process.env.REACT_APP_API_URL}assets/${file.id}`).then((x) => { setSrc(x); });
    } else {
      addTokenToURL(`${process.env.REACT_APP_API_URL}assets/${file.id}?width=335&height=250`).then((x) => { setSrc(x); });
    }
  }, [file]);

  function onLoad() {
    // delay for demo only
    setIsLoading(false);

    // setIsLoading(false)
  }

  const imageOnErrorHandler = (event) => {
    event.currentTarget.src = `${process.env.REACT_APP_FRONTEND_URL}img/noimage.svg`;
  };

  const renderThumbnailReturn = () => {
    if (file) {
      if (file.type.includes('video')) {
        // console.log("inside video", file);
        return (
          <>
            <div className="products-list__img-wrap">
              <video
                className="submitImg"
                src={src}
                width={100}
                height={100}
                style={{ objectFit: "cover" }}
              />
            </div>
          </>
        );
      }
      if (file.type.includes('image')) {
        return (
          <>
            {isLoading && (
              <Skeleton
                height={100}
                className="avatar-placeholder"
              />
            )}
            <div className="products-list__img-wrap">
              <img
                className="submitImg"
                key={file?.id}
                src={src}
                alt={src}
                style={{ display: isLoading ? "none" : "block" }}
                onError={imageOnErrorHandler}
                onLoad={onLoad}
              />
            </div>
          </>
        );
      }
      if (file.type.includes('audio')) {
        return (
          <>
            <div className="products-list__img-wrap">
              <img className="submitImg" src="/img/audio-file.svg" alt="Download file" />
            </div>
          </>
        );
      }
      return (
        <div className="products-list__img-wrap">
          <img
            className="submitImg"
            key={file?.id}
            src="/img/download-file.svg"
            alt={src}
            style={{ display: isLoading ? "none" : "block" }}
            onError={imageOnErrorHandler}
            onLoad={onLoad}
          />
        </div>
      );
    }
  };

  return (
    <>
      {src && (
        renderThumbnailReturn()
      )}
    </>
  );
};


const getSorting = (order, orderBy) => {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
};

const MatTable = ({
  quo,
  isFirstSubmission,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { socket } = useContext(SocketContext);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState(new Map([]));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowCount, setRowCount] = useState();
  const [pageCount, setPageCount] = React.useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const pageSize = 10;
    const pageIndex = 0;
    const startRow = pageSize * pageIndex;
    const endRow = startRow + pageSize;
    getUploadedGalleryFile(page + 1).then((res) => {
      const rowData = res?.data?.map((a) => ({
        id: a.id,
        preview: <PhotoFormatter file={a} />,
        filename_download: a.filename_download,
        filesize: `${Number(a.filesize / 1024 / 1024).toFixed(3)} KB`,
        type: a.type,
        uploaded_on: `${formatDate(a.uploaded_on)}`,
      }));
      setData(rowData);
      setRowCount(res.meta[0].count);
      setPageCount(Math.ceil(res.meta[0].count / page));
    });
  }, [page]);



  const photoPicked = async (fileList) => {
    if (fileList.length > 0) {
      const axiosCallSent = isFirstSubmission ? pickedFileFirstSubmission : pickedFileReSubmission;
      dispatch(
        apiCallBegan({
          axiosCall: axiosCallSent,
          data: { quoId: quo.id, fileList },
          // onStart: chatAddMessagBegan.type,
          // onSuccess: chatAddedMessage.type,
        })
      );
      const conversationId = await quoCreateConversation(quo.receiver, quo.id);
      history.push(`/c/${conversationId}`);
    }
  };


  const handleRequestSort = (event, property) => {
    const orderByTemp = property;
    let orderTemp = 'desc';
    if (orderBy === property && order === 'desc') { orderTemp = 'asc'; }
    setOrder(orderTemp);
    setOrderBy(orderByTemp);
  };

  const handleSelectAllClick = (event, checked) => {
    if (checked) {
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      setSelected(newSelected);
      return;
    }
    setSelected(new Map([]));
  };

  const handleClick = (event, id) => {
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    setSelected(newSelected);
  };

  const handleChangePage = (event, item) => {
    setPage(item);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleDeleteSelected = () => {
    let copyData = [...data];
    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }
    setData(copyData);
    setSelected(new Map([]));
  };

  const isSelected = id => !!selected.get(id);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (page * rowsPerPage));

  return (
    <>
      <MatTableToolbar
        numSelected={[...selected].filter(el => el[1]).length}
        handleDeleteSelected={handleDeleteSelected}
        onRequestSort={handleRequestSort}
      />
      <TableContainer>
        <Table stickyHeader={true}>
          <MatTableHead
            numSelected={[...selected].filter(el => el[1]).length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rowCount}
          />
          <TableBody>
            {data
              .map((d) => {
                const select = isSelected(d.id);
                return (
                  <TableRow
                    role="checkbox"
                    onClick={event => handleClick(event, d.id)}
                    aria-checked={select}
                    tabIndex={-1}
                    key={d.id}
                    selected={select}
                  >
                    <TableCell padding="checkbox">
                      <TableCheckbox checked={select} />
                    </TableCell>
                    <TableCell
                      right
                      component="th"
                      scope="row"
                      padding="none"
                    >
                      {d.preview}
                    </TableCell>
                    <TableCell right>
                      {d.filename_download}
                    </TableCell>
                    <TableCell right>
                      {d.filesize}
                    </TableCell>
                    <TableCell right>
                      {d.type}
                    </TableCell>
                    <TableCell right>
                      {d.uploaded_on}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="btn-toolbar">
        <button type="button" className="btn btn-primary" onClick={() => photoPicked([...selected].filter(el => el[1]))} disabled={[...selected].filter(el => el[1]).length === 0} title="Submit">
          {[...selected].filter(el => el[1]).length > 0 ? `Submit ${[...selected].filter(el => el[1]).length} file(s)` : "Please choose file(s)"}
        </button>
      </div>
      <TablePagination
        component="div"
        count={rowCount}
        rowsPerPage={10}
        page={page}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[]}
        dir="ltr"
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
      />
      <DropFilePond setData={setData} setRowCount={setRowCount} />
    </>
  );
};

export default MatTable;

import React, {
  useRef, useState
} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import formatDate from '~~/datetime_render/FormatDate';
import getTransText from '~~/get-text/getTransText';

const ReadRenderProductList = ({ item }) => (
  <tr key={item.id}>
    <td>{formatDate(item?.date_created)}</td>
    <td>
      {item?.title}
    </td>
    <td>
      {item?.description}
    </td>
    <td>
      {item?.price}
    </td>
    <td className="align-center">{item?.status}</td>
    <td>
      <Button color="primary" className="btn-sm" tag={Link} to={`/product_edit/${item?.id}`}>Edit</Button>
    </td>
  </tr>
);

export default ReadRenderProductList;

// action
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";

export const getOpenCallList = createAction("opencall/getOpenCallList");
export const gotOpenCallDetailById = createAction("opencall/gotOpenCallDetailById");
export const getQuoInterestedList = createAction("opencall/getQuoInterestedList");
export const getUserClickedList = createAction("opencall/getUserClickedList");
export const pickedMe = createAction("opencall/pickedMe");
export const pickMeAlreadyClicked = createAction("opencall/pickMeButtonClicked");
export const openedModal = createAction("opencall/openedModal");
export const closedModal = createAction("opencall/closedModal");


// reducer
const initialState = {
  list: [],
  pagination: {},
  quoDetail: null,
  quoInterestedList: [],
  userClickedList: [],
  modal: false,
  error: '',
};

const opencallReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getOpenCallList.type, (state, action) => ({
      ...state,
      list: action.payload.data,
      pagination: action.payload.pagination,
      error: '',
    }))
    .addCase(gotOpenCallDetailById.type, (state, action) => ({
      ...state,
      quoDetail: action.payload,
      error: '',
    }))
    .addCase(getQuoInterestedList.type, (state, action) => ({
      ...state,
      quoInterestedList: JSON.stringify(action.payload) !== "{}" ? action.payload : [],
      error: '',
    }))
    .addCase(getUserClickedList.type, (state, action) => ({
      ...state,
      userClickedList: JSON.stringify(action.payload) !== "{}" ? action.payload : [],
      error: '',
    }))
    .addCase(pickedMe.type, (state, action) => {
      state.quoInterestedList.unshift(action.payload);
      state.quoDetail.isEnrolled = 1;
      const index = state.list?.data?.findIndex((c) => c.id === action.payload.quo_id);
      if (index > -1) {
        state.list.data[index].isEnrolled = 1;
      }
    })
    .addCase(openedModal.type, (state, action) => ({
      ...state,
      modal: true,
    }))
    .addCase(closedModal.type, (state, action) => ({
      ...state,
      modal: false,
    }))
    .addCase(pickMeAlreadyClicked.type, (state, action) => ({
      ...state,
      error: action.payload.response,
    }));
});

export default opencallReducer;
// export const getAuthStore = createSelector();

import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import AuthCode from 'react-auth-code-input';
import QRCode from "react-qr-code";
import EyeIcon from 'mdi-react/EyeIcon';
import {
  Button,
  FormGroup,
  Input,
  ButtonToolbar,
} from 'reactstrap';
import { enableUserTfa, generateTFASecret } from '~/apiCalls/userApi';
import getTransText from '~~/get-text/getTransText';
import { apiCallBegan } from '~/store/api';
import {
  userTfaEnable, userProfileUpdateError,
} from '~/store/user';

const TfaForm = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [qrString, setQrString] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const {
    error,
  } = useSelector(state => state.user);

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const onSubmit = async (values) => {
    try {
      const secretString = await generateTFASecret(values);
      setQrString(secretString);
    } catch (e) {
      setErrorMsg(e.response.errors[0].message);
    }
  };


  const onSubmitDone = async (values) => {
    dispatch(apiCallBegan({
      axiosCall: enableUserTfa,
      data: values,
      onSuccess: userTfaEnable.type,
      onError: userProfileUpdateError.type,
    }));
  };

  return (
    <React.Fragment>
      {!qrString
        ? (
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, submitting, values }) => (
              <div>
                <form className="form" onSubmit={handleSubmit}>
                  <FormGroup className="form__form-group">
                    <span className="form__form-group-label">{getTransText("Enter your password to enable Two-Factor Authentication")}</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <i className="las la-lock"></i>
                      </div>
                      <Field
                        name="password"
                      >
                        {({ input, meta }) => (
                          <div className="form__form-group-field form__form-group-input-wrap--error-above">
                            <Input
                              {...input}
                              name="password"
                              type={showPassword ? 'text' : 'password'}
                              placeholder={getTransText("password")}
                              className="input-without-border-radius"
                            />
                            <button
                              type="button"
                              className={`form__form-group-button${showPassword ? ' active' : ''}`}
                              onClick={toggleShowPassword}
                            ><EyeIcon />
                            </button>
                            {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                            {errorMsg && <span className="form__form-group-error">{errorMsg}</span>}
                          </div>
                        )}
                      </Field>
                    </div>
                  </FormGroup>
                  <ButtonToolbar className="form__button-toolbar btn-toolbar--center">
                    <Button color="primary" type="submit">{getTransText("Next")}</Button>
                  </ButtonToolbar>
                </form>
              </div>
            )}
          </Form>
        )
        : (
          <Form
            onSubmit={onSubmitDone}
            initialValues={{
              secret: qrString?.secret || "",
            }}
          >
            {({ handleSubmit, submitting, values }) => (
              <div>
                <form className="form" onSubmit={handleSubmit}>
                  <FormGroup className="form__form-group">
                    <span className="form__form-group-label">{getTransText("Scan the code in your authenticator app (e.g. Google, Microsoft authenticator in app store) to finish setting up 2FA")}</span>
                    <div className="form__form-qrcode">{qrString && <QRCode value={qrString.otpauth_url} />}</div>
                    {qrString && <div className="form__form-qrcode_code">{qrString.secret}</div>}
                    <div>
                      <Field
                        name="otp"
                      >
                        {({ input, meta }) => (
                          <div>
                            <AuthCode {...input} allowedCharacters="numeric" containerClassName="otp__container" inputClassName="otp__input" />
                            {error && <span className="form__form-group-error">{error}</span>}
                          </div>
                        )}
                      </Field>
                    </div>
                  </FormGroup>
                  <ButtonToolbar className="form__button-toolbar btn-toolbar--center">
                    <Button color="primary" type="submit">{getTransText("Done")}</Button>
                  </ButtonToolbar>
                </form>
              </div>
            )}
          </Form>
        )}
    </React.Fragment>
  );
};


export default TfaForm;


/* eslint-disable */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { authRefreshed, authRefreshFailed } from '~/store/auth';
import { refresh } from '~/apiCalls/authApi';
import { apiCallBegan } from '~/store/api';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';

const RefreshTokenFromCookie = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();


  useEffect(() => {
    // dispatch(apiCallBegan({
    //   axiosCall: refresh,
    //   onSuccess: authRefreshed.type,
    //   onError: authRefreshFailed.type,
    // }));
    if (query.get("reason")) {
      history.push("/log_in/" + query.get("reason"));
    } else {
      history.push("/");
    }
  }, []);

  return (
    <LoadingLogo />
  );
};

export default RefreshTokenFromCookie;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

const icon = `${process.env.PUBLIC_URL}/img/burger.svg`;

const Button = ({ onClick, className }) => {
  const {
    unRead,
  } = useSelector((state) => state.chats);
  const {
    active,
  } = useSelector((state) => state.quos);


  const topbarClass = classnames({
    'topbar__button': true,
    'topbar__btn--new': (unRead.sentBoxUnread + unRead.receivedBoxUnread) > 0 || active > 0,
  }, className);

  return (
    <button
      onClick={onClick}
      className={topbarClass}
      aria-label="menu"
      type="button"
    >
      <img src={icon} alt="" className="topbar__button-icon" />
      <div className="topbar__btn-new-label">
        <div />
      </div>
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const TopbarSidebarButton = ({ onClickDesktop, onClickMobile }) => (
  <div>
    <Button onClick={onClickDesktop} className="topbar__button--desktop" />
    <Button onClick={onClickMobile} className="topbar__button--mobile" />
  </div>
);

TopbarSidebarButton.propTypes = {
  onClickDesktop: PropTypes.func.isRequired,
  onClickMobile: PropTypes.func.isRequired,
};

export default TopbarSidebarButton;

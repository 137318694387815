import React, { useEffect, useState, useRef } from 'react';
import { Prompt } from "react-router-dom";
import { Field, Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import TagsInput from 'react-tagsinput';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  ButtonGroup,
  ButtonToolbar,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import AvatarEditor from "react-avatar-editor";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-lazy-load-image-component/src/effects/blur.css';
import AvatarRender from '~~/avatar_render';
import getTransText, { GetCountryName } from '~~/get-text/getTransText';
import ModalParent from '~~/modal_parent/ModalParent';
import { changedProfileTab } from '~/store/profile';
import ProfileSettingTabs from './ProfileSettingTabs';
import { updateAvatar, editUserProfile } from '~/apiCalls/userApi';
import { apiCallBegan } from '~/store/api';
import {
  userProfileUpdated,
  userProfileUpdateError,
  userClearedError,
} from '~/store/user';
import 'react-tagsinput/react-tagsinput.css';


const ProfileSettingForm = ({ setCollapseToggle }) => {
  const dispatch = useDispatch();
  const {
    id,
    avatar,
    random_no,
    email,
    location,
    description,
    username,
    tags,
  } = useSelector(state => state.user.userData);
  const [toggler, setToggler] = useState(false);
  const [changeProfileButton, setChangeProfileButton] = useState("Change Profile Picture");
  const {
    error,
    success,
  } = useSelector(state => state.user);
  const [errorMsg, setErrorMsg] = useState("");

  const switchToggler = () => {
    setToggler(!toggler);
  };
  const [image, setImage] = useState();
  const [scaleState, setSacleState] = useState(1);
  const editor = useRef(null);
  const Ava = (typeof (id) !== 'undefined' && id != null) ? AvatarRender(avatar, random_no) : "";

  const required = value => (value ? undefined : getTransText('Required'));
  const moreThan5 = value => (value.length < 5 ? getTransText('Please input more than 5 char.') : undefined);
  const noSpaceAllowed = value => (value.indexOf(' ') >= 0 ? getTransText('No space allowed') : undefined);
  const maxLength = value => (value.length > 30 ? getTransText('No more than 30 chars.') : undefined);
  const usernameOnlyContain = value => {
    const re = new RegExp("^(?:[a-zA-Z0-9]|([._])(?!\\1)){5,30}$");
    if (!re.test(value)) {
      return getTransText("contains only letters, numbers, periods and underscores.");
    }
    return undefined;
  };

  const composeValidators = (...validators) => value => validators.reduce((error2, validator) => error2 || validator(value), undefined);



  const ChangeProfilePictureClick = (e) => {
    setImage(e.target.files[0]);
    switchToggler();
  };

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setSacleState(scale);
  };

  const handleSave = async (e) => {
    e.currentTarget.disabled = true;
    setChangeProfileButton("Loading..");
    const dataUrl = editor.current.getImage().toDataURL();
    const result = await fetch(dataUrl);
    const blob = await result.blob();
    const formData = new FormData();
    formData.append('title', `${username}-avatar`);
    formData.append('file_purpose', "avatar");
    formData.append('file', blob, image.name);
    const returnAvatarId = await updateAvatar(formData);
    if (returnAvatarId) {
      switchToggler();
    }
  };


  const onSubmit = (values) => {
    dispatch(apiCallBegan({
      axiosCall: editUserProfile,
      data: values,
      onSuccess: userProfileUpdated.type,
      onError: userProfileUpdateError.type,
    }));
  };

  useEffect(() => {
    if (error && error !== "") {
      if (error.errors[0].message.includes('unique')) {
        setErrorMsg(getTransText("This username has been taken."));
      }
    }
  }, [error]);


  useEffect(() => {
    if (success !== "" && success !== "hydrated") {
      NotificationManager.info(getTransText(success));
    }
  }, [success]);

  const toggle = (tab) => {
    dispatch(changedProfileTab(tab));
  };

  return (
    <Row>
      <Col xs="12">
        <h3 className="page-title">
          {getTransText("Profile Setting")}
        </h3>
        <div className="profile_setting_wrapper">
          <div className="tabs">
            <Nav pills justified>
              <NavItem>
                <NavLink
                  className="active"
                  onClick={() => toggle('1')}
                >
                  {getTransText("profile.setting.detail")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className=""
                  onClick={() => toggle('2')}
                >
                  {getTransText("profile.setting.photos")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className=""
                  onClick={() => toggle('3')}
                >
                  {getTransText("profile.setting.questions")}
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              description,
              role: 'f3734efa-3f78-4f53-8ee6-8d06d6353089',
              username,
              tags: tags || [],
              avatar: 'e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae',
            }}
            validate={values => {
              const errors = {};
              // if (!values.username) {
              //   errors.username = getTransText('Required');
              // } else if (values.username.length < 5) {
              //   errors.username = getTransText('Please input more than 5 char.');
              // } else if (values.username.indexOf(' ') >= 0) {
              //   errors.username = getTransText('No space allowed');
              // }
              if (values.description) {
                if (values.description.length > 250) {
                  errors.description = getTransText('No more than 250 length.');
                }
              }

              return errors;
            }}
          >
            {({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <NotificationContainer leaveTimeout={6000} />
                <ProfileSettingTabs activeTab="1" />
                <ButtonToolbar className="btn-toolbar--center">
                  {/* <Button color="cancel" onClick={() => { history.goBack(); }}>{getTransText("Cancel")}</Button> */}
                  <Button color="primary" type="submit">{getTransText("Submit")}</Button>
                </ButtonToolbar>
              </form>
            )}
          </Form>
        </div>
        <Prompt
          message={() => {
            dispatch(userClearedError());
          }}
        />
      </Col>
    </Row>
  );
};

export default ProfileSettingForm;

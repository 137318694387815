/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import TopbarInfo from '~~/topbarInfo/Topbar';

const ExamplePage = () => (
  <>
    <div className="layout">
      <TopbarInfo />
    </div>
    <Container className="quabal__container terms__container">
      <Row>
        <Col md={8} xl={6}>
          <h3 className="page-title">QUOBal Privacy Policy</h3>
          <p className="small">This Privacy Policy was last updated on Friday, August 19, 2022</p>
          <p>Welcome to QUOBal and our platform at www.quobal.com and thank you for your interest in our services and company. We take the protection of your personal information very seriously and process your data in accordance with the Delaware Online Privacy and Protection Act (&ldquo;DOPPA&rdquo;) and the General Data Protection Regulation (&ldquo;GDPR&rdquo;).</p>
          <p>With the help of this Privacy Policy, we inform you comprehensively about the processing of your personal information by us and the rights to which you are entitled.</p>
          <p>Personal information is information that makes it possible to identify a natural person. This includes, your name, date of birth, address, telephone number, e-mail address, but also your IP address. Anonymous data as such only exists if no personal reference to the user can be made.</p>
          <p><strong>The Data Controller</strong></p>
          <p>In accordance with the DOPPA and the GDPR, the person responsible for processing of personal information when using the platform is:</p>
          <p>QUObal.com</p>
          <p>Quomark Limited</p>
          <p>1201 Orange Street, Suite 600,</p>
          <p>Wilmington, New Castle County,</p>
          <p>Delaware 19801, USA</p>
          <p>Web: <a href="http://www.quobal.com">www.quobal.com</a></p>
          <p>E-Mail: <a href="mailto:info@quobal.com">info@quobal.com</a></p>
          <p><strong>Categories of data subjects and types of data processed</strong></p>
          <p>During the course of using our platform and services, we process the following types of data from visitors and users:</p>
          <ul>
            <li>inventory data (e.g., Username),</li>
            <li>contact data (e.g., e-mail),</li>
            <li>content data (e.g., text entries, messages),</li>
            <li>usage data (e.g., web pages visited, interest in content, access times), and</li>
            <li>meta/communication data (e.g., device information, IP addresses).</li>
          </ul>
          <p><strong>Purpose of the processing</strong></p>
          <p>The Purpose of processing personal information are:</p>
          <ul>
            <li>provision of the platform and services, its functions and contents,</li>
            <li>responding to contact requests and communicating with users,</li>
            <li>security measures, and</li>
            <li>reach measurement/marketing.</li>
          </ul>
          <p><strong>Relevant legal basis</strong></p>
          <p>In accordance with the DOPPA and the GDPR, the following legal basis, unless specifically described below apply to the processing of your personal information:</p>
          <ul>
            <li>Consent: the individual has given clear consent to process personal information for a specific purpose<em> (Art. 6 (1) a) GDPR).</em></li>
            <li>Contract: the processing is necessary for a contract or because you have asked us to take specific steps before entering into a contract <em>(Art. 6 (1) b) GDPR)</em>.</li>
            <li>Legal obligation: the processing is necessary for us to comply with the law (not including contractual obligations) <em>(Art. 6 (1) c) GDPR)</em>.</li>
            <li>Legitimate interests: the processing is necessary for our legitimate interests or the legitimate interests of a third party, unless there is a good reason to protect your personal information which overrides those legitimate interests<em> (Art. 6 (1) f) GDPR).</em></li>
          </ul>
          <p><strong>Security of your personal information</strong></p>
          <p>We take appropriate technical and organizational measures, taking into account the state of the art, the implementation costs and the nature, scope, circumstances and purposes of the processing, as well as the varying likelihood and severity of the risk to the rights and freedoms of natural persons, in order to ensure a level of protection appropriate to the risk.</p>
          <p>The measures include, in particular, ensuring the confidentiality, integrity and availability of data by controlling physical access to the data, as well as access to, input, disclosure, ensuring availability and segregation of the data. We also have procedures in place to ensure the exercise of data subjects' rights, deletion of data and response to data compromise.</p>
          <p>Furthermore, we also take the protection of personal information into account during the development and selection of hardware, software and procedures, in accordance with the principle of data protection through technology design and through data protection-friendly default settings.</p>
          <p><strong>Cooperation with processors and third parties</strong></p>
          <p>If, in the course of our processing, we disclose data to other persons and affiliated companies, transmit it to them or otherwise grant them access to the data, this will only be done on the basis of a legal permission (e.g. if a transmission of the data to third parties, such as to payment service provider (<a href="https://stripe.com/gb/privacy">Stripe</a>), is necessary for the performance of the contract, you have consented, a legal obligation provides for this or on the basis of our legitimate interests (e.g. when using agents, web hosts, etc.). If we commission third parties to process data on the basis of a so-called &ldquo;processing agreement&rdquo;.</p>
          <p><strong>Transfers to third countries</strong></p>
          <p>If we process data in a third country or if this is done in the context of using third-party services or disclosing or transferring data to third parties, this is only done if it is done in order to fulfil our (pre-)contractual obligations, on the basis of your consent, due to a legal obligation or on the basis of our legitimate interests.</p>
          <p><strong>Your rights</strong></p>
          <p>You are entitled at any moment to enforce the rights available to you, as a Delaware Resident or Citizen:</p>
          <ul>
            <li>the categories and specific pieces of personal information we have collected about you;</li>
            <li>the categories of sources from which we collected the personal information;</li>
            <li>the business or commercial purpose for which we have collected or sold the personal information;</li>
            <li>the categories of third parties with whom we have shared the personal information; and</li>
            <li>the categories of personal information about you that we have sold or disclosed for a business purpose, and the categories of third parties to whom we old or disclosed that information for a business purpose.</li>
          </ul>
          <p>The following rights arise from the GDPR for you as a Citizen of the European Union:</p>
          <ul>
            <li>Pursuant to Art. 15 GDPR, you may request information about your personal information processed by me. In particular, you can request information about the processing purposes, the categories of personal information, the categories of recipients to whom your data have been or will be disclosed, the planned storage period, the existence of a right to rectification, erasure, restriction of processing or objection, the existence of a right of complaint, the origin of your data if it has not been collected by me, about a transfer to third countries or to international organizations, and about the existence of automated decision-making, including profiling, and, if applicable, meaningful information about its details.</li>
            <li>Pursuant to Art. 16 GDPR, you can immediately request the correction of inaccurate or the completion of your personal information stored by me.</li>
            <li>Pursuant to Art. 17 GDPR, you may request the erasure of your personal information stored by me, unless the processing is necessary for the exercise of the right to freedom of expression and information, for compliance with a legal obligation, for reasons of public interest or for the establishment, exercise, or defense of legal claims.</li>
            <li>Pursuant to Art. 18 GDPR, you may request the restriction of the processing of your personal information if you dispute the accuracy of the data, the processing is unlawful, I no longer need the data and you object to their erasure because you need them for the assertion, exercise or defense of legal claims. You also have the right under Article 18 of the GDPR if you have objected to the processing in accordance with Article 21 of the GDPR.</li>
            <li>Pursuant to Art. 20 GDPR, you may request to receive your personal information that you have provided to me in a structured, commonly used, and machine-readable format or you may request that it be transferred to another controller.</li>
            <li>Pursuant to Art. 7 (3) GDPR, you may revoke your consent once given to me at any time. This has the consequence that I may no longer continue the data processing based on this consent for the future.</li>
            <li>In accordance with Art. 77 GDPR, you have the right to complain to a supervisory authority. As a rule, you can contact the supervisory authority of your usual place of residence, your place of work for this purpose.</li>
            <li>Right of objection. When your personal information is processed on the basis of legitimate interests pursuant to Art. 6 (1) f) GDPR, you have the right to object to the processing of your personal information pursuant to Art. 21 GDPR, insofar as there are grounds for doing so that arise from your particular situation or the objection is directed against direct advertising. In the case of direct advertising, you have a general right of objection, which is implemented by me without specifying a particular situation.</li>
            <li>If the GDPR is applicable to you, you have the right to make a complaint to a data protection supervisory authority at any time.</li>
          </ul>
          <p>If you wish to access such personal information or exercise any of the rights listed above, you should apply in writing, providing evidence of your identity. Any communication from us in relation to your rights as detailed above will be provided free of charge. However, in case of requests that are manifestly unfounded or excessive, in particular because of their repetitive character, we may charge a reasonable fee taking into account the administrative costs of providing the information or communication or taking the action requested; or refuse to act on the request.</p>
          <p><strong>Cookies </strong></p>
          <p>Cookies" are small files that are stored on your device. Different information can be stored within the cookies. We may use temporary and permanent cookies and will explain this in our Cookie Policy. The legal basis for the use of cookies is either your consent or our legitimate interest.</p>
          <p><strong>Deletion of data</strong></p>
          <p>The data processed by us will be deleted or its processing restricted in accordance with the DOPPA and GDPR. Unless expressly stated in this data protection declaration, the data stored by us will be deleted as soon as it is no longer required for its intended purpose and the deletion does not conflict with any statutory retention obligations. If the data is not deleted because it is required for other and legally permissible purposes, its processing will be restricted. I.e., the data is blocked and not processed for other purposes. This applies, for example, to data that must be retained for reasons of commercial or tax law.</p>
          <p><strong>Contacting us</strong></p>
          <p>If you contact us, we process the following data from you for the purpose of processing and handling your enquiry: Name, contact details -if provided by you- and your message. The legal basis of the data processing is our obligation to fulfil the contract and/or to fulfil our pre-contractual obligations and/or our legitimate interest in processing your inquiry.</p>
          <p><strong>Registration</strong></p>
          <p>When you register, we collect some personal information, such as your username, e-mail address, and your profile photo and a description, if provided. Alternatively, you are able to sign up using the convenience log in and sign up from <a href="https://www.facebook.com/about/privacy/previous">Facebook</a> and <a href="https://policies.google.com/privacy">Google</a>. For Facebook and Google log in and sign up, you will be asked to provide your basic information (i.e., name, email address, and display picture) linked to your Google account. If granted, username and password will be auto generated to fill in the rest of required user data. When registering via connect functions, you agree to the respective terms and conditions of Facebook or Google and also consent to certain data from your respective profile of being transferred to us.</p>
          <p>Registered users also have the option of changing or deleting the data provided during registration at any time. The processing of the data entered during registration is based on the user's consent and the implementation of pre-contractual measures. Data will only be processed in this context as long as you retain your user account. Afterwards, they will be deleted, provided that there are no legal storage obligations to the contrary. To contact us in this context, please use the contact details provided at the end of this privacy policy. The provision of your personal information is voluntary, based solely on your consent. Without the provision of your personal information, we cannot grant you access to our offered content and services.</p>
          <p><strong>Profile</strong></p>
          <p>As a registered user, you have the opportunity to create a user profile with just a few clicks and details (e.g., you can link your Instagram story and upload media to your library). If you make use of the option, the relevant profile data you provide will be transferred to your profile. Of course, you can change the information at any time via the settings in your profile.</p>
          <p>When creating a profile, you can submit personal information. You have choices about the information on your profile. You don&rsquo;t have to provide additional information on your profile; however, profile information helps you to get more from our Services. It&rsquo;s your choice whether to include sensitive information on your profile and to make that sensitive information public. Please do not upload or add personal information to your profile that you would not want to be available.</p>
          <p>The legal basis for the processing of your personal information is the establishment and implementation of the user contract for the use of the service.</p>
          <p><strong>Other Users</strong></p>
          <p>In addition to the information you provide to us directly, we also receive information about you from third parties. This includes other users who may provide us with information about you while using our Services. Equally, when you voluntarily share information on our Services (including your public profile), you disclose that information to other users. Please be careful with your information and make sure that you only share content that you truly agree to publish, as neither you nor we can control what others do with your information once you share it. If you want to make all or part of your profile or certain content visible only to certain groups of users, you can set appropriate restrictions in your settings.</p>
          <p><strong>Payment Processing</strong></p>
          <p>In the case of making or receiving payment for services you receive or provide, you are required to link your <a href="https://stripe.com/gb/privacy">Stripe</a> account. We do not collect or store any payment transaction information such as credit card numbers or bank details during the payment process. You only provide this information directly to Stripe.</p>
          <p><strong>Use of our platform</strong></p>
          <p>We process personal information of users for the purpose of using the platform and for the purpose of fulfilling the contract. The users can manage and change all information in their profile. If you use our platform, we store the data required for the fulfilment of the contract until final deletion of the access and/or after expiry of the statutory retention periods. The legal basis of the data processing is our obligation to fulfill the contract and/or to fulfill our pre-contractual obligations and/or our legitimate interest.</p>
          <p><strong>Administration, financial accounting, office organization, contact management</strong></p>
          <p>We process data within the scope of administrative tasks as well as organization of our business, financial accounting, and compliance with legal obligations, such as archiving.</p>
          <p>In doing so, we process the same data that we process in the context of providing our contractual services. The purpose and our interest in the processing lies in the administration, financial accounting, office organization, archiving of data, i.e., tasks that serve the maintenance of our business activities, performance of our tasks and provision of our services.</p>
          <p>The deletion of data with regard to contractual services and contractual communication corresponds to the information mentioned in these processing activities.</p>
          <p>In this context, we disclose or transmit data to the tax authorities, consultants such as tax advisors or auditors as well as other fee offices and payment service providers.</p>
          <p>Furthermore, we store information on suppliers, contractors, and other business partners on the basis of our business interests, e.g., for the purpose of contacting them at a later date. This data, most of which is company-related, is stored permanently.</p>
          <p>We may share personal information with unaffiliated third parties who partner with us to deliver our services or on our commercial activities. Depending on the choices you have made and the nature of the joint activity, these third parties may contact you regarding products or services that may interest you.</p>
          <p>We may share personal information with third party service providers who provide us with services, such as data analysis, online advertising, payment processing, order fulfillment, IT services, customer service, and other similar services.</p>
          <p>We grant our service providers access to personal information only to the extent needed for them to perform their functions and require them to protect the confidentiality and security of such personal information. We may share your personal information to our subsidiaries and corporate affiliates, including future subsidiaries and affiliates to use your information consistent with this Policy.</p>
          <p><strong>Collection of access data and log files</strong></p>
          <p>We, collect data on every access to our platform on the basis of our legitimate interest. The access data includes the name of the website accessed, file, date and time of access, amount of data transferred, notification of successful access, browser type and version, the user's operating system, referrer URL (the previously visited page), IP address and the requesting provider.</p>
          <p>Log file information is stored for security reasons (e.g., for the clarification of abuse or fraud) for a maximum of 7 days and then deleted. Data whose further storage is necessary for evidentiary purposes is exempt from deletion until the respective incident is finally clarified.</p>
          <p><strong>Data Breaches/Notification</strong></p>
          <p>Databases or data sets that include Personal information may be breached inadvertently or through wrongful intrusion. Upon becoming aware of a data breach, we will notify all affected individuals whose Personal information may have been compromised, and the notice will be accompanied by a description of action being taken to reconcile any damage as a result of the data breach. Notices will be provided as expeditiously as possible after which the breach was discovered.</p>
          <p><strong>Children&rsquo;s Privacy</strong></p>
          <p>Our services are restricted to users who are 18 years of age or older. We do not knowingly collect personal information from anyone under the age of 18. If you suspect that a user is under the age of 18, please contact us.</p>
          <p><strong>Controls For Do-Not-Track Features</strong></p>
          <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
          <p><strong>Changes</strong></p>
          <p>Because we&rsquo;re always looking for new and innovative ways to improve our platform and services, this policy may change over time. We will notify you before any material changes take effect so that you have time to review the changes.</p>
          <p><strong>Who should I contact for more information?</strong></p>
          <p>If you have any questions or comments about our Privacy Policy or wish to exercise your rights under applicable laws, please contact us using the following contact details:</p>
          <p>QUObal.com</p>
          <p>Quomark Limited</p>
          <p>1201 Orange Street, Suite 600,</p>
          <p>Wilmington, New Castle County,</p>
          <p>Delaware 19801, USA</p>
          <p>Web: <a href="http://www.quobal.com">www.quobal.com</a></p>
          <p>E-Mail: info@quobal.com</p>
        </Col>
      </Row>
    </Container>
  </>
);

export default ExamplePage;

import React, { useEffect, useState, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import { produce } from 'immer';
import {
  Button,
  ButtonToolbar,
  FormGroup,
  Popover,
  PopoverHeader,
  PopoverBody,
  Input,
} from 'reactstrap';
import FilepondComponent from '~~/filepond-component/';
import getTransText from '~~/get-text/getTransText';
import { readProductApi, getProductFilesApi, getThumbnailsApi } from '../../apiCalls/marketplaceApi';
import { apiCallBegan } from '~/store/api';
import {
  getProductInfo,
  getProductFiles,
  getThumbnails,
  marketplaceError,
  addedThumbnails,
  addedProductFiles,
} from '~/store/marketplace';


const PlaceQuo = () => {
  const {
    userData,
  } = useSelector(state => state.user);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverOpen2, setPopoverOpen2] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const toggle2 = () => setPopoverOpen2(!popoverOpen2);
  const [token, setToken] = useState(null);
  const thumbnailsInstance = useRef(null);
  const productInstance = useRef(null);
  const { product_id } = useParams();
  const dispatch = useDispatch();

  const {
    currentProductInfo,
    thumbnails,
    productFiles,
  } = useSelector(state => state.marketplace);

  const history = useHistory();

  useEffect(async () => {
    if (product_id) {
      dispatch(apiCallBegan({
        axiosCall: readProductApi,
        data: product_id,
        onSuccess: getProductInfo.type,
        onError: marketplaceError.type,
      }));
      dispatch(apiCallBegan({
        axiosCall: getProductFilesApi,
        data: product_id,
        onSuccess: getProductFiles.type,
        onError: marketplaceError.type,
      }));
      dispatch(apiCallBegan({
        axiosCall: getThumbnailsApi,
        data: product_id,
        onSuccess: getThumbnails.type,
        onError: marketplaceError.type,
      }));
    }
  }, [product_id]);

  const thumbnailOnload = (response) => {
    const lists = JSON.parse(response);
    dispatch(addedThumbnails(lists));
    thumbnailsInstance.current.removeFiles();
    toggle();
  };

  const productOnload = (response) => {
    const lists = JSON.parse(response);
    dispatch(addedProductFiles(lists));
    productInstance.current.removeFiles();
    toggle2();
  };

  const onSubmit = async (values) => {
    const credentials = values;
    // const res = await createPreQuo(credentials);
    // history.push(`/pay/${res}`);
  };

  const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);
  const mustBeNumber = value => (Number.isNaN(value) ? getTransText('Must be a number') : undefined);
  const minPrice = value => (value < 0.5 ? getTransText('At least $0.50 usd') : undefined);
  const maxPrice = value => (value > 10000 ? getTransText('No more than $10000 usd') : undefined);


  return (
    <React.Fragment>
      {/* <div className="card__title">
        <h5 className="bold-text">{getTransText("Place QUO anonymously to ")}<span className="quo__card__user">{username}</span></h5>
      </div> */}
      <Form
        onSubmit={onSubmit}
        initialValues={{
          title: currentProductInfo?.title,
        }}
        validate={values => {
          const errors = {};
          if (!values.request) {
            errors.title = getTransText("Please write your request");
          } else if (!values.price) {
            errors.price = getTransText('Please fill in price');
            // eslint-disable-next-line 
          } if (isNaN(values.price)) {
            errors.price = getTransText('Must be a number');
          } else if (!values.receiver) {
            errors.receiver = getTransText('Required');
          }
          return errors;
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <form className="form quo__form" onSubmit={handleSubmit}>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("marketplace.title.title")}</span>
              <Field
                name="title"
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <Input
                        {...input}
                        name="title"
                        type="text"
                        placeholder={getTransText("marketplace.title.placeholder")}
                        className="input-without-border-radius"
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("marketplace.title.descriptions")}</span>
              <Field
                name="thumbnails"
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <ReactQuill
                        {...input}
                        formats={[]}
                        modules={{
                          toolbar: []
                        }}
                        name="request"
                        type="textarea"
                        placeholder={getTransText("marketplace.descriptions.placeholder")}
                      // className="input-without-border-radius"
                      // theme="bubble"
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("marketplace.title.price")}</span>
              <Field
                name="price"
                validate={composeValidators(mustBeNumber, minPrice, maxPrice)}
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <Input
                        {...input}
                        name="price"
                        type="text"
                        placeholder={getTransText("USD")}
                        className="input-without-border-radius"
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
            <div>


            </div>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("marketplace.title.thumbnails")}</span>
              <Button id="Popover1" type="button" className="btn control-btn btn-primary " label="Upload file"><i className="las la-upload"></i></Button>
              <Popover trigger="legacy" placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
                <PopoverBody>
                  <FilepondComponent filepurpose="marketplace-thumbnails" onload={thumbnailOnload} ref={thumbnailsInstance} description={product_id} />
                </PopoverBody>
              </Popover>
              {JSON.stringify(thumbnails)}
            </FormGroup>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("marketplace.title.files")}</span>
              <Button id="Popover2" type="button" className="btn control-btn btn-primary " onClick={() => console.log("ss")} label="Upload file"><i className="las la-upload"></i></Button>
              <Popover trigger="legacy" placement="bottom" isOpen={popoverOpen2} target="Popover2" toggle={toggle2}>
                <PopoverBody>
                  <FilepondComponent filepurpose="marketplace-products" onload={productOnload} ref={productInstance} description={product_id} />
                </PopoverBody>
              </Popover>
              {JSON.stringify(productFiles)}
            </FormGroup>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit"><i className="icon-quo"></i> {getTransText("marketplace.newproduct.publish")}</Button>
              <Button color="primary" type="button"><i className="icon-quo"></i> {getTransText("marketplace.newproduct.save-draft")}</Button>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    </React.Fragment>
  );
};


export default PlaceQuo;

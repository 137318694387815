
import { client, clientPublic } from '~/apiCalls/directusConfig';

// ManageQuo API

export const getOpenQuosPublic = async (cpage) => {
  try {
    const res = await clientPublic.request(() => ({
      path: `/open-call?current_page=${cpage}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};


export const getOpenQuoByIdPublic = async (quoId) => {
  try {
    const res = await clientPublic.request(() => ({
      path: `/open-call/${quoId}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};


export const getOpenQuos = async (cpage) => {
  try {
    const res = await client.request(() => ({
      path: `/open-call?current_page=${cpage}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};


export const getOpenQuoById = async (quoId) => {
  try {
    const res = await client.request(() => ({
      path: `/open-call/${quoId}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};


export const getOpenQuoUserClickedList = async (option) => {
  const res = await client.request(() => ({
    path: `/open-call/picked-list`,
    method: "GET",
  }));
  return (res);
};

export const getOpenQuoPickedListById = async (quoId) => {
  try {
    const res = await client.request(() => ({
      path: `/open-call/interested-list/${quoId}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};


export const getOpenQuoPickedListCountById = async (quoId) => {
  try {
    const res = await client.request(() => ({
      path: `/open-call/interested-list-count/${quoId}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};


export const pickMeClickApi = async (option) => {
  const res = await client.request(() => ({
    path: `/open-call/pick-me/`,
    method: "POST",
    body: JSON.stringify(option),
  }));
  return (res);
};


export const acceptSearchingApi = async (option) => {
  const res = await client.request(() => ({
    path: `/manage-quo/accept-searching`,
    method: "POST",
    body: JSON.stringify(option),
  }));
  return (res);
};



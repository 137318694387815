import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import OpenDetailContainer from './OpenDetailContainer';



const OpenDetailDirectPage = () => (
  <div className="quo__opencall__page__container">
    <Card>
      <CardBody>
        <OpenDetailContainer />
      </CardBody>
    </Card>
  </div>
);
export default OpenDetailDirectPage;

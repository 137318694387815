import React from 'react';
import {
  Modal,
} from 'reactstrap';
import classNames from 'classnames';

const LightBoxModalParent = function LightBoxModalParent(props) {
  const {
    children,
    className,
    downloadSrc,
    isOpen,
    toggle,
  } = props;
  const lightboxModal = classNames({
    ...className,
    "lightbox_modal": true,
  });

  return (
    <Modal className={lightboxModal} isOpen={isOpen} toggle={() => toggle()}>
      <button className="modal__close-btn" aria-label="modal__close-btn" type="button" onClick={() => toggle()}><i className="las la-times"></i></button>
      {/* <button className="lightbox__close-btn" aria-label="lightbox__close-btn" type="button" onClick={() => toggle()}><i className="las la-times"></i></button> */}
      <div className="lightbox_modal_container">
        {children}
      </div>
      {downloadSrc && <div className="lightbox__footer"> <a href={downloadSrc} className="lightbox__download-btn" aria-label="lightbox__download-btn"><i className="las la-download"></i>Download Now</a></div>}
    </Modal>
  );
};
export default LightBoxModalParent;

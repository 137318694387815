import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Button, ButtonToolbar, Card, CardBody } from 'reactstrap';
import ProductLists from './components/ProductsListTable';
import { getProductList, marketplaceError } from '~/store/marketplace';
import { apiCallBegan } from '~/store/api';
import { readProductListApi, countProductListApi, readUserProductListApi } from '~/apiCalls/marketplaceApi';
import getTransText from '~~/get-text/getTransText';
import ModalParent from '~~/modal_parent/ModalParent';
import NewProductName from '~~/marketplace/NewProductName';

const ProductsList = () => {
  const dispatch = useDispatch();
  const { productList } = useSelector(state => state.marketplace);
  const [page, setPage] = useState(1);
  const [lists, setLists] = useState();
  const { authenticated } = useSelector(state => state.auth);
  const [toggler, setToggler] = useState(false);
  const switchToggler = () => {
    setToggler(!toggler);
  };

  useEffect(async () => {
    const count = await countProductListApi();
    const productListRes = await readProductListApi(page);
    // Update the list format
    dispatch(getProductList({
      data: productListRes,
      count,
    }));
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{getTransText("productsetting.productlist.pagetitle")}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              {authenticated && (
                <>
                  <ModalParent className="new_product_model" toggler={toggler} switchToggler={switchToggler}>
                    <div>
                      <NewProductName />
                    </div>
                  </ModalParent>
                  <ButtonToolbar className="btn-toolbar--right">
                    <Button color="primary" type="button" onClick={() => switchToggler()}><i className="icon-quo"></i>{getTransText("productsetting.productlist.newproduct")}</Button>
                  </ButtonToolbar>
                </>
              )}
              <ProductLists rows={productList} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductsList;

import React from 'react';
import TopbarSidebarButton from './TopbarSidebarButton';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => (
  <div className="topbar topbar__info">
    <div className="topbar__wrapper">
      <TopbarSidebarButton
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        changeSidebarVisibility={changeSidebarVisibility}
      />
    </div>
  </div>
);

export default Topbar;

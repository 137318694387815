import React, { useContext, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  Button, ButtonToolbar, FormGroup,
  Input
} from 'reactstrap';
import ReactQuill from 'react-quill';
import { counterPending, quoCreateConversation } from '~/apiCalls/quoApi';
import { SocketContext } from "~/context/SocketContext";
import { apiCallBegan } from "~/store/api";
import getTransText from '~~/get-text/getTransText';

const CounterForm = ({
  friendInfo, quo, switchToggler,
}) => {
  const dispatch = useDispatch();
  const { socket } = useContext(SocketContext);
  // eslint-disable-next-line
  const [username, setUsername] = useState();
  const history = useHistory();
  const {
    id,
  } = useSelector(state => state.user.userData);
  useEffect(() => {
    setUsername(friendInfo.username);
  }, [friendInfo]);

  const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);
  const mustBeNumber = value => (Number.isNaN(value) ? getTransText('Must be a number') : undefined);
  const minPrice = value => (value < 0.5 ? getTransText('At least $0.50 usd') : undefined);
  const maxPrice = value => (value > 10000 ? getTransText('No more than $10000 usd') : undefined);


  const onSubmit = async (values) => {
    dispatch(
      apiCallBegan({
        axiosCall: counterPending,
        data: { quoId: quo.id, values },
        // socketEmit: { socket, event: "sendMessage" },
        // onSuccess: chatAddedMessage.type,
      })
    );
    const conversationId = await quoCreateConversation(quo.receiver, quo.id);
    setTimeout(() => history.push(`/c/${conversationId}`), 100);
  };

  return (
    <>
      <div className="modal__title">
        <h4 className="bold-text">{getTransText("Counter Quo")}</h4>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          title: quo.latest_title,
          request: quo.latest_description,
          price: quo.latest_price,
          quoId: quo.Id,
        }}
        validate={values => {
          const errors = {};
          if (!values.request) {
            errors.request = getTransText("Please write your request");
          } else if (!values.price) {
            errors.price = getTransText("Please fill in price");
          } if (isNaN(values.price)) {
            errors.price = getTransText('Must be a number');
          }
          return errors;
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <form className="form counter__form" onSubmit={handleSubmit}>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("quoform.title.title")}</span>
              <Field
                name="title"
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <Input
                        {...input}
                        name="title"
                        type="text"
                        placeholder={getTransText("quoform.title.placeholder")}
                        className="input-without-border-radius"
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("quoform.request.title")}</span>
              <Field
                name="request"
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field form__form-group-input-wrap--error-above">
                    <div className="form__form-group-input-wrap">
                      <ReactQuill
                        {...input}
                        formats={[]}
                        modules={{
                          toolbar: []
                        }}
                        name="request"
                        type="textarea"
                        placeholder={getTransText("quoform.request.placeholder")}
                      // className="input-without-border-radius"
                      // theme="bubble"
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("Request Price")}</span>
              <Field
                name="price"
                validate={composeValidators(mustBeNumber, minPrice, maxPrice)}
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <Input
                        {...input}
                        name="price"
                        type="text"
                        placeholder={getTransText("USD")}
                        className="input-without-border-radius"
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
            <ButtonToolbar className="btn-toolbar--center">
              <Button color="primary" type="submit">{getTransText("Counter")}</Button>
              <Button type="button" onClick={() => switchToggler()}>
                {getTransText("Cancel")}
              </Button>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    </>
  );
};


export default CounterForm;

/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import FilePreview from '~~/file-preview';
import LightBoxModalParent from '~~/modal_parent/LightBoxModalParent';

const MediaItem = ({
  file, index,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = (e) => {
    setModal(!modal);
  };

  return (
    <>
      <LightBoxModalParent isOpen={modal} toggle={toggle}>
        {file && <FilePreview mime={file.type} src={`assets/${file.id}`} title={file.filename_download} />}
        {/* {file && <LightBox file={file} />} */}
      </LightBoxModalParent>
      {/*  */}
      <FilePreview mime={file.type} src={`assets/${file.id}`} title={file.filename_download} isThumbnail={true} onClick={toggle} />
      {/* </button> */}
      <div className="media__item__name">{file?.filename_download}</div>
      <div className="media__item__type">{file?.type}</div>
      <div className="media__item__type">{Number(file?.filesize / 1024 / 1024).toFixed(3)} MB</div>
    </>
  );
};

export default MediaItem;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { apiCallBegan } from '~/store/api';
import { getQuoStatus } from '~/apiCalls/quoApi';
import {
  getActiveQuo,
} from '~/store/quos';

const RouteChangeTracker = ({ history }) => {
  const {
    id
  } = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();

  useEffect(async () => {
    if (id) {
      dispatch(apiCallBegan({
        axiosCall: getQuoStatus,
        onSuccess: getActiveQuo.type,
      }));
    }
  }, [history.location, id]);

  history.listen((location, action) => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });
  });

  return <div></div>;
};

export default withRouter(RouteChangeTracker);

import EyeIcon from 'mdi-react/EyeIcon';
import React, { useState } from 'react';
import AuthCode from 'react-auth-code-input';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, FormGroup, Input } from 'reactstrap';
import CheckBox from '~~/form/CheckBox';
import getTransText from '~~/get-text/getTransText';

const errorMsgRender = (state) => {
  const { opencall } = state;
  let error = '';
  if (opencall.error) {
    if (opencall.error.status === 401 || opencall.error.status === 403) {
      if (opencall.error.errors[0].message.includes('Clicked')) {
        error = getTransText("You submitted already");
      }
    }
  }
  return ({
    errorMsg: error,
  });
};

const PickMeForm = ({ onSubmit, errorMsg = '' }) => {
  const required = value => (value ? undefined : 'Required');

  const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, values }) => (
        <form className="form open__call__comment-form" onSubmit={handleSubmit}>
          <FormGroup className="form__form-group" controlid="formBasicEmail">
            <div className="form__form-group-field form__form-group-input-wrap--error-above">
              {errorMsg && <div className="form__form-group-error">{errorMsg}</div>}
              {/* <div className="form__form-group-icon">
                <i className="las la-at"></i>
              </div> */}
              <Field
                name="comment"
                type="textarea"
                className="input-without-border-radius"
                validate={composeValidators(required)}
              >
                {({ input, meta }) => (
                  <>
                    <Input {...input} placeholder="comment..." />
                    {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                  </>
                )}
              </Field>
            </div>
          </FormGroup>
          <FormGroup className="form__form-group">
            <Field name="is_target" component="input" type="checkbox" id="is_target" validate={composeValidators(required)}>
              {({ input, meta }) => (
                <div className="form__form-group-field">
                  <CheckBox
                    {...input}
                    type="checkbox"
                    name="is_target"
                    label={getTransText("pickmeform.understand.disclaimer")}
                  />
                  {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                </div>
              )}
            </Field>
          </FormGroup>
          <div className="btn-toolbar btn-toolbar--center">
            <Button className="btn btn-primary" type="submit" disabled={submitting}>
              {getTransText("Submit")}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

export default connect(errorMsgRender)(PickMeForm);

import React from 'react';
import { Field, Form } from 'react-final-form';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Button, ButtonGroup,
  ButtonToolbar, FormGroup, Input
} from 'reactstrap';
import { contactUsSubmission } from '~/apiCalls/userApi';
import getTransText from '~~/get-text/getTransText';

const LanguageSettingForm = ({ setCollapseToggle }) => {
  const dispatch = useDispatch();
  const {
    email,
  } = useSelector(state => state.user.userData);
  const location = useLocation();

  const required = value => (value ? undefined : getTransText('Required'));

  const onSubmit = async (values, form) => {
    await contactUsSubmission(values)
      .then((response) => {
        NotificationManager.info(getTransText("The message has been delivered to our team."));
        form.restart();
      })
      .catch((response) => {
        NotificationManager.info(getTransText("Network error"));
      });
  };

  return (
    <div>
      <h3 className="page-title">
        <button className="account_setting_button--menu" type="button" onClick={() => setCollapseToggle(false)}>
          <i className="las la-arrow-left"></i>
        </button>
        {getTransText("Help")}
      </h3>
      <NotificationContainer leaveTimeout={6000} />
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, values }) => (
          <form className="form login-form" onSubmit={handleSubmit}>
            <FormGroup className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="subject"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field form__form-group-input-wrap--error-above">
                      <input
                        {...input}
                        type="text"
                        className="react-select"
                        style={{ display: 'block' }}
                        placeholder={getTransText("Subject")}
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
            </FormGroup>
            <FormGroup className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="name"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field form__form-group-input-wrap--error-above">
                      <input
                        {...input}
                        type="text"
                        className="react-select"
                        style={{ display: 'block' }}
                        placeholder={getTransText("Name")}
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
            </FormGroup>
            <FormGroup className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="email"
                  validate={required}
                  initialValue={email}
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field form__form-group-input-wrap--error-above">
                      <input
                        {...input}
                        type="email"
                        className="react-select"
                        style={{ display: 'block' }}
                        placeholder={getTransText("Email")}
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
            </FormGroup>
            <FormGroup className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="description"
                  type="textarea"
                  validate={required}
                  initialValue={location?.state?.id && `About Quo (id: ${location?.state?.id}):\n`}
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field form__form-group-input-wrap--error-above">
                      <Input
                        {...input}
                        type="textarea"
                        style={{ display: 'block' }}
                        placeholder={getTransText("Descripe your issue here...")}
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
            </FormGroup>
            <ButtonToolbar className="btn-toolbar--center">
              <Button color="primary" type="submit">{getTransText("Submit")}</Button>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    </div>
  );
};


export default LanguageSettingForm;

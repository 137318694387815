import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import TopbarMenuLink from './TopbarMenuLink';
import getTransText from '~~/get-text/getTransText';
import TopbarLanguage from './TopbarLanguage';
// import PropTypes from 'prop-types';

const icon = `${process.env.PUBLIC_URL}/img/burger.svg`;

/* const TopbarSidebarButton = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => (
  <div>
    <button type="button" className="topbar__button topbar__button--desktop" onClick={changeSidebarVisibility}>
      <img src={icon} alt="" className="topbar__button-icon" />
    </button>
    <button type="button" className="topbar__button topbar__button--mobile" onClick={changeMobileSidebarVisibility}>
      <img src={icon} alt="" className="topbar__button-icon" />
    </button>
  </div>
); */

const TopbarSidebarButton = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const location = useLocation();

  return (
    <div className="topbar__right">
      {/* {!location.pathname.includes("log_in")
        && (
          <Link to={{ pathname: `/log_in` }}>
            <div className="btn btn-primary topbar__get-start-button">{getTransText("Get Started")}</div>
          </Link>
        )} */}

      <Link className="topbar__btn" to="/log_in">
        <div className="topbar__login">
          <span>{getTransText("Login")}</span>
        </div>
      </Link>
      <TopbarLanguage onClick={handleToggleCollapse} />
    </div>
  );
};



/* 
      {
        isCollapsed && (
          <button
            type="button"
            aria-label="button collapse"
            className="topbar__button topbar__button--desktop"
            onClick={handleToggleCollapse}
          />
        )
      }
      <Collapse isOpen={isCollapsed} className="topbar__info_menu-wrap">
<div className="topbar__info_menu">
  <button type="button" className="topbar__button topbar__button--desktop" onClick={handleToggleCollapse}>
    <i className="las la-times"></i>
  </button>
  <TopbarMenuLink title={getTransText("About QUOBal")} path="/l" onClick={handleToggleCollapse} />
  <TopbarMenuLink title={getTransText("Login")} path="/log_in" onClick={handleToggleCollapse} />
  <TopbarMenuLink title={getTransText("FAQ")} path="/FAQ" onClick={handleToggleCollapse} />
  <TopbarMenuLink title={getTransText("Terms and Condition")} path="/terms-and-condition" onClick={handleToggleCollapse} />
  <TopbarLanguage onClick={handleToggleCollapse} />
</div>
</Collapse> */
/* TopbarSidebarButton.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
}; */

export default TopbarSidebarButton;

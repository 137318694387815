import produce from "immer";
import React, { useState, useEffect } from "react";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import { useSelector } from 'react-redux';
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import en_US from "filepond/locale/en-en";
import ja from "filepond/locale/ja-ja";
import zh_TW from "filepond/locale/zh-tw";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { formatDate, PhotoFormatter } from '~~/table/index.jsx';
import { client, clientPublic } from '~/apiCalls/directusConfig';


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DropFilePond = ({
  fields,
  setFiles: setGalleryFiles,
  setData,
  setRowCount,
}) => {
  const [files, setFiles] = useState([]);
  const [token, setToken] = useState(null);
  const {
    langCode,
  } = useSelector(state => state.ui);
  const filePondLang = () => {
    switch (langCode) {
      case 'zh-TW':
        return zh_TW;
      case 'en-US':
        return en_US;
      case 'ja':
        return ja;
      default:
        return en_US;
    }
  };
  useEffect(() => {
    client.getToken().then((x) => setToken(x));
  }, []);

  return (
    <div className="App">
      {token && (
        <FilePond
          {...filePondLang()}
          files={files}
          allowReorder={true}
          allowMultiple={true}
          allowRevert={false}
          onupdatefiles={setFiles}
          server={{
            url: `${process.env.REACT_APP_API_URL}files`,
            process: {
              method: 'POST',
              headers: {
                "Authorization": `Bearer ${token}`,
              },
              onload: (response) => {
                const res = JSON.parse(response);
                if (setGalleryFiles) {
                  setGalleryFiles(produce((draft) => {
                    draft.unshift(res.data);
                  }));
                }
                if (fields) {
                  fields.unshift(res.data);
                }
                if (setData) {
                  const rowData = {
                    id: res.data.id,
                    preview: <PhotoFormatter file={res.data} />,
                    filename_download: res.data.filename_download,
                    filesize: `${Number(res.data.filesize / 1024 / 1024).toFixed(3)} KB`,
                    type: res.data.type,
                    uploaded_on: `${formatDate(res.data.uploaded_on)}`,
                  };
                  setData(produce((draft) => {
                    draft.unshift(rowData);
                  }));
                  setRowCount(produce((draft) => { draft += 1; return (draft); }));
                }
              },
              onerror: (response) => response.data,
              ondata: (formData) => {
                const a = new FormData();
                a.append('file_purpose', "private");
                formData.forEach((value, key) => a.append(key, value));
                return a;
              },
            },
          }}
          instantUpload={true}
        />
      )}
    </div>
  );
};

export default DropFilePond;

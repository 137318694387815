import React, { useEffect, useState, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import {
  useHistory
} from 'react-router-dom';
import {
  Button,
  ButtonToolbar,
  FormGroup,
  Input,
} from 'reactstrap';
import getTransText from '~~/get-text/getTransText';
import { createProductNameApi } from '~/apiCalls/marketplaceApi';


const ProfileContainer = () => {
  const history = useHistory();

  const onSubmit = async (values) => {
    const res = await createProductNameApi(values);
    console.log("test", res);
    if (res) {
      history.push(`/product_edit/${res.id}`);
    }
  };


  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          title: "",
        }}
        validate={values => {
          const errors = {};
          if (!values.title) {
            errors.title = getTransText("Please write your request");
          }
          return errors;
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <form className="form quo__form" onSubmit={handleSubmit}>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("marketplace.title.title")}</span>
              <Field
                name="title"
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <Input
                        {...input}
                        name="title"
                        type="text"
                        placeholder={getTransText("marketplace.title.placeholder")}
                        className="input-without-border-radius"
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit"><i className="icon-quo"></i> {getTransText("marketplace.create.new-product-name")}</Button>
            </ButtonToolbar>
          </form>
        )}
      </Form>
    </>
  );
};


export default ProfileContainer;

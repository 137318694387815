import { DateTime } from "luxon";
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Button, ButtonToolbar, Tooltip } from 'reactstrap';
import { acceptPending, rejectPending, quoCreateConversation } from '~/apiCalls/quoApi';
import { SocketContext } from "~/context/SocketContext";
import { apiCallBegan } from "~/store/api";
import CounterForm from '~~/counter_form/CounterForm';
import { roundUpHour } from '~~/datetime_render/FormatDate';
import getTransText from '~~/get-text/getTransText';
import ModalParent from '~~/modal_parent/ModalParent';

const PendingStatus = ({
  quo,
  isButton = true,
}) => {
  const dispatch = useDispatch();
  const { socket } = useContext(SocketContext);
  const [friendInfo, setFriendInfo] = useState(null);
  const [isSender, setIsSender] = useState(false);
  const {
    id,
  } = useSelector(state => state.user.userData);
  const [toggler, setToggler] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const annoymous = {
    avatar: "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae",
    username: getTransText("Anonymous"),
    random_no: quo.random_no,
  };
  const history = useHistory();
  const ref = useRef(null);
  const ref2 = useRef(null);
  const [rejectToggler, setRejectToggler] = useState(false);

  const switchRejectToggler = () => {
    setRejectToggler(!rejectToggler);
  };

  const switchToggler = () => {
    setToggler(!toggler);
  };

  const acceptClick = async (quoId) => {
    dispatch(
      apiCallBegan({
        axiosCall: acceptPending,
        data: quoId,
        // socketEmit: { socket, event: "sendMessage" },
        // onSuccess: chatAddedMessage.type,
      })
    );
    const conversationId = await quoCreateConversation(quo.receiver, quo.id);
    setTimeout(() => history.push(`/c/${conversationId}`), 100);
  };


  const rejectClick = async (quoId) => {
    dispatch(
      apiCallBegan({
        axiosCall: rejectPending,
        data: quoId,
        // socketEmit: { socket, event: "sendMessage" },
        // onSuccess: chatAddedMessage.type,
      })
    );
    const conversationId = await quoCreateConversation(quo.receiver, quo.id);
    setTimeout(() => history.push(`/c/${conversationId}`), 100);
  };

  // useEffect(async () => {
  //   if (id) {
  //     await capabilities()
  //       .then((responseC) => {
  //         // setCapbable(responseC);
  //       });
  //   }
  // }, [id]);


  useEffect(async () => {
    if ((quo.receiver === id)) {
      setFriendInfo(annoymous);
      setIsSender(false);
    } else {
      if (quo.receiver) {
        setFriendInfo({
          avatar: quo.avatar,
          username: quo.username,
          random_no: quo.random_no,
        });
        setIsSender(true);
      }
    }
    setIsLoaded(true);
  }, [quo.receiver, quo]);

  const counterButtonRenderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      // Render a completed state
      return ("");
    }
    // Render a countdown
    return (
      <div className="quo__btn__container">
        <button ref={ref2} type="button" className="quo__btn quo__btn-counter" onClick={() => setToggler(!toggler)}>
          {getTransText("Counter")}
        </button>
        <Tooltip
          placement="bottom"
          autohide={false}
          isOpen={true}
          target={ref2}
        >
          <div className="quo__timer">
            <span className="countdown_group">
              <span className="countdown_number">
                {days}
              </span>
              <span className="countdown_unit">
                {getTransText("D")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {hours}
              </span>
              <span className="countdown_unit">
                {getTransText("H")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {minutes}
              </span>
              <span className="countdown_unit">
                {getTransText("M")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {seconds}
              </span>
              <span className="countdown_unit">
                {getTransText("S")}
              </span>
            </span>
          </div>
        </Tooltip>
      </div>
    );
  };

  const acceptButtonRenderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      // Render a completed state
      return ("");
    }
    // Render a countdown

    return (
      <div className="quo__btn__container">
        <button type="button" ref={ref} className="quo__btn quo__btn-accept" onClick={() => acceptClick(quo.id)}>
          {getTransText("Accept")}
        </button>
        <Tooltip
          placement="bottom"
          autohide={false}
          isOpen={true}
          target={ref}
        >
          <div className="quo__timer">
            <span className="countdown_group">
              <span className="countdown_number">
                {days}
              </span>
              <span className="countdown_unit">
                {getTransText("D")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {hours}
              </span>
              <span className="countdown_unit">
                {getTransText("H")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {minutes}
              </span>
              <span className="countdown_unit">
                {getTransText("M")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {seconds}
              </span>
              <span className="countdown_unit">
                {getTransText("S")}
              </span>
            </span>
          </div>
        </Tooltip>
      </div>

    );
  };

  const renderButton = () => {
    if (isSender) {
      return ("");
    }

    if (quo.responsed) {
      return ("");
    }

    if (!isLoaded) {
      return ("");
    }
    if (!isButton) {
      return ("");
    }

    // if (!capbable) {
    //   return (
    //     <div className="quo__btns">
    //       <div className="center">{getTransText("Interested? Please ")}<Link to="/d" className="quo__btn quo__btn-counter">{getTransText("Connect to Stripe")}</Link>{getTransText(" to proceed!")} </div>
    //     </div>
    //   );
    // }
    const s = DateTime.fromSeconds(quo.charge_date, { zone: "utc" });

    return (
      <div className="quo__btns">
        <div className="quo__btn__container"><button type="button" className="quo__btn quo__btn-reject" onClick={() => switchRejectToggler()}>{getTransText("Reject")}</button></div>
        <Countdown date={roundUpHour(s.plus({ hours: 24 })).setZone("system")} renderer={counterButtonRenderer} />
        <Countdown date={roundUpHour(s.plus({ hours: 48 })).setZone("system")} renderer={acceptButtonRenderer} />
      </div>
    );
  };

  return (
    <>
      <ModalParent modalClassName="countering__modal" toggler={toggler} switchToggler={switchToggler}>
        <CounterForm friendInfo={friendInfo} quo={quo} switchToggler={switchToggler} />
      </ModalParent>
      <ModalParent className="confirm__modal" toggler={rejectToggler} switchToggler={switchRejectToggler}>
        <div className="modal__title">
          <h4 className="bold-text">{getTransText("QUO.status.reply.confirm.reject")}</h4>
        </div>
        <ButtonToolbar className="btn-toolbar--center">
          <Button color="primary" type="submit" onClick={() => rejectClick(quo.id)}>{getTransText("Confirm")}</Button>
        </ButtonToolbar>
      </ModalParent>
      <div className="quo__status">
        {
          isSender
            ? <div className="quo__status-notice">{getTransText("QUO.status.buyer.pending")}</div>
            : <div className="quo__status-notice">{getTransText("QUO.status.creator.pending")}</div>
        }
      </div>
      {renderButton()}
    </>
  );
};

export default PendingStatus;

import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import ChatCard from './components/ChatCard';

const Chat = () => (
  <Container className="chat__container">
    <Row>
      <Col xs={12}>
        <ChatCard />
      </Col>
    </Row>
  </Container>
);

export default Chat;

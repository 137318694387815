import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getTransText from '~~/get-text/getTransText';
import ModalParent from '~~/modal_parent/ModalParent';
import QuoSubmittedMedia from '~~/quo-media/QuoSubmittedMedia';

const CompleteStatus = ({
  quo,
  isButton = true,
}) => {
  const [friendInfo, setFriendInfo] = useState(null);
  const {
    id,
  } = useSelector(state => state.user.userData);
  const [toggler, setToggler] = useState(false);
  const annoymous = {
    avatar: "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae",
    username: getTransText("Anonymous"),
    random_no: quo.random_no,
  };
  const switchToggler = () => {
    setToggler(!toggler);
  };

  useEffect(async () => {
    let isCancelled = false;
    if ((quo.receiver === id)) {
      setFriendInfo(annoymous);
    } else {
      if (quo.receiver) {
        setFriendInfo({
          avatar: quo.avatar,
          username: quo.username,
          random_no: quo.random_no,
        });
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [quo.receiver]);

  return (
    <>
      <ModalParent toggler={toggler} switchToggler={switchToggler}>
        <QuoSubmittedMedia quoId={quo.id} submission={quo.submission} />
      </ModalParent>
      <div className="quo__status">
        <div className="quo__status-notice">{getTransText("QUO.status.buyer.completed")}</div>
        <button type="button" className="quo__btn" onClick={() => setToggler(!toggler)}>{getTransText("Files")}</button>
      </div>
    </>
  );
};

export default CompleteStatus;

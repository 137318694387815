import React from 'react';

const QuoSVG = ({ quoClass }) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.99 27.77" className={quoClass}>
      <path d={`m12.5,0C5.6,0,0,5.6,0,12.6c0,6.4,4.8,11.7,11,12.5v2.6l1.8-.3c7.1-1.3,
      12.2-7.5,12.2-14.8S19.4,0,12.5,0Zm1.3,24.2v-1.8l-2-.1c-5-.4-9-4.7-9-9.8,0-5.3,
      4.4-9.7,9.7-9.7s9.7,4.4,9.7,9.8-3.4,10-8.4,11.6Z`}
      />
    </svg>
  </>
);

export default QuoSVG;

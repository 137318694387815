import { DateTime } from "luxon";
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import { quoCreateConversation, acceptDeal } from '~/apiCalls/quoApi';
import { SocketContext } from "~/context/SocketContext";
import { apiCallBegan } from "~/store/api";
import { roundUpHour } from '~~/datetime_render/FormatDate';
import getTransText from '~~/get-text/getTransText';
import MatTable from '~~/MaterialTable/MatTable/';
import ModalParent from '~~/modal_parent/ModalParent';
import QuoSubmittedMedia from '~~/quo-media/QuoSubmittedMedia';

const DeliveredStatus = ({
  quo,
  isButton = true,
}) => {
  const dispatch = useDispatch();
  const { socket } = useContext(SocketContext);
  const [friendInfo, setFriendInfo] = useState(null);
  const ref = useRef(null);
  const [isSender, setIsSender] = useState(false);
  const {
    id,
  } = useSelector(state => state.user.userData);
  const [toggler, setToggler] = useState(false);
  const [togglerDnd, setTogglerDnd] = useState(false);
  const [clickResponsed, setClickResponsed] = useState(false);
  const [confirmToggler, setConfirmToggler] = useState(false);
  const history = useHistory();
  const annoymous = {
    avatar: "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae",
    username: getTransText("Anonymous"),
    random_no: quo.random_no,
  };


  const switchToggler = () => {
    setToggler(!toggler);
  };

  const switchTogglerDnd = () => {
    setTogglerDnd(!togglerDnd);
  };

  const switchConfirmToggler = () => {
    setConfirmToggler(!confirmToggler);
  };

  const acceptAndCloseTheDeal = async (quoId) => {
    dispatch(
      apiCallBegan({
        axiosCall: acceptDeal,
        data: quoId,
        // socketEmit: { socket, event: "sendMessage" },
        // onSuccess: chatAddedMessage.type,
      })
    );
    const conversationId = await quoCreateConversation(quo.receiver, quo.id);
    setTimeout(() => history.push(`/c/${conversationId}`), 100);
  };

  useEffect(async () => {
    if ((quo.receiver === id)) {
      setFriendInfo(annoymous);
      setIsSender(false);
    } else {
      if (quo.receiver) {
        setFriendInfo({
          avatar: quo.avatar,
          username: quo.username,
          random_no: quo.random_no,
        });
        setIsSender(true);
      }
    }
  }, [quo.receiver]);

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      // Render a completed state
      return ("");
    }
    // Render a countdown
    return (
      <>
        <button type="button" ref={ref} className="quo__btn quo__btn-submit" onClick={() => setTogglerDnd(!togglerDnd)}>
          {getTransText("Re-Submit")}
        </button>
        <Tooltip
          placement="bottom"
          autohide={false}
          isOpen={true}
          target={ref}
        >
          <div className="quo__timer">
            <span className="countdown_group">
              <span className="countdown_number">
                {days}
              </span>
              <span className="countdown_unit">
                {getTransText("D")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {hours}
              </span>
              <span className="countdown_unit">
                {getTransText("H")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {minutes}
              </span>
              <span className="countdown_unit">
                {getTransText("M")}
              </span>
            </span>
            <span className="countdown_group">
              <span className="countdown_number">
                {seconds}
              </span>
              <span className="countdown_unit">
                {getTransText("S")}
              </span>
            </span>
          </div>
        </Tooltip>
      </>
    );
  };

  const renderButton = () => {
    const s = DateTime.fromSeconds(quo.charge_date, { zone: "utc" });

    if (!isButton) {
      return ("");
    }

    if (quo.responsed) {
      return ("");
    }

    if (isSender) {
      return (
        <div className="quo__btns">
          <button type="button" className="quo__btn quo__btn-delivered" onClick={() => setConfirmToggler(!confirmToggler)}>{getTransText("Complete the QUO")}</button>
        </div>
      );
    }


    return (
      <div className="quo__btns">
        <Countdown className="quo__countdown" date={roundUpHour(s.plus({ hours: 168 })).setZone("system")} renderer={renderer} />
      </div>
    );
  };

  return (
    <>
      <ModalParent toggler={toggler} switchToggler={switchToggler}>
        <QuoSubmittedMedia quoId={quo.id} submission={quo.submission} />
      </ModalParent>
      <ModalParent toggler={togglerDnd} switchToggler={switchTogglerDnd}>
        <MatTable quo={quo} isFirstSubmission={false} />
      </ModalParent>
      <ModalParent className="confirm__modal" toggler={confirmToggler} switchToggler={switchConfirmToggler}>
        <h3 className="page-subhead subhead">
          {getTransText("Confirm to accept the files and finish the deal?")}
        </h3>
        <div className="btn-toolbar">
          <button type="button" className="btn btn-primary" onClick={() => acceptAndCloseTheDeal(quo.id)}>{getTransText("Accept")}</button>
          <button type="button" className="btn btn-secondary" onClick={() => setConfirmToggler(!confirmToggler)}>{getTransText("Cancel")}</button>
        </div>
      </ModalParent>
      {(quo.submission && quo.submission === 1)
        && (
          <div className="quo__status">
            {isSender
              ? (
                <>
                  <div className="quo__status-notice">
                    {/* <span className="quo__status-name">{UsernameRender(friendInfo?.username, quo.receiver === id)}</span> {quo.submission && quo.submission !== "1" && (getTransText(`Re-`))}{getTransText(" submitted the")} */}
                    {/* {quo.submission && quo.submission !== "1" && (getTransText(`Re-`))} */}
                    {getTransText("QUO.status.buyer.delivered")}
                  </div>
                  <button type="button" className="quo__btn" onClick={() => setToggler(!toggler)}>{getTransText("Files")}</button>
                </>
              )
              : (
                <>
                  <div className="quo__status-notice">
                    {getTransText("QUO.status.creator.delivered")}
                  </div>
                  <button type="button" className="quo__btn" onClick={() => setToggler(!toggler)}>{getTransText("Files")}</button>
                </>
              )}
          </div>
        )}
      {(quo.submission && quo.submission > 1)
        && (
          <div className="quo__status">
            {isSender
              ? (
                <>
                  <div className="quo__status-notice">
                    {/* <span className="quo__status-name">{UsernameRender(friendInfo?.username, quo.receiver === id)}</span> {quo.submission && quo.submission !== "1" && (getTransText(`Re-`))}{getTransText(" submitted the")} */}
                    {/* {quo.submission && quo.submission !== "1" && (getTransText(`Re-`))} */}
                    {getTransText("QUO.status.buyer.redelivered")}
                  </div>
                  <button type="button" className="quo__btn" onClick={() => setToggler(!toggler)}>{getTransText("Files")}</button>
                </>
              )
              : (
                <>
                  <div className="quo__status-notice">
                    {getTransText("QUO.status.creator.redelivered")}
                  </div>
                  <button type="button" className="quo__btn" onClick={() => setToggler(!toggler)}>{getTransText("Files")}</button>
                </>
              )}
          </div>
        )}
      {renderButton()}
    </>
  );
};

export default DeliveredStatus;

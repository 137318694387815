import React from 'react';
import { useSelector } from 'react-redux';
import Profile from "./Profile";

import {
  getProfileContents, getMembers,
} from '~/apiCalls/userApi';
import {
  getMembersPublic,
  getProfileContentsPublic,
} from '~/apiCalls/publicApi';

const ProfileContainer = () => {
  const {
    authenticated,
  } = useSelector(state => state.auth);

  return (
    <>
      {authenticated ? (
        <>
          <Profile getMembers={getMembers} getProfileContents={getProfileContents} />
        </>
      ) : (
        <>
          <div className="container__wrap nosidebar">
            <Profile getMembers={getMembersPublic} getProfileContents={getProfileContentsPublic} />
          </div>
        </>
      )}
    </>
  );
};


export default ProfileContainer;

import { client, clientPublic } from '~/apiCalls/directusConfig';



export const isSupportedCountryByIdPublic = async (shortcode) => {
  const res = await clientPublic.request(() => ({
    path: `/public-access/country-specs/${shortcode}`,
    method: "GET",
  }));
  return (res);
};

export const getSellerRatingPublic = async (username) => {
  try {
    const res = await clientPublic.request(() => ({
      path: `/public-access/seller-rating/${username}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    return null;
  }
};

export const getProfileContentsPublic = async (username) => {
  try {
    const res = await clientPublic.request(() => ({
      path: `/public-access/profile/${username}`,
      method: "POST",
    }));
    return (res[0]);
  } catch (err) {
    return null;
  }
};

export const getPromoteProfile = async () => {
  try {
    const res = await clientPublic.request(() => ({
      path: `/public-access/promoted-members`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    return null;
  }
};

export const getMembersPublic = async (username) => {
  try {
    const res = await clientPublic.request(() => ({
      path: `/public-access/members/${username}`,
      method: "POST",
    }));
    return (res);
  } catch (err) {
    return null;
  }
};

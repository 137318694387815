import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { useSelector, useDispatch } from 'react-redux';
import { changedLangCode } from '~/store/ui';

const gb = `${process.env.PUBLIC_URL}/img/language/gb.png`;
const tw = `${process.env.PUBLIC_URL}/img/language/fr.png`;
const jp = `${process.env.PUBLIC_URL}/img/language/de.png`;

const GbLng = () => (
  <span className="topbar__language-btn-title">
    {/* <img src={gb} alt="gb" /> */}
    <span>English</span>
  </span>
);

const TwLng = () => (
  <span className="topbar__language-btn-title">
    {/* <img src={tw} alt="tw" /> */}
    <span>中文</span>
  </span>
);

const JpLng = () => (
  <span className="topbar__language-btn-title">
    {/* <img src={jp} alt="jp" /> */}
    <span>日本語</span>
  </span>
);

const TopbarLanguage = ({ onClick }) => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [mainButtonContent, setMainButtonContent] = useState(<GbLng />);
  const {
    langCode,
  } = useSelector(state => state.ui);

  const toggleLanguage = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    switch (langCode) {
      case 'en-US':
        setMainButtonContent(<GbLng />);
        break;
      case 'zh-TW':
        setMainButtonContent(<TwLng />);
        break;
      case 'jp':
        setMainButtonContent(<JpLng />);
        break;
      default:
        setMainButtonContent(<GbLng />);
        break;
    }
  }, [langCode]);


  const changeLanguage = (lng) => {
    switch (lng) {
      case 'en':
        dispatch(changedLangCode("en-US"));
        setMainButtonContent(<GbLng />);
        toggleLanguage();
        onClick();
        break;
      case 'tw':
        dispatch(changedLangCode("zh-TW"));
        setMainButtonContent(<TwLng />);
        toggleLanguage();
        onClick();
        break;
      case 'jp':
        dispatch(changedLangCode("ja"));
        setMainButtonContent(<JpLng />);
        toggleLanguage();
        onClick();
        break;
      default:
        dispatch(changedLangCode("en-US"));
        setMainButtonContent(<GbLng />);
        break;
    }
  };

  return (
    <div className="topbar__collapse topbar__collapse--language">
      <button className="topbar__btn" type="button" onClick={toggleLanguage}>
        {mainButtonContent}
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="language button"
          onClick={toggleLanguage}
        />
      )}
      <Collapse
        isOpen={isCollapsed}
        className="topbar__collapse-content topbar__collapse-content--language"
      >
        <button
          className="topbar__language-btn"
          type="button"
          onClick={() => changeLanguage('en')}
        >
          <GbLng />
        </button>
        <button
          className="topbar__language-btn"
          type="button"
          onClick={() => changeLanguage('tw')}
        >
          <TwLng />
        </button>
        {/* <button
          className="topbar__language-btn"
          type="button"
          onClick={() => changeLanguage('jp')}
        >
          <JpLng />
        </button> */}
      </Collapse>
    </div>
  );
};

export default TopbarLanguage;

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagsInput from 'react-tagsinput';
import { Field } from 'react-final-form';
import {
  Row, Col, FormGroup, Input, Button,
} from 'reactstrap';
import AvatarEditor from "react-avatar-editor";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-lazy-load-image-component/src/effects/blur.css';
import AvatarRender from '~~/avatar_render';
import ModalParent from '~~/modal_parent/ModalParent';
import getTransText, { GetCountryName } from '~~/get-text/getTransText';
import { updateAvatar, editUserProfile } from '~/apiCalls/userApi';
import { apiCallBegan } from '~/store/api';
import {
  userProfileUpdated,
  userProfileUpdateError,
  userClearedError,
} from '~/store/user';


const ProfileSettingDetail = ({ setCollapseToggle, values }) => {
  const dispatch = useDispatch();
  const {
    id,
    avatar,
    random_no,
    email,
    location,
    description,
    username,
    tags,
  } = useSelector(state => state.user.userData);
  const [toggler, setToggler] = useState(false);
  const [changeProfileButton, setChangeProfileButton] = useState("Change Profile Picture");
  const {
    error,
    success,
  } = useSelector(state => state.user);
  const [errorMsg, setErrorMsg] = useState("");

  const switchToggler = () => {
    setToggler(!toggler);
  };

  const [image, setImage] = useState();
  const [scaleState, setSacleState] = useState(1);
  const editor = useRef(null);
  const Ava = (typeof (id) !== 'undefined' && id != null) ? AvatarRender(avatar, random_no) : "";

  const required = value => (value ? undefined : getTransText('Required'));
  const moreThan5 = value => (value.length < 5 ? getTransText('Please input more than 5 char.') : undefined);
  const noSpaceAllowed = value => (value.indexOf(' ') >= 0 ? getTransText('No space allowed') : undefined);
  const maxLength = value => (value.length > 30 ? getTransText('No more than 30 chars.') : undefined);
  const usernameOnlyContain = value => {
    const re = new RegExp("^(?:[a-zA-Z0-9]|([._])(?!\\1)){5,30}$");
    if (!re.test(value)) {
      return getTransText("contains only letters, numbers, periods and underscores.");
    }
    return undefined;
  };

  const composeValidators = (...validators) => value => validators.reduce((error2, validator) => error2 || validator(value), undefined);



  const ChangeProfilePictureClick = (e) => {
    setImage(e.target.files[0]);
    switchToggler();
  };

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setSacleState(scale);
  };

  const handleSave = async (e) => {
    e.currentTarget.disabled = true;
    setChangeProfileButton("Loading..");
    const dataUrl = editor.current.getImage().toDataURL();
    const result = await fetch(dataUrl);
    const blob = await result.blob();
    const formData = new FormData();
    formData.append('title', `${username}-avatar`);
    formData.append('file_purpose', "avatar");
    formData.append('file', blob, image.name);
    const returnAvatarId = await updateAvatar(formData);
    if (returnAvatarId) {
      switchToggler();
    }
  };


  const onSubmit = (submitValues) => {
    dispatch(apiCallBegan({
      axiosCall: editUserProfile,
      data: submitValues,
      onSuccess: userProfileUpdated.type,
      onError: userProfileUpdateError.type,
    }));
  };

  useEffect(() => {
    if (error && error !== "") {
      if (error.errors[0].message.includes('unique')) {
        setErrorMsg(getTransText("This username has been taken."));
      }
    }
  }, [error]);


  useEffect(() => {
    if (success !== "" && success !== "hydrated") {
      NotificationManager.info(getTransText(success));
    }
  }, [success]);


  return (
    <>
      <ModalParent className="account_setting_button-cropper" toggler={toggler} switchToggler={switchToggler}>
        <AvatarEditor
          ref={editor}
          scale={parseFloat(scaleState)}
          width={150}
          height={150}
          rotate={0}
          border={25}
          borderRadius={100}
          image={image}
          className="editor-canvas"
          crossOrigin="anonymous"
        />
        <input
          name="scale"
          type="range"
          onChange={handleScale}
          min="1"
          max="2"
          step="0.01"
          defaultValue="1"
        />
        <Button onClick={handleSave}>{changeProfileButton}</Button>
      </ModalParent>
      <div className="profile__setting__top">
        <Row>
          <Col md={4}>
            <FormGroup className="form__form-group">
              <div className="form__edit_avatar">
                <label className="click_to_change_profile" htmlFor="uploadAvatarLabel">
                  <LazyLoadImage src={Ava} alt="avatar" effect="blur" />
                  <input type="file" id="uploadAvatarLabel" className="uploadAvatar" style={{ display: 'none' }} onChange={ChangeProfilePictureClick} accept=".jpg,.jpeg,.png" />
                  <i className="las la-edit"></i>
                </label>
              </div>
            </FormGroup>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("E-mail")}</span>
              <div className="form__form-group-field">
                {email}
              </div>
            </FormGroup>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("Country")}</span>
              <div className="form__form-group-field">
                {GetCountryName(location)}
              </div>
            </FormGroup>
          </Col>
          <Col md={8}>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("Username")}</span>
              <Field
                name="username"
                validate={composeValidators(required, moreThan5, noSpaceAllowed, maxLength, usernameOnlyContain)}
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <Input
                        {...input}
                        name="username"
                        type="text"
                        placeholder={getTransText("Name")}
                        className="input-without-border-radius"
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                      {errorMsg && <span className="form__form-group-error">{errorMsg}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("Tags about you")}{getTransText(" (To make you easier to be found)")}</span>
              <Field
                name="tags"
                allowNull={true}
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      {/* <Input
                        {...input}
                        name="tags"
                        type="text"
                        placeholder={getTransText("tag1,tag2,tag3")}
                        className="input-without-border-radius"
                      /> */}
                      <TagsInput
                        className="form-control tag-input"
                        {...input}
                        maxTags={3}
                        onlyUnique={true}
                        inputProps={{ placeholder: getTransText("Press Enter to create tag") }}
                      />
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                      <span className="form__form-group-description">({getTransText("Max. 3 Tags")})</span>
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
            <FormGroup className="form__form-group">
              <span className="form__form-group-label">{getTransText("Description")}</span>
              <Field
                name="description"
                parse={x => x}
                allowNull={true}
              >
                {({ input, meta }) => (
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <Input
                        {...input}
                        name="description"
                        type="textarea"
                        placeholder={getTransText("Write something about you...")}
                        className="input-without-border-radius"
                      />
                      {/* <span className="form__form-group-description">{values.description?.length || 0} / 250</span> */}
                      {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                    </div>
                  </div>
                )}
              </Field>
            </FormGroup>
          </Col>
        </Row>
      </div>

    </>
  );
};

export default ProfileSettingDetail;

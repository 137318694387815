import React from 'react';
import {
  Modal,
} from 'reactstrap';

const ModalParent = function ModalParent(props) {
  const { 
    children, 
    toggler, 
    switchToggler, 
    className,
    onClosed,
    modalClassName,
  } = props;
  return (
    <Modal className={className} isOpen={toggler} toggle={() => switchToggler()} onClosed={onClosed} modalClassName={modalClassName}>
      <button className="modal__close-btn" aria-label="modal__close-btn" type="button" onClick={() => switchToggler()}><i className="las la-times"></i></button>
      {children}
    </Modal>
  );
};
export default ModalParent;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button
} from 'reactstrap';
import { isRatedApi, ratingApi } from '~/apiCalls/quoApi';
import getTransText from '~~/get-text/getTransText';

const RatingStatus = ({
  quo,
  isButton = true,
}) => {
  const [friendInfo, setFriendInfo] = useState(null);
  const [isSender, setIsSender] = useState(false);
  const [isRated, setIsRated] = useState(null);
  const [clickResponsed, setClickResponsed] = useState(false);
  const {
    id,
  } = useSelector(state => state.user.userData);
  const annoymous = {
    avatar: "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae",
    username: getTransText("Anonymous"),
    random_no: quo.random_no,
  };
  useEffect(async () => {
    if ((quo.receiver === id)) {
      setFriendInfo(annoymous);
      setIsSender(true);
      await isRatedApi(quo?.id, "seller")
        .then((r) => {
          setIsRated(r);
        });
    } else {
      if (quo.receiver) {
        setFriendInfo({
          avatar: quo.avatar,
          username: quo.username,
          random_no: quo.random_no,
        });
        setIsSender(false);
        await isRatedApi(quo?.id, "buyer")
          .then((r) => {
            setIsRated(r);
          });
      }
    }
  }, [quo.receiver]);

  const rating = async (response, target) => {
    await ratingApi(quo.id, response, target)
      .then((res) => {
        setClickResponsed(true);
      });
  };

  const renderButton = () => {
    if (!isButton) {
      return ("");
    }

    if (isSender) {
      if (clickResponsed) {
        return (
          <div className="quo__status-notice">
            {getTransText("You have rated to Buyer")}
          </div>
        );
      }
      if (isRated?.isExist) {
        return (
          <div className="quo__status-notice">
            {getTransText("You have rated to Buyer")}
          </div>
        );
      }

      return (
        <div className="quo__status-notice">{getTransText("Please rate the buyer")}
          <Button
            type="button"
            color="primary"
            outline
            className="control-btn btn-rate_good"
            onClick={() => rating(true, "buyer")}
          >
            <i className="las la-thumbs-up"></i>
          </Button>
          <Button
            type="button"
            color="primary"
            outline
            className="control-btn btn-rate_bad"
            onClick={() => rating(false, "buyer")}
          >
            <i className="las la-thumbs-down"></i>
          </Button>
        </div>
      );
    }



    if (clickResponsed) {
      return (
        <div className="quo__status-notice">
          {getTransText("You have rated to Seller")}
        </div>
      );
    }

    if (isRated?.isExist) {
      return (
        <div className="quo__status-notice">
          {getTransText("You have rated to Seller")}
        </div>
      );
    }

    return (
      <div className="quo__status-notice">{getTransText("Please rate the seller")}
        <button
          type="button"
          className="btn control-btn btn-rate_good"
          onClick={() => rating(true, "seller")}
        >
          <i className="las la-thumbs-up"></i>
        </button>
        <button
          type="button"
          className="btn control-btn btn-rate_bad"
          onClick={() => rating(false, "seller")}
        >
          <i className="las la-thumbs-down"></i>
        </button>
      </div>
    );
  };


  return (
    <>
      <div className="quo__status">
        <div className="quo__status-notice">{getTransText("QUO.status.buyer.rating")}</div>
      </div>
      <div className="quo__status">
        {renderButton()}
      </div>
    </>
  );
};

export default RatingStatus;

import React from 'react';
import { Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import ProductItems from '~~/catalog/ProductItems';

const CatalogItems = () => {
  const { productList } = useSelector(state => state.marketplace);

  return (
    <Col md={12} lg={12}>
      {productList?.data && (<ProductItems items={productList.data} />)}
    </Col>
  );
};

export default CatalogItems;

import React, { useEffect, useState } from 'react';
import { Pagination } from "react-pagination-bar";
import ReactGA from 'react-ga4';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Link } from "react-router-dom";
import { Col, Container, Row, Card, CardBody, Button, ButtonToolbar, UncontrolledAlert } from 'reactstrap';
// import ProfileMain from '../../shared/components/profile/ProfileMain';
// import ProfileCalendar from './components/ProfileCalendar';
import { getOpenQuos, getOpenQuoUserClickedList, getOpenQuosPublic } from '~/apiCalls/openCallApi';
import getTransText from '~~/get-text/getTransText';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';
import { apiCallBegan } from '~/store/api';
import ModalParent from '~~/modal_parent/ModalParent';
import { getOpenCallList, getUserClickedList } from '~/store/opencall';
import QuoFormWoSeller from '~~/quo_form/QuoFormWoSeller';
import QuoTabs from './components/QuoTabs';
import SampleSearchingData from "./SampleSearchingData.json";
import ModalWarning from '~~/ModalWarning';
import { accepted18 } from '~/store/ui';

const OpenCallContainer = () => {
  const history = useHistory();
  const [quos, setQuos] = useState(undefined);
  const [togglerPlaceQuo, setTogglerPlaceQuo] = useState(false);
  const dispatch = useDispatch();
  const {
    isAccepted18,
  } = useSelector(state => state.ui);

  const [loaded, setLoaded] = useState(false);
  const {
    list,
  } = useSelector(state => state.opencall);
  const {
    authenticated,
  } = useSelector(state => state.auth);

  useEffect(async () => {
    if (authenticated) {
      dispatch(apiCallBegan({
        axiosCall: getOpenQuos,
        data: 1,
        onSuccess: getOpenCallList.type,
      }));
    } else {
      dispatch(apiCallBegan({
        axiosCall: getOpenQuosPublic,
        data: 1,
        onSuccess: getOpenCallList.type,
      }));
    }
    setLoaded(true);
  }, [authenticated]);

  useEffect(() => {
    setQuos(list);
  }, [list]);

  const switchTogglerPlaceQuo = () => {
    if (authenticated) {
      setTogglerPlaceQuo(!togglerPlaceQuo);
    } else {
      ReactGA.event({
        category: 'Pre-Registration Engagement',
        action: "Unauth Pick me Button click",
      });
      history.push('/log_in');
    }
  };

  return (
    <>
      {!isAccepted18 && (
        <>
          <ModalWarning />
        </>
      )}
      <ModalParent modalClassName="quo__modal" toggler={togglerPlaceQuo} switchToggler={switchTogglerPlaceQuo}>
        <QuoFormWoSeller />
      </ModalParent>
      <Container className="open__call__container">
        {loaded
          ? (
            <Row>
              <Col>
                <div className="quobal__logo__container">
                  <Link className="quobal__logo" to="/buying" />
                  <Link className="quobal__logo__text" to="/buying">{getTransText("About QUOBal")}</Link>
                </div>
                {/* <div className="open__call-filter">
                  <Button
                    color="primary"
                  >{`${getTransText("Latest")}`}
                  </Button>
                  <Button
                    color="primary"
                  >{`${getTransText("Popular")}`}
                  </Button>
                </div> */}
                <QuoTabs quos={quos} />
                <div className="open__call-flow">
                  <UncontrolledAlert color="success">
                    <i className="las la-shield-alt"></i>
                    <p className="bold-text">{getTransText("opencall.buying-selling-board.moneyback.title")}</p>
                    <p>{getTransText("opencall.buying-selling-board.moneyback.description")}</p>
                  </UncontrolledAlert>
                  <Button
                    color="primary"
                    onClick={switchTogglerPlaceQuo}
                  >
                    <i className="icon-quo"></i>{getTransText("opencall.buying-selling-board.quopanel.placeAQuo")}
                  </Button>
                </div>
              </Col>
            </Row>
          )
          : (
            <LoadingLogo />
          )}
      </Container>
    </>
  );
};
export default OpenCallContainer;

/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useContext,
  useEffect,
  useRef
} from "react";
import { useSelector, useDispatch } from "react-redux";
import Lottie from 'react-lottie';
import { useParams, Prompt } from "react-router-dom";
import ChatBubble from "./ChatBubble";
import ChatField from "./ChatField";
import ChatTopbar from "./ChatTopbar";
import {
  getMessages,
  sendMessage,
  getTotalUnreadNumberForMenu,
  updateReadTime,
} from "~/apiCalls/chatApi";
import {
  uploadFile,
  getPermissionId,
} from "~/apiCalls/userApi";
import getTransText from "~~/get-text/getTransText";
import { apiCallBegan } from "~/store/api";
import {
  chatStartFetchingMessage,
  chatGetMessage,
  chatToggleActiveTab,
  chatToggleActiveChat,
  chatAddMessagBegan,
  chatAddedMessage,
  chatLeaveChat,
  chatFetchMoreMessageBagan,
  chatFetchMoreMessageSuccess,
  chatGetUnRead,
} from "~/store/chats";
import { SocketContext } from "~/context/SocketContext";
import animationData from './loading-dots.json';

const ChatDialog = ({ dialogClass }) => {
  const dispatch = useDispatch();
  const { socket } = useContext(SocketContext);
  const { userData } = useSelector((state) => state.user);
  const {
    messages,
    chatMate,
    isMessageAdd,
    fetched,
    activeTab,
    pagination,
  } = useSelector((state) => state.chats);
  const [newMessages, setNewMessages] = useState("");
  const [memberB, setMemberB] = useState(null);
  const [friendInfo, setFriendInfo] = useState(null);
  const { conversation_id } = useParams();
  const [totalScrollHeight, setTotalScrollHeight] = useState();
  const [loaded, setLoaded] = useState(false);

  const option = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const messagesEnd = useRef();

  const scrollToBottom = (scrollHeight = 0) => {
    if (messagesEnd && messagesEnd.current) {
      messagesEnd.current.scrollTop = messagesEnd.current.scrollHeight - scrollHeight;
    }
  };

  const stickyBottom = (pHeight, pTop, pClient) => {
    if (pHeight - pTop - pClient <= 1) {
      scrollToBottom();
    }
  };

  useEffect(() => {
    if (conversation_id) {
      dispatch(chatToggleActiveChat(conversation_id));

      const data = {
        conversation_id,
        page: 1,
      };
      dispatch(
        apiCallBegan({
          axiosCall: getMessages,
          data,
          onStart: chatStartFetchingMessage.type,
          onSuccess: chatGetMessage.type,
        })
      );
    }
    scrollToBottom();
  }, [conversation_id]);

  useEffect(async () => {
    if (chatMate) {
      if (chatMate.memberb_id === userData?.id) {
        dispatch(chatToggleActiveTab("1"));
      } else {
        dispatch(chatToggleActiveTab("2"));
      }
      dispatch(
        apiCallBegan({
          axiosCall: updateReadTime,
          data: conversation_id,
        })
      );

      setMemberB(chatMate);
      setFriendInfo(
        chatMate.memberb_id === userData?.id
          ? {
            avatar: "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae",
            username: getTransText("Anonymous"),
            random_no: chatMate.random_no,
          }
          : {
            avatar: chatMate.avatar,
            username: chatMate.username,
            random_no: chatMate.random_no,
          }
      );
      scrollToBottom();
    }
  }, [chatMate]);


  useEffect(() => {
    if (fetched === "start") {
      setTotalScrollHeight(messagesEnd.current.scrollHeight);
    }
    if (fetched === "done") {
      messagesEnd.current.scrollTop = messagesEnd.current.scrollHeight - totalScrollHeight;
    }
  }, [fetched]);

  useEffect(() => {
    if (isMessageAdd === "done" || isMessageAdd === "start") {
      scrollToBottom();
    }
  }, [isMessageAdd]);

  const leaveChatRoom = () => {
    if (chatMate) {
      dispatch(chatToggleActiveChat(null));
      dispatch(
        apiCallBegan({
          axiosCall: updateReadTime,
          data: conversation_id,
        })
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newMessages !== "") {
      const message = {
        sender_id: userData?.id,
        q_message: newMessages,
        conversation_id,
      };
      const { clientHeight, scrollHeight, scrollTop } = messagesEnd.current;
      try {
        dispatch(
          apiCallBegan({
            axiosCall: sendMessage,
            data: message,
            onStart: chatAddMessagBegan.type,
            onSuccess: chatAddedMessage.type,
          })
        );
        setNewMessages("");
        stickyBottom(scrollHeight, scrollTop, clientHeight);
      } catch (err) {
        // console.log(err);
      }
    }
  };

  const sendFileMessageClick = async (e) => {
    const fileInput = e.target;
    const fileType = fileInput?.files[0].type;
    const formData2 = new FormData();
    let msgText = "";
    if (fileType.includes("video")) {
      msgText = "Video File";
    } else if (fileType.includes("image")) {
      msgText = "Image File";
    } else {
      msgText = "File";
    }
    formData2.append('title', `${new Date().toISOString().slice(0, 19).replace('T', ' ')}-msg`);
    if (fileType.includes("video")) {
      formData2.append('file_purpose', "message-Video");
    } else if (fileType.includes("image")) {
      formData2.append('file_purpose', "message-Image");
    } else {
      formData2.append('file_purpose', "message-Other");
    }
    formData2.append('conversation_id', conversation_id);
    formData2.append('file', fileInput?.files[0]);
    dispatch(chatAddMessagBegan());
    const returnFileContent = await uploadFile(formData2);
    const returnPermissionId = await getPermissionId(returnFileContent.id);
    const isSentBox = (chatMate.memberb_id !== userData?.id);
    const newMessageArray = {
      conversation_id,
      id: returnPermissionId.msgId,
      date_created: returnFileContent.uploaded_on,
      sender_id: returnFileContent.uploaded_by,
      q_message: msgText,
      message_type: "file",
      file_permission_id: returnPermissionId.id,
      username: friendInfo.username,
      avatar: friendInfo.avatar,
      random_no: chatMate.random_no,
      isSentBox,
      memberb_id: friendInfo.memberb_id,
    };
    dispatch(chatAddedMessage(newMessageArray));
  };

  const wraperstyle = {
    textAlign: "left",
    height: "100%",
    overflowY: "auto",
  };


  const fetchMessage = async () => {
    if (pagination && pagination.current_page) {
      const fetchDataInput = {
        conversation_id,
        page: pagination.current_page + 1,
      };
      dispatch(
        apiCallBegan({
          axiosCall: getMessages,
          data: fetchDataInput,
          onStart: chatFetchMoreMessageBagan.type,
          onSuccess: chatFetchMoreMessageSuccess.type,
        })
      );
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    const lastScrollTop = scrollHeight - scrollTop;

    const top = Math.round(scrollTop) === 0;
    if (top && scrollHeight > scrollTop) {
      if (pagination.last_page > pagination.current_page) {
        fetchMessage();
      }
    }
  };

  return (
    <div className={dialogClass}>
      <ChatTopbar friendInfo={friendInfo} messages={messages} />
      <div className="chat__dialog">
        {fetched === "start" && (
          <div className="loadingio-spinner-rolling-h0gdcpc6y5q">
            <div className="ldio-vbm0w6sebpp">
              <div>
              </div>
            </div>
          </div>
        )}
        <div
          className="chat__scroll"
          css={wraperstyle}
          ref={messagesEnd}
          onScroll={handleScroll}
        >
          <div className="chat__dialog-messages-wrap">
            {messages === null || messages?.length === 0 ? (
              <div className="chat__dialog-messages">
                <div className="chat__dialog-messages-empty">
                  <p>No messages</p>
                </div>
              </div>
            ) : (
              <>
                <div className="chat__dialog-messages">
                  {messages && messages?.map((item) => (
                    <ChatBubble
                      key={item.id}
                      memberB={memberB}
                      friendInfo={friendInfo}
                      message={item}
                    />
                  ))}
                  {isMessageAdd === "start" && (
                    <div className="chat__bubble chat__bubble--active">
                      <div className="chat__bubble-message-wrap">
                        <div id="sending">
                          <Lottie
                            options={option}
                            isClickToPauseDisabled={true}
                            width={40}
                            height={40}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

              </>
            )}
          </div>
        </div>
      </div>
      <ChatField
        handleSubmit={handleSubmit}
        sendFileMessageClick={sendFileMessageClick}
        newMessages={newMessages}
        setNewMessages={setNewMessages}
      />
      <Prompt
        message={() => {
          leaveChatRoom();
        }}
      />
    </div>
  );
};

export default ChatDialog;

import { apiCallBegan, apiCallFailed, apiCallSuccess } from '../api';

const apiMiddleware = store => next => async action => {
  const { dispatch } = store;
  if (action.type !== apiCallBegan.type) return next(action);
  const {
    axiosCall,
    directusCall,
    data,
    socketEmit,
    onStart,
    onSuccess,
    onError,
  } = action.payload;
  next(action);
  if (onStart) dispatch({ type: onStart });
  try {
    let input = null;
    if (data) input = data;
    let response;
    if (axiosCall) {
      response = await axiosCall(input);
    } else {
      response = await directusCall(input);
    }
    const payloadReturn = response?.data || response;
    // if (socketEmit) {
    //   const { socket, event } = socketEmit;
    //   socket.emit(event, payloadReturn);
    // }
    if (onSuccess) dispatch({ type: onSuccess, payload: payloadReturn });
    dispatch(apiCallSuccess(payloadReturn));
  } catch (error) {
    dispatch(apiCallFailed(error));
    if (onError) dispatch({ type: onError, payload: error });
  }
};

export default apiMiddleware;

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
// import CloseIcon from 'mdi-react/CloseIcon';
// import MinusIcon from 'mdi-react/MinusIcon';
// import AutorenewIcon from 'mdi-react/AutorenewIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import QuoDetail from '~~/quo-details/QuoDetail';
import SearchingStatus from '~~/status_panel/SearchingStatus';
import getTransText from '~~/get-text/getTransText';
import { getOpenQuoPickedListCountById } from '~/apiCalls/openCallApi';
import formatDate from '~~/datetime_render/FormatDate';

const QuoPanel = ({
  quo,
  capbable,
}) => {
  const [interestedCount, setInterestedCount] = useState();

  useEffect(async () => {
    if (quo) {
      await getOpenQuoPickedListCountById(quo.id)
        .then((response) => {
          if (response && Object.keys(response).length > 0) {
            setInterestedCount(response[0]);
          }
        });
    }
  }, [quo]);
  return (
    <Card>
      <CardBody className="panel__body quo__body quo__body-searching">
        <div className="panel__refresh"><LoadingIcon /></div>
        <div className="panel__top">
          <div className="quo__start">{formatDate(quo.date_created)}</div>
          <div className="quo__inbox">
            {/* asdak */}
          </div>
          <div className="quo__more">
            <i className="las la-angle-right"></i>
          </div>
        </div>
        <QuoDetail quo={quo} />
        <div className="opencall-enroll">
          <span>
            {interestedCount?.liked}
          </span>
          <span>
            {`${getTransText("opencall.buying-selling-board.quopanel.enroll")}`}
          </span>
        </div>
        <SearchingStatus quo={quo} />
        <Link className="quo__body__link" to={`/oq/${quo.id}`}></Link>
      </CardBody>
    </Card>
  );
};

export default QuoPanel;

import EyeIcon from 'mdi-react/EyeIcon';
import React, { useState } from 'react';
import AuthCode from 'react-auth-code-input';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, FormGroup, Input } from 'reactstrap';
import getTransText from '~~/get-text/getTransText';

const errorMsgRender = (state) => {
  const { auth } = state;
  let error = '';
  try {
    if (auth.error) {
      if (auth.error.response.status === 401 || auth.error.response.status === 403) {
        if (auth.error.errors[0].message.includes('credentials')) {
          error = getTransText("Invaild Email or Password.");
        }
        if (auth.error.errors[0].message.includes('suspended')) {
          error = getTransText("User suspended or deleted.");
        }
        if (auth.error.errors[0].message.includes('Invalid provider.')) {
          error = getTransText("This email is registered with external provider.");
        }
        if (auth.error.errors[0].message.includes('OTP')) {
          error = getTransText(auth.error.errors[0].message);
        }
      }
    }
  } catch (e) {
    console.log("error", e);
  }

  return ({
    isFetching: auth.isFetching,
    errorMsg: error,
  });
};

const LogInForm = ({
  onSubmit, reason, errorMsg = '', isFetching, payloadData = '',
}) => {
  const history = useHistory();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const required = value => (value ? undefined : getTransText('Required'));
  const mustBeNumber = value => (value.length < 8 ? getTransText('More than 8 digits required') : undefined);
  const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);
  const handleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, values }) => (
        <form className="form login-form" onSubmit={handleSubmit}>
          {errorMsg && !errorMsg.includes("OTP") && <div className="form__form-group-error">{errorMsg}</div>}
          {!errorMsg && reason && <div className="form__form-group-error">{reason}</div>}
          <FormGroup className="form__form-group" controlid="formBasicEmail">
            <div className="form__form-group-field form__form-group-input-wrap--error-above">
              <div className="form__form-group-icon">
                <i className="las la-at"></i>
              </div>
              <Field
                name="email"
                type="email"
                className="input-without-border-radius"
                validate={composeValidators(required, mustBeNumber)}
              >
                {({ input, meta }) => (
                  <>
                    <Input {...input} placeholder="example@gmail.com" />
                    {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                  </>
                )}
              </Field>
            </div>
          </FormGroup>
          <FormGroup className="form__form-group" controlid="formBasicEmail">
            <Field
              name="password"
              className="input-without-border-radius"
              validate={composeValidators(required, mustBeNumber)}
            >
              {({ input, meta }) => (
                <div className="form__form-group-field form__form-group-input-wrap--error-above">
                  <div className="form__form-group-icon">
                    <i className="las la-lock"></i>
                  </div>
                  <Input {...input} type={isPasswordShown ? "text" : "password"} placeholder={getTransText("Password")} />
                  <button
                    className={`form__form-group-button${isPasswordShown ? ' active' : ''}`}
                    onClick={() => handleShowPassword()}
                    type="button"
                  ><EyeIcon />
                  </button>
                  {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                </div>
              )}
            </Field>
          </FormGroup>
          {(errorMsg.includes('OTP'))
            && (
              <FormGroup className="form__form-group" controlid="formBasicEmail">
                <Field
                  name="otp"
                  validate={composeValidators(required)}
                >
                  {({ input, meta }) => (
                    <div>
                      <span className="form__form-group-label">{getTransText("One Time Password")}</span>
                      <AuthCode {...input} allowedCharacters="numeric" containerClassName="otp__container" inputClassName="otp__input" />
                    </div>
                  )}
                </Field>
              </FormGroup>
            )}
          <p className="account__links">
            <Link
              to={{
                pathname: "/forget",
              }}
            >
              {getTransText("forget password?")}
            </Link>
          </p>
          <div className="account__btns">
            <Button className="btn btn-primary account__btn account__btn--small btn-login" type="submit" disabled={submitting}>
              {isFetching ? `${getTransText("Loading")}...` : getTransText("Login")}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
export default connect(errorMsgRender)(LogInForm);

// action
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";

export const buyerLoginBeforeOutreachPayment = createAction("redirect/BuyerLoginBeforeOutreachPayment");
export const buyerLoginBeforePayment = createAction("redirect/BuyerLoginBeforePayment");
export const creatorLoginBeforeAcceptingInviting = createAction("redirect/CreatorLoginBeforeAcceptingInviting");
export const creatorLoginBeforeReceivingOutreachPayment = createAction("redirect/CreatorLoginBeforeReceivingOutreachPayment");
export const creatorLoginBeforeBuyingSellingPick = createAction("redirect/creatorLoginBeforeBuyingSellingPick");
export const loggingOutreachPayment = createAction("redirect/LoggingOutreachPayment");

// reducer
const initialState = {
  type: "",
  quoId: "",
  action: "",
  role: "",
};

const redirectReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(buyerLoginBeforeOutreachPayment.type, (state, action) => ({
      type: "outreach",
      quoId: action.payload,
      action: "redirect login",
      role: "buyer",
    }))
    .addCase(buyerLoginBeforePayment.type, (state, action) => ({
      type: "payment",
      quoId: action.payload,
      action: "redirect login",
      role: "buyer",
    }))
    .addCase(creatorLoginBeforeAcceptingInviting.type, (state, action) => ({
      type: "outreach",
      quoId: action.payload,
      action: "redirect login",
      role: "creator",
    }))
    .addCase(creatorLoginBeforeReceivingOutreachPayment.type, (state, action) => ({
      type: "outreach",
      quoId: action.payload,
      action: "redirect login",
      role: "creator",
    }))
    .addCase(creatorLoginBeforeBuyingSellingPick.type, (state, action) => ({
      type: "buyingselling",
      quoId: action.payload,
      action: "redirect login",
      role: "creator",
    }))
    .addCase(loggingOutreachPayment.type, (state, action) => ({
      ...state,
      type: "outreach",
      action: "logged",
    }));
});

export default redirectReducer;
// export const getAuthStore = createSelector();

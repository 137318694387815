import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Button } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import TopbarMenuLink from './TopbarMenuLink';
import getTransText from '~~/get-text/getTransText';
import TopbarLanguage from './TopbarLanguage';
import BurgerSVG from '~~/burgerSVG/';

// import PropTypes from 'prop-types';

const icon = `${process.env.PUBLIC_URL}/img/burger.svg`;



/* const TopbarSidebarButton = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => (
  <div>
    <button type="button" className="topbar__button topbar__button--desktop" onClick={changeSidebarVisibility}>
      <img src={icon} alt="" className="topbar__button-icon" />
    </button>
    <button type="button" className="topbar__button topbar__button--mobile" onClick={changeMobileSidebarVisibility}>
      <img src={icon} alt="" className="topbar__button-icon" />
    </button>
  </div>
); */

const TopbarSidebarButton = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const location = useLocation();
  const {
    isFetching,
    authenticated,
    refreshStatus,
    error,
  } = useSelector(state => state.auth);

  return (
    <div className="topbar__right">
      {
        !isCollapsed && (
          <button
            type="button"
            aria-label="button collapse"
            className="topbar__button topbar__button--desktop"
            onClick={handleToggleCollapse}
          >
            <img src={icon} alt="" className="topbar__button-icon" />
            {/* <BurgerSVG /> */}
          </button>
        )
      }

      <div className="quobal__logo__container">
        <Link className="quobal__logo" to="/buying" />
        <Link className="quobal__logo__text" to="/buying">{getTransText("About QUOBal")}</Link>
      </div>

      <Collapse isOpen={isCollapsed} className="topbar__info_menu-wrap">
        <div className="topbar__info_menu">
          <button type="button" className="topbar__button topbar__button--desktop" onClick={handleToggleCollapse}>
            <i className="las la-times"></i>
          </button>
          <TopbarLanguage onClick={handleToggleCollapse} />
          <TopbarMenuLink title={getTransText("landing.menu.buyingselling")} path="/" onClick={handleToggleCollapse} />
          <TopbarMenuLink title={getTransText("landing.menu.buyer")} path="/buying" onClick={handleToggleCollapse} />
          <TopbarMenuLink title={getTransText("landing.menu.seller")} path="/selling" onClick={handleToggleCollapse} />
          <TopbarMenuLink title={getTransText("FAQ")} path="/FAQ" onClick={handleToggleCollapse} />
          <TopbarMenuLink title={getTransText("Terms and Condition")} path="/terms-and-condition" onClick={handleToggleCollapse} />
          {!authenticated && <Link className="btn btn-primary btn-login" to="/log_in" onClick={handleToggleCollapse}>{getTransText("Login")}</Link>}
        </div>
      </Collapse>
    </div>
  );
};


/* TopbarSidebarButton.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
}; */

export default TopbarSidebarButton;

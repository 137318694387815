import store from '../../store/store';
/* eslint-disable global-require */

export const GetTransText = (Atitle) => {
  const { webtext, langCode } = store.getState().ui;
  if (langCode === "ja") {
    return "";
  }

  if (webtext && typeof (webtext) !== "undefined") {
    const returnText = webtext.find((x) => (x.translations.length > 0 && x.title === Atitle))?.translations[0].web_text || Atitle;
    return returnText;
  }

  return Atitle;
};

export default GetTransText;

export const GetTransTextByGroup = (Atitle) => {
  const { grouptext, langCode } = store.getState().ui;
  if (langCode === "ja") {
    return "";
  }

  if (grouptext && typeof (grouptext) !== "undefined") {
    const returnText = grouptext.find((x) => (x.translations.length > 0 && x.title === Atitle))?.translations[0].web_text || Atitle;
    return returnText;
  }

  return Atitle;
};

export const GetCountryName = (Code) => {
  const { langCode } = store.getState().ui;
  let locationRender;

  if (langCode === "zh-TW") {
    locationRender = require(`~~/country_lang/zh-TW.json`);
  }
  if (langCode === "ja") {
    locationRender = require(`~~/country_lang/ja.json`);
  }
  if (langCode === "en-US") {
    locationRender = require(`~~/country_lang/en-US.json`);
  }

  return locationRender?.countries[Code];
};

import React, { useEffect, useState } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import {
  ButtonToolbar,
  ButtonGroup,
  Button,
  Card,
  CardBody
} from 'reactstrap';
import {
  capabilities, getBalance,
  isSupportedCountry
} from '~/apiCalls/paymentApi';
import getTransText from '~~/get-text/getTransText';
import ModalParent from '~~/modal_parent/ModalParent';
import PayoutForm from '../payout_form/PayoutForm';
import LinkOrLogin from './LinkOrLogin';

const QuoBalance = () => {
  const {
    langCode,
  } = useSelector(state => state.ui);
  const [toggler, setToggler] = useState(false);
  const [capbable, setCapbable] = useState(undefined);
  const [currentBalance, setCurrentBalance] = useState();
  const [payoutToggler, setPayoutedToggler] = useState(false);
  const [supportedCountry, setSupportCountry] = useState(false);
  const [currencyLang, setCurrencyLang] = useState('en_US');
  const {
    id,
  } = useSelector(state => state.user.userData);

  const switchPayoutToggler = () => {
    setPayoutedToggler(!payoutToggler);
    NotificationManager.info('Payout Success.');
  };

  const LangCodeForCurrency = (e) => {
    switch (e) {
      case 'zh-TW':
        return 'zh-TW';
      case 'en-US':
        return 'en-US';
      case 'ja':
        return 'ja-JP';
      default:
        return 'en-US';
    }
  };

  const switchToggler = () => {
    setToggler(!toggler);
  };

  useEffect(async () => {
    let isCancelled = false;
    if (id) {
      await isSupportedCountry()
        .then((res) => {
          if (!isCancelled) {
            setSupportCountry(res.supported);
          }
        })
        .catch(() => {
          setSupportCountry(false);
        });
      await capabilities()
        .then((res) => {
          if (!isCancelled) {
            setCapbable(res);
            if (res) {
              getBalance()
                .then((response) => setCurrentBalance(response));
            }
          }
        });
    }
    return () => {
      isCancelled = true;
    };
  }, [id, payoutToggler]);


  return (
    <>
      {
        supportedCountry && capbable
        && (
          <div className="wallet__balance">
            <ModalParent toggler={toggler} switchToggler={switchToggler}>
              <PayoutForm balance={currentBalance} switchToggler={switchToggler} switchPayoutToggler={switchPayoutToggler} />
            </ModalParent>
            <NotificationContainer leaveTimeout={6000} />
            <Card className="quo__balance">
              <CardBody>
                <div className="balance_box">
                  <h4 className="card__title">{getTransText("Avaliable Balance")}</h4>
                  <div className="quo__stats">
                    <div className="quo__stat balance__total">
                      <div className="quo_balance-stat">{currentBalance
                        ? (
                          <>
                            {new Intl.NumberFormat(LangCodeForCurrency(langCode), { style: 'currency', currency: currentBalance?.available[0]?.currency }).format(currentBalance?.available[0]?.amount / 100)}
                          </>
                        )
                        : (
                          <div className="loadingio-spinner-rolling-h0gdcpc6y5q">
                            <div className="ldio-vbm0w6sebpp">
                              <div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pending__box">
                  <div className="quo__stats">
                    <div className="quo__stat balance__total">
                      <div className="quo_balance-stat">{currentBalance
                        ? (
                          <>
                            {new Intl.NumberFormat(LangCodeForCurrency(langCode), { style: 'currency', currency: currentBalance?.available[0]?.currency }).format(currentBalance?.pending[0]?.amount / 100)}
                          </>
                        )
                        : (
                          <div className="loadingio-spinner-rolling-h0gdcpc6y5q">
                            <div className="ldio-vbm0w6sebpp">
                              <div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <h4 className="card__title">{getTransText("Pending")}</h4>
                </div>
                <ButtonToolbar className="btn-toolbar--center">
                  <ButtonGroup>
                    <Button color="primary" className="btn-payout" onClick={switchToggler}>{getTransText("Payout")}</Button>
                    <LinkOrLogin capbable={capbable} />
                  </ButtonGroup>
                </ButtonToolbar>
              </CardBody>
            </Card>
          </div>
        )
      }
    </>

  );
};


export default QuoBalance;

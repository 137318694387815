/* eslint-disable */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useHistory, useParams
} from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row
} from 'reactstrap';
import { sendQuoMessageTrigger } from '~/apiCalls/quoApi';
import { apiCallBegan } from '~/store/api';
import { emittedSuccessPayment } from '~/store/quos';
import getTransText from "~~/get-text/getTransText";

const RedeemSuccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    emitterTrigger,
  } = useSelector(state => state.quos);
  const { quoId } = useParams();

  return (
    <div className="verification__container quabal__container">
      <Container>
        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <div className="badge-icon">
                  <span className="icon"><i className="las la-check"></i></span>
                  <h5>{getTransText("redeem.success.message")}</h5>
                  <p>{getTransText("You are being redirected. Please")} <Link to="/manageQuo">{getTransText("click here")}</Link> {getTransText("if your browser does not redirect you.")}</p>
                </div>
              </CardBody>
            </Card>
            <Link className="quobal__logo horizontal" to="/l" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}



export default RedeemSuccess;

import React from 'react';
import AcceptStatus from './AcceptStatus';
import CompleteStatus from './CompleteStatus';
import CounteringStatus from './CounteringStatus';
import DeliveredStatus from './DeliveredStatus';
import PendingStatus from './PendingStatus';
import RatingStatus from './RatingStatus';
import RejectedStatus from './RejectedStatus';
import TimeOutStatus from './TimeOutStatus';

const quoStatusOutput = ({ quo, isButton = true }) => {
  if (quo.current_status === "Pending" || quo.current_status === "pending") {
    return (<PendingStatus quo={quo} isButton={isButton} />);
  }
  if (quo.current_status === "Countering" || quo.current_status === "countering") {
    return (<CounteringStatus quo={quo} isButton={isButton} />);
  }
  if (quo.current_status === "Rejected" || quo.current_status === "rejected") {
    return (<RejectedStatus quo={quo} isButton={isButton} />);
  }
  if (quo.current_status === "Accepted" || quo.current_status === "accepted") {
    return (<AcceptStatus quo={quo} isButton={isButton} />);
  }
  if (quo.current_status === "Delivered" || quo.current_status === "delivered") {
    return (<DeliveredStatus quo={quo} isButton={isButton} />);
  }
  if (quo.current_status === "Timeout" || quo.current_status === "timeout") {
    return (<TimeOutStatus quo={quo} isButton={isButton} />);
  }
  if (quo.current_status === "Rating") {
    return (<RatingStatus quo={quo} isButton={isButton} />);
  }
  if (quo.current_status === "Completed") {
    return (<CompleteStatus quo={quo} isButton={isButton} />);
  }
  return ("");
};

export default quoStatusOutput;

import React, { useState } from 'react';
import { loginDashboard } from '~/apiCalls/paymentApi';
import getTransText from '~~/get-text/getTransText';

const LinkOrLogin = ({ capbable }) => {
  const [loading, setLoading] = useState(false);
  const loginClick = async () => {
    setLoading(true);
    await loginDashboard().then((e) => {
      window.open(e.url);
    });
    setLoading(false);
  };

  return (
    <>
      {capbable
        ? <button type="button" className="btn btn-outline-primary btn-connect-stripe" onClick={() => loginClick()} disabled={loading}>{loading ? getTransText("Loading") : getTransText("Manage on Stripe")}<i className="lab la-stripe-s"></i></button>
        : ""}
    </>
  );
};

export default LinkOrLogin;

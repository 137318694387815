import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { getBookmarkList } from '~/apiCalls/userApi';
import getTransText from '~~/get-text/getTransText';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';
import BookmarkedProfileContainer from './components/BookmarkedProfileContainer';

const BookmarkedProfile = () => {
  const [bookmarkList, setBookmarkList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    id,
  } = useSelector(state => state.user.userData);

  useEffect(async () => {
    let isCancelled = false;
    await getBookmarkList()
      .then(res => {
        if (!isCancelled) {
          setBookmarkList(res[0]);
          setIsLoaded(true);
        }
      });
    return () => {
      isCancelled = true;
    };
  }, [id]);

  const renderBookmarkList = () => {
    if (!isLoaded) {
      return <LoadingLogo />;
    }
    if (bookmarkList.length === 0) {
      return (
        <>
          <Col>
            <div className="badge-icon">
              <span className="icon"><i className="las la-id-card-alt"></i></span>
              <h5>{getTransText("No Bookmarks")}</h5>
              <p>{getTransText("Invite your favourite creators and bookmark now!")}</p>
            </div>
          </Col>
        </>
      );
    }
    return (
      <>
        {bookmarkList.map(item => (<BookmarkedProfileContainer key={item.username} info={item} />))}
      </>
    );
  };

  return (
    <Container className="bookmarked__profiles">
      <Row>
        <Col md={12}>
          <div className="bookmarked__profile__wrapper">
            {renderBookmarkList()}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BookmarkedProfile;

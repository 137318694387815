import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TabContent, TabPane,
} from 'reactstrap';
import Gallery from '~~/profile_gallery/Gallery';
import ProfileSettingDetail from './ProfileSettingDetail';
import ProfileSettingQuestions from './ProfileSettingQuestions';

const ProfileSettingTabs = ({ values }) => {
  const {
    profileTab,
    userInfo,
  } = useSelector(state => state.profile);
  return (
    <>
      <TabContent activeTab={profileTab}>
        <TabPane tabId="1">
          <ProfileSettingDetail />
        </TabPane>
        <TabPane tabId="2">
          <Gallery />
        </TabPane>
        <TabPane tabId="3">
          <ProfileSettingQuestions values={values} />
        </TabPane>
      </TabContent>

    </>
  );
};

export default ProfileSettingTabs;

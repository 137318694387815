import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Logout } from '~/apiCalls/authApi';
import { apiCallBegan } from '~/store/api.js';
import { authLoggedOut } from '../../store/auth';
import getTransText from '~~/get-text/getTransText';

function LogoutAuth() {
  const dispatch = useDispatch();
  const {
    authenticated,
  } = useSelector(state => state.auth);
  if (authenticated) {
    dispatch(apiCallBegan({
      axiosCall: Logout,
      onSuccess: authLoggedOut.type,
    }));
  }
  
  return (
    <div>{getTransText("Loggouted")}</div>
  );
}

export default LogoutAuth;

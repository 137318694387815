import { combineReducers } from 'redux';
import chats from "./chats";
import auth from "./auth";
import ui from './ui';
import user from "./user";
import profile from "./profile";
import quos from "./quos";
import redirect from "./redirect";
import inviting from "./inviting";
import opencall from "./opencall";
import managequos from "./managequos";
import marketplace from './marketplace';
import cart from './cart';

export default combineReducers({
  chats,
  user,
  auth,
  ui,
  profile,
  quos,
  redirect,
  opencall,
  inviting,
  managequos,
  marketplace,
  cart,
});

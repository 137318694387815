import { DateTime } from "luxon";
import store from '~/store/store.js';

export const roundUpHour = (luxonObj) => (luxonObj.plus({ minute: 59, second: 59, millisecond: 999 }).startOf("hours"));

const formatDate = (datetimeValue, dateFormat) => {
  const { langCode } = store.getState().ui;
  const UTCTime = DateTime.fromISO(datetimeValue, { zone: "utc" });
  const SystemTime = UTCTime.setZone("system");
  if (dateFormat) {
    return SystemTime.toFormat(dateFormat);
  }
  return SystemTime.setLocale(langCode).toLocaleString(DateTime.DATETIME_MED);
};

export const formatSeconds = (datetimeValue, dateFormat) => {
  const { langCode } = store.getState().ui;
  const UTCTime = DateTime.fromSeconds(datetimeValue, { zone: "utc" });
  const SystemTime = UTCTime.setZone("system");
  if (dateFormat) {
    return SystemTime.toFormat(dateFormat);
  }
  return SystemTime.setLocale(langCode).toLocaleString(DateTime.DATETIME_SHORT);
};



export default formatDate;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import quoLogoClassSwitch from '~~/quoLogoClass/quoLogoClassSwitch';


const ChatQuoDetail = ({ quo }) => {
  const [title, setTitle] = useState(quo.latest_description.length < 20 ? quo.latest_title : `${quo.latest_title.substring(0, 20)}...`);
  const true_price = quo.countering_price || quo.latest_price;
  const {
    langCode,
  } = useSelector(state => state.ui);
  const location = useLocation();
  const [isToggledReadMore, setIsToggledReadMore] = useState(false);

  const LangCodeForCurrency = () => {
    switch (langCode) {
      case 'zh-TW':
        return 'zh-TW';
      case 'en-US':
        return 'en-US';
      case 'ja':
        return 'ja-JP';
      default:
        return 'en-US';
    }
  };


  return (
    <>
      <div className="quo__info">
        <div className="quo__badge">
          <i className={`quo__badge-pic ${quoLogoClassSwitch(true_price)}`}></i>
          <div className="quo__price">{new Intl.NumberFormat(LangCodeForCurrency(), { style: 'currency', currency: 'usd' }).format(true_price)}</div>
        </div>
      </div>
      <div>
        <Link to={{ pathname: `/q/${quo.quo_id ? quo.quo_id : quo.id}`, state: { from: location.pathname } }}>
          <h5 className="bold-text">
            {title}
          </h5>
        </Link>
      </div>
    </>

  );
};

export default ChatQuoDetail;

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { getSubmittedMediaByQuoId } from '~/apiCalls/quoApi';
import DeliverList from '~~/deliver_list/DeliverList';
import getTransText from '~~/get-text/getTransText';

const QuoSubmittedMedia = ({ quoId, submission }) => {
  const [files, setFiles] = useState([]);
  const [isReceiver, setIsReceiver] = useState();
  useEffect(async () => {
    let isCancelled = false;
    if (quoId) {
      const filesResponse = await getSubmittedMediaByQuoId(quoId, submission);
      if (!isCancelled) {
        setFiles(filesResponse.files);
        setIsReceiver(filesResponse.isReceiver);
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [quoId]);

  return (
    <Container>
      <Row>
        <Col>
          <h3 className="page-title">{getTransText("Delivered files")}</h3>
          <h3 className="page-subhead subhead">
            {isReceiver ? "" : getTransText("Please make sure your files are correct before completing the QUO. You can still discuss the file with the content creator on this stage.")}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          {files && <DeliverList files={files} />}
        </Col>
      </Row>
    </Container>
  );
};

export default QuoSubmittedMedia;

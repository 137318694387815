/* eslint-disable */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useHistory, useParams
} from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row
} from 'reactstrap';
import getTransText from "~~/get-text/getTransText";

const PickSuccess = () => {
  const history = useHistory();
  const {
    emitterTrigger,
  } = useSelector(state => state.quos);
  const { quoId } = useParams();

  useEffect(() => {
    setTimeout(() => history.push("/manageQuo"), 5000);
  }, []);



  return (
    <div className="verification__container quabal__container">
      <Container>
        <Row>
          <Col md="8">
            <div className="badge-icon">
              <span className="icon"><i className="las la-check"></i></span>
              <h5>{getTransText("success.picked.creator")}</h5>
              <p>{getTransText("You are being redirected. Please")} <Link to="/manageQuo">{getTransText("click here")}</Link> {getTransText("if your browser does not redirect you.")}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}



export default PickSuccess;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOpenQuoPickedListById, pickMeClickApi, getOpenQuoById } from '~/apiCalls/openCallApi';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';
import { getQuoInterestedList, pickedMe, pickMeAlreadyClicked, gotOpenCallDetailById } from '~/store/opencall';
import { apiCallBegan } from '~/store/api';
import OpenDetail from './OpenDetail';



const OpenDetailContainer = () => {
  const pathnameSplited = location.pathname.split("/");
  const quo_id = pathnameSplited[2];
  const dispatch = useDispatch();
  const {
    quoDetail,
  } = useSelector(state => state.opencall);

  useEffect(async () => {
    let isCancelled = false;
    dispatch(apiCallBegan({
      axiosCall: getOpenQuoById,
      data: quo_id,
      onSuccess: gotOpenCallDetailById.type,
    }));
    dispatch(apiCallBegan({
      axiosCall: getOpenQuoPickedListById,
      data: quo_id,
      onSuccess: getQuoInterestedList.type,
    }));

    return () => {
      isCancelled = true;
    };
  }, [quo_id]);


  return (
    <>
      {(quoDetail && quoDetail.id === quo_id)
        ? (
          <OpenDetail />
        )
        : (
          <LoadingLogo />
        )}
    </>
  );
};
export default OpenDetailContainer;

import { Formik } from 'formik';
import React from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button, ButtonToolbar,
  Card,
  CardBody,
  Col, Form,
  FormGroup,
  Input
} from 'reactstrap';
import { editUserProfile, updateAvatar } from '~/apiCalls/userApi';
import AvatarRender from '~~/avatar_render';
import getTransText from '~~/get-text/getTransText';

const ModifyUser = async (credentials, history) => {
  const username = await editUserProfile(credentials);
  if (username) {
    history.push(`/p/${username.username}`);
  }
};

const ProfileForm = () => {
  const { userData } = useSelector((state) => state.user);
  const history = useHistory();
  const Ava = (typeof (userData) !== 'undefined' && userData.id != null) ? AvatarRender(userData.avatar, userData.random_no) : "";

  const ChangeProfilePictureClick = async () => {
    const fileInput = document.querySelector('input[class="uploadAvatar"]');
    const formData = new FormData();
    formData.append('title', `${userData?.username}-avatar`);
    formData.append('file_purpose', "avatar");
    formData.append('file', fileInput?.files[0]);
    await updateAvatar(formData);
  };

  return (
    <Col md={12} lg={8} xl={8}>
      <Card>
        <CardBody>
          <Formik
            enableReinitialize
            initialValues={{
              description: userData.description,
              role: 'f3734efa-3f78-4f53-8ee6-8d06d6353089',
              username: userData.username,
              tags: userData.tags,
              avatar: 'e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae',
            }}
            validate={values => {
              const errors = {};
              if (!values.username) {
                errors.username = 'Required';
              } else if (values.username.length < 5) {
                errors.username = 'Please input more than 5 char.';
              } else if (values.username.indexOf(' ') >= 0) {
                errors.username = 'No space allowed';
              }
              if (values.description.length > 250) {
                errors.description = 'No more than 250 length.';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              ModifyUser(values, history);
              setSubmitting(false);
            }}
          >

            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (

              <Form className="form form--horizontal form-edit_profile" onSubmit={handleSubmit}>
                <FormGroup className="form__form-group">
                  <div className="form__edit_avatar">
                    <label className="click_to_change_profile" htmlFor="uploadAvatarLabel">
                      <img src={Ava} alt="avatar" />
                      <input type="file" id="uploadAvatarLabel" className="uploadAvatar" style={{ display: 'none' }} onChange={() => ChangeProfilePictureClick()} accept=".jpg,.jpeg,.png" />
                      <i className="las la-edit"></i>
                    </label>
                  </div>
                </FormGroup>
                <FormGroup className="form__form-group">
                  <span className="form__form-group-label">E-mail</span>
                  <div className="form__form-group-field">
                    {userData.email}
                  </div>
                </FormGroup>
                <FormGroup className="form__form-group">
                  <span className="form__form-group-label">Country</span>
                  <div className="form__form-group-field">
                    {userData.location}
                  </div>
                </FormGroup>
                <FormGroup className="form__form-group">
                  <span className="form__form-group-label">Username</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <Input
                        name="username"
                        component="input"
                        type="text"
                        placeholder="Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.username}
                        required
                        className="input-without-border-radius"
                      />
                      {errors?.username && touched.username && <span className="form__form-group-error">{errors.username}</span>}
                    </div>
                  </div>
                </FormGroup>
                <FormGroup className="form__form-group">
                  <span className="form__form-group-label">Description</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <Input
                        name="description"
                        component="input"
                        type="textarea"
                        placeholder="Write something about you..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        required
                        className="input-without-border-radius"
                      />
                      <span className="form__form-group-description">{values.description?.length || 0} / 250</span>
                      {errors?.description && touched.description && <span className="form__form-group-error">{errors.description}</span>}
                    </div>
                  </div>
                </FormGroup>
                <FormGroup className="form__form-group">
                  <span className="form__form-group-label">Tags that descripe you</span>
                  <div className="form__form-group-field">
                    <Input
                      name="tags"
                      component="input"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tags || ''}
                      placeholder="tag1,tag2,tag3"
                      className="input-without-border-radius"
                    />
                    {errors?.tags && touched.tags && <span className="form__form-group-error">{errors.tags}</span>}
                  </div>
                </FormGroup>
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? `${getTransText("Loading")}...` : getTransText("Submit")}
                  </Button>
                </ButtonToolbar>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Col>
  );
};


export default ProfileForm;

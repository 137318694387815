import {
  FieldArray, Form, Formik
} from "formik";
import React, {
  useEffect, useState
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { Form, Field } from "react-final-form";
// import arrayMutators from "final-form-arrays";
// import { FieldArray } from "react-final-form-arrays";
import '@brainhubeu/react-carousel/lib/style.css';
import { NotificationContainer } from 'react-notifications';
import { Container, Row } from 'reactstrap';
import DropFilePond from './DropFilePond';

const MyMediaLibraryContainer = () => {
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const {
    id,
    username,
  } = useSelector(state => state.user.userData);
  const {
    profileContents,
  } = useSelector(state => state.profile);
  const onSubmit = async (values) => {
    // 
    // console.log("done");
  };


  useEffect(() => {
    setFiles(profileContents);
  }, [profileContents]);

  return (
    <Container>
      <>
        <NotificationContainer leaveTimeout={6000} />
        <DropFilePond setFiles={setFiles} />
      </>
    </Container>
  );
};

export default MyMediaLibraryContainer;

/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';
import CollapseComponent from '~~/Collapse';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import InvitationCreatorQuoPanel from "./components/InvitationCreatorQuoPanel"
import { uiGroupTextLoaded } from '~/store/ui';
import { apiCallBegan } from '~/store/api';
import { getWebTextByGroup } from '~/apiCalls/uiApi';
import { GetTransTextByGroup } from '~~/get-text/getTransText';
import getTransText from '~~/get-text/getTransText';


const InvitingQuoForCreator = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { quoId } = useParams();
  const [updatedQuoId, setUpdatedQuoId] = useState(null);
  const [usernameClaim, setUsernameClaim] = useState("");
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();
  const {
    langCode,
  } = useSelector(state => state.ui);


  useEffect(() => {
    ReactGA.event({
      category: `Pre-Registration Engagement`,
      action: `Landing - InvitingQuoForCreator PageView from ${query.get("f")}`,
    });
    dispatch(apiCallBegan({
      axiosCall: getWebTextByGroup,
      data: { langCode, group: "landing" },
      onSuccess: uiGroupTextLoaded.type,
    }));
  }, [langCode]);

  useEffect(() => {
    if (quoId) {
      setUpdatedQuoId(quoId);
    }
  }, [quoId]);



  return (
    <>
      <Container className="seller__redeem">
        <Row>
          <Col md={8} xl={6}>
            <div className="quobal-logo">Quobal</div>
            <h3 className="subhead">{getTransText("quobal.redeem.title")} </h3>
            <p>{getTransText("quobal.redeem.welcomemsg1")}</p>
            <p>{getTransText("quobal.redeem.welcomemsg2")}</p>
            <InvitationCreatorQuoPanel id={updatedQuoId} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InvitingQuoForCreator;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import {
  Row, Col, FormGroup,
} from 'reactstrap';
import getTransText from '~~/get-text/getTransText';
import renderRadioButtonField from '~~/form/RadioButton';
import CheckBox from '~~/form/CheckBox';


const ProfileSettingQuestions = () => {
  const {
    profileTab,
    userInfo,
  } = useSelector(state => state.profile);
  return (
    <>
      <Row>
        <Col md="12">
          <FormGroup className="form__form-group">
            <span className="form__form-group-label">{getTransText("Do you accept NSFW content?")}</span>
            <div className="form__form-group-field">
              <Field
                name="isNSFW"
                component={renderRadioButtonField}
                label={getTransText("Yes")}
                radioValue="Yes"
              // styleType="colored"
              />
            </div>
            <div className="form__form-group-field">
              <Field
                name="isNSFW"
                component={renderRadioButtonField}
                label={getTransText("No")}
                radioValue="No"
              // styleType="colored"
              />
            </div>
          </FormGroup>
          <FormGroup className="form__form-group">
            <span className="form__form-group-label">{getTransText("Are you open to face-revealing content requests?")}</span>
            <div className="form__form-group-field">
              <Field
                name="isFace"
                component={renderRadioButtonField}
                label={getTransText("Yes")}
                radioValue="Yes"
              // styleType="colored"
              />
            </div>
            <div className="form__form-group-field">
              <Field
                name="isFace"
                component={renderRadioButtonField}
                label={getTransText("No")}
                radioValue="No"
              // styleType="colored"
              />
            </div>
          </FormGroup>
          <FormGroup className="form__form-group">
            <span className="form__form-group-label">{getTransText("Which types of requests do you accept? (Select all that apply)")}</span>
            <Field
              name="Heterosexual"
              component="input"
              type="checkbox"
              id="Heterosexual"
            >
              {({ input, meta }) => (
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                    <CheckBox
                      {...input}
                      type="checkbox"
                      name="Heterosexual"
                      label={getTransText("Heterosexual")}
                    // value={isToggleCheckboxEnabled}
                    // checked={isToggleCheckboxEnabled}
                    // onChange={handleToggleCheckBox}
                    />
                    {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                  </div>
                </div>
              )}
            </Field>
            <Field
              name="Homosexual"
              component="input"
              type="checkbox"
              id="Homosexual"
            >
              {({ input, meta }) => (
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                    <CheckBox
                      {...input}
                      type="checkbox"
                      name="Homosexual"
                      label={getTransText("Homosexual")}
                    // value={isToggleCheckboxEnabled}
                    // checked={isToggleCheckboxEnabled}
                    // onChange={handleToggleCheckBox}
                    />
                    {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                  </div>
                </div>
              )}
            </Field>
            <Field
              name="Bisexual"
              component="input"
              type="checkbox"
              id="Bisexual"
            >
              {({ input, meta }) => (
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                    <CheckBox
                      {...input}
                      type="checkbox"
                      name="Bisexual"
                      label={getTransText("Bisexual")}
                    // value={isToggleCheckboxEnabled}
                    // checked={isToggleCheckboxEnabled}
                    // onChange={handleToggleCheckBox}
                    />
                    {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                  </div>
                </div>
              )}
            </Field>
          </FormGroup>
          <FormGroup className="form__form-group">
            <span className="form__form-group-label">{getTransText("What is the minimum starting price you accept for your content?")}</span>
            <div className="form__form-group-field">
              <Field
                name="minPrice"
                component={renderRadioButtonField}
                label={getTransText("$10")}
                radioValue="Yes"
              // styleType="colored"
              />
            </div>
            <div className="form__form-group-field">
              <Field
                name="minPrice"
                component={renderRadioButtonField}
                label={getTransText("$20")}
                radioValue="No"
              // styleType="colored"
              />
            </div>
            <div className="form__form-group-field">
              <Field
                name="minPrice"
                component={renderRadioButtonField}
                label={getTransText("$50")}
                radioValue="No"
              // styleType="colored"
              />
            </div>
            <div className="form__form-group-field">
              <Field
                name="minPrice"
                component={renderRadioButtonField}
                label={getTransText("$100")}
                radioValue="No"
              // styleType="colored"
              />
            </div>
            <div className="form__form-group-field">
              <Field
                name="minPrice"
                component={renderRadioButtonField}
                label={getTransText("$200")}
                radioValue="No"
              // styleType="colored"
              />
            </div>
          </FormGroup>
        </Col>
      </Row>

    </>
  );
};

export default ProfileSettingQuestions;

import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { TableCheckbox, TableSortLabel } from '@/shared/components/MaterialTableElements';

const rows = [
  {
    id: 'preview', disablePadding: true, label: 'Preview',
  },
  {
    id: 'filename_download', disablePadding: false, label: 'File name',
  },
  {
    id: 'filesize', disablePadding: false, label: 'Size',
  },
  {
    id: 'type', disablePadding: false, label: 'Type',
  },
  {
    id: 'uploaded_on', disablePadding: false, label: 'Date',
  },
];

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

const MatTableHead = ({
  onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,
}) => (
  <TableHead>
    <TableRow>
      <TableCell padding="checkbox">
        <TableCheckbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={numSelected === rowCount}
          onChange={onSelectAllClick}
        />
      </TableCell>
      {rows.map(row => (
        <TableCell
          sort
          right
          key={row.id}
          align="right"
          padding={row.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === row.id ? order : false}
        >
          {row.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

MatTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default MatTableHead;

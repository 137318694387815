/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classNames from 'classnames';
import {
  Col,
  Container,
  Row,
  ButtonToolbar,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import ReactGA from 'react-ga4';
import { Link, useHistory, useLocation } from 'react-router-dom';
import TopbarInfo from '~~/topbarInfo/Topbar';
import getTransText from '~~/get-text/getTransText';
import LandingTabPage from '~~/landingTabPage';


const getMultipleRandom = (arr, num) => {
  const shuffled = arr.sort(() => 0.5 - Math.random());
  const all = shuffled.map((obj, index) => ({ ...obj, visible: true, zindex: index }))
  return all.slice(0, num);
}

const LandingB = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const {
    langCode,
  } = useSelector(state => state.ui);
  const [usernameClaim, setUsernameClaim] = useState("");
  const [collapseToggle, setCollapseToggle] = useState(classNames({
    "landing-logo": true,
  }));

  const handleClaim = () => {
    ReactGA.event({
      category: 'Pre-Registration Engagement',
      action: " Buyer Clicked Claim Button",
      label: usernameClaim,
    });
    history.push(`/register/${usernameClaim}`)
  }

  useScrollPosition(({ prevPos, currPos }) => {
    setCollapseToggle(classNames({
      "landing-logo": true,
      "small": currPos.y === 0 ? false : true,
    }));
  })

  useEffect(() => {
    ReactGA.event({
      category: 'Pre-Registration Engagement',
      action: `Landing PageView from ${query.get("f")}`,
    });
  }, []);

  return (
    <>
      {/* <TopbarInfo /> */}
      <Container className="landing landing__buying__selling buyer">
        <LandingTabPage buyingActive="active" />
        <div className="fix__content animate__animated animate__flipInY">
          <div className="landing-logo">QUOBal</div>
          <div className="page-title">{getTransText("landingb.page.title")}</div>
          <div className="subhead">{getTransText("landingb.page.subhead")}</div>
          <div className="benefits__section">
            <ul className="benefits-list">
              <li><strong>{getTransText("landingb.benefit.1.bold")}</strong> {getTransText("landingb.benefit.1.content")}</li>
              <li><strong>{getTransText("landingb.benefit.2.bold")}</strong> {getTransText("landingb.benefit.2.content")}</li>
              <li><strong>{getTransText("landingb.benefit.3.bold")}</strong> {getTransText("landingb.benefit.3.content")}</li>
              <li><strong>{getTransText("landingb.benefit.4.bold")}</strong> {getTransText("landingb.benefit.4.content")}</li>
              <li><strong>{getTransText("landingb.benefit.5.bold")}</strong> {getTransText("landingb.benefit.5.content")}</li>
            </ul>
          </div>
          <Form className="landing__reg__form" onSubmit={handleClaim}>
            <FormGroup>
              <Label>{getTransText("landingb.form.quobal")}</Label>
              <Input type="text" value={usernameClaim} onChange={(e) => setUsernameClaim(e.target.value)}></Input>
              <Button type="submit" className="btn btn-secondary">{getTransText("landingb.form.btn")}</Button>
            </FormGroup>
          </Form>
          <div className="caption">{getTransText("landingb.caption")}</div>
          <footer>
            <div className="left">
              <div className="copyright">{getTransText("quobal.copyright")}</div>
              <ul className="footer-links">
                <li>|  <Link to="/FAQ">{getTransText("FAQ")}</Link></li>
                <li>|  <Link to="/terms-and-condition">{getTransText("quobal.tnc")}</Link></li>
                <li>|  <Link to="/privacy-policy">{getTransText("quobal.privacy")}</Link></li>
              </ul>
            </div>
            <ul className="footer-links">
              <li><Link to={{ pathname: "https://www.instagram.com/quob.al" }} target="_blank"><i className="lab la-instagram"></i></Link></li>
              <li><Link to={{ pathname: "https://discord.gg/qkb9HpeaxC" }} target="_blank"><i className="lab la-discord"></i></Link></li>
            </ul>
          </footer>
        </div>
        <Row className="landing__section animate__animated animate__fadeIn">
          <Col>
            <div className="landing__section__image"><img src={`${process.env.REACT_APP_FRONTEND_URL}img/landing2401-1.png`} /></div>
            <div className="landing__section__header">{getTransText("landingb.image.1.content")}</div>
            <ButtonToolbar className="btn-toolbar--center">
              <Link className="btn btn-outline-primary" to="/register" onClick={() => {
                ReactGA.event({
                  category: 'Pre-Registration Engagement',
                  action: " Buyer Clicked Try it Button",
                  label: usernameClaim,
                });
              }}>{getTransText("landing.callforaction.opencall")}</Link>
            </ButtonToolbar>
          </Col>
        </Row>
        <Row className="landing__section animate__animated animate__fadeIn">
          <Col>
            <div className="landing__section__image"><img src={`${process.env.REACT_APP_FRONTEND_URL}img/landing2401-2.png`} /></div>
            <div className="landing__section__header">{getTransText("landingb.image.2.content")}</div>
            <ButtonToolbar className="btn-toolbar--center">
              <Link className="btn btn-outline-primary" to="/register" onClick={() => {
                ReactGA.event({
                  category: 'Pre-Registration Engagement',
                  action: " Buyer Clicked Try it Button",
                  label: usernameClaim,
                });
              }}>{getTransText("landing.callforaction.opencall")}</Link>
            </ButtonToolbar>
          </Col>
        </Row>
        <Row className="landing__section animate__animated animate__fadeIn">
          <Col>
            <div className="landing__section__image"><img src={`${process.env.REACT_APP_FRONTEND_URL}img/landing2401-3.png`} /></div>
            <div className="landing__section__header">{getTransText("landingb.image.3.content")}</div>
            <div className="landing__section__header">{getTransText("landingb.image.3.2.content")}</div>
            <ButtonToolbar className="btn-toolbar--center">
              <Link className="btn btn-outline-primary" to="/register" onClick={() => {
                ReactGA.event({
                  category: 'Pre-Registration Engagement',
                  action: " Buyer Clicked Try it Button",
                  label: usernameClaim,
                });
              }}>{getTransText("landing.callforaction.opencall")}</Link>
            </ButtonToolbar>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LandingB;
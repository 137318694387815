import axios from 'axios';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
// import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
// import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
// import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import {
  Button, FormGroup, Input
} from 'reactstrap';
import getTransText from '~~/get-text/getTransText';


const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const RegisterForm = () => {
  const {
    langCode,
  } = useSelector(state => state.ui);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const required = value => (value ? undefined : getTransText('Required'));
  const moreThan5 = value => (value.length < 5 ? getTransText('More than 5 digits required') : undefined);
  const mustBeNumber = value => (value.length < 8 ? getTransText('More than 8 digits required') : undefined);
  const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const onSubmit = async (values) => {
    let errorMsg = "";
    values.reset_url = `${process.env.REACT_APP_FRONTEND_URL}reset`;
    await api.post('auth/password/request', values).then((response) => {
      // Success
      if (response) {
        history.push("/RequestSuccess");
      }
    })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 503) {
            if (error.response.data.errors[0].message.includes('email')) {
              errorMsg = { ...errorMsg, email: getTransText("Email does not exist.") };
            }
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });
    return errorMsg;
  };





  return (
    <Form
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        submitError,
        submitting,
        values
      }) => (
        <form className="form" onSubmit={handleSubmit}>
          <FormGroup className="form__form-group">
            <div className="form__form-group-field form__form-group-input-wrap--error-above">
              <div className="form__form-group-icon">
                <i className="las la-at"></i>
              </div>
              <Field
                name="email"
                type="email"
                className="input-without-border-radius"
              >
                {({ input, meta }) => (
                  <>
                    <Input {...input} placeholder="example@gmail.com" />
                    {(meta.error || meta.submitError) && meta.touched && <span className="form__form-group-error">{meta.error || meta.submitError}</span>}
                  </>
                )}
              </Field>
            </div>
          </FormGroup>
          <div className="account__btns register__btns">
            <Button type="submit" color="primary" className="account__btn">
              {getTransText("Send Reset Email")}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

export default RegisterForm;

import classNames from 'classnames';
import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle, Col, Container,
  Row
} from 'reactstrap';

import getTransText from '~~/get-text/getTransText';
import ModalParent from '~~/modal_parent/ModalParent';
import DragAndDropTable from '~~/profile_gallery_editor';

const ProfileStepCard = ({ profileContents, setProfileContents }) => {
  const [toggler, setToggler] = useState(false);
  const [togglerDnd, setTogglerDnd] = useState(false);


  const switchTogglerDnd = () => {
    setTogglerDnd(!togglerDnd);
  };



  const steps__card = classNames({
    "steps__card": true,
    "completed": profileContents.length > 0,
  });

  const switchToggler = () => {
    setToggler(!toggler);
  };

  return (
    <>
      <ModalParent toggler={togglerDnd} switchToggler={switchTogglerDnd}>
        <DragAndDropTable profileContents={profileContents} setProfileContents={setProfileContents} />
      </ModalParent>
      <Card className={steps__card}>
        <CardBody>
          <Container className="steps__card__container">
            <Row>
              <Col xs={3} md={2} className="step__icon">
                <i className="las la-image"></i>
              </Col>
              <Col xs={6} md={8}>
                <CardTitle className="bold-text" tag="h5">{getTransText('Highlights')}</CardTitle>
                <p>{getTransText('Upload some pictures representing you.')}</p>
              </Col>
              <Col xs={3} md={2} className="step__button">
                <Button type="button" color="primary" outline onClick={() => switchTogglerDnd()}><i className="las la-angle-right"></i></Button>
                <i className="las la-check-circle"></i>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </>
  );
};

export default ProfileStepCard;

// action
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";

export const redeemedInviting = createAction("inviting/RedeemedInviting");
export const redeemedInvitingError = createAction("inviting/RedeemedInvitingError");

// reducer
const initialState = {
  success: "",
  error: "",
};

const invitingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(redeemedInviting.type, (state, action) => ({
      ...state,
      success: action.payload,
    }))
    .addCase(redeemedInvitingError.type, (state, action) => ({
      ...state,
      error: action.payload,
    }));
});

export default invitingReducer;
// export const getAuthStore = createSelector();

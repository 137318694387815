import React from 'react';
import TimeLineItem from './TimeLineItem';
import TimeLineListNotYet from './TimeLineListNotYet';

const TimelineHistory = ({ data }) => (
  <div className="timeline">
    {data?.map((q) => (
      <TimeLineItem key={q.quo_activity_id} quo={q} />
    ))}
    <TimeLineListNotYet data={data} />
  </div>
);

export default TimelineHistory;

import {
  readMe,
  createItem,
  readItem,
  readItems,
  generateTwoFactorSecret,
  enableTwoFactor,
  disableTwoFactor,
  uploadFiles,
  updateUser,
  updateMe,
} from '@directus/sdk';
import produce from 'immer';
import store from '~/store/store.js';
import { client, clientPublic } from '~/apiCalls/directusConfig';

import { userProfileUpdated } from '~/store/user';

// Login API
const setLocalStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // catch possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
};

const getLocalStorage = (key) => {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (e) {
    // if error, return initial value
    return null;
  }
};


export const getUserDataApi = async () => {
  const res = await client.request(
    readMe({
      fields: ['*'],
    })
  );
  return res;
};


// Account Setting
export const changePassword = async (credentials) => {
  const { password_check, new_password } = credentials;
  const config = { "password_check": password_check, "new_password": new_password };
  const changePasswordReturn = await client
    .request(() => ({
      path: `/user-setting/change-password`,
      method: "POST",
      body: JSON.stringify(config),
    }));
  return changePasswordReturn;
};

export const deleteAccountApi = async () => {
  const deleteAccountReturn = await client
    .request(() => ({
      path: `/user-setting/delete-account`,
      method: "POST",
    }));
  return (deleteAccountReturn);
};

export const changeLanguage = async (credentials) => {
  const { language } = credentials;
  const config = { "language": language };
  const changeLanguageReturn = await client
    .request(() => ({
      path: `/user-setting/change-lang`,
      method: "POST",
      body: JSON.stringify(config),
    }));
  return (changeLanguageReturn);
};

export const changeTags = async (credentials, dispatch) => {
  const { tags } = credentials;
  const config = { "tags": tags };
  const changeTagsReturn = await client
    .request(() => ({
      path: `/user-setting/change-tags`,
      method: "POST",
      body: JSON.stringify(config),
    }));
  if (changeTagsReturn) {
    const res5 = getLocalStorage('user');
    const nextState = produce(res5, draftState => {
      draftState.data.tags = changeTagsReturn.data.tags;
    });
    setLocalStorage('user', nextState);
    dispatch({ type: "USER_UPDATE_SUCCESS", payload: nextState });
  }
  return (changeTagsReturn);
};

export const generateTFASecret = async (credentials) => {
  const { password } = credentials;
  const config = { password };
  const sercretString = await client.request(generateTwoFactorSecret(config));
  return (sercretString);
};


export const contactUsSubmission = async (values) => {
  const res = await client.request(createItem("contact_us", values));
  return (res);
};

export const enableUserTfa = async (credentials) => {
  const { otp, secret } = credentials;
  const config = { otp, secret };
  const enableTfa = await client.request(enableTwoFactor(config));
  return (enableTfa);
};


export const disableUserTfa = async (credentials) => {
  const { otp } = credentials;
  const config = { otp };
  const enableTfa = await client.request(disableTwoFactor(otp));
  return (enableTfa);
};


export const notiToggleApi = async (config) => {
  const res = await client.request(() => ({
    path: `/user-setting/toggle-noti`,
    method: "POST",
    body: JSON.stringify(config),
  }));
  return (res);
};

export const getNotiSetting = async () => {
  const res = await client.request(readItems("user_settings"));
  return (res);
};


// Edit Profile API

export const firstSetupProfile = async (credentials) => {
  const res = await client.request(() => ({
    path: `/user-setting/`,
    method: "PATCH",
    body: JSON.stringify(credentials),
  }));
  // console.log("here2", res);
  return res;
};

export const editUserProfile = async (credentials) => {
  const { id } = store.getState().user.userData;
  const {
    username,
    description,
    country,
    tags,
  } = credentials;
  const location = country;

  const config = {
    "username": username,
    "description": description,
    "location": location,
    "tags": tags,
  };
  const res = await client.request(() => ({
    path: `/users/${id}`,
    method: "PATCH",
    body: JSON.stringify(config),
  }));
  // console.log("here2", res);
  return res;
};


export const updateAvatar = async (formData) => {
  try {
    const res = await client.request(uploadFiles(formData));
    const config = { "avatar": res.id };
    store.dispatch(userProfileUpdated(config));
    return config;
  } catch (err) {
    // // console.log(err);
  }
};

export const uploadFile = async (formData) => {
  try {
    const res = await client.request(uploadFiles(formData));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};


export const getProfileContents = async (username) => {
  try {
    const res = await client.request(() => ({
      path: `/file-access/profile/${username}`,
      method: "POST",
    }));
    return (res[0]);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const shareStatusUpdate = async () => {
  const config = { is_shared: true };
  const res = await client.request(updateMe(config));
  return (res);
};

export const switchToCreatorModeApi = async () => {
  const config = { is_creator: true };
  const res = await client.request(updateMe(config));
  return (res);
};

export const switchToBuyerModeApi = async () => {
  const config = { is_creator: false };
  const res = await client.request(updateMe(config));
  return (res);
};

export const getProfileContentsById = async (picId) => {
  try {
    const config = { responseType: "blob" };
    const res = await client.request(() => ({
      path: `/file-access/${picId}?width=300&height=300`,
      method: "POST",
    }));
    /* const config = {"avatar" : res.data.data.id};
    const res2 = await api.patch('/users/'+ user.data.id, config);
    const data = await api.get(API_URL + "/users/me", config)
    const res3 = {
        "token" : user.token,
        "data" : data.data.data};
        setLocalStorage('user', res3);
    dispatch({ type: "USER_UPDATE", payload: res3 });
    */
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const getPermissionId = async (file_id) => {
  try {
    const config = { "file_id": file_id };
    const res = await client.request(() => ({
      path: '/file-access/permission-id',
      method: "POST",
      body: JSON.stringify(config),
    }));
    /* const config = {"avatar" : res.data.data.id};
    const res2 = await api.patch('/users/'+ user.data.id, config);
    const data = await api.get(API_URL + "/users/me", config)
    const res3 = {
        "token" : user.token,
        "data" : data.data.data};
        setLocalStorage('user', res3);
    dispatch({ type: "USER_UPDATE", payload: res3 });
    */
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const getSellerRating = async (username) => {
  try {
    const res = await client.request(() => ({
      path: `/user-setting/seller-rating/${username}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    return null;
  }
};


export const sortForPersonalProfile = async (values) => {
  try {
    const config = { payload: values };
    const res = await client.request(() => ({
      path: "/file-access/sort-gallery",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res.data);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};


export const deleteProfilePhoto = async (values) => {
  try {
    const config = { payload: values };
    const res = await client.request(() => ({
      path: "/file-access/delete-profile",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const getProfileSort = async (user_id) => {
  try {
    const res = await client.request(readItems("user_profile_sort", {
      sort: ['sort'], // Array indicating sorting by 'sort' and then by 'date_created' in descending order
      filter: {
        user_created: {
          _eq: user_id // Assuming user_id is a variable that has been defined earlier
        }
      }
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const getBookmarkList = async () => {
  try {
    const res = await client.request(() => ({
      path: `/user-setting/bookmark-list`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};


export const createBookmark = async (username, action) => {
  try {
    const config = { "username": username, "action": action };
    const res = await client.request(() => ({
      path: "/user-setting/create-bookmark",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

export const checkIsBookmarked = async (username) => {
  try {
    const res = await client.request(() => ({
      path: `/user-setting/IsBookmarked/${username}`,
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

// Search API

export const getMembers = async (username) => {
  try {
    const config = { "username": username };
    const res = await client.request(() => ({
      path: '/members/',
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};

// Profile API

export const searchMembers = async (text) => {
  try {
    const config = { "search": text };
    const res = await client.request(() => ({
      path: "/members/search",
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res[0]);
  } catch (err) {
    // // console.log(err);
    return null;
  }
};


import React from 'react';
import getTransText from '~~/get-text/getTransText';

const InvitingStatus = ({
  quo,
}) => (
  <>
    <div className="quo__status">
      <div className="quo__status-notice">{getTransText("quo.status.buyer.inviting")}</div>
    </div>
  </>
);

export default InvitingStatus;

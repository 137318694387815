import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import {
  Card, CardBody
} from 'reactstrap';
import ChatQuoDetail from '~~/chat-quo-details/ChatQuoDetail';
import getTransText from '~~/get-text/getTransText';

const QuoPanel = ({
  quo,
}) => {
  const {
    langCode,
  } = useSelector(state => state.ui);
  const location = useLocation();

  return (
    <Card>
      <CardBody className="panel__body quo__body quo__body-pending">
        <ChatQuoDetail quo={quo} />
        <div className="btn-toolbar">
          <Link to={{ pathname: `/q/${quo.id}`, state: { from: location.pathname } }}>
            <button type="button" className="btn btn-primary">
              {getTransText("View offer")}
            </button>
          </Link>
        </div>
      </CardBody>
    </Card>
  );
};
export default QuoPanel;

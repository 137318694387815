import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col } from 'reactstrap';
// import CloseIcon from 'mdi-react/CloseIcon';
// import MinusIcon from 'mdi-react/MinusIcon';
// import AutorenewIcon from 'mdi-react/AutorenewIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import {
  quoCreateConversation
} from '~/apiCalls/quoApi';
import formatDate from '~~/datetime_render/FormatDate';
import QuoDetail from '~~/quo-details/QuoDetail';
import DeliveredStatus from '~~/status_panel/DeliveredStatus';

const QuoPanel = ({ quo }) => {
  const history = useHistory();

  const quoChatClick = async (chatId, receiver, quoId) => {
    if (chatId) {
      history.push(`/c/${chatId}`);
    } else {
      const conversationId = await quoCreateConversation(receiver, quoId);
      history.push(`/c/${conversationId}`);
    }
  };

  return (
    <Card>
      <CardBody className="panel__body quo__body quo__body-accepted">
        <div className="panel__refresh"><LoadingIcon /></div>
        <div className="panel__top">
          <div className="quo__start">{formatDate(quo.date_created)}</div>
          <div className="quo__inbox">
            <Button
              outline
              className="control-btn quo__chat-click"
              color="primay"
              onClick={() => quoChatClick(quo.related_conversation, quo.receiver, quo.id)}
            >
              <i className="las la-comments"></i>
            </Button>
          </div>
          <div className="quo__more">
            <i className="las la-angle-right"></i>
          </div>
        </div>
        <QuoDetail quo={quo} />
        <DeliveredStatus quo={quo} isQuoPanel={true} />
        <Link className="quo__body__link" to={{ pathname: `/q/${quo.quo_id ? quo.quo_id : quo.id}`, state: { from: location.pathname } }}></Link>
      </CardBody>
    </Card>
  );
};
export default QuoPanel;

const quoLogoClassSwitch = (val) => {
  if (val < 10) {
    return "quo__badge-bl10";
  }
  if (val < 20) {
    return "quo__badge-bl20";
  }
  if (val < 50) {
    return "quo__badge-bl50";
  }
  if (val < 100) {
    return "quo__badge-bl100";
  }
  if (val < 200) {
    return "quo__badge-bl200";
  }
  if (val < 500) {
    return "quo__badge-bl500";
  }
  if (val > 499) {
    return "quo__badge-bl1000";
  }
  return "quo__badge-deal";
};

export default quoLogoClassSwitch;

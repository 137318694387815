import React from "react";
import { Link } from "react-router-dom";

export const UsernameRender = (username, isAnnoymous) => {
  if (isAnnoymous) {
    return username;
  }

  return (<Link to={`/p/${username}`}>{username}</Link>);
};

export default UsernameRender;

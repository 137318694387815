import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ProfileForm from './components/ProfileForm';
import getTransText from '~~/get-text/getTransText';

const EditProfile = () => (
  <Container>
    <Row>
      <Col md={0} lg={2}>
      </Col>
      <Col md={12} lg={8}>
        <h3 className="page-title">{getTransText("Edit Profile")}</h3>
      </Col>
      <Col md={0} lg={2}>
      </Col>
    </Row>
    <Row>
      <Col md={0} lg={2} xl={2}>
      </Col>
      <ProfileForm />
      <Col md={0} lg={2} xl={2}>
      </Col>
    </Row>
  </Container>
);

export default EditProfile;

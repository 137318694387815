import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  Card, CardBody
} from 'reactstrap';
import ChatQuoDetail from '~~/chat-quo-details/ChatQuoDetail';
import getTransText from '~~/get-text/getTransText';

const QuoPanel = ({
  quo,
}) => {
  const {
    langCode,
  } = useSelector(state => state.ui);
  const location = useLocation();

  return (
    <Card>
      <CardBody className="panel__body quo__body quo__body-timeout">
        <div className="chat__quo-action">
          <i className="las la-hourglass-end"></i>
          <div className="bold-text">{getTransText("Timeout")}</div>
        </div>
        <ChatQuoDetail quo={quo} />
        <div className="btn-toolbar">
          <Link to={{ pathname: `/q/${quo.id}`, state: { from: location.pathname } }}>
            <div className="btn btn-primary">{getTransText("View Details")}</div>
          </Link>
        </div>
      </CardBody>
    </Card>
  );
};
export default QuoPanel;

import { client, clientPublic } from '~/apiCalls/directusConfig';

// Balance API
export const isSupportedCountry = async () => {
  const res = await client.request(() => ({
    path: '/quo-payment/country-specs',
    method: "GET",
  }));
  return (res);
};

export const isSupportedCountryById = async (shortcode) => {
  const res = await client.request(() => ({
    path: `/quo-payment/country-specs/${shortcode}`,
    method: "GET",
  }));
  return (res);
};

export const getBalance = async () => {
  try {
    const res = await client.request(() => ({
      path: '/quo-payment/retrieve-balance',
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};

export const capabilities = async () => {
  try {
    const res = await client.request(() => ({
      path: '/quo-payment/capabilities',
      method: "GET",
    }));
    return (res.capabilities);
  } catch (err) {
    console.log("err", err);
    // // console.log(err);
  }
};

export const loginDashboard = async () => {
  try {
    const res = await client.request(() => ({
      path: '/quo-payment/login',
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};

export const accountLinkAccess = async () => {
  try {
    const res = await client.request(() => ({
      path: '/quo-payment/accountLink',
      method: "GET",
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};

export const payNow = async (meta) => {
  const config = {
    meta,
  };
  const res = await client.request(() => ({
    path: '/quo-payment/pay',
    method: "POST",
    body: JSON.stringify(config),
  }));
  return (res);
};


export const outreachPayNow = async (meta) => {
  const config = {
    meta,
  };
  const res = await client.request(() => ({
    path: '/quo-payment/outreach-pay',
    method: "POST",
    body: JSON.stringify(config),
  }));
  return (res);
};

export const getBalanceTransaction = async (start, end, starting_after = null) => {
  try {
    const config = {
      startTime: start,
      endTime: end,
      starting_after,
    };
    const res = await client.request(() => ({
      path: '/quo-payment/balance-transaction',
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};

export const getBalanceTransactionPayment = async (start, end) => {
  try {
    const config = {
      startTime: start,
      endTime: end,
    };
    const res = await client.request(() => ({
      path: '/quo-payment/balance-transaction-earning',
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};


export const getTransfer = async (transferId) => {
  try {
    const config = {
      transferId,
    };
    const res = await client.request(() => ({
      path: '/quo-payment/retrieve-transfer',
      method: "POST",
      body: JSON.stringify(config),
    }));
    return (res);
  } catch (err) {
    // // console.log(err);
  }
};

export const payoutsApi = async (credentials) => {
  const res = await client.request(() => ({
    path: '/quo-payment/payout',
    method: "POST",
    body: JSON.stringify(credentials),
  }));
  return (res);
};


export const isPaymentUnsettle = async () => {
  try {
    const res = await client.request(() => ({
      path: `/quo-payment/is-payment-unsettle`,
      method: "GET",
    }));
    return res;
  } catch (err) {
    // // console.log(err);
    return null;
  }
};


import React, { useState, useContext } from 'react';
import {
  Link,
} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import CloseIcon from 'mdi-react/CloseIcon';
import { searchMembers } from '~/apiCalls/userApi';
import AvatarRender from '~~/avatar_render';

const TopbarSearch = ({ setIsMobileSearchBarCollapsed }) => {
  const [myOptions, setMyOptions] = useState([]);
  const getDataFromAPI = async (text) => {
    if (text.length > 2) {
      const response = await searchMembers(text);
      if (response && Object.keys(response).length > 0) {
        const usernameOptions = response?.map((user) => {
          const a = {
            avatar: user.avatar,
            username: user.username,
            random_no: user.random_no,
          };
          return a;
        });
        setMyOptions(usernameOptions);
      }
    }
  };

  return (
    <Autocomplete
      autoComplete
      autoHighlight
      options={myOptions}
      getOptionLabel={(option) => option.username}
      renderOption={(option) => (
        <React.Fragment>
          <Link to={`/p/${option.username}`} className="topbar__search-result" onClick={setIsMobileSearchBarCollapsed ? ()=>setIsMobileSearchBarCollapsed(true) : ""}>
            <div
              style={{ cursor: "pointer" }}
              key={option.username}
            >
              <img
                className="topbar__avatar-img"
                src={`${AvatarRender(option.avatar, option.random_no)}`}
                alt={`"${option.username}"-avatar`}
              />
              {option.username}
            </div>
          </Link>
        </React.Fragment>
      )}
      renderInput={
        (params) => (
          <form className="topbar__search-wrap">
            <TextField
              {...params}
              onChange={e => getDataFromAPI(e.target.value)}
              className="topbar__search-field"
            />
          </form>
        )
      }
    />

  );
};
export default TopbarSearch;

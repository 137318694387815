import { DateTime } from "luxon";
import React, {
  useContext, useEffect, useMemo, useState
} from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import VideoImageThumbnail from 'react-video-thumbnail'; // use npm published version
import { addTokenToURL } from '~/apiCalls/authApi';
import {
  pickedFileFirstSubmission,
  pickedFileReSubmission,
  quoCreateConversation,
  getUploadedGalleryFile
} from '~/apiCalls/quoApi';
import { SocketContext } from "~/context/SocketContext";
import { apiCallBegan } from "~/store/api";
import {
  chatAddedMessage,
  chatAddMessagBegan
} from "~/store/chats";
import DnDTable from './components/DnDReactTable';

export const PhotoFormatter = ({ file }) => {
  const [src, setSrc] = useState();

  useEffect(() => {
    addTokenToURL(`${process.env.REACT_APP_API_URL}assets/${file.id}?width=335&height=250`).then((x) => { setSrc(x); });
  }, [file]);

  const renderThumbnailReturn = () => {
    if (file) {
      if (file.type.includes('video')) {
        return (
          <>
            <div className="products-list__img-wrap">
              <VideoImageThumbnail
                videoUrl={src}
                cors={false}
                renderThumbnail={true}
                snapshotAtTime={2}
              />
            </div>
          </>
        );
      }
      if (file.type.includes('image')) {
        return (
          <div className="products-list__img-wrap">
            <img
              className="profileContentImg"
              key={file?.id}
              src={src}
              alt={src}
            />
          </div>
        );
      }
      return (
        <div className="products-list__img-wrap">
          No preview
        </div>
      );
    }
  };


  return (
    <>
      {src && renderThumbnailReturn()}
    </>
  );
};

export const formatDate = (datetimeValue) => {
  const UTCTime = DateTime.fromISO(datetimeValue, { zone: "utc" });
  const SystemTime = UTCTime.setZone("system");
  return SystemTime.toFormat('yyyy LLL dd HH:mm');
};

const DragAndDropTable = ({
  quo,
  isFirstSubmission,
}) => {
  const dispatch = useDispatch();
  const { socket } = useContext(SocketContext);
  const {
    id,
  } = useSelector(state => state.user.userData);
  const columns = useMemo(
    () => [
      {
        id: 'id',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <div>
            <input type="checkbox" {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      {
        Header: 'Preview',
        accessor: 'preview',
        disableGlobalFilter: true,
      },
      {
        Header: 'File name',
        accessor: 'filename_download',
      },
      {
        Header: 'Size',
        accessor: 'filesize',
        disableGlobalFilter: true,
      },
      {
        Header: 'Type',
        accessor: 'type',
        disableGlobalFilter: true,
      },
      {
        Header: 'Date',
        accessor: 'uploaded_on',
        disableGlobalFilter: true,
      },
    ],
    [],
  );
  const [isValueLoaded, setIsValueLoaded] = useState(false);
  const [reactTableData, setReactTableData] = useState([]);
  const [reactTableHeader, setReactTableHeader] = useState(columns);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const history = useHistory();
  const { conversation_id } = useParams();




  const photoPicked = async (fileList) => {
    if (fileList.length > 0) {
      const axiosCallSent = isFirstSubmission ? pickedFileFirstSubmission : pickedFileReSubmission;
      dispatch(
        apiCallBegan({
          axiosCall: axiosCallSent,
          data: { quoId: quo.id, fileList },
          // socketEmit: { socket, event: "sendMessage" },
          onStart: chatAddMessagBegan.type,
          onSuccess: chatAddedMessage.type,
        })
      );
      const conversationId = await quoCreateConversation(quo.receiver, quo.id);
      history.push(`/c/${conversationId}`);
    }
  };

  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;

    // We'll even set a delay to simulate a server here
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      const startRow = pageSize * pageIndex;
      const endRow = startRow + pageSize;
      getUploadedGalleryFile(pageIndex + 1).then((res) => {
        const rowData = res?.data?.map((a) => ({
          id: a.id,
          preview: <PhotoFormatter file={a} />,
          filename_download: a.filename_download,
          filesize: `${Number(a.filesize / 1024 / 1024).toFixed(3)} KB`,
          type: a.type,
          uploaded_on: `${formatDate(a.uploaded_on)}`,
        }));
        setReactTableData(rowData);
        setIsValueLoaded(true);
        setPageCount(Math.ceil(res.meta[0].count / pageSize));
      });
    }
  }, []);

  return (
    <>
      <DnDTable isValueLoaded={isValueLoaded} pageCount={pageCount} fetchData={fetchData} reactTableHeader={reactTableHeader} reactTableData={reactTableData} setReactTableData={setReactTableData} photoPicked={photoPicked} />
    </>
  );
};

export default DragAndDropTable;


import {
  readItem,
  readItems,
} from '@directus/sdk';
import { client, clientPublic } from '~/apiCalls/directusConfig';



// Language API
export const getWebTextById = async (id, langCode) => {
  const langTextRes = await clientPublic.request(readItem("web_text", id, {
    fields: ['title', 'translations.*'],
    deep: {
      translations: {
        _filter: {
          languages_code: {
            _eq: langCode
          }
        }
      }
    }
  }));
  return (langTextRes);
};

export const getWebTextByTitle = async (title, langCode) => {
  const langTextRes2 = await clientPublic.request(readItems("web_text", {
    filter: {
      title: {
        _eq: title
      }
    },
    fields: ['translations.*'],
    deep: {
      translations: {
        _filter: {
          languages_code: {
            _eq: langCode
          }
        }
      }
    }
  }));
  return (langTextRes2);
};

export const getWebTextByGroup = async ({ group, langCode }) => {
  const langTextRes3 = await clientPublic.request(readItems("web_text", {
    filter: {
      group: {
        _eq: group
      }
    },
    fields: ['title', 'translations.web_text'],
    deep: {
      translations: {
        _filter: {
          languages_code: {
            _eq: langCode
          }
        }
      }
    }
  }));
  return (langTextRes3.data);
};

export const getWebTextByLang = async (langCode) => {
  const langTextRes4 = await clientPublic.request(readItems("web_text", {
    limit: 999,
    fields: ['title', 'translations.web_text'],
    deep: {
      translations: {
        _filter: {
          languages_code: {
            _eq: langCode
          }
        }
      }
    }
  }));
  return (langTextRes4);
};

/* eslint-disable */
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';

const ExamplePage = () => (
  <div className="verification__container quabal__container">
    <Container>
      <Row>
        <Col md="6">
          <Card>
            <CardBody>
              <div className="badge-icon">
                <span className="icon"><i className="las la-check"></i></span>
                <h5>Email Verification</h5>
                <p>Your email has been verified. Please <NavLink to="/log_in">Login</NavLink> to get started.</p>
              </div>
            </CardBody>
          </Card>
          <Link className="quobal__logo horizontal" to="/l" />
        </Col>
      </Row>
    </Container>
    <div className="rc-notification-notice rc-notification-fade-appear rc-notification-fade-appear-active rc-notification-fade full ltr-support rc-notification-notice-closable">
      <p>We use cookies and other technologies that are essential to provide you our services and site functionality, as described in our&nbsp;<a href="https://www.ebay.co.uk/help/policies/member-behaviour-policies/ebay-cookie-notice?id=4267">Cookie Notice</a>. If you agree and click &apos;Accept all&apos;, we will also allow&nbsp;<a href="https://www.ebay.co.uk/gdpr?vendorlist=true">third-party companies</a>, who we partner with, to store cookies on your device and use similar technologies to collect and use your personal data (e.g., IP address) for advertising personalisation, measurement and analytics, on and off our sites. You may decline consent by clicking &apos;Decline all&apos; or make granular choices by clicking Customise my choices&apos;. You may withdraw your consent at any time by visiting&nbsp;<a href="https://www.ebay.co.uk/gdpr">https://www.ebay.co.uk/gdpr</a>.</p>
      <p>Data processing purposes: Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.</p>
    </div>
  </div>
);

export default ExamplePage;

import {
  createItem,
  readItem,
  readItems,
  generateTwoFactorSecret,
  enableTwoFactor,
  disableTwoFactor,
  uploadFiles,
  updateUser,
  updateMe,
  aggregate,
} from '@directus/sdk';

import { client, clientPublic } from '~/apiCalls/directusConfig';



export const getCartInfoListApi = async (values) => {
  console.log("id", values);
  const res = await client.request(readItems("marketplace", {
    filter: {
      id: {
        _in: values,
      },
    },
    fields: ['*', 'thumbnail_list.product_thumbnail_id'],
  }));
  return (res);
};

export const marketplaceCheckoutApi = async (cart) => {
  console.log("cart", cart);
  const res = await client.request(() => ({
    path: `/quo-payment/marketplace-checkout`,
    method: "POST",
    body: JSON.stringify(cart),
    headers: {
      'Content-Type': 'application/json',
    },
  }));
  return (res);
};

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row
} from 'reactstrap';
import getTransText from '~~/get-text/getTransText';

const RegSuccess = () => {
  const { email } = useParams();

  return (
    <div className="verification__container quabal__container">
      <Container>
        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <div className="badge-icon">
                  <span className="icon"><i className="las la-check"></i></span>
                  <h5>{getTransText("Verify Your Email Address")}</h5>
                  <p>{getTransText("Your account cannot be used until your email address has been verified. To verify your email address, look for the verification email in your inbox and click the link in that email.")}</p>
                  <p>{getTransText("Email")}{email && `: ${email}`}</p>
                </div>
              </CardBody>
            </Card>
            <Link className="quobal__logo horizontal" to="/l" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default RegSuccess;

/* eslint-disable */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Col, Row, Button,
} from 'reactstrap';
import getTransText from '~~/get-text/getTransText';
import {
  accountLinkAccess,
} from '~/apiCalls/paymentApi';

const UnsettlePayment = ({ settle }) => {
  const {
    langCode,
  } = useSelector(state => state.ui);
  const LangCodeForCurrency = (e) => {
    switch (e) {
      case 'zh-TW':
        return 'zh-TW';
      case 'en-US':
        return 'en-US';
      case 'ja':
        return 'ja-JP';
      default:
        return 'en-US';
    }
  };

  const accountLinkClick = async () => {
    await accountLinkAccess().then((e) => {
      window.open(e.url);
    });
  };

  return (
    <>
      {settle?.has
        && (
          <Row className="unsettle__payment">
            <Col xs={12}>
              <p>{getTransText("You have")} <span className="bold-text">{new Intl.NumberFormat(LangCodeForCurrency(langCode), { style: 'currency', currency: 'usd' }).format(settle.amount)}</span> {getTransText("not yet received.")}</p>
              <p>{getTransText("Please")}<Button color="primary" outline className="btn-connect-stripe" onClick={() => accountLinkClick()}>{getTransText("Connect Stripe")}<i className="lab la-stripe-s"></i></Button>{getTransText("to receive your fund.")}</p>
            </Col>
          </Row>
        )}
    </>
  );
};

export default UnsettlePayment;

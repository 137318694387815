import '@brainhubeu/react-carousel/lib/style.css';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import {
  FieldArray, Form, Formik
} from "formik";
import React, {
  useEffect, useState
} from 'react';
import { NotificationContainer } from 'react-notifications';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import { getUploadedGalleryFile } from '~/apiCalls/quoApi';
import {
  getProfileSort,
} from '~/apiCalls/userApi';
import FormDropzone from '~~/FormDropzone';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';
import ModalParent from '~~/modal_parent/ModalParent';
import MediaList from './MediaList';


const MyUploadedGalleryContainer = () => {
  const [toggler, setToggler] = useState(false);
  const [files, setFiles] = useState([]);
  const {
    id,
  } = useSelector(state => state.user.userData);
  const [toggler2, setToggler2] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(null);
  const [originalSort, setOriginalSort] = useState();
  const [isValueLoaded, setIsValueLoaded] = useState(false);
  const [carouselImages, setCarouselImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isBottom, setIsBottom] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);




  const switchToggler = () => {
    setToggler(!toggler);
  };

  // const handleProfileUpdate = async (values) => {
  //   const forProfileSort = values.uploadObject.filter((a) => (a.profile === true));
  //   await sortForPersonalProfile(forProfileSort)
  //     .then(() => {
  //       NotificationManager.info(getTransText('Profile Updated'));
  //     })
  //     .catch(() => {
  //       NotificationManager.info(getTransText('Network Error'));
  //     });
  // };

  useScrollPosition(async ({ prevPos, currPos }) => {
    // todo cache the page height
    if (!isBottom && hasMore && (currPos.y * -1 + window.innerHeight)
      >= document.documentElement.scrollHeight) {
      setIsBottom(true);
      const res = await getUploadedGalleryFile(currentPage + 1);
      if (res.length > 0) {
        setFiles(files.concat(res.data));
        setCurrentPage(currentPage + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      // do my code here which includes loading more data from a webservice
    } else {
      setIsBottom(false);
    }
  });





  useEffect(async () => {
    let isCancelled = false;
    const filesResponse = await getUploadedGalleryFile(currentPage);
    if (!isCancelled) {
      setFiles(filesResponse.data);
    }
    await getProfileSort(id).then((res) => {
      if (!isCancelled) {
        if (res) {
          setOriginalSort(res);
        } else {
          setOriginalSort([]);
        }
      }
      setIsValueLoaded(true);
    });
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Col xs={12}>
      <ModalParent toggler={toggler} switchToggler={switchToggler}>
        <FormDropzone setFiles={setFiles} />
      </ModalParent>
      {!isValueLoaded
        ? (<LoadingLogo />)
        : (
          <>
            <NotificationContainer leaveTimeout={6000} />
            <Formik
              enableReinitialize
              initialValues={{
                uploadObject: originalSort.map((a) => ({ id: a.file_id, profile: true })),
              }}
              onSubmit={values => setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
              }, 500)}
              render={({ values, resetForm }) => (
                <Form>

                  <FieldArray
                    name="uploadObject"
                    render={arrayHelpers => (
                      <div className="medialist">
                        <MediaList files={files} resetForm={resetForm} setFiles={setFiles} values={values} arrayHelpers={arrayHelpers} toggler={toggler} setToggler={setToggler} />
                      </div>
                    )}
                  />
                </Form>
              )}
            />
          </>
        )}
    </Col>
  );
};

export default MyUploadedGalleryContainer;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getTransText from '~~/get-text/getTransText';

const RejectedStatus = ({
  quo,
  isButton = true,
}) => {
  const [friendInfo, setFriendInfo] = useState(null);
  const {
    id,
  } = useSelector(state => state.user.userData);
  const annoymous = {
    avatar: "e71e2b3f-4e6c-49cc-a22c-c65b1bcda1ae",
    username: getTransText("Anonymous"),
    random_no: quo.random_no,
  };
  useEffect(async () => {
    if ((quo.receiver === id)) {
      setFriendInfo(annoymous);
    } else {
      if (quo.receiver) {
        setFriendInfo({
          avatar: quo.avatar,
          username: quo.username,
          random_no: quo.random_no,
        });
      }
    }
  }, [quo.receiver]);

  return (
    <>
      <div className="quo__status">
        <div className="quo__status-notice">{getTransText("QUO.status.buyer.rejected")}</div>
      </div>
    </>
  );
};

export default RejectedStatus;

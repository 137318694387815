import {
  createItem,
  readItem,
  readItems,
  generateTwoFactorSecret,
  enableTwoFactor,
  disableTwoFactor,
  updateItem,
  uploadFiles,
  updateUser,
  updateMe,
  aggregate,
} from '@directus/sdk';

import { client, clientPublic } from '~/apiCalls/directusConfig';



export const createProductNameApi = async (values) => {
  const res = await client.request(createItem("marketplace", values));
  return (res);
};

export const countProductListApi = async () => {
  const res = await client.request(aggregate('marketplace', {
    aggregate: { count: '*' },
  }));
  return (res);
};
export const readProductListApi = async () => {
  const res = await client.request(readItems("marketplace", {
    limit: 20,
  }));
  return (res);
};

export const editProductApi = async ({ id, values }) => {
  const res = await client.request(updateItem("marketplace", id, values));
  return (res);
};

export const publishProductApi = async ({ id, values }) => {
  const res = await client.request(updateItem("marketplace", id, values));
  return (res);
};

export const readUserProductListApi = async (username) => {
  const res = await client.request(() => ({
    path: "/marketplace/get-productlist-by-username",
    method: "POST",
    body: JSON.stringify({ username }),
  }));
  return (res);
};

export const readProductApi = async (key) => {
  const res = await client.request(readItem("marketplace", key));
  return (res);
};

export const getProductFilesApi = async (key) => {
  console.log("getProductFilesApi", key);
  const res = await client.request(readItems('marketplace_product_files', {
    filter: {
      marketplace_product_id: {
        _eq: key,
      },
    },
  }));
  return (res);
};

export const getThumbnailsApi = async (key) => {
  const res = await client.request(readItems('marketplace_thumbnail', {
    filter: {
      marketplace_product_id: {
        _eq: key,
      },
    },
  }));
  return (res);
};


// action
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";

export const getActiveQuo = createAction("quos/getActiveQuo");
export const emittedSuccessPayment = createAction("quos/emittedSuccessPayment");
export const leftEmitPage = createAction("quos/leftEmitPage");


// reducer
const initialState = {
  total: 0,
  active: 0,
  deal: 0,
  emitterTrigger: false,
  emitterPayload: null,
};

const quosReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getActiveQuo.type, (state, action) => ({
      ...state,
      total: action.payload.total,
      active: action.payload.active,
      deal: action.payload.deal,
    }))
    .addCase(emittedSuccessPayment.type, (state, action) => ({
      ...state,
      emitterTrigger: action.payload.quo_activity_id,
      emitterPayload: action.payload,
    }))
    .addCase(leftEmitPage.type, (state, action) => ({
      ...state,
      emitterTrigger: false,
      emitterPayload: null,
    }));
});

export default quosReducer;
// export const getAuthStore = createSelector();

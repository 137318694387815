import { platform } from 'process';
import React, { useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  FormGroup,
  Label,
  Input,
  UncontrolledCollapse,
  Card,
  CardBody,
  Container,
  Row,
  Col
} from 'reactstrap';
import getTransText from '~~/get-text/getTransText';
import CheckBox from '~~/form/CheckBox';
import { createOutreachPreQuoAdmin } from '../../apiCalls/quoApi';

const PlaceQuo = () => {
  const {
    userData,
  } = useSelector(state => state.user);
  const [username, setUsername] = useState();
  const history = useHistory();
  const [isToggleCheckboxEnabled, setIsToggleCheckboxEnabled] = useState(false);

  const handleToggleCheckBox = () => {
    setIsToggleCheckboxEnabled(!isToggleCheckboxEnabled);
  };

  const onSubmit = async (values) => {
    const credentials = values;
    if (values?.instagram_username) {
      credentials.social_username.push({ ig_user: values.instagram_username });
    }
    if (values?.facebook_username) {
      credentials.social_username.push({ fb_user: values.facebook_username });
    }
    if (values?.twitter_username) {
      credentials.social_username.push({ x_user: values.twitter_username });
    }
    credentials.is_open_call = !values.is_target;
    const res = await createOutreachPreQuoAdmin(credentials);
  };

  const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);
  const mustBeNumber = value => (Number.isNaN(value) ? getTransText('Must be a number') : undefined);
  const minPrice = value => (value < 0.5 ? getTransText('At least $0.50 usd') : undefined);
  const maxPrice = value => (value > 10000 ? getTransText('No more than $10000 usd') : undefined);
  const isOpenCallInput = value => (value > 10000 ? getTransText('No more than $10000 usd') : undefined);


  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState('');

  const handleCheckboxChange = () => {
    setIsDisabled(!isDisabled);
  };

  const handleLogoSelect = (event) => {
    setSelectedLogo(event.target.value);
  };

  const logos = [
    { id: 'facebook', src: "/", name: 'Facebook' },
    { id: 'twitter', src: "/", name: 'Twitter' },
    { id: 'instagram', src: "/", name: 'Instagram' },
    // add more logos as needed
  ];



  return (
    <React.Fragment>
      <Container className="quo__admin__container">
        <Row>
          <Col md="10">
            <h3 className="page-title">Open call QUO admin</h3>
            <Card>
              <CardBody>
                {userData?.role === "e84fea53-b002-4001-a861-36d3fd654bb2" && (
                  <Form
                    onSubmit={onSubmit}
                    initialValues={{
                      title: "",
                      request: "",
                      price: "",
                    }}
                    validate={values => {
                      const errors = {};
                      if (!values.title) {
                        errors.title = getTransText('quoform.error.required.title');
                      }
                      if (!values.request) {
                        errors.request = getTransText("Please write your request");
                      } else if (!values.price) {
                        errors.price = getTransText('Please fill in price');
                        // eslint-disable-next-line 
                      }
                      if (isNaN(values.price)) {
                        errors.price = getTransText('Must be a number');
                      }
                      if (values.is_target && (!values.facebook_username && !values.instagram_username && !values.twitter_username)) {
                        errors.is_target = getTransText('quoform.error.socialmedia.atleastone');
                      }
                      return errors;
                    }}
                  >
                    {({ handleSubmit, submitting, values }) => (
                      <form className="form" onSubmit={handleSubmit}>
                        <FormGroup className="form__form-group">
                          <span className="form__form-group-label">{getTransText("quoform.title.title")}</span>
                          <Field
                            name="title"
                          >
                            {({ input, meta }) => (
                              <div className="form__form-group-field">
                                <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                                  <Input
                                    {...input}
                                    name="title"
                                    type="text"
                                    placeholder={getTransText("quoform.title.placeholder")}
                                    className="input-without-border-radius"
                                  />
                                  {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                                </div>
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                        <FormGroup className="form__form-group">
                          <span className="form__form-group-label">{getTransText("Your Request")}</span>
                          <Field
                            name="request"
                          >
                            {({ input, meta }) => (
                              <div className="form__form-group-field">
                                <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                                  <ReactQuill
                                    {...input}
                                    formats={[]}
                                    modules={{
                                      toolbar: []
                                    }}
                                    name="request"
                                    type="textarea"
                                    placeholder={getTransText("I would like...")}
                                  />
                                  {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                                </div>
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                        <FormGroup className="form__form-group form__form-group-price">
                          <span className="form__form-group-label">{getTransText("Request Price")}</span>
                          <Field
                            name="price"
                            validate={composeValidators(mustBeNumber, minPrice, maxPrice)}
                          >
                            {({ input, meta }) => (
                              <div className="form__form-group-field">
                                <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                                  <Input
                                    {...input}
                                    name="price"
                                    type="text"
                                    placeholder={getTransText("USD")}
                                    className="input-without-border-radius"
                                  />
                                  {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                                </div>
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                        <ButtonToolbar className="form__button-toolbar">
                          <Button color="primary" type="submit"><i className="icon-quo"></i> {getTransText("quoform.button.place")}</Button>
                        </ButtonToolbar>
                      </form>
                    )}
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};


export default PlaceQuo;

import React from 'react';
import AuthCode from 'react-auth-code-input';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import {
  Button, ButtonGroup,
  ButtonToolbar, FormGroup
} from 'reactstrap';
import { disableUserTfa } from '~/apiCalls/userApi';
import { apiCallBegan } from '~/store/api';
import { userProfileUpdateError, userTfaDisable } from '~/store/user';
import getTransText from '~~/get-text/getTransText';

const DisableTfaForm = () => {
  const dispatch = useDispatch();
  const onSubmit = async (values) => {
    dispatch(apiCallBegan({
      axiosCall: disableUserTfa,
      data: values,
      onSuccess: userTfaDisable.type,
      onError: userProfileUpdateError.type,
    }));
  };


  return (
    <React.Fragment>
      <Form
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting, values }) => (
          <div>
            <form className="form" onSubmit={handleSubmit}>
              <FormGroup className="form__form-group">
                <span className="form__form-group-label">{getTransText("Enter the OTP to disable 2FA")}</span>
                <div className="form__form-group-field">
                  <Field
                    name="otp"
                  >
                    {({ input, meta }) => (
                      <div>
                        <AuthCode {...input} allowedCharacters="numeric" containerClassName="otp__container" inputClassName="otp__input" />
                      </div>
                    )}
                  </Field>
                </div>
              </FormGroup>
              <ButtonToolbar className="btn-toolbar--center">
                <Button color="primary" type="submit">{getTransText("Done")}</Button>
              </ButtonToolbar>
            </form>
          </div>
        )}
      </Form>
    </React.Fragment>
  );
};


export default DisableTfaForm;


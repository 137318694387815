import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import ProfileTimeLine from './ProfileTimeLine';
import {
  TabContent, TabPane, Col, Card, CardBody,
} from 'reactstrap';
import QuoForm from '~~/quo_form/QuoForm';
import QuoFormMe from '~~/quo_form/QuoForm_me';
import ProfileContent from './ProfileContent';
// import showResults from './Show';
// import ProfileSettings from './ProfileSettings';
import Marketplace from '../../Marketplace/index';

const ProfileTabs = () => {
  const {
    profileTab,
    userInfo,
  } = useSelector(state => state.profile);
  return (
    <>
      <TabContent activeTab={profileTab}>
        <TabPane tabId="1">
          <ProfileContent />
        </TabPane>
        <TabPane tabId="2">
          <Marketplace />
        </TabPane>
        <TabPane tabId="3">
          <Card className="quo__card">
            <CardBody>
              <QuoForm friendInfo={userInfo} />
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="4">
          Link to text
        </TabPane>
      </TabContent>

    </>
  );
};

export default ProfileTabs;
